import config from 'configs/generalConfig.json'

const DEV_SCHEMES = [
  'multiDev',
  'multiDev2',
  'multiDev3',
  'multiDev4',
  'multiDevPersonal',
  'multiDevPersonal2',
  'multiDevPersonal3',
  'multiDevPersonal4',
] as const
export type DevScheme = typeof DEV_SCHEMES[number]
export const isDevScheme = (x: any): x is DevScheme => DEV_SCHEMES.includes(x)

const PROD_SCHEMES = [
  'multi',
  'multiAlpha',
] as const
export type ProdScheme = typeof PROD_SCHEMES[number]
export const isProdScheme = (x: any): x is ProdScheme =>
  PROD_SCHEMES.includes(x)

export type Scheme = DevScheme | ProdScheme
export const isScheme = (x: any): x is Scheme =>
  isProdScheme(x) || isDevScheme(x)

export const defaultScheme: Scheme = (() => {
  if (isScheme(config.scheme)) {
    return config.scheme
  } else {
    return 'multi'
  }
})()

export const schemesList: readonly Scheme[] = config.isProd
  ? PROD_SCHEMES
  : DEV_SCHEMES

/**
 * Some older macOS clients send up invalid scheme strings. This function is here for
 * backwards compatibility.
 * @param potentialScheme Anything, but probably a string you think is a Scheme—or close to a scheme.
 * @returns A Scheme if we can confidently figure out what was intended.
 */
export const maybeTransformToScheme = (
  potentialScheme: String
): Scheme | undefined => {
  return schemesList.find((x) => {
    return x.toLowerCase() === potentialScheme.toLowerCase()
  })
}
