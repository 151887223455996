import React from 'react'
import { useHistory } from 'react-router'
import { SetupPage } from '../SetupPage'

import { useAsync } from 'react-async-hook'
import { createAccountAndJoinTeam } from '../utils/createAccountAndJoinTeam'
import Error from '../Error'
import { PageCategory, usePageTracking } from 'utilities/analytics'
import SpinnerOverlay from 'components/UI/Spinner'

const TeamInvite: React.FC = () => {
  const history = useHistory()

  usePageTracking(PageCategory.onboarding, 'Team Join', { source: 'Invite' })
  const createAccountJoinTeamFromInviteLink = useAsync(async () => {
    await createAccountAndJoinTeam()
    history.push('/setup/team/post-create')
  }, [])

  return (
    <SetupPage title='Join an existing team'>
      <>
        {createAccountJoinTeamFromInviteLink.loading && (
          <SpinnerOverlay darkMode>Creating your account...</SpinnerOverlay>
        )}
        {createAccountJoinTeamFromInviteLink.error && (
          <Error
            headline='Something went wrong joining the team from your invite link.'
            errorMsg={createAccountJoinTeamFromInviteLink.error.message}
          />
        )}
      </>
    </SetupPage>
  )
}

export default TeamInvite
