import React, { useEffect } from 'react'
import { useAuth } from 'components/AuthProvider'
import { useHistory } from 'react-router-dom'
import Button from 'brand/Button'
import { SetupPage } from 'components/Setup/SetupPage'
import { HeaderWithAppIcon } from 'components/UI/HeaderWithAppIcon/HeaderWithAppIcon'

const NoAccount: React.FC = () => {
  const { user, logOut } = useAuth()
  const history = useHistory()

  useEffect(() => {
    if (user) {
      // if appUser exists, then they have an account and shouldn't be here
      history.push('/launch')
    }
  }, [history, user])

  /**
   * @param path path to push to history
   */
  const logOutAndRedirect = async (path: string) => {
    await logOut()
    history.push(path)
  }

  return (
    <SetupPage title="Oops, looks like you don't have an account">
      <section>
        <HeaderWithAppIcon
          title='Oops'
          message="it seems you don't have an account with us yet..."
        />
        <Button
          darkMode
          fluid
          onClick={() => {
            logOutAndRedirect('/signup')
          }}
        >
          Create a new account
        </Button>
        <Button
          fluid
          kind='secondary'
          onClick={() => {
            logOutAndRedirect('/login')
          }}
        >
          Try login again
        </Button>
      </section>
    </SetupPage>
  )
}

export default NoAccount
