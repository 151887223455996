import { DocumentData, DocumentReference } from 'firebase/firestore'
import { MightHaveId } from './MightHaveId'

import { safePrimitiveGet, safeDateGet, safeArrayRefGet } from './utils'

export enum Action {
  Play = 'PLAY',
  Pause = 'PAUSE',
  Seek = 'SEEK',
  Next = 'NEXT',
  Previous = 'PREVIOUS',
}

export interface QueueItem {
  id: string
  url: string
  thumbnail: string
  duration: number
  title: string
}

export interface RoomMedia extends MightHaveId {
  action: Action
  created?: Date
  createdBy: DocumentReference
  duration: number
  isLive: boolean
  isLooping?: boolean
  nextPageToken?: string
  playlist?: string
  playlistName?: string
  prevPageToken?: string
  provider: string
  queue?: DocumentReference[]
  thumbnail: string
  time: number
  title: string
  url: string
}

export function getCalculatedMediaTime(roomMedia: RoomMedia) {
  switch (roomMedia.action) {
    case Action.Pause:
      return roomMedia.time
    default: {
      let diff = 0
      if (roomMedia.created) {
        const startTime = roomMedia.created.getTime()
        diff = (Date.now() - startTime) / 1000
      }
      return roomMedia.time + diff
    }
  }
}

export const createRoomMedia = (
  data: DocumentData | undefined,
  id: string | undefined
): RoomMedia | null => {
  if (!data) {
    return null
  }

  try {
    const newMedia: RoomMedia = {
      ID: id, // should always be set

      action: safePrimitiveGet(data, 'action', 'string'),
      created: safeDateGet(data, 'created'),
      createdBy: data.createdBy,
      duration: safePrimitiveGet(data, 'duration', 'number'),
      isLive: safePrimitiveGet(data, 'isLive', 'boolean'),
      isLooping: safePrimitiveGet(data, 'isLooping', 'boolean'),
      nextPageToken: safePrimitiveGet(data, 'nextPageToken', 'string'),
      playlist: safePrimitiveGet(data, 'playlist', 'string'),
      prevPageToken: safePrimitiveGet(data, 'prevPageToken', 'string'),
      provider: safePrimitiveGet(data, 'provider', 'string'),
      queue: safeArrayRefGet(data, 'queue'),
      thumbnail: safePrimitiveGet(data, 'thumbnail', 'string'),
      time: safePrimitiveGet(data, 'time', 'number'),
      title: safePrimitiveGet(data, 'title', 'string'),
      url: safePrimitiveGet(data, 'url', 'string'),
    }
    return newMedia
  } catch {
    return null
  }
}
