import { browserName } from 'react-device-detect'
import { functions } from 'utilities'

export enum ScreenshareAttemptResult {
  NO_CLIENT = 'no client',
  SYSTEM_PERMISSIONS_ERROR = 'system permission error',
  CANCELLED = 'cancelled',
  UNKNOWN = 'unknown',
}

export type ScreenshareModalContent = {
  title: string
  buttonTitle: string
  message: string
}

export function getScreenshareError(
  errorMessage?: string
): ScreenshareAttemptResult {
  // const errorMessage: string | undefined = error.message
  // Unfortunately, the error messages reported by different browsers vary.
  // The messages here are based on trying different scenarios by hand in Chrome and Safari
  // and are not future-proof.
  if (errorMessage?.includes('Permission denied by system')) {
    // Chrome -- the user tried to share their screen, but Chrome doesn't have
    // permission to share the screen at a system level (ie Preferences on macOS)
    return ScreenshareAttemptResult.SYSTEM_PERMISSIONS_ERROR
  } else if (errorMessage?.includes('Permission denied')) {
    // Chrome -- the user was prompted to pick a window to share, but closed the prompt
    // with the cancel button
    return ScreenshareAttemptResult.CANCELLED
  } else if (
    errorMessage?.includes(
      'The request is not allowed by the user agent or the platform in the current context'
    )
  ) {
    // Safari -- the user was actively asked permission to share the screen, but denied permission
    return ScreenshareAttemptResult.CANCELLED
  } else {
    // Unknown
    return ScreenshareAttemptResult.UNKNOWN
  }
}

export function generateModalContent(
  errorType: ScreenshareAttemptResult | undefined
): ScreenshareModalContent | undefined {
  if (!errorType) {
    return undefined
  }

  const browsername = browserName || 'your browser'
  const defaultError = {
    title: 'Error',
    message: 'An unknown error occurred while trying to screenshare.',
    buttonTitle: 'Okay',
  }

  switch (errorType) {
    case ScreenshareAttemptResult.SYSTEM_PERMISSIONS_ERROR:
      return {
        title: 'Enable permissions to share screen',
        message: `To share your screen, give ${browsername} Screen Recording permission in System Preferences.`,
        buttonTitle: 'Done',
      }
    case ScreenshareAttemptResult.CANCELLED:
      // The user cancelled the prompt -- we don't need to show anything
      return undefined
    case ScreenshareAttemptResult.NO_CLIENT:
      // This should *not* happen -- we should always have an Agora client in-call
      return defaultError
    default:
      return defaultError
  }
}

export function getUserNames(users: functions.PublicUser[]): string {
  if (users.length === 0) return ''

  const firstUserName = users[0]?.firstName
  const secondUserName = users[1]?.firstName

  if (users.length === 1) {
    return firstUserName
  } else if (users.length === 2) {
    return `${firstUserName} and ${secondUserName}`
  } else {
    return `${firstUserName}, ${secondUserName}, and ${users.length - 2} others`
  }
}

/** Transforms a name into initials up to 2 letters
 *
 * @param {string} name
 * @return {string} up to 2 letters representing initials
 */
export function getInitials(name = ''): string {
  return name
    .split(' ')
    .slice(0, 2) // use max the first 2 words
    .map((part) => {
      const chars = Array.from(part) // cast to array to elegantly handle unicode chars like emojis
      return chars[0] // use first character
    })
    .join('')
}

/** Returns full name from first and last name
 *
 * @param {string} [firstName]
 * @param {string} [lastName]
 * @return {string} full name
 */
export function getFullName(firstName = '', lastName = ''): string {
  return `${firstName} ${lastName}`.trim()
}

/**
 * In order to optimally pack circular avatars into a rectangle,
 * compute the maximum diameter of each avatar subject to both height and width constraints of the rectangle
 * This function is an implementation of https://math.stackexchange.com/a/2570649
 * @param x the container width in px
 * @param y the container height in px
 * @param n the number of avatars to fit into the given area
 * @param gap optional: the spacing between avatars in px
 * @returns the maximun diameter of an avatar
 */
export const calcMaxAvatarDiameter = (
  x: number,
  y: number,
  n: number,
  gap = 0
): number => {
  // Compute number of rows and columns, and cell size
  const ratio = x / y
  const nColsFloat = Math.sqrt(n * ratio)
  const nRowsFloat = Math.sqrt(n / ratio)

  // Find best option filling the whole height
  let maxRowsHeightConstrained = Math.ceil(nRowsFloat)
  let maxColsHeightConstrained = Math.ceil(n / maxRowsHeightConstrained)
  while (maxRowsHeightConstrained * ratio < maxColsHeightConstrained) {
    maxRowsHeightConstrained++
    maxColsHeightConstrained = Math.ceil(n / maxRowsHeightConstrained)
  }

  // maxAvatarDiameterHeightConstrained is the max diameter (max height) that a given avatar can be
  // taking into account only the container height
  const maxAvatarDiameterHeightConstrained = y / maxRowsHeightConstrained

  // Find best option filling the whole width
  let maxColsWidthConstrained = Math.ceil(nColsFloat)
  let maxRowsWidthConstrained = Math.ceil(n / maxColsWidthConstrained)
  while (maxColsWidthConstrained < maxRowsWidthConstrained * ratio) {
    maxColsWidthConstrained++
    maxRowsWidthConstrained = Math.ceil(n / maxColsWidthConstrained)
  }

  // maxAvatarDiameterWidthConstrained is the max diameter (max width) that a given avatar can be
  // taking into account only the container width
  const maxAvatarDiameterWidthConstrained = x / maxColsWidthConstrained

  // Find the best values
  // given that we know both cell sizes will fit (since in each of the height and width constraints, we calculate the other axis necessary to fit all n participants)
  // pick the larger avatar diamater
  let maxAvatarDiameter
  if (maxAvatarDiameterHeightConstrained < maxAvatarDiameterWidthConstrained) {
    maxAvatarDiameter = maxAvatarDiameterWidthConstrained
  } else {
    maxAvatarDiameter = maxAvatarDiameterHeightConstrained
  }

  // subtract the gap space between avatars from the maxAvatarDiameter
  maxAvatarDiameter -= gap

  return maxAvatarDiameter
}
