import { getAuth } from 'firebase/auth'
import {
  DatabaseReference,
  DataSnapshot,
  getDatabase,
  goOffline,
  goOnline,
  increment,
  onDisconnect,
  onValue,
  ref,
  serverTimestamp,
  set,
  Unsubscribe,
  update,
} from 'firebase/database'
import { ErrorWithCause } from 'pony-cause'
import * as Sentry from 'sentry'
import { v4 as uuidv4 } from 'uuid'
import { createSession } from './functions'



export type SessionID = string

/** The originator of a Connectivity change. Either Client or Server. */
export enum SessionConnectionStatusChangeSource {
  Client = 'CLIENT', // The client explicitly updated the online status
  Server = 'SERVER' // The server updated the online status
}

/** A partial enum of status reasons this client uses. */
export enum SessionConnectionStatusChangeReason {
  ClientGoingOnline = 'CLIENT_GOING_ONLINE',
  ClientGoingOffline = 'CLIENT_GOING_OFFLINE',
  ServerSideDisconnectionDetected = 'SERVER_SIDE_DISCONNECTION_DETECTED',
}

/** Represents the online status of a session in the Realtime Database
 * @property {userID} - UserID of the authenticated user - may be registered or anonymous
 * @property {isOnline} - The sessions' "connectivity"
 * @property {source} - A string representing 'who' changed the connectivity (client, direct v. server, batch)
 * @property {reason} - Reason for the current connectivity
 * @property {timestamp} - Last time the record was updated.
 * @property {version} – An ordinal number representing the order which changes were received.
 */
export type RealtimeSessionRecord = {
  userID: string
  isOnline: boolean
  source: SessionConnectionStatusChangeSource
  reason: SessionConnectionStatusChangeReason
  timestamp: ReturnType<typeof serverTimestamp>
  version: ReturnType<typeof increment>
}

// Return a Realtime Session node representing an online session.
function generateSessionConnected(userID: string): RealtimeSessionRecord {

  return {
    userID: userID,
    isOnline: true,
    source: SessionConnectionStatusChangeSource.Client,
    reason: SessionConnectionStatusChangeReason.ClientGoingOnline,
    timestamp: serverTimestamp(),
    version: increment(1)
  }
}

// Return an update of an "online" record that marks the session as offline
// (e.g. the server detects a loss of connectivity and commits these changes.)
function generateSessionDisconnectedUpdates(userID: string) {
  return {
    userID,
    isOnline: false,
    // Although we are preparing these values on the client, the server
    // is where the change is committed from.
    source: SessionConnectionStatusChangeSource.Server,
    reason: SessionConnectionStatusChangeReason.ServerSideDisconnectionDetected,
    timestamp: serverTimestamp(),
    version: increment(1)
  }
}

// Return an update of an "online" record that marks the session as offline
// (e.g. the server detects a loss of connectivity and commits these changes.)
function generateKeepAliveUpdate() {
  return {
    isOnline: true,
    source: SessionConnectionStatusChangeSource.Client,
    reason: SessionConnectionStatusChangeReason.ClientGoingOnline,
    timestamp: serverTimestamp(),
    version: increment(1)
  }
}

// Legacy "Presence" implementation
let initialized = false
let unsubscribeConnectedObserver: Unsubscribe | undefined
let unsubscribeSessionObserver: Unsubscribe | undefined

// Reified Sessions
export type SessionStartedResult = {
  sessionID?: string
  presenceToken?: string
}

// If we ever see the server setting us offline, emit a 'keep-alive' update.
async function handleSessionNodeChange(snap: DataSnapshot) {
  const sessionNode = snap.val()
  console.debug("Realtime session node changed", sessionNode)
  if (sessionNode.source === SessionConnectionStatusChangeSource.Server
    && sessionNode.isOnline === false) {
    console.debug("Realtime session marked as offline by server, emitting keep-alive")
    const keepAlive = generateKeepAliveUpdate()
    await update(snap.ref, keepAlive)
  }
}

/** Initialize a Connectivity/Presence Session, then return the resulting identifier.
 *  When reified sessions are enabled, this is a session ID
 *  When using legacy "Presence", this is a token ID
 */
export const startCallPresence = async (useReifiedSessions: boolean): Promise<SessionStartedResult> => {
  const resultValues: SessionStartedResult = {}
  if (unsubscribeConnectedObserver) {
    unsubscribeConnectedObserver()
    unsubscribeConnectedObserver = undefined
    initialized = false
    Sentry.warning('startCallPresence called when already running')
  }

  const currentAuthUser = getAuth().currentUser
  if (!currentAuthUser) {
    throw new Error("Can't enable presence since there is no currentUser available")
  }

  // Fetch the current user's ID from Firebase Authentication.
  const userID = currentAuthUser.uid

  // Guard against registered access if reified sessions are not enabled
  if (!useReifiedSessions && !currentAuthUser.isAnonymous) {
    // This is not supported because we would show that user as online in every one else's dock and that
    // won't work with the web client because it doesn't support the conversation invites, emojis, etc
    throw new Error('Presence for non anoymous users is not supported')
  }

  // Create a reference to this user's specific status node.
  // This is where we will store data about being online/offline.
  const database = getDatabase()

  let sessionRef: DatabaseReference
  let prepareDisconnect: () => Promise<void>
  let observeSessionNode: () => Promise<void>

  let onlineEvent: Record<string, unknown>

  if (useReifiedSessions) {
    const sessionID = await createSession({ applicationID: 'web' })
    resultValues.sessionID = sessionID

    sessionRef = ref(database, '/sessions/' + sessionID)
    onlineEvent = generateSessionConnected(userID)
    const disconnectProps = generateSessionDisconnectedUpdates(userID)
    prepareDisconnect = async () => {
      onDisconnect(sessionRef).update(disconnectProps)
    }
    observeSessionNode = async () => {
      unsubscribeSessionObserver = onValue(sessionRef, handleSessionNodeChange)
    }
  } else {
    // Legacy Presence/Status
    sessionRef = ref(database, '/status/' + userID)

    // The user might create a new account or log in while in the guest call.
    // That means the userID will change, so the Realtime Database rule of having the logged in userID
    // matching the status/{userID} path won't be true.
    // In order to allow that write to work anyway, we pass this token.
    // This token works as a password to be able to modify the status even if we are not the logged in user anymore
    const presenceToken = uuidv4()
    resultValues.presenceToken = presenceToken

    // We'll create two constants which we will write to
    // the Realtime database when this device is offline
    // or online.
    prepareDisconnect = async () => {
      return onDisconnect(sessionRef).set({
        status: false,
        last_changed: serverTimestamp(),
        origin: 'web',
        token: presenceToken
      })
    }

    onlineEvent = {
      status: true,
      last_changed: serverTimestamp(),
      origin: 'web',
      token: presenceToken,
    }
  }

  // Create a reference to the special '.info/connected' path in
  // Realtime Database. This path returns `true` when connected
  // and `false` when disconnected.
  const connectedRef = ref(database, '.info/connected')


  // We ensure we go online so the Realtime Database can connect to servers
  goOnline(database)

  // We want to wait for the first time we can write to the Realtime Database
  return new Promise<SessionStartedResult>((resolve, reject) => {
    if (unsubscribeConnectedObserver) {
      unsubscribeConnectedObserver()
      initialized = false
      Sentry.warning('Presence was initialized concurrently')
    }

    unsubscribeConnectedObserver = onValue(
      connectedRef,
      async (snapshot) => {
        try {
          // If we're not currently connected, don't do anything.
          if (!snapshot.val()) {
            return
          }

          await prepareDisconnect()
          await set(sessionRef, onlineEvent)
          if (observeSessionNode) observeSessionNode()

          if (!initialized) {
            initialized = true
            console.debug("Connected to Realtime DB: ", onlineEvent)
            resolve(resultValues)
          }
        } catch (error) {
          if (initialized) {
            Sentry.error(new ErrorWithCause('Error while setting presence up again', { cause: error }))
            return
          }

          if (unsubscribeConnectedObserver) {
            unsubscribeConnectedObserver()
          }

          reject(new ErrorWithCause('Error while initializing presence', { cause: error }))
        }
      },
      (error) => {
        unsubscribeConnectedObserver = undefined

        if (initialized) {
          Sentry.error(new ErrorWithCause('Error checking connected state for presence', { cause: error }))
          return
        }

        reject(new ErrorWithCause('Error checking connected state while initializing presence', { cause: error }))
      },
    )
  })
}

export const stopCallPresence = async (): Promise<void> => {
  initialized = false

  if (unsubscribeSessionObserver) {
    unsubscribeSessionObserver()
    unsubscribeSessionObserver = undefined
  }

  if (unsubscribeConnectedObserver) {
    unsubscribeConnectedObserver()
    unsubscribeConnectedObserver = undefined
  }

  goOffline(getDatabase())
}
