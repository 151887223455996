import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  getAuth,
  GoogleAuthProvider,
  signInWithCredential,
  signInWithEmailLink,
  User as AuthUser,
  UserCredential,
} from 'firebase/auth'
import { Link, Redirect } from 'react-router-dom'

import {
  getGoogleDataAndUpdateSetupData,
  getInviteData,
  getUserProfileData,
  setSetupData,
  setUnverifiedUserFullname,
} from './utils/SetupDataHandler'
import { isProbablyMobile, isProbablyNotMacOS } from 'utilities/browserUtil'
import { useAuth, verifyHasAppUser } from 'components/AuthProvider'
import { useQuery } from 'utilities/locationHooks'

import SpinnerOverlay from 'components/UI/Spinner'
import ErrorComponent from './Error'
import { SetupPage } from './SetupPage'
import GetStarted from './GetStarted/GetStarted'
import { EventTypes, PageCategory, page, track } from 'utilities/analytics'
import { getFirebaseWebAPIBaseUrl, GoogleLoginResult } from 'utilities'
import config from 'configs/generalConfig.json'
import { HeaderWithAppIcon } from 'components/UI/HeaderWithAppIcon/HeaderWithAppIcon'
import {
  INVALID_ACTION_CODE,
  NO_EMAIL_FROM_EMAIL_AUTH_LINK,
} from './GetStarted/getErrorMessage'
import { FirebaseError } from 'firebase/app'
import { LocationDescriptor } from 'history'

/** Following email-auth, persist the users' authType as 'email' in SetupData */
const persistEmailSetupData = (cred?: UserCredential) => {
  if (cred) setSetupData({ profile: { authType: 'email' } })
}

/**  Used by postAuthRedirect; When a user has successfully authenticated, use provided metadata to determine where the user should go next */
async function chooseRedirectTarget(): Promise<LocationDescriptor> {
  // after a user has authed in signup flow

  // likely desktop users who are not macOS should proceed to windows waitlist
  if (!isProbablyMobile() && isProbablyNotMacOS()) {
    return '/setup/waitlist'
  }

  // This includes the normal case but also likely mobile users that should proceed to setup 
  // (setup to check for mobile users and conditionally email download link)
  return '/setup/team'
}

const SignUpPage: React.FC = () => {
  const { auth, user, isAuthAndAccountInitialized, isLoggedInWithAccount } =
    useAuth()

  const query = useQuery()
  const inviteData = getInviteData()
  const signupType = inviteData ? 'joiner' : 'creator'
  const conflictingUserEmail = query.get('useremail')
  const queryReferralID = query.get('r_id')

  // Sep 20, 2023: an experiment - we have this parameter to indicate whether our framer /newsletter page auto-redirected to /signup
  // we want to determine whether the /newsletter page led to more clicks on the auth with google or auth with email buttons
  const queryAutoRedirected = query.get('redirected')

  const [loading, setLoading] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [redirectUrl, setRedirectUrl] = useState<LocationDescriptor>()
  const [error, setError] = useState<string>()

  const signupPageContent = useMemo((): { header: string; caption: string } => {
    if (inviteData?.teamName) {
      return {
        header: `Join ${inviteData.teamName}`,
        caption: `Multi is a macOS app that makes collaborating with teammates effortless. It makes any app multiplayer.`,
      }
    }

    return {
      header: 'Sign up for Multi',
      caption: `Multi is a macOS app that makes collaborating with teammates effortless. It makes any app multiplayer.`,
    }
  }, [inviteData?.teamName])

  // Following authentication, redirect the user to the appropriate page.
  const postAuthRedirect = useCallback(
    async (cred: UserCredential | AuthUser | undefined) => {
      if (!cred) {
        // Authentication failed, abort redirection
        return
      }

      // Determine where the user goes next and invoke a redirect.
      const target = await chooseRedirectTarget()
      setRedirectUrl(target)
    },
    []
  )

  // analytics
  useEffect(() => {
    const pageProperties = {
      utm_source: query.get('utm_source'),
      utm_medium: query.get('utm_medium'),
      utm_campaign: query.get('utm_campaign'),
      source: query.get('source'),
      type: signupType,
      redirected: query.get('redirected'),
      referral_id: query.get('r_id'),
    }
    page(PageCategory.onboarding, 'Signup', pageProperties)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only call on initial page load
  }, [])

  useEffect(() => {
    // Detect when a user is attempting to sign-up, have been authenticated,
    // have no account, but somehow missed the original transition from
    //  /signup -> /setup/team
    // this can happen if a user auths to signup, but doesn't complete the flow and goes back to /signup later
    const userProfile = getUserProfileData()
    if (
      isAuthAndAccountInitialized &&
      auth &&
      !auth.isAnonymous &&
      auth.email &&
      !user &&
      userProfile?.authType &&
      userProfile.fullname &&
      typeof redirectUrl === 'undefined' &&

      // Email sent is a dead end for the webapp. We don't want to update this tab because that might be
      // confusing for the user (why do I have this tab in a state I didn't see) and also because
      // it can cause races to the backend if tabs actively start invoking non idempotent services
      // on the backend, like when creating an account from a call link
      !emailSent
    ) {
      postAuthRedirect(auth)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthAndAccountInitialized, auth, user, redirectUrl, emailSent, query])

  useEffect(() => {
    // Check the other comment on emailSent about why it is necessary to not to redirect when in that state
    if (
      isAuthAndAccountInitialized && 
      isLoggedInWithAccount && 
      user && 
      !user?.isGuest && 
      !emailSent
      ) {
      setRedirectUrl('/launch')
    }
  }, [isAuthAndAccountInitialized, isLoggedInWithAccount, user, emailSent])

  // analytics
  useEffect(() => {
    trackGoogleAdData()

    /** Inspect the browsers' query parameters and forward any UTM variables
     *  for later use. */
    function trackGoogleAdData() {
      // if have complete google ad data, log an analytics event and store it for account creation/team creation later
      const utm_source = query.get('utm_source')
      const utm_medium = query.get('utm_medium')
      const utm_campaign = query.get('utm_campaign')

      if (utm_campaign && utm_medium && utm_source) {
        const googleAdData = {
          utm_source,
          utm_medium,
          utm_campaign,
        }
        setSetupData({ googleAdData })
      }
    }
  }, [query])

  const signUpWithGoogle = useCallback(async () => {
    try {
      track(EventTypes.signupGoogleAuthClicked, {
        redirected: queryAutoRedirected,
      })
    } catch (error) {
      console.warn('Unable to log auth button click')
    }
    const clientID = config.webAppClientID

    const client = google.accounts.oauth2.initCodeClient({
      client_id: clientID,
      scope:
        'openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar.readonly',
      ux_mode: 'redirect',
      redirect_uri: `${getFirebaseWebAPIBaseUrl()}/api/v1/google/auth`,
    })
    client.requestCode()
  }, [queryAutoRedirected])

  const emailSentCallback = useCallback(async () => {
    setEmailSent(true)
  }, [])

  const maybeAuthFromGoogleToken = useCallback(async () => {
    if (!query) {
      return
    }
    const source = query.get('source')
    if (source === 'conferenceaddon') {
      setSetupData({ profile: { authSource: source } })
    }

    const authToken = query.get('token')
    const googleUserID = query.get('googleid')

    if (!authToken || !googleUserID) {
      return
    }

    setLoading(true)
    try {
      const authGoogle = getAuth()
      authGoogle.useDeviceLanguage()
      // use the auth token to get a credential to sign into Firebase
      const credential = GoogleAuthProvider.credential(null, authToken)
      const userCredential = await signInWithCredential(authGoogle, credential)
      const loginResult: GoogleLoginResult = {
        userCredential,
        googleUserID,
      }
      await getGoogleDataAndUpdateSetupData(loginResult)
      const hasAppUser = await verifyHasAppUser(userCredential)
      if (!hasAppUser) {
        postAuthRedirect(userCredential)
      }
    } catch (error) {
      console.warn('Failed to auth from google token', error)
      setError('Unable to authenticate from Google')
    } finally {
      setLoading(false)
    }
  }, [postAuthRedirect, query])

  const maybeAuthFromURL = useCallback(async () => {
    // completing sign up auth through email
    if (auth && !auth.isAnonymous) {
      return
    }

    if (!query.get('apiKey')) {
      return
    }

    const email = query.get('email')

    if (!email) {
      setError(NO_EMAIL_FROM_EMAIL_AUTH_LINK)
      return
    }

    setLoading(true)

    // these additional query params are necessary as iOS safari within email apps does not
    // share state or localstorage with the regular iOS safari app
    // in the email auth case on iOS, all data collected in localstorage before auth must
    // be re-collected through query params and set again
    const unverifiedUserFullname = query.get('unverifiedUserFullname')
    const teamID = query.get('teamID')
    const inviteID = query.get('inviteID')

    try {
      const cred = await signInWithEmailLink(
        getAuth(),
        email,
        window.location.href
      )

      persistEmailSetupData(cred)

      if (unverifiedUserFullname) {
        setUnverifiedUserFullname(unverifiedUserFullname)
      }

      if (teamID && inviteID) {
        setSetupData({ invite: { teamID, inviteID } })
      }

      const hasAppUser = await verifyHasAppUser(cred)
      if (!hasAppUser) {
        postAuthRedirect(cred)
      }
    } catch (error) {
      console.warn('Failed to verifyHasAppUser', error)
      if (
        (error as FirebaseError).code &&
        (error as FirebaseError).code === INVALID_ACTION_CODE
      ) {
        setError(INVALID_ACTION_CODE)
      } else {
        setError('Unable to verify user')
      }
    } finally {
      setLoading(false)
    }
  }, [auth, postAuthRedirect, query])

  useEffect(() => {
    maybeAuthFromURL()
    maybeAuthFromGoogleToken()
  }, [maybeAuthFromGoogleToken, maybeAuthFromURL])

  return (
    <SetupPage title='Get started'>
      {
        // if we have a path to redirect to, do it
        redirectUrl ? (
          <Redirect to={redirectUrl} />
        ) : // if the user email already has an account, show that error
        conflictingUserEmail ? (
          <ErrorComponent
            headline='Your Google Account is already linked to another account.'
            errorMsg={
              'You already have an account under the email ' +
              conflictingUserEmail
            }
            retryAction={() => {
              setRedirectUrl('/login')
            }}
          />
        ) : // if we're loading, render a spinner
        loading ? (
          <SpinnerOverlay darkMode />
        ) : (
          // base case: get started form
          <GetStarted
            continueWithGoogle={signUpWithGoogle}
            emailSent={emailSentCallback}
            header={
              <HeaderWithAppIcon
                title={signupPageContent.header}
                message={signupPageContent.caption}
              />
            }
            redirect={
              <p>
                <span>Already have an account? </span>
                <Link to='/login'>Log in</Link>
              </p>
            }
            queryAutoRedirected={queryAutoRedirected ?? undefined}
            queryReferralID={queryReferralID ?? undefined}
            error={error}
          />
        )
      }
    </SetupPage>
  )
}

export default SignUpPage
