import Button from 'brand/Button'
import React from 'react'
import { functions } from 'utilities/firebase-utils'
import config from 'configs/generalConfig.json'

import styles from './InviteLinkError.module.scss'

interface Props {
  teamName?: string
  errorCode: functions.InviteLinkErrorCode
  isLoggednInWithAccount: boolean
  redirectToLaunch: () => void
}

function getErrorHeaderFromErrorCode(
  code: functions.InviteLinkErrorCode,
  teamName?: string
): string {
  switch (code) {
    // TODO remove unused codes later. Keeping then around for now to avoid temporary compatibility issues if
    // webapp is deployed before than backend or we rollback.
    case 101:
    case 102:
      return "Something's wrong with this link"
    case 103:
    case 104:
      return "This invite's expired"
    case 105:
      return `Welcome to ${teamName ? teamName + ' on' : ''} Multi`
    default:
      return 'Something went wrong while fetching invite'
  }
}

function getErrorSubHeaderFromErrorCode(
  code: functions.InviteLinkErrorCode,
  teamName?: string
): string {
  switch (code) {
    case 101:
    case 102:
      return 'Check for typos or ask your teammate to send a new link.'
    case 103:
    case 104:
      return `Ask a teammate for a new invite link to join the team ${teamName}`
    case 105:
      return `You're already part of the team ${teamName}. Open Multi on macOS to see who's available to talk.`
    default:
      return 'Try refreshing the page or ask your teammate for a new link. If problem persists, please write to us at hello@multi.app'
  }
}
const InviteLinkError = (props: Props) => {
  return (
    <div className={styles.inviteLinkError}>
      <h1>{getErrorHeaderFromErrorCode(props.errorCode, props.teamName)}</h1>
      <p>{getErrorSubHeaderFromErrorCode(props.errorCode, props.teamName)}</p>
      {props.isLoggednInWithAccount && props.errorCode === 105 && (
        <Button darkMode onClick={props.redirectToLaunch}>
          Open Multi on macOS
        </Button>
      )}
      {(props.errorCode === 101 || props.errorCode === 102) && (
        <a href={config.remotionSite}>
          <Button darkMode kind='secondary'>
            Back to Multi.app
          </Button>
        </a>
      )}
    </div>
  )
}

export default InviteLinkError
