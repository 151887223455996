import Button from 'brand/Button'
import React from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

import { usePageTracking, PageCategory } from 'utilities/analytics'
import { SetupPage } from './SetupPage'
import styles from './SetupPage.module.scss'
import { HeaderWithAppIcon } from 'components/UI/HeaderWithAppIcon/HeaderWithAppIcon'
import { functions } from 'utilities'

const Download: React.FC = () => {
  const history = useHistory()
  usePageTracking(PageCategory.onboarding, 'Download')

  const downloadAndNav = () => {
    functions.onboardingEvent({
      step: `"Download" clicked on Download page`,
    })

    // init download
    window.open('https://updates.multi.app/latest', '_self')

    // move to the invite step
    history.push('/setup/invite')
  }

  return (
    <SetupPage title='Download'>
      <section>
        <HeaderWithAppIcon title='Download Multi' message='' />
        <Button
          darkMode
          fluid
          onClick={downloadAndNav}
          className={styles.downloadButton}
        >
          Download
        </Button>
        <p className={styles.macos_version_note}>Multi requires macOS 12+</p>
        <p className={styles.centered}>
          <Link to='/launch'>Already have the app? Launch Multi</Link>
        </p>
      </section>
    </SetupPage>
  )
}

export default Download
