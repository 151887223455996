const UNCONFIRMED_EMAIL_KEY = 'UnconfirmedEmailData'

export type UnconfirmedEmailData = string // used to pre-populate /signup email field when users enter an email address in /login that has no associated account

export const getUnconfirmedEmail = (): UnconfirmedEmailData | undefined => {
  const data = sessionStorage.getItem(UNCONFIRMED_EMAIL_KEY)
  if (!data) {
    return
  }

  return data
}

export const setUnconfirmedEmail = (email: string | undefined): void => {
  if (!email) {
    sessionStorage.removeItem(UNCONFIRMED_EMAIL_KEY)
    return
  }
  sessionStorage.setItem(UNCONFIRMED_EMAIL_KEY, email)
}
