import Button from 'brand/Button'
import React from 'react'
import { Modal } from 'semantic-ui-react'

import styles from './Modal.module.scss'

type BrandModalProps = {
  buttons?: React.ReactNode
  children: React.ReactNode
  dismissBtnText?: string
  dimmer?: 'default' | 'inverted' | 'blurring' | 'none'
  onClose: () => void
  open: boolean
}

/**
 * @param buttons any additional buttons to be rendered to the left of the standard 'Close' button
 * @param children content to render in the modal
 * @param dismissBtnText text to show on the close button. defaults to 'Close' if not specified
 * @param dimmer // if specified, then will apply a translucent background to viewport areas outside the modal, see https://react.semantic-ui.com/modules/modal/#content-dimmer
 * @param onClose // function to perform on the close action, typically updating a boolean state variable that causes open to be false
 * @param open // boolean specifying whether the modal should be open
 * @returns Modal React Function Component
 */
export const BrandModal: React.FC<BrandModalProps> = ({
  buttons,
  children,
  dismissBtnText = 'Close',
  dimmer = 'default',
  onClose,
  open,
}) => {
  const selectDimmerStyle = (): JSX.Element | string | undefined => {
    if (dimmer === 'default') {
      return // undefined gives the default darkened translucent background behaviour
    } else if (dimmer === 'none') {
      return <Modal.Dimmer id={styles.notDimmed} /> // do not dim
    }

    return dimmer // return inverted or blurring, string options supported by Semantic UI
  }

  return (
    <Modal
      basic
      className={styles.brandModal}
      open={open}
      onClose={onClose}
      dimmer={selectDimmerStyle()}
    >
      <Modal.Content>
        <div className={styles.content}>
          {children}
          <div className={styles.buttons}>
            {buttons}
            <Button darkMode onClick={onClose}>
              {dismissBtnText}
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default BrandModal
