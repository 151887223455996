import React from 'react'
import styles from './card.module.scss'
import classNames from 'classnames'

interface CardProps {
  children: React.ReactNode
  style?: React.CSSProperties | undefined
  darkMode?: boolean
}

const Card = (props: CardProps) => {
  const { children, style = undefined, darkMode = false } = props
  return (
    <div
      className={classNames(styles.card, {
        [styles.darkMode]: darkMode,
      })}
      style={style}
    >
      {children}
    </div>
  )
}

export default Card
