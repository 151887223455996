import React from 'react'
import { useAsync } from 'react-async-hook'

import { functions } from 'utilities/firebase-utils'
import { paramsToObject, useQuery } from 'utilities/locationHooks'
import { removeAllSetupData, setSetupData } from './utils/SetupDataHandler'
import { useAuth } from 'components/AuthProvider'
import { useHistory } from 'react-router'

import { Spinner } from 'components/UI/Spinner'
import { SetupPage } from './SetupPage'

const JoinTeamRedirect: React.FC = () => {
  const history = useHistory()
  const { user, isLoggedInWithAccount } = useAuth()
  const query = useQuery()
  const search = paramsToObject(query.entries())
  const { inviteID, teamID, teamName, source } = search

  useAsync(async () => {
    setSetupData({
      invite: {
        inviteID,
        teamID,
        teamName,
        source,
      },
    })

    // TODO: replace with await'ed server call to check for authUser and appUser
    // isLoggedInWithAccount relies on asyc document updates within AuthProvider
    // if this is the first Multi web app page the user visits, isLoggedInWithAccount
    // may be inaccurate.
    if (isLoggedInWithAccount) {
      // enter this block if user has an appUser and an authUser
      const teamIds = user?.teams.map((team) => team.id) || []

      if (teamID && teamIds.includes(teamID)) {
        // user is already on the same team
        removeAllSetupData()
        history.push('/launch')
      } else if (teamID && inviteID) {
        // user is joining a second team with invite link
        // (only invite links will have an inviteID)
        await functions.joinTeamFromInviteLink({ teamID, inviteID })

        removeAllSetupData()
        history.push('/setup/download')
      } else {
        // don't allow users to join a second team through guest call
        history.push('/setup/ask-for-invite')
      }
    } else {
      history.push('/signup')
    }
  }, [isLoggedInWithAccount, user])

  return (
    <SetupPage title='Join a team'>
      <Spinner darkMode />
    </SetupPage>
  )
}

export default JoinTeamRedirect
