import React, { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
  color?: 'black' | 'white'
}

const SCREENSHARE_GREEN = '#30D158'

const IconScreenshare = (props: Props) => {
  const { color = SCREENSHARE_GREEN, ...otherProps } = props

  return (
    <svg
      {...otherProps}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 6.68066C4.89543 6.68066 4 7.57609 4 8.68066V15.6807C4 16.7852 4.89543 17.6807 6 17.6807H18C19.1046 17.6807 20 16.7852 20 15.6807V8.68066C20 7.57609 19.1046 6.68066 18 6.68066H6ZM8.5 18.6807C8.22386 18.6807 8 18.9045 8 19.1807C8 19.4568 8.22386 19.6807 8.5 19.6807H15.5C15.7761 19.6807 16 19.4568 16 19.1807C16 18.9045 15.7761 18.6807 15.5 18.6807H8.5ZM12.5303 9.15034L12 8.62001L11.4697 9.15034L8.96967 11.6503C8.67678 11.9432 8.67678 12.4181 8.96967 12.711C9.26256 13.0039 9.73744 13.0039 10.0303 12.711L11.25 11.4913V14.6807C11.25 15.0949 11.5858 15.4307 12 15.4307C12.4142 15.4307 12.75 15.0949 12.75 14.6807V11.4913L13.9697 12.711C14.2626 13.0039 14.7374 13.0039 15.0303 12.711C15.3232 12.4181 15.3232 11.9432 15.0303 11.6503L12.5303 9.15034Z'
        fill={color}
      />
    </svg>
  )
}

export default IconScreenshare
