import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import Helmet from 'react-helmet'

import config from 'configs/generalConfig.json'
import styles from '../scss/container.module.scss'
import SpinnerOverlay from 'components/UI/Spinner'

function createLink(scheme: string, userID: string, linkSource?: string) {
  let url = `${scheme}://talk?user-id=${userID}`
  if (linkSource) {
    url += `&source=${linkSource}`
  }
  return url
}

const TalkInRemotion: React.FC = () => {
  const location = useLocation()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [userID, setUserID] = useState('')
  const [linkSource, setLinkSource] = useState('')

  useEffect(() => {
    const query = queryString.parse(location.search)
    const userID = query.userID
    if (typeof userID !== 'string') {
      history.push('/')
      return
    }
    setUserID(userID)
    const linkSource = query.source
    if (typeof linkSource === 'string') {
      setLinkSource(linkSource)
      openApp(userID, linkSource)
    } else {
      openApp(userID)
    }
  }, [history, location.search])

  const openApp = async (userID: string, linkSource?: string) => {
    setLoading(true)
    const url = createLink(config.scheme, userID, linkSource)
    window.location.href = url
    setLoading(false)
  }

  return (
    <>
      <Helmet>
        <title>Talk</title>
      </Helmet>
      <SpinnerOverlay darkMode active={loading}>
        Joining conversation...
      </SpinnerOverlay>
      <div className={styles.container}>
        <h1 className={styles.header}>Joining conversation...</h1>
        <p style={{ margin: '10px 0px' }}>
          Multi App not opening?
          <button
            className='transparentButton darkMode'
            onClick={() => openApp(userID, linkSource)}
          >
            Try again
          </button>
          or
          <Link className='transparentButton darkMode' to='/download'>
            Download for macOS
          </Link>
        </p>
      </div>
    </>
  )
}

export default TalkInRemotion
