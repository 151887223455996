import { MightHaveId } from './MightHaveId'

import { safePrimitiveGet, safeArrayRefGet, safeDateGet } from './utils'
import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  getDoc,
  getFirestore,
  onSnapshot,
  QueryDocumentSnapshot,
  SnapshotOptions,
  query,
  where,
} from 'firebase/firestore'
import { useEffect, useState } from 'react'
import {
  AppUser,
  userConverter,
  USERS_COLLECTION,
  RoomMedia,
  SpotifyMedia,
} from 'models'

export interface Invite {
  created: string
  createdBy: string
  inviteID: string
}
export interface Icon {
  type: string
  value: string
}
export interface Room {
  id: string
  convoID: string
  name: string
  icon?: Icon
  media?: RoomMedia
  spotifyMedia?: SpotifyMedia
}
export interface Team extends MightHaveId {
  name: string
  created?: Date
  createdBy?: string
  sharedDomain?: string
  users: DocumentReference[]
  invites?: {
    [key: string]: Invite
  }
  slackWorkspaceID?: string
  slackWorkspaceName?: string
  slackAuthTokenInvalid?: boolean

  /** @deprecated Rooms shouldn't be accessed from the team anymore */
  rooms?: Room[]
}

/** The name of the Firestore collection where Team objects are stored */
export const TEAMS_COLLECTION = 'Teams'

/** Subscribe to a singular `Team` object from Firestore based on `teamID`
 *
 *
 * @param {string} [teamID]
 * @return {*}  {(Team | undefined)}
 *
 * @example
 * const convo = useConvo(convoID)
 * const convoTeam = useTeam(convo?.team)
 */
export function useTeam(teamID?: string): Team | undefined {
  const [team, setTeam] = useState<Team>()
  useEffect(() => {
    if (!teamID) return

    const ref = doc(getFirestore(), TEAMS_COLLECTION, teamID).withConverter(
      teamConverter
    )
    const unsub = onSnapshot(ref, (snap) => {
      console.debug('Team was updated:', snap.data())
      setTeam(snap.data())
    })

    return unsub
  }, [teamID])

  return team
}

/** Subscribe to a collection of `Team` objects that the provided user belongs
 *  to.
 *
 * @param {AppUser | undefined} [user] - The current user
 * @return {*} {Team[]} - An array of teams, possibly empty
 *
 * @example
 * const { user } = useAuth()
 * const userTeams = useUserTeams(user)
 */
export function useUserTeams(user: AppUser | undefined): Team[] {
  const [teams, setTeams] = useState<Team[]>([])

  useEffect(() => {
    // Exit early if there are no teams
    if (!user || !user.teams || user.teams.length === 0) {
      return
    }

    const fs = getFirestore()

    const teamsCollection = collection(fs, TEAMS_COLLECTION)
    const userRef = doc(fs, USERS_COLLECTION, user.ID as string)
    const q = query(
      teamsCollection,
      where('users', 'array-contains', userRef)
    ).withConverter(teamConverter)

    const unsub = onSnapshot(q, (snap) => {
      console.debug('Teams were updated:', snap.docs)
      const teams: Team[] = []
      snap.forEach((teamSnap) => {
        teams.push(teamSnap.data())
      })
      setTeams(teams)
    })

    return unsub
  }, [user?.ID])

  return teams
}

/** Subscribe to a collection of Firestore documents representing all the unique AppUsers
 * present in a list of Teams
 *
 * @deprecated This effect is a shim of past behavior and
 *             as a team we'd like to explore improved
 *             access patterns. Please don't use this
 *             effect in new code.
 *             See Discussion: https://github.com/Remotionco/js/pull/776#discussion_r908839863
 * @param {Team[]} teams
 * @return {*}  {AppUser[]} - Every unique AppUser referenced by teams > users
 */
export function useLegacyAllUniqueUsersInTeams(teams: Team[]): AppUser[] {
  const [allUsers, setUsers] = useState<AppUser[]>([])

  useEffect(() => {
    let isMounted = true

    const fetchUsers = async () => {
      const uniqUserRefs = teams.reduce((acc, team) => {
        team.users.forEach((ref) => {
          acc.add(ref)
        })
        return acc
      }, new Set<DocumentReference>())

      // nb. "Type-gymnastics" to coerce a set into an array we can map over below.
      const userRefsAsArray = [...uniqUserRefs]

      const userPromises = userRefsAsArray.map(async (ref) => {
        const res = await getDoc(ref.withConverter(userConverter))
        return res.data()
      })

      const allRes = await Promise.all(userPromises)
      const newUsers: AppUser[] = allRes.filter((u): u is AppUser => {
        return !!u
      })

      if (isMounted) {
        setUsers(newUsers)
      }
    }

    fetchUsers()

    return () => {
      isMounted = false
    }
  }, [teams])

  return allUsers
}

/** Implements a 'converter' function for use with `useConverter`, building out returned
 * documents as domain-specific Team objects
 */
export const teamConverter: FirestoreDataConverter<Team> = {
  toFirestore: (team: Team): DocumentData => {
    return team as any
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Team => {
    const data = snapshot.data(options)
    const appTeam = createTeam(data, snapshot.id)
    return appTeam as Team
  },
}

export const createTeam = (
  data: DocumentData | undefined,
  id: string | undefined
): Team | null => {
  if (!data) {
    return null
  }

  const newTeam: Team = {
    ID: id, // should always be set
    name: data.name,
    users: [],
  }

  newTeam.created = safeDateGet(data, 'created')
  newTeam.createdBy = safePrimitiveGet(data, 'createdBy', 'string')
  newTeam.sharedDomain = safePrimitiveGet(data, 'sharedDomain', 'string')
  newTeam.users = safeArrayRefGet(data, 'users')
  newTeam.invites = safePrimitiveGet(data, 'invites', 'object')
  newTeam.slackWorkspaceID = safePrimitiveGet(
    data,
    'slackWorkspaceID',
    'string'
  )
  newTeam.slackWorkspaceName = safePrimitiveGet(
    data,
    'slackWorkspaceName',
    'string'
  )
  newTeam.slackAuthTokenInvalid = safePrimitiveGet(
    data,
    'slackAuthTokenInvalid',
    'boolean'
  )
  newTeam.rooms = safePrimitiveGet(data, 'rooms', 'object')
  return newTeam
}
