import { UAParser } from 'ua-parser-js'
import { isChrome, isChromium, isSafari } from 'react-device-detect'

/**
 * We can't be certain that a user is using macOS, but if we
 * get enough data sometimes we can figure out the user is on
 * something else, which is better than nothing.  */
export const isProbablyNotMacOS = (): boolean => {
  const parser = new UAParser()

  const deviceVendor = parser.getDevice().vendor
  if (deviceVendor && deviceVendor !== 'Apple') {
    return true
  }

  const deviceType = parser.getDevice().type
  if (
    deviceType &&
    (deviceType === 'mobile' ||
      deviceType === 'wearable' ||
      deviceType === 'tablet')
  ) {
    return true
  }

  const osName = parser.getOS().name
  if (osName && osName !== 'Mac OS') {
    return true
  }

  // if our tests all fail, then we're probably okay...
  return false
}

/**
 * much like isProbablyNotMacOS, we can't be entirely sure what device a user is on
 * but we can likely determine if it is a mobile device
 */
export const isProbablyMobile = (): boolean => {
  const mobileDevices = ['mobile', 'wearable', 'tablet']

  const parser = new UAParser()
  const userDevice = parser.getDevice()

  if (userDevice.type && mobileDevices.includes(userDevice.type)) {
    return true
  }

  const osName = parser.getOS().name
  if (osName && osName.toLowerCase().includes('android')) {
    return true
  }

  if (osName && osName.toLowerCase().includes('ios')) {
    return true
  }

  return false
}

/**
 * CC note: safari is supported for Agora guest calls, but not for Zoom guest calls
 * if a Safari user tries to join a zoom guest call, we direct them to an error page
 * see joinCallAsync() in GuestCallProvider for more details
 *  */
export const isSupportedBrowser = isChrome || isChromium || isSafari
