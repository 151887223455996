import React, { ChangeEvent, useEffect, useState } from 'react'
import { Redirect } from 'react-router'

import { isFreeEmailDomain } from 'utilities/freeEmailDomains'
import { useAuth } from 'components/AuthProvider'
import {
  CreateTeamData,
  getUserProfileData,
  setSetupData,
} from '../utils/SetupDataHandler'
import { createAccountCreateTeam } from '../utils/createAccountCreateTeam'
import Button from 'brand/Button'
import { SetupPage } from '../SetupPage'
import SpinnerOverlay from 'components/UI/Spinner'
import styles from './Team.module.scss'
import { PageCategory, usePageTracking } from 'utilities/analytics'
import Input from 'brand/Input/Input'
import Error from '../Error'
import { isProbablyNotMacOS } from 'utilities/browserUtil'
import { functions } from 'utilities'

export const TEAM_OS_VALUES = [
  'All macOS',
  'All Windows',
  'Mostly macOS',
  'Mostly Windows',
  'Other',
]

const REDIRECT_ROUTES = {
  signup: '/signup',
  open: '/launch',
  setup: {
    teamCreate: '/setup/team/create',
    waitlist: '/setup/waitlist',
    postCreate: '/setup/team/post-create',
  },
}

const TeamCreate: React.FC = () => {
  const { auth, user } = useAuth()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const [redirectPath, setRedirectPath] = useState<string>()

  // form values
  const [allowDomainCapture, setAllowDomainCapture] = useState<boolean>()
  const [teamName, setTeamName] = useState<string>()
  const teamOS = TEAM_OS_VALUES[0]

  const [readyToSubmit, setReadyToSubmit] = useState(false)

  usePageTracking(PageCategory.onboarding, 'Create Team')

  // check that a user is authed, and has completed setup prerequsites
  useEffect(() => {
    if (!auth || (!getUserProfileData() && !user)) {
      setRedirectPath(REDIRECT_ROUTES.signup)
    }
  }, [auth, user])

  useEffect(() => {
    if (teamName && teamOS) {
      setReadyToSubmit(true)
    } else {
      setReadyToSubmit(false)
    }
  }, [teamName, teamOS])

  const onCreateAccountAndTeamAndDownload = async () => {
    setLoading(true)
    setError(undefined)

    functions.onboardingEvent({
      step: `"Continue to download" clicked on TeamCreate page`,
    })

    if (!readyToSubmit) {
      return
    }

    if (!(teamOS === 'All macOS' || teamOS === 'Mostly macOS')) {
      setRedirectPath(REDIRECT_ROUTES.setup.waitlist)
      setLoading(false)
      return
    }

    try {
      const team: CreateTeamData = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        teamName: teamName!, // checked in readyToSubmit
        teamOS,
        allowDomainCapture: allowDomainCapture || false,
      }

      setSetupData({ team }) // persist team form data in local storage (SetupData)
      await createAccountCreateTeam()

      if (!isProbablyNotMacOS()) {
        // if likely on macOS, init download
        window.open('https://updates.multi.app/latest', '_self')
      }

      setRedirectPath(REDIRECT_ROUTES.setup.postCreate)
    } catch (error) {
      if (error !== null && 'message' in (error as ErrorEvent)) {
        setError((error as ErrorEvent).message)
      } else {
        setError("Couldn't create team")
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <SetupPage title='Create a team'>
      {
        // if there is an error, don't render anything else
        error ? (
          <Error
            headline='Error creating team'
            errorMsg={error}
            retryAction={onCreateAccountAndTeamAndDownload}
          />
        ) : // otherwise, if the page is loading, render a spinner
        loading ? (
          <SpinnerOverlay darkMode>Creating your team...</SpinnerOverlay>
        ) : // if there is no error, and we are not loading, render the redirect path
        redirectPath ? (
          <Redirect
            to={{
              pathname: redirectPath,
              state: {
                from: REDIRECT_ROUTES.setup.teamCreate,
              },
            }}
          />
        ) : (
          // render team creation form
          <section>
            <h1>Create a team</h1>
            <div className={styles.newTeamForm}>
              <TeamName teamName={teamName} setTeamName={setTeamName} />
              <DomainCaptureOptIn
                allowDomainCapture={allowDomainCapture}
                setAllowDomainCapture={setAllowDomainCapture}
              />
              <Button
                kind={readyToSubmit ? 'primary' : 'tertiary'}
                fluid
                darkMode
                disabled={!readyToSubmit}
                onClick={onCreateAccountAndTeamAndDownload}
              >
                Continue to download
              </Button>
            </div>
          </section>
        )
      }
    </SetupPage>
  )
}

type TeamNameProps = {
  teamName: string | undefined
  setTeamName: (teamName: string) => void
}
const TeamName: React.FC<TeamNameProps> = ({ teamName, setTeamName }) => {
  const [nameChangeTracked, setNameChangeTracked] = useState<boolean>(false)
  const onChangeTeamName = (event: ChangeEvent<HTMLInputElement>) => {
    if (!nameChangeTracked) {
      functions.onboardingEvent({
        step: `Team name changed on TeamCreate page`,
      })
      setNameChangeTracked(true)
    }
    setTeamName(event.target.value)
  }
  return (
    <>
      <div className={styles.createTeamLabel}>
        What&apos;s your team called? *
      </div>
      <Input
        fluid
        darkMode
        placeholder='Acme Co.'
        value={teamName ?? ''}
        onChange={onChangeTeamName}
      />
    </>
  )
}

type DomainCaptureOptInProps = {
  allowDomainCapture: boolean | undefined
  setAllowDomainCapture: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >
}
const DomainCaptureOptIn: React.FC<DomainCaptureOptInProps> = (props) => {
  const { allowDomainCapture, setAllowDomainCapture } = props
  const { auth } = useAuth()
  const [teamDomain, setTeamDomain] = useState<string>()

  useEffect(() => {
    if (!auth?.email) {
      return
    }
    if (isFreeEmailDomain(auth.email)) {
      setAllowDomainCapture(false)
      return
    }

    const domain = auth.email.split('@')[1] // the part of the user's email address after the @ symbol
    setTeamDomain(domain)
    setAllowDomainCapture(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- setAllowDomainCapture is React.Dispatch
  }, [auth?.email])

  const toggleAllowDomainCapture = () => {
    setAllowDomainCapture(!allowDomainCapture)
  }

  return (
    <>
      {teamDomain && (
        <div className={styles.domainCaptureCheck}>
          <label>
            <input
              id='domainCaptureBox'
              name='domainCapture'
              type='checkbox'
              checked={!!allowDomainCapture}
              onChange={toggleAllowDomainCapture}
            />
            Allow people with an @{teamDomain} email address to join your team
            without an invite.
          </label>
        </div>
      )}
    </>
  )
}

export default TeamCreate
