import React, { useState, useMemo, useCallback } from 'react'
import { useAsync } from 'react-async-hook'
import classNames from 'classnames'
import { Loader } from 'semantic-ui-react'
import { UID } from 'agora-rtc-sdk-ng'

import { getImageUrl } from 'components/UI/AvatarImage/AvatarImage.helper'
import AvatarGridAgora from '../../components/AvatarGridAgora'
import CallControls from '../../components/CallControls'
import { InCallFeedback } from '../../components/CallFeedback'
import GuestCallSpotify from '../GuestCallSpotify'
import IconScreenshare from 'components/UI/Icons/Screenshare'
import phone from '../../assets/phone.svg'
import threePerson from '../../assets/three-person.svg'
import { GuestCallScreensharingPopup } from '../GuestCallScreensharingPopup'
import { useGuestCall } from '../../GuestCallProvider'
import { Modal } from 'brand'
import {
  generateModalContent,
  ScreenshareAttemptResult,
  getScreenshareError,
} from '../../guest-call-utils'

import InCallUpsell from '../../components/InCallUpsell'
import { useGuestCallAgora } from './GuestCallAgoraProvider'
import AgoraScreenShare from 'components/Public/GuestCall/GuestCallOngoing/Agora/AgoraScreenShare'
import { getRemotionToAgoraUID } from 'components/Public/GuestCall/GuestCallOngoing/Agora/useAgora'
import { useAuth } from 'components/AuthProvider'
import { addUserToConvoField, ConvoField, useConvo } from 'models'

import styles from '../GuestCallOngoing.module.scss'

const GuestCallAgora: React.FC = () => {
  const { roomName, userMap, convoID, avSetup } = useGuestCall()

  const {
    leaveCall,
    screenTrack,
    agoraRemoteUsers,
    toggleAudio,
    toggleVideo,
    changeAudioInputDevice,
    changeVideoInputDevice,
    startScreenshare,
    stopScreenshare,
  } = useGuestCallAgora()

  const convo = useConvo(convoID)

  const { auth } = useAuth()
  const currentUserID = auth?.uid

  const [screenshareResult, setScreenshareResult] =
    useState<ScreenshareAttemptResult>()

  const [leaveCallClicked, setLeaveCallClicked] = useState(false)

  const screensharingUserID = useMemo((): string | undefined => {
    const remoteScreensharingUsers =
      convo?.screensharingUsers.filter(({ id }) => id !== currentUserID) || []

    if (!remoteScreensharingUsers.length) return

    const lastScreensharingUserID =
      remoteScreensharingUsers[remoteScreensharingUsers.length - 1].id

    return lastScreensharingUserID
  }, [convo?.screensharingUsers, currentUserID])

  const isRemoteScreensharing = !!screensharingUserID
  const isLocalScreensharing = !!screenTrack

  const screensharingUserName = screensharingUserID
    ? userMap[screensharingUserID]?.firstName
    : undefined

  const remoteScreenshareElement = useMemo(() => {
    if (!screensharingUserID) return null

    const remoteUser = agoraRemoteUsers.find(
      ({ uid }) => uid === getRemotionToAgoraUID(screensharingUserID)
    )

    if (!remoteUser) return null

    return <AgoraScreenShare videoTrack={remoteUser.videoTrack} />
  }, [screensharingUserID, agoraRemoteUsers])

  const spotifyMedia = convo?.spotifyMedia

  const getUserSharingSpotify = useAsync(async () => {
    if (!spotifyMedia?.createdBy) return

    const userSharing = userMap[spotifyMedia.createdBy]

    if (!userSharing) return

    const avatar = await getImageUrl(userSharing.avatar)
    return { name: userSharing.firstName, avatar }
  }, [spotifyMedia, userMap])

  const modalContent = generateModalContent(screenshareResult)

  const isUserScreensharing = useCallback(
    (agoraUID: UID): boolean => {
      return screensharingUserID
        ? agoraUID === getRemotionToAgoraUID(screensharingUserID)
        : false
    },
    [screensharingUserID]
  )

  const stopLocalScreenshare = useCallback(async () => {
    try {
      await stopScreenshare()
    } catch (error) {
      console.error('Error stopping screenshare:', error)
    }
  }, [stopScreenshare])

  const startLocalScreenshare = useCallback(async () => {
    if (!currentUserID || !convoID) {
      return
    }

    await stopLocalScreenshare()

    try {
      await startScreenshare()
    } catch (error: any) {
      const result = getScreenshareError(error.message)
      setScreenshareResult(result)
      return
    }

    try {
      await addUserToConvoField({
        field: ConvoField.ScreensharingUsers,
        userID: currentUserID,
        convoID,
      })
    } catch (error) {
      console.error('Error adding screensharing user to convo:', error)
    }
  }, [currentUserID, convoID, stopLocalScreenshare, startScreenshare])

  const changeAudioDevice = useCallback(
    (deviceID: string) => {
      changeAudioInputDevice(deviceID, avSetup.audioEnabled)
    },
    [avSetup.audioEnabled, changeAudioInputDevice]
  )

  const changeVideoDevice = useCallback(
    (deviceID: string) => {
      changeVideoInputDevice(deviceID, avSetup.videoEnabled)
    },
    [avSetup.videoEnabled, changeVideoInputDevice]
  )

  return (
    <div className={styles.inCall}>
      {modalContent && (
        <Modal
          open={true}
          onClose={() => setScreenshareResult(undefined)}
          dismissBtnText={modalContent.buttonTitle}
        >
          <>
            <h3>{modalContent.title}</h3>
            <p>{modalContent.message}</p>
          </>
        </Modal>
      )}
      <div className={styles.topBar}>
        <div className={styles.topBarSection}>
          <span className={styles.convoName}>{roomName}</span>
          <span className={styles.convoUsers}>
            <img src={threePerson} /> You
            {agoraRemoteUsers.length
              ? ` + ${agoraRemoteUsers.length} others`
              : ''}
          </span>
          {screensharingUserName && (
            <div className={styles.screensharingUser}>
              <IconScreenshare
                className={styles.screensharingIcon}
                color='white'
              />
              Viewing {screensharingUserName || 'someone'}&apos;s screen
            </div>
          )}
        </div>
        <div className={styles.topBarSection}>
          <InCallFeedback />
          <InCallUpsell />
        </div>
      </div>

      <div
        className={classNames(styles.videoElementsContainer, {
          [styles.screenshare]: isRemoteScreensharing,
        })}
      >
        {remoteScreenshareElement}
        <div className={styles.avatarsContainer}>
          <AvatarGridAgora
            isLocalScreensharing={isLocalScreensharing}
            isRemoteScreensharing={isRemoteScreensharing}
            isUserScreensharing={isUserScreensharing}
          />
        </div>
      </div>

      <div className={styles.bottomBar}>
        <div className={styles.callControlsContainer}>
          <CallControls
            toggleAudio={toggleAudio}
            toggleVideo={toggleVideo}
            changeAudioInputDevice={changeAudioDevice}
            changeVideoInputDevice={changeVideoDevice}
          />
          {!isRemoteScreensharing && (
            <GuestCallScreensharingPopup
              startShare={startLocalScreenshare}
              stopShare={stopLocalScreenshare}
              isLocalScreensharing={isLocalScreensharing}
            >
              <AgoraScreenShare videoTrack={screenTrack} isLocal />
            </GuestCallScreensharingPopup>
          )}
          <button
            className={styles.hangUpButton}
            onClick={() => {
              leaveCall()
              setLeaveCallClicked(true)
            }}
          >
            {leaveCallClicked ? (
              <Loader size='small' inline='centered' active inverted />
            ) : (
              <img
                src={phone}
                className={styles.phoneIcon}
                width='30'
                alt='Leave conversation'
              />
            )}
          </button>
        </div>

        {getUserSharingSpotify.result && spotifyMedia?.title && (
          <GuestCallSpotify
            spotifyMedia={spotifyMedia}
            userSharing={getUserSharingSpotify.result}
          />
        )}
      </div>
    </div>
  )
}

export default GuestCallAgora
