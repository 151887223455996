import React from 'react'

import ListenModal from '../components/ListenModal'
import spotify from 'components/UI/Icons/Logos/spotify-logo.png'
import { SpotifyMedia } from 'models'

import styles from './GuestCallOngoing.module.scss'

export type UserSharingSpotify = {
  name: string
  avatar: string
}

type GuestCallSpotifyProps = {
  userSharing: UserSharingSpotify
  spotifyMedia: SpotifyMedia
}

const GuestCallSpotify: React.FC<GuestCallSpotifyProps> = (
  props: GuestCallSpotifyProps
) => {
  const { userSharing, spotifyMedia } = props

  return (
    <div className={styles.musicContainer}>
      <div className={styles.thumbnailContainer}>
        <img src={spotify} className={styles.spotifyIcon} height='30' />
        <img
          src={userSharing.avatar}
          className={styles.userSharingAvatar}
          height='25'
        />
      </div>

      <div className={styles.musicInfo}>
        <span className={styles.userSharingName}>
          {userSharing.name}&apos;s Spotify
        </span>
        <br />
        <span title={spotifyMedia.title}>{spotifyMedia.title}</span>
        <span> • </span>
        <span title={spotifyMedia.artist}>{spotifyMedia.artist}</span>
      </div>

      <ListenModal />
    </div>
  )
}

export default GuestCallSpotify
