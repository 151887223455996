import classNames from 'classnames'
import React from 'react'

import avatarImage from '../assets/default-avatar.svg'
import styles from './DefaultAvatar.module.scss'

type Props = {
  userInitials?: string
  removeZIndex?: boolean // for Zoom calls
  clipCorners?: boolean
}

const DefaultAvatar: React.FC<Props> = ({
  userInitials,
  removeZIndex,
  clipCorners,
}) => (
  <div
    className={classNames(styles.defaultAvatar, {
      [styles.noZIndex]: removeZIndex,
      [styles.clipCorners]: clipCorners,
    })}
  >
    {userInitials ? (
      <div className={styles.initialsAvatar}>{userInitials}</div>
    ) : (
      <img src={avatarImage} />
    )}
  </div>
)

export default DefaultAvatar
