import React, { useMemo } from 'react'

import { AppUser } from 'models'
import styles from './JoinRoom.module.scss'
import RoomEmoji from './RoomEmoji'
import { Button } from 'semantic-ui-react'

export type Props = {
  user: AppUser | undefined
  teamID: string
  onLaunch: () => void
  onJoinTeam: () => void
  hasValidToken: boolean
  teamName?: string
  roomName?: string
  userName?: string
  icon?: string
  color?: {
    start: string
    end: string
  }
}

const LoggedIn: React.FC<Props> = (props: Props) => {
  const {
    user,
    teamID,
    onLaunch,
    onJoinTeam,
    hasValidToken,
    teamName,
    icon,
    color,
    roomName,
    userName,
  } = props

  const userIsInTeam = useMemo(() => {
    if (!user) return false
    return !!user.teams.find((team) => team.id === teamID)
  }, [user, teamID])

  return userIsInTeam ? (
    <div className={styles.centered}>
      {roomName && (
        <h1>
          Joining {icon && color && <RoomEmoji icon={icon} color={color} />}
          {roomName}
        </h1>
      )}
      {userName && (
        <h1>
          {"You're"} joining {userName} in a call
        </h1>
      )}

      <Button fluid darkMode onClick={() => onLaunch()}>
        Launch Multi
      </Button>
    </div>
  ) : (
    <div className={styles.centered}>
      {hasValidToken ? (
        <>
          <h1>
            {roomName && (
              <>
                Join {icon && color && <RoomEmoji icon={icon} color={color} />}
                {roomName} in {teamName}
              </>
            )}
            {userName && <>{userName} invited you to join a call</>}
          </h1>
          <p>{teamName} is a team in Multi.</p>

          <Button fluid darkMode onClick={() => onJoinTeam()}>
            Join {teamName}
          </Button>
        </>
      ) : (
        <>
          <h1>
            {roomName && (
              <>
                Join {roomName || userName} in {teamName}
              </>
            )}
            {userName && <>{userName} invited you to join a call</>}
          </h1>
          <p>
            Looks like you&apos;re not on the {teamName} team. For your
            security, ask someone on the team for a new invite link.
          </p>
        </>
      )}
    </div>
  )
}

export default LoggedIn
