import { MightHaveId } from './MightHaveId'
import { safePrimitiveGet, safeArrayRefGet, safeDateGet } from './utils'
import {
  arrayRemove,
  arrayUnion,
  doc,
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  getDocFromServer,
  getFirestore,
  onSnapshot,
  QueryDocumentSnapshot,
  SnapshotOptions,
  updateDoc,
} from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { createRoomMedia, RoomMedia } from './RoomMedia'
import { USERS_COLLECTION } from './AppUser'

export interface Emoji {
  type: string
  value: string
}
export interface Topic {
  description: string
  emoji: Emoji
}

export interface SpotifyMedia {
  action?: string
  artist?: string
  created?: Date
  createdBy?: string
  thumbnail?: string
  time?: number
  title?: string
  uri?: string
  usersListening?: string[]
}

/** The name of the Firestore collection where Convo objects are stored */
export const CONVOS_COLLECTION = 'Convos'
export interface Convo extends MightHaveId {
  created?: Date
  createdBy?: string
  users: DocumentReference[]
  screensharingUsers: DocumentReference[]
  talkingUsers: DocumentReference[]
  team: string
  topic?: Topic
  type?: string
  media?: RoomMedia
  spotifyMedia?: SpotifyMedia
  participants?: Record<string, { userID: string }>
}

/** Implements a 'converter' function for use with `useConverter`,
 *  building out returned documents as domain-specific Convo objects */
export const convoConverter: FirestoreDataConverter<Convo> = {
  toFirestore: (convo: Convo): DocumentData => {
    return convo as any
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Convo => {
    const data = snapshot.data(options)
    const appConvo = createConvo(data, snapshot.id)
    return appConvo
  },
}

/** Subscribe to a single Convo document in Firestore by ID
 *
 * @export
 * @param {string} convoID
 * @return {*}  {(Convo | undefined)}
 */
export function useConvo(convoID: string): Convo | undefined {
  const [convo, setConvo] = useState<Convo>()

  useEffect(() => {
    const ref = doc(getFirestore(), CONVOS_COLLECTION, convoID).withConverter(
      convoConverter
    )
    const unsub = onSnapshot(ref, (snap) => {
      console.debug('Convo onSnapshot:', snap.data())
      setConvo(snap.data())
    })
    return unsub
  }, [convoID])

  return convo
}

export const createConvo = (data: DocumentData, id: string): Convo => {
  const newConvo: Partial<Convo> = {
    ID: id, // should always be set
    users: [],
    screensharingUsers: [],
  }

  newConvo.created = safeDateGet(data, 'created')
  newConvo.createdBy = safePrimitiveGet(data, 'createdBy', 'string')
  newConvo.users = safeArrayRefGet(data, 'users')
  newConvo.screensharingUsers = safeArrayRefGet(data, 'screensharingUsers')
  newConvo.talkingUsers = safeArrayRefGet(data, 'talkingUsers')
  newConvo.team = safePrimitiveGet(data, 'team', 'string')
  newConvo.topic = safePrimitiveGet(data, 'topic', 'object')
  newConvo.type = safePrimitiveGet(data, 'type', 'string')
  newConvo.media = createRoomMedia(data.media, id) ?? undefined
  newConvo.spotifyMedia = safePrimitiveGet(data, 'spotifyMedia', 'object')
  newConvo.participants =
    safePrimitiveGet(data, 'participants', 'object') ?? undefined

  return newConvo as Convo
}

/** Fetch a Convo by ID once. Returns undefined if the convo does not exist or is inaccessible. */
async function fetchConvo(convoID: string): Promise<Convo | undefined> {
  const ref = doc(getFirestore(), CONVOS_COLLECTION, convoID).withConverter(
    convoConverter
  )
  try {
    return (await getDocFromServer(ref)).data()
  } catch {
    console.debug('Failed to retrieve Convo with ID', convoID)
    return undefined
  }
}

/** Returns true if a user is participating in a conversation. */
export async function isUserInConversation(
  convoID: string,
  userID: string
): Promise<boolean> {
  const convo = await fetchConvo(convoID)
  if (!convo) return false

  const participants = convo?.participants || {}
  const participantUserIds = Object.values(participants).map(
    (record) => record.userID
  )
  const usersAsStringIds = convo.users.map((ref) => ref.id)

  return !!(
    participantUserIds.includes(userID) || usersAsStringIds.includes(userID)
  )
}

export enum ConvoField {
  ScreensharingUsers = 'screensharingUsers',
  TalkingUsers = 'talkingUsers',
  CameraSharingUsers = 'cameraSharingUsers',
  AudioSharingUsers = 'audioSharingUsers',
}

export const addUserToConvoField = async (params: {
  field: ConvoField
  userID: string
  convoID: string
}): Promise<void> => {
  const userRef = doc(getFirestore(), USERS_COLLECTION, params.userID)
  const convoRef = doc(getFirestore(), CONVOS_COLLECTION, params.convoID)
  await updateDoc(convoRef, { [params.field]: arrayUnion(userRef) })
}

export const removeUserFromConvoField = async (params: {
  field: ConvoField
  userID: string
  convoID: string
}): Promise<void> => {
  const userRef = doc(getFirestore(), USERS_COLLECTION, params.userID)
  const convoRef = doc(getFirestore(), CONVOS_COLLECTION, params.convoID)
  await updateDoc(convoRef, { [params.field]: arrayRemove(userRef) })
}
