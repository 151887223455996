import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { Form, Input } from 'semantic-ui-react'

import Button from 'brand/Button'
import { HeaderWithAppIcon } from 'components/UI/HeaderWithAppIcon/HeaderWithAppIcon'

import styles from './EmailForm.module.scss'
import { Spinner } from 'components/UI/Spinner'
import { validateEmail } from 'utilities'

type Props = {
  name: string
  setName: Dispatch<SetStateAction<string>>
  email: string
  setEmail: Dispatch<SetStateAction<string>>
  sendEmailLink: (email: string) => Promise<void>
  continueWithGoogle: () => Promise<void>
  isLogin: boolean
}

const EmailForm: React.FC<Props> = (props) => {
  const {
    name,
    setName,
    email,
    setEmail,
    continueWithGoogle,
    sendEmailLink,
    isLogin = true,
  } = props
  const [sendEmailLinkStarted, setSendEmailLinkStarted] = useState(false)

  const handleEnterKey = useCallback(
    (event: React.KeyboardEvent) => {
      if (
        event.key !== 'Enter' ||
        !email ||
        !validateEmail(email) ||
        (!isLogin && !name)
      ) {
        return
      }
      setSendEmailLinkStarted(true)
      sendEmailLink(email)
    },
    [email, isLogin, name, sendEmailLink]
  )

  return (
    <>
      <div>
        <HeaderWithAppIcon title={isLogin ? 'Log in' : 'Sign up'} />
        {!isLogin && (
          <Form.Field onKeyDown={handleEnterKey} className={styles.inputField}>
            <label>Full name</label>
            <Input
              required
              fluid
              value={name}
              onChange={(_e, data) => setName(data.value)}
              placeholder='Alexander Embiricos'
              autoComplete='off'
            />
          </Form.Field>
        )}
        <Form.Field onKeyDown={handleEnterKey} className={styles.inputField}>
          <label>Work email</label>
          <Input
            required
            fluid
            type='email'
            value={email}
            onChange={(_e, data) => setEmail(data.value)}
            placeholder='alexander@multi.app'
            autoComplete='off'
          />
        </Form.Field>
      </div>
      <Button
        fluid
        darkMode
        disabled={
          sendEmailLinkStarted ||
          !email ||
          !validateEmail(email) ||
          (!isLogin && !name)
        }
        onClick={() => {
          setSendEmailLinkStarted(true)
          sendEmailLink(email)
        }}
      >
        {sendEmailLinkStarted ? <Spinner inline size='small' /> : 'Continue'}
      </Button>
      <Button
        fluid
        darkMode
        kind='secondary'
        borderless
        onClick={continueWithGoogle}
      >
        Continue with Google instead
      </Button>
    </>
  )
}

export default EmailForm
