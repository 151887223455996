import { Redirect, useLocation } from 'react-router'
import React from 'react'
import SpinnerOverlay from 'components/UI/Spinner'
import { useAuth } from 'components/AuthProvider'

type Props = {
  children: React.ReactNode
}

/**
 * RequireAuthUser is a route wrapper that requires a user to have an auth user
 * (authenticated with google or email) but not necessarily created an account yet (no app user)
 * formerly known as SemiPrivateRoute
 */
export const RequireAuthUser: React.FC<Props> = ({ children }) => {
  const { isAuthAndAccountInitialized, isLoggedIn } = useAuth()
  const location = useLocation()

  return !isAuthAndAccountInitialized ? (
    <SpinnerOverlay darkMode />
  ) : isLoggedIn ? (
    <>{children}</>
  ) : (
    <Redirect to={{ pathname: '/signup', state: { from: location } }} />
  )
}

/**
 *
 * RequireAuthUserAndAppUser is a route wrapper that requires a user to have an auth user
 * (authenticated with google or email) and an app user (created an account)
 * formerly known as PrivateRoute
 */
export const RequireAuthUserAndAppUser: React.FC<Props> = ({ children }) => {
  const { user, isAuthAndAccountInitialized, isLoggedInWithAccount } = useAuth()
  const location = useLocation()

  const hasAccount = isLoggedInWithAccount && user && !user?.isGuest

  return !isAuthAndAccountInitialized ? (
    <SpinnerOverlay darkMode />
  ) : hasAccount ? (
    <>{children}</>
  ) : user?.isGuest ? (
    <Redirect
      to={{
        pathname: '/logout',
      }}
    />
  ) : (
    <Redirect
      to={{
        pathname: '/signup',
        state: { from: location },
      }}
    />
  )
}
