import classNames from 'classnames'
import differenceBy from 'lodash/differenceBy'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { calcMaxAvatarDiameter } from '../guest-call-utils'

import IconCaretDown from 'components/UI/Icons/CaretDown'
import IconCaretLeft from 'components/UI/Icons/CaretLeft'
import IconCaretRight from 'components/UI/Icons/CaretRight'
import IconCaretUp from 'components/UI/Icons/CaretUp'

import styles from './AvatarGrid.module.scss'
import { useGuestCall } from '../GuestCallProvider'
import { AVProvider } from 'utilities/firebase-utils/functions'
import { useGuestCallZoom } from '../GuestCallOngoing/Zoom/GuestCallZoomProvider'

export type GridItem = {
  element: React.ReactElement
  key: string
}

type Props = {
  elements: GridItem[]
  verticalNav: boolean
  setAvatarSize?: React.Dispatch<React.SetStateAction<number>>
}

// must match $grid-gap in AvatarGrid.module.scss
const GAP = 20
// must match $min-width in GuestCallOngoing.module.scss
export const MIN_WIDTH = 160

const ZOOM_MAX_ITEMS_PER_PAGE = 5

const AvatarGrid: React.FC<Props> = (props: Props) => {
  const { elements, verticalNav, setAvatarSize } = props
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [elementWidth, setElementWidth] = useState(MIN_WIDTH)
  const containerRef = useRef<HTMLDivElement>(null)
  const { channelDetails } = useGuestCall()
  const { isChatOpen } = useGuestCallZoom()

  const provider = useMemo(() => {
    if (!channelDetails) {
      return
    }
    return channelDetails.provider
  }, [channelDetails])

  const optimizeAvatarPacking = useCallback(() => {
    if (!containerRef.current) return

    // get containerWidth and containerHeight, both in px
    const container = containerRef.current
    const { offsetWidth: containerWidth, offsetHeight: containerHeight } =
      container

    // for zoom guest calls, limit the max number of total avatars
    const numTotalAvatars =
      provider === AVProvider.ZOOM
        ? Math.min(elements.length, ZOOM_MAX_ITEMS_PER_PAGE)
        : elements.length

    const calculatedWidth = calcMaxAvatarDiameter(
      containerWidth,
      containerHeight,
      numTotalAvatars,
      GAP
    )

    // item final width
    // it should not be smaller than min-width or exceed container height
    const itemWidth = Math.max(MIN_WIDTH, calculatedWidth)

    // min 1 column, min 1 row
    const numRows = Math.max(Math.floor(containerHeight / (itemWidth + GAP)), 1)
    // if remoteUser is screensharing (verticalNav=true), enforce single column
    const numCols = verticalNav
      ? 1
      : Math.max(Math.floor(containerWidth / (itemWidth + GAP)), 1)

    const maxItemsPerPage =
      provider === AVProvider.ZOOM
        ? Math.min(numCols * numRows, ZOOM_MAX_ITEMS_PER_PAGE)
        : numCols * numRows
    const numTotalPages = Math.ceil(elements.length / maxItemsPerPage)

    setElementWidth(itemWidth)
    setItemsPerPage(maxItemsPerPage)
    setTotalPages(numTotalPages)

    // set avatar size for AvatarGridWrapper
    if (setAvatarSize) {
      setAvatarSize(itemWidth)
    }
  }, [elements.length, provider, setAvatarSize, verticalNav])

  useEffect(() => {
    setTimeout(optimizeAvatarPacking, 1)
    window.addEventListener('resize', optimizeAvatarPacking)
    return () => window.removeEventListener('resize', optimizeAvatarPacking)
  }, [elements, optimizeAvatarPacking])

  // for Zoom calls, recalculate avatar packing if the chat is toggled
  useEffect(() => {
    if (channelDetails?.provider !== AVProvider.ZOOM) {
      return
    }
    optimizeAvatarPacking()
  }, [channelDetails?.provider, isChatOpen, optimizeAvatarPacking])

  const calcVisibleElementIndexes = useCallback(() => {
    let page = currentPage
    if (totalPages < currentPage) {
      page = totalPages || 1
      setCurrentPage(page)
    }
    const startIndex = (page - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage

    return { startIndex, endIndex }
  }, [currentPage, itemsPerPage, totalPages])

  const visibleElements: GridItem[] = useMemo(() => {
    const { startIndex, endIndex } = calcVisibleElementIndexes()
    const usersToDisplay = elements
      .slice(startIndex, endIndex)
      .map((gridItem) => ({
        ...gridItem,
        element: React.cloneElement(gridItem.element, { isOnPage: true }),
      }))
    return usersToDisplay
  }, [calcVisibleElementIndexes, elements])

  const hiddenElements = useMemo(() => {
    return differenceBy(elements, visibleElements, 'key').map(
      (hiddenGridItem) => ({
        ...hiddenGridItem,
        element: React.cloneElement(hiddenGridItem.element, {
          isOnPage: false,
        }),
      })
    )
  }, [elements, visibleElements])

  return (
    <div
      className={classNames(styles.gridContainer, {
        [styles.verticalNav]: verticalNav,
      })}
    >
      <div className={styles.pageNavigation}>
        {currentPage > 1 &&
          (verticalNav ? (
            <IconCaretUp onClick={() => setCurrentPage((state) => state - 1)} />
          ) : (
            <IconCaretLeft
              onClick={() => setCurrentPage((state) => state - 1)}
            />
          ))}
      </div>
      <div ref={containerRef} className={styles.avatarGrid}>
        {visibleElements.map((item) => (
          <div
            key={item.key}
            style={{ width: elementWidth, height: elementWidth }}
          >
            {item.element}
          </div>
        ))}
      </div>
      <div className={styles.pageNavigation}>
        {currentPage < totalPages &&
          (verticalNav ? (
            <IconCaretDown
              onClick={() => setCurrentPage((state) => state + 1)}
            />
          ) : (
            <IconCaretRight
              onClick={() => setCurrentPage((state) => state + 1)}
            />
          ))}
      </div>
      <div className={styles.avatarGridHidden}>
        {hiddenElements.map((item) => (
          <div key={item.key}>{item.element}</div>
        ))}
      </div>
    </div>
  )
}

export default AvatarGrid
