import React, { useState, useEffect } from 'react'
import { logEvent, functions, AnalyticsEvent } from 'utilities/firebase-utils'
import { sendGenericErrorNotification } from 'components/LocalNotification'
import SpinnerOverlay from '../Spinner'
import { CopyLink } from '../CopyLink'

import alertStyles from '../Alerts/alert.module.scss'

interface Props {
  teamID: string
  teamName: string
  onError: () => void
}

export const InviteTeammate: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [teamInviteLink, setTeamInviteLink] = useState('')

  useEffect(() => {
    generateInviteLink()
  }, [])

  const generateInviteLink = () => {
    setLoading(true)
    functions
      .generateTeamInviteLink({ teamID: props.teamID })
      .then((response: functions.GenerateTeamInviteLinkResponse) => {
        if (response.inviteLink) {
          setTeamInviteLink(response.inviteLink)
        } else {
          sendGenericErrorNotification()
        }
      })
      .catch(() => {
        props.onError()
      })
      .finally(() => setLoading(false))
  }

  const onTeamInviteLinkCopied = () => {
    logEvent(AnalyticsEvent.INVITE_LINK_COPIED, {})
  }

  return (
    <div>
      <SpinnerOverlay darkMode active={loading} />
      <h3 className={alertStyles.header}>Invite your teammates</h3>
      <p className={alertStyles.message}>
        Share this link with your team to join you on Multi
      </p>

      <CopyLink link={teamInviteLink} onLinkCopied={onTeamInviteLinkCopied} />
    </div>
  )
}
