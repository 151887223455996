import Button from 'brand/Button'
import React from 'react'
import { Link } from 'react-router-dom'

import styles from './JoinRoom.module.scss'
import RoomEmoji from './RoomEmoji'

type Props = {
  hasValidToken: boolean
  onLaunch: () => void
  onJoinTeam: () => void
  roomName?: string
  userName?: string
  teamName?: string
  icon?: string
  color?: {
    start: string
    end: string
  }
}

const LoggedOut: React.FC<Props> = (props: Props) => {
  const {
    hasValidToken,
    onLaunch,
    onJoinTeam,
    roomName,
    userName,
    icon,
    color,
    teamName,
  } = props

  return hasValidToken ? (
    <div className={styles.centered}>
      <h1>
        {roomName && (
          <>
            Joining {icon && color && <RoomEmoji icon={icon} color={color} />}
          </>
        )}
        {userName && <>{userName} invited you to join a call</>}
      </h1>
      {roomName && (
        <p>{roomName} is a room in Multi, your team&apos;s virtual office.</p>
      )}
      {userName && teamName && (
        <p>
          {teamName} in Multi is your {"team's"} virtual office
        </p>
      )}
      <div>
        <Button darkMode fluid onClick={() => onJoinTeam()}>
          Log in
        </Button>
      </div>
      <div style={{ marginTop: '18px' }}>
        <Button darkMode kind='secondary' fluid onClick={() => onJoinTeam()}>
          Sign up
        </Button>
      </div>
      <div className={styles.marginTop}>
        Already have Multi?
        <button
          className='transparentButton darkMode'
          onClick={() => onLaunch()}
        >
          Open the app
        </button>
        or
        <Link className='transparentButton darkMode' to='/download'>
          Download for macOS
        </Link>
        .
      </div>
    </div>
  ) : (
    <div className={styles.centered}>
      {roomName && (
        <>
          <p>This is a room in Multi, your team&apos;s virtual office.</p>
          <h1>Joining a room</h1>
        </>
      )}
      {userName && <h1>{userName} invited you to join a call</h1>}
      {userName && teamName && (
        <p>
          {teamName} in Multi is your {"team's"} virtual office
        </p>
      )}
      <div>
        <Button darkMode onClick={() => onLaunch()}>
          Open Multi App
        </Button>
      </div>
      <div className={styles.marginTop}>
        Don&apos;t have an account? For your security, ask someone on the team
        for a new invite link.
      </div>
    </div>
  )
}

export default LoggedOut
