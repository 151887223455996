import React from 'react'

import Header from 'components/UI/Header'
import SpinnerOverlay from 'components/UI/Spinner'
import { useAuth } from 'components/AuthProvider'

import { useGuestCall, GuestModeState } from './GuestCallProvider'
import GuestCallEnded from './GuestCallEnded'
import GuestCallOngoingContainer from './GuestCallOngoing'
import { GuestCallPreCall, GuestCallAVSetup } from './GuestCallSetup'

import styles from './GuestCall.module.scss'
import ErrorPage from './components/ErrorPage'

const GuestCall: React.FC = () => {
  const { guestModeState, joinCallAsync } = useGuestCall()
  const { isAuthAndAccountInitialized } = useAuth()

  if (isAuthAndAccountInitialized === false || joinCallAsync.loading) {
    return (
      <GuestCallWrapper>
        <SpinnerOverlay darkMode active={true} />
      </GuestCallWrapper>
    )
  }

  switch (guestModeState) {
    case GuestModeState.PreCall:
      return (
        <GuestCallWrapper>
          <GuestCallPreCall />
        </GuestCallWrapper>
      )
    case GuestModeState.SetupAV:
      return (
        <GuestCallWrapper>
          <GuestCallAVSetup />
        </GuestCallWrapper>
      )
    case GuestModeState.InCall:
      return (
        <div className={styles.mainContainer}>
          <GuestCallOngoingContainer />
        </div>
      )
    case GuestModeState.CallEnded:
      return <GuestCallEnded />

    case GuestModeState.SafariUser:
      return (
        <GuestCallWrapper>
          <ErrorPage
            headline='Are you on Safari?'
            body='Multi is supported in Chrome on macOS. For the best experience, download our macOS desktop app.'
            btnLink={{ copy: 'Download', linkTo: '/signup' }}
          />
        </GuestCallWrapper>
      )

    case GuestModeState.IOSUser: {
      return (
        <GuestCallWrapper>
          <ErrorPage
            headline='Are you on iOS?'
            body='Multi is supported in Chrome on macOS. For the best experience, download our macOS desktop app.'
            btnLink={{ copy: 'Sign Up', linkTo: '/signup' }}
          />
        </GuestCallWrapper>
      )
    }

    case GuestModeState.ZoomUnsupported:
      return (
        <GuestCallWrapper>
          <ErrorPage
            headline='Under construction'
            body='Zoom calls in web are still a work in progress, stay tuned!'
          />
        </GuestCallWrapper>
      )
  }
}

const GuestCallWrapper: React.FC<{ children: React.ReactNode }> = (props) => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerContainer}>
        <Header transparent hideNav size='medium' darkMode hideLogout={true} />
      </div>
      {props.children}
    </div>
  )
}

export default GuestCall
