import React from 'react'
import { SetupPage } from './SetupPage'

import styles from './SetupPage.module.scss'

const CloseAddonAuthPopup: React.FC = () => {
  return (
    <SetupPage title='Success!'>
      <h1>Success!</h1>
      <p className={styles.caption}>
        You can close the popup and reload Google Calendar to start scheduling
        events with Multi
      </p>
    </SetupPage>
  )
}

export default CloseAddonAuthPopup
