import React from 'react'
import { toast, ToastOptions, Slide } from 'react-toastify'
import ToastUI, { ToastType } from './Toast'

const toastOptions: ToastOptions = {
  position: 'top-center',
  autoClose: 10000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  transition: Slide,
  rtl: false,
  closeButton: false,
}

export const sendNotification = (
  message: string,
  type: ToastType,
  options = {}
) => {
  toast(<ToastUI message={message} type={type} />, {
    ...toastOptions,
    ...options,
  })
}

export const sendGenericErrorNotification = () => {
  const message =
    'Something went wrong, try again or contact support@multi.app'
  sendNotification(message, 'error')
}

export default sendNotification
