import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import styles from '../scss/container.module.scss'
import Button from 'brand/Button'
import { useHistory, useLocation } from 'react-router'

const errorCodes: { [key: string]: string } = {
  1001: 'That Slack workspace is already linked to a different Multi team. Visit the Manage Teams page to manage your Slack workspace connections.',
  1002: 'Another Slack workspace is already linked to this Multi team. Visit the Manage Teams page to manage your Slack workspace connections.',
  1003: 'That Slack user is already linked with a different Multi user. Visit the Manage Teams page to manage your Slack workspace connections.',
  1004: 'Your Multi account is already linked with a different user in that Slack workspace. Visit the Manage Teams page to manage your Slack workspace connections.',
  1005: 'Slack authentication failed, permission denied to access the Slack workspace.',
  1010: 'Something went wrong during Slack authentication--please try again. If problem persists, please write to us at hello@multi.app',
}
const LinkSlackAccountError = () => {
  const [errorMessage, setErrorMessage] = useState(errorCodes[1010])

  const history = useHistory()
  const location = useLocation()
  const shouldGoToOnboarding = localStorage.getItem('onboarding')

  const clickHandler = () => {
    if (shouldGoToOnboarding) {
      history.replace('/onboarding')
    } else {
      history.replace('/teams')
    }
  }

  useEffect(() => {
    const query = queryString.parse(location.search)
    const errorCode = query.errorCode
    if (typeof errorCode !== 'string') {
      history.replace('/teams')
      return
    }
    const message = errorCodes[errorCode] ?? errorCodes['1010']
    setErrorMessage(message)
  }, [])

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>We can’t link your account</h1>
      <p className={styles.subHeader}>{errorMessage}</p>
      <Button fluid darkMode size='large' onClick={clickHandler}>
        {shouldGoToOnboarding ? 'Continue' : 'Go to team management page'}
      </Button>
    </div>
  )
}
export default LinkSlackAccountError
