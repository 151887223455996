import React from 'react'
import Button from 'brand/Button'

import styles from './SetupPage.module.scss'

type Props = {
  headline: string
  errorMsg?: string
  retryButtonMessage?: string
  retryAction?: () => void
}

/**
 *
 * @param headline: string
 * @param errorMsg?: string - optional additional error details
 * @param retryAction?: () => void - optional function to retry error
 */
const Error: React.FC<Props> = (props: Props) => {
  return (
    <>
      <h1>{props.headline}</h1>
      {props.errorMsg && (
        <p className={styles.caption}>Error: {props.errorMsg}</p>
      )}
      {props.retryAction && (
        <Button fluid onClick={props.retryAction}>
          {props.retryButtonMessage ?? "Try Again"}
        </Button>
      )}
      <p className={styles.centered}>
        {props.retryAction
          ? 'Still not working? Let us know at support@multi.app'
          : 'Contact us at support@multi.app'}
      </p>
    </>
  )
}

export default Error
