import React, { useEffect, useMemo } from 'react'

import micOn from '../assets/mic-on.svg'
import videoOn from '../assets/video-on.svg'
import micOff from '../assets/mic-off.svg'
import videoOff from '../assets/video-off.svg'
import micOffYellow from '../assets/mic-off-yellow.svg'
import videoOffYellow from '../assets/video-off-yellow.svg'
import caretDown from '../assets/caret-down.svg'
import checkmark from '../assets/checkmark.svg'
import styles from './CallControl.module.scss'
import { Popup } from 'semantic-ui-react'

enum DeviceState {
  on = 'on',
  off = 'off',
  disabled = 'disabled',
}

export enum DeviceTypes {
  AudioInput = 'audioinput',
  VideoInput = 'videoinput',
}

const iconMap: Record<DeviceTypes, Record<DeviceState, string>> = {
  audioinput: {
    on: micOn,
    off: micOff,
    disabled: micOffYellow,
  },
  videoinput: {
    on: videoOn,
    off: videoOff,
    disabled: videoOffYellow,
  },
}

type Props = {
  type: DeviceTypes
  devices: MediaDeviceInfo[]
  enabled: boolean
  selectedDeviceId?: string
  onToggle: () => void
  onDeviceSelected: (deviceId: string) => void
  onDeviceSelectionOpen: () => void
}

const CallControl: React.FC<Props> = (props: Props) => {
  const {
    type,
    devices,
    enabled,
    selectedDeviceId,
    onToggle,
    onDeviceSelected,
    onDeviceSelectionOpen,
  } = props

  useEffect(() => {
    if (!selectedDeviceId && devices.length > 0) {
      // Pre-select first device if there is no selected device
      onDeviceSelected(devices[0].deviceId)
    } else if (selectedDeviceId && devices.length > 0) {
      // If the selected device is no longer available, pre-select the first device
      const device = devices.find((item) => item.deviceId === selectedDeviceId)
      if (!device) {
        onDeviceSelected(devices[0].deviceId)
      }
    }
  }, [devices, selectedDeviceId])

  const tooltip = useMemo(() => {
    if (selectedDeviceId) {
      return
    }
    switch (type) {
      case DeviceTypes.AudioInput:
        return 'No audio input device available'
      case DeviceTypes.VideoInput:
        return 'No video input device available'
    }
  }, [type, selectedDeviceId])

  const controlState: DeviceState = useMemo(() => {
    if (!devices.length) {
      return DeviceState.disabled
    }
    return enabled ? DeviceState.on : DeviceState.off
  }, [enabled, devices.length])

  return (
    <div className={styles.callControl}>
      <img
        className={styles.callControlLeft}
        src={iconMap[type][controlState]}
        width='32'
        height='32'
        onClick={onToggle}
        title={tooltip}
      />
      <div className={styles.divider} />
      <Popup
        disabled={devices.length === 0}
        closeOnDocumentClick
        closeOnEscape
        on='click'
        trigger={<img src={caretDown} width='17' height='17' />}
        className={styles.popup}
        onOpen={onDeviceSelectionOpen}
      >
        <Popup.Content>
          {devices.map((device) => (
            <div
              key={device.deviceId}
              className={styles.device}
              onClick={() => onDeviceSelected(device.deviceId)}
            >
              {nameForDevice(device)}
              {device.deviceId === selectedDeviceId || devices.length === 1 ? (
                <img src={checkmark} width='24' height='24' />
              ) : (
                <div className={styles.imagePlaceholder} />
              )}
            </div>
          ))}
        </Popup.Content>
      </Popup>
    </div>
  )
}

function nameForDevice(device: MediaDeviceInfo): string {
  // Some devices (like the internal MacBook microphone) will include "Default" in
  // their reported label when they are the default device. Others (AirPods) will *not*
  // include the word "Default", making it look like there are duplicated devices in
  // the list (RM 5293)
  if (device.deviceId === 'default') {
    if (device.label.toLowerCase().includes('default')) {
      return device.label
    }
    return `Default - ${device.label}`
  }
  return device.label
}

export default CallControl
