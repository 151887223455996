import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  track,
  EventTypes,
  usePageTracking,
  PageCategory,
} from 'utilities/analytics'
import { useAuth } from 'components/AuthProvider'
import { LocalStorageItem, getItem } from 'data/localStorage'

import { SetupPage } from 'components/Setup/SetupPage'
import Button from 'brand/Button'

import setupStyles from './SetupPage.module.scss'
import Input from 'brand/Input/Input'
import { isProbablyMobile } from 'utilities/browserUtil'
import classNames from 'classnames'
import { HeaderWithAppIcon } from 'components/UI/HeaderWithAppIcon/HeaderWithAppIcon'
import { functions } from 'utilities'

const DOWNLOAD_URL = 'https://updates.multi.app/latest'

const InvitePage: React.FC = () => {
  const { user } = useAuth()
  const teamID = user?.teams[0].id
  const teamInviteLink = getItem(LocalStorageItem.teamInviteLink)

  usePageTracking(PageCategory.onboarding, 'Invite')

  const downloadAgainClicked = () => {
    window.open(DOWNLOAD_URL, '_self')
    functions.onboardingEvent({
      step: `"Download again" clicked on Invite page`,
    })
    track(EventTypes.redownloadLinkClicked)
  }

  const header = useMemo(() => {
    if (isProbablyMobile()) {
      return <h1>Continue to download on desktop</h1>
    }

    return <HeaderWithAppIcon title='Downloading Multi' message='' />
  }, [])

  const caption = useMemo(() => {
    if (isProbablyMobile()) {
      return (
        <>
          <p className={setupStyles.caption}>
            We sent a download link to <b>{user?.email ?? 'your email'}</b>
          </p>
          <p className={setupStyles.caption}>
            On macOS?
            <a href={DOWNLOAD_URL} onClick={downloadAgainClicked}>
              Download now
            </a>{' '}
          </p>
        </>
      )
    }

    return (
      <>
        <p className={setupStyles.centered}>
          Not downloading?{' '}
          <a href={DOWNLOAD_URL} onClick={downloadAgainClicked}>
            Try again
          </a>{' '}
          <br />
          Or, already have the app? <Link to='/launch'>Launch Multi</Link>
        </p>

        <p
          className={classNames(
            setupStyles.caption,
            setupStyles.macOS12OrHigher
          )}
        >
          Multi requires macOS 12+
        </p>
      </>
    )
  }, [user?.email])

  const content = useMemo(() => {
    if (teamInviteLink && teamID) {
      return (
        <section className={setupStyles.inviteContent}>
          <hr />
          <p
            className={classNames(
              setupStyles.caption,
              setupStyles.mobileInputCaption
            )}
          >
            Invite your team
          </p>
          <InviteTeamLink teamInviteLink={teamInviteLink} teamID={teamID} />
        </section>
      )
    } else {
      return <></>
    }
  }, [teamID, teamInviteLink])

  return (
    <SetupPage title='Invite your Team'>
      <section>
        {header}
        {caption}
        {content}
      </section>
    </SetupPage>
  )
}

export default InvitePage

// Fetch & present a team invite link.
const InviteTeamLink: React.FC<{ teamInviteLink: string; teamID: string }> = ({
  teamInviteLink,
  teamID,
}) => {
  const [isCopied, setCopied] = useState(false)

  const copyLink = () => {
    functions.onboardingEvent({
      step: `"Copy link" clicked`,
    })
    track(EventTypes.teamInviteLinkCopied, { team_id: teamID })
    navigator.clipboard.writeText(teamInviteLink)
    setCopied(true)
  }

  return (
    <div className={setupStyles.inviteLinkWrapper}>
      <Input value={teamInviteLink} readOnly ellipsisOverflow darkMode />
      <Button kind='primary' onClick={copyLink} size='small' darkMode>
        {isCopied ? 'Copied!' : 'Copy link'}
      </Button>
    </div>
  )
}
