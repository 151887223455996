import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import keyBy from 'lodash/keyBy'

import { functions } from 'utilities/firebase-utils'
import config from 'configs/generalConfig.json'
import useAgora, { createAgoraClient, UseAgoraReturn } from './useAgora'

import { useGuestCall } from '../../GuestCallProvider'
import {
  useConvo,
  ConvoField,
  addUserToConvoField,
  removeUserFromConvoField,
} from 'models'
import { useAuth } from 'components/AuthProvider'
import { getImageUrl } from 'components/UI/AvatarImage/AvatarImage.helper'
import useMicVolume from 'components/Public/GuestCall/GuestCallOngoing/Zoom/useMicVolume'

const GuestCallAgoraContext = createContext({} as GuestCallAgoraContextType)

type GuestCallAgoraContextType = Omit<UseAgoraReturn, 'leave' | 'join'> & {
  leaveCall: () => Promise<void>
}

export const useGuestCallAgora = (): GuestCallAgoraContextType =>
  useContext(GuestCallAgoraContext)

type Props = {
  children: React.ReactNode
}

const GuestCallAgoraProvider: React.FC<Props> = (props) => {
  const { children } = props
  const {
    convoID,
    convoToken,
    channelDetails,
    avSetup,
    userMap,
    setUserMap,
    shouldLeaveCall,
    leaveCallFirebase,
  } = useGuestCall()

  const convo = useConvo(convoID)
  const { auth } = useAuth()
  const currentUserID = auth?.uid
  const { isLocalUserSpeaking } = useMicVolume()

  const removeLocalUserFirebaseConvoField = useCallback(
    async (field: ConvoField) => {
      if (!currentUserID) {
        return
      }
      try {
        await removeUserFromConvoField({
          field,
          userID: currentUserID,
          convoID,
        })
      } catch (error) {
        console.error(
          `Error removing local user from firebase convo field ${field}:`,
          error
        )
      }
    },
    [convoID, currentUserID]
  )

  const addLocalUserFirebaseConvoField = useCallback(
    async (field: ConvoField) => {
      if (!currentUserID || !convoID) {
        return
      }

      try {
        await addUserToConvoField({
          field,
          userID: currentUserID,
          convoID,
        })
      } catch (error) {
        console.error(
          `Error adding local user from firebase convo field ${field}:`,
          error
        )
      }
    },
    [convoID, currentUserID]
  )

  const agoraClient = useMemo(createAgoraClient, [])
  const agora = useAgora(agoraClient, removeLocalUserFirebaseConvoField)

  const leaveCall = useCallback(async () => {
    // As a call is active, capture the providers' leave function to be called first
    // sets local state, calls leaveConversation server function and clear firebase presence
    await leaveCallFirebase(agora.leave)
  }, [leaveCallFirebase, agora.leave])

  // Follow changes to Convo.
  useEffect(() => {
    if (shouldLeaveCall(convo)) {
      leaveCall()
    }
  }, [convo, shouldLeaveCall, leaveCall])

  // leave call when in call experience is unmounting
  useEffect(() => {
    return () => {
      leaveCall()
    }
    // intentionally disabling since we don't want leaveCall() run on each re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // also leave call when user closes the tab
  useEffect(() => {
    function handleTabClose(event: BeforeUnloadEvent) {
      event.preventDefault()
      leaveCall()
      event.returnValue = '' // as of Chrome 51, the message displayed in the alert window on close tab is not customizable
    }

    window.addEventListener('beforeunload', handleTabClose)

    return () => {
      window.removeEventListener('beforeunload', handleTabClose)
    }
  }, [leaveCall])

  // join a call as soon as channelDetails are available
  useEffect(() => {
    joinCall()

    async function joinCall() {
      if (!channelDetails) {
        return
      }

      const { userID, channelID, token = '', secret = '' } = channelDetails

      agora.join(config.agoraAppID, channelID, token, secret, userID, avSetup)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelDetails])

  // get remote users' avatars and names from server
  useEffect(() => {
    if (!channelDetails || !convo?.users.length) return

    // fetch for users not in userMap
    const usersToQuery = convo.users
      .filter((user) => !userMap[user.id])
      .map((user) => user.id)

    if (usersToQuery.length > 0) {
      fetchUsersAndAvatars(usersToQuery)
    }

    async function fetchUsersAndAvatars(userIDs: string[]) {
      const { users } = await functions.getUsersInConversation({
        convoID,
        userIDs,
        token: convoToken,
      })
      const fetchAvatars = users.map((user) =>
        user.avatar ? getImageUrl(user.avatar) : Promise.resolve('')
      )
      const avatars = (await Promise.allSettled(fetchAvatars)).map((result) =>
        result.status === 'fulfilled' ? result.value : ''
      )
      const usersWithAvatars = users.map((user, i) => ({
        ...user,
        avatarUrl: avatars[i],
      }))

      const newUserData = keyBy(usersWithAvatars, 'id')
      setUserMap((currentValue) => ({
        ...currentValue,
        ...newUserData,
      }))
    }

    // omit userMap and setUserMap
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelDetails, convo?.users, convoID, convoToken])

  // update local user AV mute/unmute states to firebase
  const updateUserAVToFirebase = useCallback(
    (isAVEnabled: boolean, field: ConvoField) => {
      if (!currentUserID || !convoID) return

      if (isAVEnabled) {
        addUserToConvoField({
          field,
          userID: currentUserID,
          convoID,
        })
      } else {
        removeUserFromConvoField({
          field,
          userID: currentUserID,
          convoID,
        })
      }
    },
    [currentUserID, convoID]
  )

  useEffect(() => {
    updateUserAVToFirebase(avSetup.videoEnabled, ConvoField.CameraSharingUsers)
  }, [avSetup.videoEnabled, updateUserAVToFirebase])

  useEffect(() => {
    updateUserAVToFirebase(avSetup.audioEnabled, ConvoField.AudioSharingUsers)
  }, [avSetup.audioEnabled, updateUserAVToFirebase])

  // update local user speaking state to firebase talkingUsers
  useEffect(() => {
    if (isLocalUserSpeaking) {
      addLocalUserFirebaseConvoField(ConvoField.TalkingUsers)
    } else {
      removeLocalUserFirebaseConvoField(ConvoField.TalkingUsers)
    }
  }, [
    addLocalUserFirebaseConvoField,
    isLocalUserSpeaking,
    removeLocalUserFirebaseConvoField,
  ])

  return (
    <GuestCallAgoraContext.Provider
      value={{
        ...agora,
        leaveCall,
      }}
    >
      {children}
    </GuestCallAgoraContext.Provider>
  )
}

export default GuestCallAgoraProvider
