import React, { useState, useEffect, useCallback, useMemo } from 'react'
import throttle from 'lodash/throttle'
import classNames from 'classnames'

import micOffIcon from '../assets/mic-off-black.svg'
import videoOffIcon from '../assets/video-off-black.svg'
import DefaultAvatar from './DefaultAvatar'
import IconScreenshare from 'components/UI/Icons/Screenshare'
import { getInitials } from '../guest-call-utils'
import { SpeakingUserState } from 'components/Public/GuestCall/GuestCallOngoing/Agora/useAgora'

import styles from './AvatarContainer.module.scss'

type Props = {
  children?: React.ReactNode
  audioOff?: boolean
  videoOff?: boolean
  screensharing?: boolean
  avatarUrl?: string
  fullName?: string
  speakingState?: SpeakingUserState
  isOnPage?: boolean // for pagination, is the AvatarContainer on the active page
  cover?: 'circle' | 'rectangle'
}

const AvatarContainer: React.FC<Props> = (props) => {
  const {
    children,
    audioOff,
    videoOff,
    screensharing,
    avatarUrl,
    fullName,
    speakingState,
    isOnPage,
    cover = 'circle',
  } = props

  // pass isOnPage prop through to all children
  const childrenWithProps = useMemo(() => {
    if (!children) {
      return children
    }

    return React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) {
        return child
      }

      // since AvatarContainer is used for a variety of child elements with various Props and we want to pass the isOnPage prop to all,
      // we need to use any in the following line
      const childWithProps = React.cloneElement(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        child as React.ReactElement<any>,
        { isonpage: isOnPage } // must be lowercased
      )

      return childWithProps
    })
  }, [children, isOnPage])

  return (
    <div className={classNames(styles.avatarContainer, styles[cover])}>
      <DefaultAvatar userInitials={getInitials(fullName)} />
      {avatarUrl && (
        <img
          src={avatarUrl}
          alt='avatar'
          className={styles.avatarImage}
          loading='lazy'
        />
      )}
      {childrenWithProps}
      {fullName && (
        <div className={styles.fullNameContainer}>
          <span className={styles.fullName}>{fullName}</span>
        </div>
      )}
      <div className={styles.avInfo}>
        {audioOff && (
          <div className={styles.avElement}>
            <img src={micOffIcon} width='24' height='24' />
          </div>
        )}
        {videoOff && (
          <div className={styles.avElement}>
            <img src={videoOffIcon} width='24' height='24' />
          </div>
        )}
        {screensharing && (
          <div className={styles.avElement}>
            <IconScreenshare />
          </div>
        )}
      </div>
      <SpeakingIndicator speakingState={speakingState} />
    </div>
  )
}

type SpeakingIndicatorProps = {
  speakingState?: SpeakingUserState
}

const SpeakingIndicator = ({ speakingState }: SpeakingIndicatorProps) => {
  const [visibleSpeakingState, setVisibleSpeakingState] = useState(false)

  const speakingThrottle = useCallback(
    throttle(
      (on: boolean) => {
        setVisibleSpeakingState(on)
      },
      500,
      { leading: true }
    ),
    []
  )

  useEffect(() => {
    speakingThrottle(speakingState?.isSpeaking ?? false)
  }, [speakingState])

  return (
    <div
      className={classNames({ [styles.avSpeaking]: visibleSpeakingState })}
    />
  )
}

export default AvatarContainer
