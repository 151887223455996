import React from 'react'
import styles from './logo.module.scss'
import logoBlack from './logo-black.svg'
import logoWhite from './logo-white.svg'
import config from 'configs/generalConfig.json'
import classNames from 'classnames'

type Props = {
  color?: 'black' | 'white'
  size?: 'small' | 'regular' | 'medium'
}

const logo: React.FC<Props> = ({
  color = 'black',
  size = 'regular',
}: Props) => (
  <div className={classNames(styles.logo, styles[size])}>
    <a href={config.remotionSite}>
      <img src={color === 'black' ? logoBlack : logoWhite} alt='Multi Logo' />
    </a>
  </div>
)

export default logo
