/**
 * This file contains utility functions to calculate video height, width, xOffset, yOffset and the number of rows, columns and pages
 * necessary to render Zoom participant videos on a single canvas
 * flowchart: https://www.figma.com/file/QajncVO9HoXA3TvmvfjGgu/zoom-video-grid-packing?node-id=1%3A178&t=SlcmEsuHhzO0zpWH-0
 */

// calculation constants defined in px
// const GAP = 20 // space between videos
export const ZOOM_ASPECT_RATIO = 16 / 9
export const MIN_WIDTH = 160 // mininum video width in px
export const MIN_HEIGHT = MIN_WIDTH / ZOOM_ASPECT_RATIO // min video height in px
const NUM_COLS_WHILE_SCREENSHARE = 1 // number of allowed columns during screensharing
export const MAX_VIDEOS_PER_PAGE = 16 // maxiumum number of videos allowed on a page

type Params = {
  x: number
  y: number
  n: number
  isScreenshare: boolean
}

/**
 * Given Params, this function determines if it is possible for n videos to fit on a single page
 * with at least the min video width and height (if all videos can fit on a single page, this means that
 * videos are allowed to be larger than the min height and min width)
 * @param {number} x canavas width in px
 * @param {number} y canavas height in px
 * @param {number} n number of videos to render
 * @param {boolean} isScreenshare if true, enforce numCols = NUM_COLS_WHILE_SCREENSHARE
 * @returns boolean representing whether the given number of videos will fit on one page if the videos are
 * min height and width
 */
export function doVideosFitOnOnePageIfAtLeastMinVideoSize({
  x,
  y,
  n,
  isScreenshare,
}: Params): boolean {
  // if screenshare doesn't matter if it fits on one page, will always have videos be min width and height
  if (isScreenshare) {
    return false
  }
  // check for num videos more than MAX_VIDEOS_PER_PAGE
  if (n > MAX_VIDEOS_PER_PAGE) {
    return false
  }

  // check there is enough area on the canvas to fit the total area all videos would be in their smallest state
  // (Don't even bother checking row/col constraints otherwise)
  if (n * MIN_WIDTH * MIN_HEIGHT > x * y) {
    return false
  }

  // check that there is a number of rows and columns for which videos can fit into
  // if the videos are at min height and width
  for (let numRows = 1; numRows <= n; numRows++) {
    const numCols = Math.ceil(n / numRows)
    if (numRows * MIN_HEIGHT <= y && numCols * MIN_WIDTH <= x) {
      return true
    }
  }

  return false
}

export type CalcVideoCanvasGridReturn = {
  videoHeight: number
  videoWidth: number
  numRows: number
  numCols: number
  numPages: number
}

/**
 * Given a number of rectangular videos to fit and a set canvas height and width,
 * calculates the height and width of each video element as well as the number of
 * rows, columns and pages required to fit these participants.
 * Only use this function if you previously used doVideosFitOnOnePageIfNotMinVideoSize
 * to determine that all n videos will fit on a single page
 * This function is meant to be used with 16:9 rectangular videos (zoom's aspect ratio)
 * @param {number} x - canavas width in px
 * @param {number} y - canavas height in px
 * @param {number} n - number of videos to render
 */
export function calcVideoCanvasGrid({
  x,
  y,
  n,
}: Omit<Params, 'isScreenshare'>): CalcVideoCanvasGridReturn {
  // find the min and max number of columns that would be able to fit on the given canvas size
  const possibleColsInCanvas = Math.floor(x / MIN_WIDTH)
  const maxCols = Math.min(n, possibleColsInCanvas)

  const possibleRowsInCanvas = Math.floor(y / MIN_HEIGHT)
  const maxRows = Math.min(n, possibleRowsInCanvas)
  const minCols = Math.ceil(n / maxRows)

  // start by assuming that the minimum video height and width
  let largestWidth = MIN_WIDTH
  let largestHeight = MIN_HEIGHT

  // for columns beginning at the max number of columns, down to the min number of columns
  for (let loopNumCols = maxCols; loopNumCols >= minCols; loopNumCols--) {
    // find number of rows needed to fit n videos
    const loopNumRows = Math.ceil(n / loopNumCols)

    // for this number of columns and rows, we section off the canvas into equal size "cells"
    const cellWidth = x / loopNumCols
    const cellHeight = y / loopNumRows

    const { videoHeight, videoWidth } = calcVideoWidthAndHeightForCell(
      cellWidth,
      cellHeight
    )

    // if video width and height are larger than current greatest video width and height
    // replace current greatest values
    if (videoHeight > largestHeight && videoWidth > largestWidth) {
      largestHeight = videoHeight
      largestWidth = videoWidth
    }
  }

  const numCols = Math.floor(x / largestWidth)
  const numRows = Math.floor(y / largestHeight)

  return {
    videoHeight: largestHeight,
    videoWidth: largestWidth,
    numRows,
    numCols,
    numPages: 1, // this algo only used when all users fit on one page, thus numPages is always 1
  }
}

/**
 * Helper function used in calcVideoCanvasGrid. Given the size of a cell, determines the height and width of a video
 * with ZOOM_ASPECT_RATIO that can fit into the cell
 * @param cellWidth
 * @param cellHeight
 * @returns {number} videoWidth and videoHeight for an individual video in the canvas
 */
function calcVideoWidthAndHeightForCell(
  cellWidth: number,
  cellHeight: number
): { videoWidth: number; videoHeight: number } {
  // since video size is still in the 16:9 aspect ratio, either x or y will be the limiting direction for video size
  const cellAspectRatio = cellWidth / cellHeight

  // if cell has extra width
  if (cellAspectRatio / ZOOM_ASPECT_RATIO >= 1) {
    // contraining direction is height: set video height, calculate video width
    return {
      videoHeight: cellHeight,
      videoWidth: cellHeight * ZOOM_ASPECT_RATIO,
    }
  } else {
    // else cell has extra height: set video width, calculate video height
    return {
      videoWidth: cellWidth,
      videoHeight: cellWidth / ZOOM_ASPECT_RATIO,
    }
  }
}

/**
 * Given a number of rectangular videos to fit and a set canvas height and width,
 * calculates the number of rows, columns and pages required to fit these participants,
 * given that participants are the minimum video width and height.
 * This function is meant to be used with 16:9 rectangular videos (zoom's aspect ratio)
 * @param {number} x - canavas width in px
 * @param {number} y - canavas height in px
 * @param {number} n - number of videos to render
 * @param {boolean} isScreenshare - if true, enforce numCols = NUM_COLS_WHILE_SCREENSHARE
 */
export function calcVideoCanvasGridMinHeightAndWidth({
  x,
  y,
  n,
  isScreenshare,
}: Params): CalcVideoCanvasGridReturn {
  const maxRowsInPage = Math.floor(y / MIN_HEIGHT) // the maximum number of rows that can fit into a given page height
  const maxColsInPage = Math.floor(x / MIN_WIDTH) // the  maximum number of cols that can fit into a given page width
  let numCols = maxColsInPage

  if (isScreenshare) {
    numCols = Math.min(NUM_COLS_WHILE_SCREENSHARE, maxColsInPage) // the lesser of either the constant value or the columns that can fit into the page
  }

  // enforce at least one column
  if (numCols < 1) {
    numCols = 1
  }

  const numRowsForAllVideos = Math.ceil(n / numCols) // number of rows needed to show all videos
  const numRows = Math.min(numRowsForAllVideos, maxRowsInPage) // the actual number of rows to show

  const numPages = Math.ceil(numRowsForAllVideos / maxRowsInPage) // number of pages needed to fufill video heights
  return {
    videoHeight: MIN_HEIGHT,
    videoWidth: MIN_WIDTH,
    numRows,
    numCols,
    numPages,
  }
}

export type CalcRenderVideoLocationParams = {
  numRows: number
  numCols: number
  videoWidth: number
  videoHeight: number
  videoIndex: number
  y: number
}

export type CalcRenderVideoLocationReturn = {
  xOffset: number
  yOffset: number
  page: number
}

/**
 * starting at the top left of the cavnas being the 0th cell, this function figures out which cell the video at a given index should fit into,
 * what the x and y offsets need to be place the video there, and which page the video should be rendered on
 * for reference, Zoom considers the bottom left corner of the canvas element to be x=0, y=0:
 * https://marketplace.zoom.us/docs/sdk/video/web/essential/video/#render-participant-videos
 * @param {number} numRows the number of rows in the canvas
 * @param {number} numCols the number of columns in the canvas
 * @param {number} videoWidth the width of an individual video in px
 * @param {number} videoHeight the height of an individual video in px
 * @param {number} videoIndex the index of the video (in an array of all participants who need to be rendered)
 * @returns {number} xOffset - number of px in x direction to offset given video by
 * @returns {number} yOffset - number of px in y direction to offset given video by
 * @returns {number} page - which page the video should be rendered on
 */
export function calcRenderVideoLocation({
  numRows,
  numCols,
  videoWidth,
  videoHeight,
  videoIndex,
  y,
}: CalcRenderVideoLocationParams): CalcRenderVideoLocationReturn {
  // find out which page the given video should be rendered on (page count starts from zero)
  const videosPerPage = numRows * numCols
  const page = Math.floor(videoIndex / videosPerPage)

  // find which cell the video should be in on the given page
  const cellIndex = videoIndex - page * videosPerPage

  // find which column the cell is in
  const cellCol = cellIndex % numCols

  // find which row the cell is in
  const cellRow = Math.floor(cellIndex / numCols)

  // find xOffset in px for the cellCol
  const xOffset = cellCol * videoWidth

  // find yOffset in px for the cellRow
  // const yOffset = (numRows - 1 - cellRow) * videoHeight // this one for starting rows from the bottom to top
  const yOffset = y - videoHeight * (cellRow + 1) // this one for rows top to bottom
  return {
    xOffset,
    yOffset,
    page,
  }
}
