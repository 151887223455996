import { History } from 'history'

export const launchRemotion = (
  history: History,
  {
    convoID,
    userID,
    source,
  }: { convoID?: string; userID?: string; source: string }
): void => {
  if (convoID) {
    // JoinRoomInMacOS component
    history.push(`/join-room/${convoID}?source=${source}`)
  }
  if (userID) {
    // TalkInRemotion component
    history.push(`/talk?userID=${userID}&source=${source}`)
  }
}
