import { MightHaveId } from './MightHaveId'
import {
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore'
import {
  safePrimitiveGet,
  safeArrayRefGet,
  safeDateGet,
  safePrimitiveNestedGet,
} from './utils'

/** The name of the Firestore collection where users are stored. */
export const USERS_COLLECTION = 'Users'
export interface AppUser extends MightHaveId {
  email: string
  isOnline: boolean
  firstName?: string
  lastName?: string
  displayName?: string
  pronouns?: string
  avatarFile?: string
  teams: DocumentReference[]
  googleUserEmail?: string
  googleCalendarEnabled?: boolean
  hideGoogleCalendarEventTitles?: boolean

  availability: UserAvailability
  availabilityLastChanged?: Date
  lastActiveDate?: Date
  lastConnection?: Date
  lastDisconnection?: Date
  timeZone?: string
  cumulativeConvoSeconds?: number
  statusLastChanged?: Date
  statusRealtimeLastChanged?: number
  type?: string
  liveSelfieFile?: string
  googleUser?: {
    googleCalendarStatus?: {
      title: string
      isActive: boolean
    }
  }
  capabilities?: string[]
  conversationID?: string
  isGuest?: boolean
}

enum UserAvailability {
  online = 'online', // default
  openToTalk = 'openToTalk',
  focusing = 'focusing',
  away = 'away',
}

function getUserAvailability(
  availability: string | undefined
): UserAvailability {
  switch (availability) {
    case 'online':
      return UserAvailability.online
    case 'openToTalk':
      return UserAvailability.openToTalk
    case 'focusing':
      return UserAvailability.focusing
    case 'away':
      return UserAvailability.away
    default:
      return UserAvailability.online
  }
}

/** Implements a 'converter' function for use with `useConverter`,
 *  building out returned documents as domain-specific AppUser objects */
export const userConverter: FirestoreDataConverter<AppUser> = {
  toFirestore: (user: AppUser): DocumentData => {
    return user as any
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): AppUser => {
    const data = snapshot.data(options)
    const appUser = createUser(data, snapshot.id)
    return appUser as AppUser
  },
}

export const createUser = (
  data: DocumentData | undefined,
  id: string | undefined
): AppUser | null => {
  if (!data) {
    return null
  }
  if (typeof data.email !== 'string' && !data.isGuest) {
    return null
  }

  const newUser: AppUser = {
    ID: id,
    email: data.email, // should always be set
    isOnline: false,
    teams: [],
    availability: UserAvailability.online,
  }

  newUser.firstName = safePrimitiveGet(data, 'firstName', 'string')
  newUser.lastName = safePrimitiveGet(data, 'lastName', 'string')
  newUser.displayName = safePrimitiveGet(data, 'displayName', 'string')
  if (!newUser.displayName) {
    newUser.displayName = `${newUser.firstName ?? ''}`
  }
  newUser.pronouns = safePrimitiveGet(data, 'pronouns', 'string')
  newUser.isOnline = safePrimitiveGet(data, 'isOnline', 'boolean') ?? false
  newUser.avatarFile = safePrimitiveGet(data, 'avatarFile', 'string')

  newUser.availability = getUserAvailability(
    safePrimitiveGet(data, 'availability', 'string')
  )
  newUser.teams = safeArrayRefGet(data, 'teams')
  newUser.googleUser = safePrimitiveGet(data, 'googleUser', 'object')
  newUser.googleUserEmail = safePrimitiveNestedGet(
    data,
    'googleUser',
    'googleUserEmail',
    'string'
  )
  newUser.googleCalendarEnabled = safePrimitiveNestedGet(
    data,
    'googleUser',
    'googleCalendarEnabled',
    'boolean'
  )
  newUser.hideGoogleCalendarEventTitles = safePrimitiveNestedGet(
    data,
    'googleUser',
    'hideCalendarEventTitles',
    'boolean'
  )

  newUser.availabilityLastChanged = safeDateGet(data, 'availabilityLastChanged')
  newUser.lastActiveDate = safeDateGet(data, 'lastActiveDate')
  newUser.lastConnection = safeDateGet(data, 'lastConnection')
  newUser.lastDisconnection = safeDateGet(data, 'lastDisconnection')
  newUser.statusLastChanged = safeDateGet(data, 'statusLastChanged')

  newUser.timeZone = safePrimitiveGet(data, 'timeZone', 'string')
  newUser.cumulativeConvoSeconds = safePrimitiveGet(
    data,
    'cumulativeConvoSeconds',
    'number'
  )
  newUser.statusRealtimeLastChanged = safePrimitiveGet(
    data,
    'statusRealtimeLastChanged',
    'number'
  )
  newUser.type = safePrimitiveGet(data, 'type', 'string')
  newUser.liveSelfieFile = safePrimitiveGet(data, 'liveSelfieFile', 'string')
  newUser.capabilities =
    safePrimitiveNestedGet(
      data,
      'latestAppIdentity',
      'capabilities',
      'object'
    ) ?? []
  newUser.conversationID = safePrimitiveGet(data, 'conversationID', 'string')
  newUser.isGuest = safePrimitiveGet(data, 'isGuest', 'boolean')

  return newUser
}
