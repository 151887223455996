import React from 'react'
import { AVProvider } from 'utilities/firebase-utils/functions'
import { useGuestCall } from '../GuestCallProvider'
import GuestCallAgora from './Agora/GuestCallAgora'
import GuestCallZoomProvider from './Zoom/GuestCallZoomProvider'
import GuestCallAgoraProvider from './Agora/GuestCallAgoraProvider'
import GuestCallZoom from './Zoom/GuestCallZoom'

const GuestCallOngoingContainer: React.FC = () => {
  const { channelDetails } = useGuestCall()

  if (channelDetails?.provider === AVProvider.AGORA) {
    return (
      <GuestCallAgoraProvider>
        <GuestCallAgora />
      </GuestCallAgoraProvider>
    )
  }

  if (channelDetails?.provider === AVProvider.ZOOM) {
    return (
      <GuestCallZoomProvider>
        <GuestCallZoom />
      </GuestCallZoomProvider>
    )
  }

  return null
}

export default GuestCallOngoingContainer
