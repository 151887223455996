import React, { useState } from 'react'
import { Modal, Icon } from 'semantic-ui-react'

import styles from './alert.module.scss'
import { functions } from 'utilities/firebase-utils'
import sendNotification, {
  sendGenericErrorNotification,
} from '../../LocalNotification'
import * as Sentry from '../../../sentry'
import { useHistory } from 'react-router'
import { useAuth } from 'components/AuthProvider'
import Button from 'brand/Button'

type Props = {
  children?: React.ReactNode
  teamID: string
  isCurrentUser: boolean
  isInvited?: boolean
  name: string
  email: string
  teamName: string
  userIDToRemove?: string
}

const RemoveUserAlert: React.FC<Props> = (props: Props) => {
  const { user } = useAuth()
  const [loading, setLoading] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const history = useHistory()

  const totalTeams = user?.teams.length || 0

  let header = `Remove ${props.name} from the team - ${props.teamName}?`
  let buttonText = loading ? 'Removing...' : 'Remove'

  if (props.isCurrentUser) {
    header = `Leave ${props.teamName}?`
    buttonText = loading ? 'Leaving...' : 'Leave team'
  }

  if (props.isInvited === true) {
    header = `Remove invite for ${props.email}?`
    buttonText = loading ? 'Removing...' : 'Remove invite'
  }

  const leaveTeam = () => {
    setLoading(true)
    functions
      .leaveTeam({ teamID: props.teamID })
      .then((data) => {
        if (data.error) {
          Sentry.warning(
            `Something went wrong while leaving team: ${props.teamID}`,
            { error: data.error }
          )
          sendGenericErrorNotification()
        } else {
          sendNotification(`Left ${props.teamName}.`, 'success')
          if (totalTeams <= 1) {
            history.push('/logout')
          }
        }
      })
      .catch((err) => {
        Sentry.error(err)
        sendGenericErrorNotification()
      })
      .finally(() => setLoading(false))
  }

  const removeInvite = () => {
    const requestData = { teamID: props.teamID, email: props.email }
    setLoading(true)
    functions
      .cancelInvite(requestData)
      .then((data) => {
        if (data.error) {
          Sentry.warning(
            `Something went wrong while removing invite from a team:`,
            {
              error: data.error,
              requestData,
            }
          )
          sendGenericErrorNotification()
        } else {
          setAlertOpen(false)
          sendNotification(`Removed invite for ${props.email}.`, 'success')
        }
      })
      .catch((err) => {
        Sentry.error(err)
        sendGenericErrorNotification()
      })
      .finally(() => setLoading(false))
  }

  const removeUserFromTeam = () => {
    const requestData = {
      teamID: props.teamID,
      userIDToRemove: props.userIDToRemove || '',
    }
    setLoading(true)
    functions
      .removeTeammate(requestData)
      .then((data) => {
        if (data.error) {
          Sentry.warning(
            `Something went wrong while removing user from a team:`,
            {
              error: data.error,
              requestData,
            }
          )
          sendGenericErrorNotification()
        } else {
          sendNotification(
            `Removed ${props.name} from ${props.teamName}.`,
            'success'
          )
        }
      })
      .catch((err) => {
        Sentry.error(err)
        sendGenericErrorNotification()
      })
      .finally(() => setLoading(false))
  }

  const handleRemove = () => {
    if (props.isCurrentUser === true) {
      if (totalTeams >= 2) {
        leaveTeam() // .. and stay on page
      } else {
        history.push(`/close-account/${props.teamID}`)
      }
    } else if (props.isInvited === true) {
      removeInvite()
    } else {
      removeUserFromTeam()
    }
  }

  return (
    <div>
      <Modal
        trigger={
          <div>
            <button
              className='transparentButton desktopOnly darkMode'
              onClick={() => setAlertOpen(true)}
            >
              {buttonText}
            </button>
            <button
              className='mobileOnly iconButton darkMode'
              onClick={() => setAlertOpen(true)}
            >
              <Icon inverted name='close' />
            </button>
          </div>
        }
        size='tiny'
        open={alertOpen}
        closeOnDimmerClick={!loading}
        onClose={() => setAlertOpen(false)}
      >
        <div className={styles.alert}>
          <h3 className={styles.header}>{header}</h3>
          <div className={styles.alertButtons}>
            <Button
              darkMode
              kind='secondary'
              disabled={loading}
              onClick={() => setAlertOpen(false)}
            >
              Cancel
            </Button>
            <Button darkMode disabled={loading} onClick={() => handleRemove()}>
              {buttonText}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default RemoveUserAlert
