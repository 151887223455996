import React, { useState } from 'react'
import './ToggleSwitch.scss'

type Props = {
  id: string
  Text?: string
  Name?: string
  onChange: () => void
  defaultChecked?: boolean
  Small?: boolean
  currentValue?: boolean
  disabled?: boolean
}

const ToggleSwitch = (props: Props) => {
  const [defaultChecked, setDefaultChecked] = useState(props.defaultChecked)
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDefaultChecked(e.target.checked ?? defaultChecked)
    props.onChange()
  }
  return (
    <div className={'toggle-switch' + (props.Small ? ' small-switch' : '')}>
      <input
        type='checkbox'
        name={props.Name}
        className='toggle-switch-checkbox'
        id={props.id}
        checked={props.currentValue}
        defaultChecked={props.defaultChecked}
        onChange={onChange}
        disabled={props.disabled}
      />
      {props.id ? (
        <label className='toggle-switch-label' htmlFor={props.id}>
          <span
            className={
              props.disabled
                ? 'toggle-switch-inner toggle-switch-disabled'
                : 'toggle-switch-inner'
            }
            data-yes={''}
            data-no={''}
          />
          <span
            className={
              props.disabled
                ? 'toggle-switch-switch toggle-switch-disabled'
                : 'toggle-switch-switch'
            }
          />
        </label>
      ) : null}
    </div>
  )
}

export default ToggleSwitch
