import React, { useMemo } from 'react'
import { useAsyncCallback } from 'react-async-hook'
import { Loader } from 'semantic-ui-react'
import { Team } from 'models/Team'
import { functions } from 'utilities/firebase-utils'
import styles from './DomainCapture.module.scss'
import { getDomain, isFreeEmailDomain } from 'utilities/freeEmailDomains'

type Props = {
  team: Team
  userEmail: string
}

const DomainCapture: React.FC<Props> = (props: Props) => {
  const { team, userEmail } = props

  const toggleDomainCapture = useAsyncCallback(async (teamID: string) => {
    await functions.toggleDomainCapture({ teamID })
  })

  const teamEmail = useMemo(() => {
    let email = team.createdBy
    if (!email) {
      email = userEmail
    }
    if (!userEmail) {
      return ''
    }
    return email
  }, [team.createdBy, userEmail])

  const isDomainCaptureEnabled = useMemo(() => {
    return !isFreeEmailDomain(teamEmail)
  }, [teamEmail])

  return isDomainCaptureEnabled ? (
    <div className={styles.domainCapture}>
      <label>
        {toggleDomainCapture.loading ? (
          <Loader inline active size='small' />
        ) : (
          <input
            type='checkbox'
            checked={!!team.sharedDomain}
            onChange={() => {
              if (toggleDomainCapture.loading) return
              toggleDomainCapture.execute(team.ID!)
            }}
          />
        )}

        <span>
          Allow people with an @{team.sharedDomain ?? getDomain(teamEmail)}{' '}
          email address to join your Multi team without an invite.
        </span>
      </label>
    </div>
  ) : null
}

export default DomainCapture
