import React, { useEffect, useState } from 'react'
import { Modal, Popup } from 'semantic-ui-react'
import classNames from 'classnames'
import compact from 'lodash/compact'

import { useAuth } from 'components/AuthProvider'
import * as Sentry from '../../../../sentry'
import Button from 'brand/Button'
import { useGuestCall } from '../GuestCallProvider'

import styles from './CallFeedback.module.scss'
import { track, EventTypes } from 'utilities/analytics'

const InCallFeedback: React.FC = () => {
  const [open, setOpen] = useState(false)

  return (
    <Popup
      basic
      inverted
      open={open}
      on='click'
      className={styles.inCallFeedback}
      onClose={() => setOpen(false)}
      trigger={
        <Button kind='tertiary' darkMode onClick={() => setOpen(true)}>
          Report an issue
        </Button>
      }
    >
      <Popup.Content>
        <CallFeedbackContent inCall darkMode closeSelf={() => setOpen(false)} />
      </Popup.Content>
    </Popup>
  )
}

const PostCallFeedback: React.FC = () => {
  const { convoID } = useGuestCall()
  const { auth } = useAuth()

  const [open, setOpen] = useState(false)
  const [submitted, setSubmitted] = useState<'positive' | 'negative'>()
  const [visible, setVisible] = useState(true)

  const openModal = setOpen.bind(null, true)
  const closeModal = setOpen.bind(null, false)

  useEffect(() => {
    // hide feedback after 3 seconds
    if (submitted === 'positive') {
      const timer = setTimeout(() => {
        setVisible(false)
      }, 3000)
      return () => {
        clearTimeout(timer)
      }
    }
    return () => undefined
  }, [submitted])

  let message
  switch (submitted) {
    case 'positive':
      message = '🎉  Thanks for your feedback!'
      break
    case 'negative':
      message = '🎉  We’ll look into it.'
      break
    default:
      message = 'How was your experience?'
  }

  const submitPositiveFeedback = () => {
    setSubmitted('positive')

    if (!auth?.uid) {
      return
    }
    const feedback = {
      call_id: convoID,
      user_iD: auth?.uid,
      platform: 'web',
      rating: 'positive',
      during_convo: false,
      anonymous_user: true,
    }
    track(EventTypes.feedbackSubmitted, feedback)
  }

  if (!visible) {
    return <div className={styles.postCallFeedbackEmpty}></div>
  }

  return (
    <div className={styles.postCallFeedbackContainer}>
      <span>{message}</span>
      {!submitted && (
        <Button kind='tertiary' onClick={submitPositiveFeedback}>
          👍
        </Button>
      )}
      {submitted !== 'positive' && (
        <Modal
          className={styles.postCallFeedback}
          onClose={closeModal}
          onOpen={openModal}
          open={open}
          trigger={
            submitted === 'negative' ? (
              <button className={styles.submitAgain} onClick={openModal}>
                Submit more feedback
              </button>
            ) : (
              <Button kind='tertiary' onClick={openModal}>
                👎
              </Button>
            )
          }
        >
          <CallFeedbackContent
            closeSelf={closeModal}
            onSubmit={() => setSubmitted('negative')}
          />
        </Modal>
      )}
    </div>
  )
}

const CallFeedbackContent: React.FC<CallFeedbackContentProps> = (props) => {
  const { closeSelf, darkMode, inCall = false, onSubmit } = props
  const { auth } = useAuth()
  const { convoID } = useGuestCall()

  const [topicSelected, setTopicSelected] = useState<TopicState>()
  const [subtopicSelected, setSubtopicSelected] = useState<TopicState>()
  const [feedbackTimer, setFeedbackTimer] = useState<number>()

  useEffect(() => {
    // add breadcrumb when user clicks through feedback
    if (topicSelected || subtopicSelected) {
      addFeedbackBreadcrumb()
    }
  }, [topicSelected, subtopicSelected])

  useEffect(() => {
    // cleanup timer
    return feedbackTimer ? () => clearTimeout(feedbackTimer) : undefined
  }, [feedbackTimer])

  const addFeedbackBreadcrumb = () => {
    if (!auth?.uid) {
      return
    }
    // clear existing timer before starting a new one
    // send breadcrumbs to Sentry if user doesn't submit feedback
    if (feedbackTimer) {
      clearTimeout(feedbackTimer)
    }
    const feedback = generateFeedback({
      completed: false,
    })
    // using window.setTimout to get the right type of number
    const timer = window.setTimeout(() => {
      submitFeedback(feedback)
    }, 60_000) // 1 minute
    setFeedbackTimer(timer)
    Sentry.breadcrumb(
      'Guest call feedback step',
      Sentry.SentryBreadcrumbCategory.GuestCallFeedback,
      feedback
    )
  }

  const submitFeedback = (feedback: ReturnType<typeof generateFeedback>) => {
    onSubmit?.call(null)

    if (feedback.completed) {
      closeSelf()
    }

    if (!auth?.uid) {
      return
    }

    Sentry.warning(
      `${
        feedback.completed ? '' : '(Incomplete) '
      }Negative call feedback from userID ${
        auth.uid
      } in convo ${convoID}\nfeedback: ${JSON.stringify(feedback)}`,
      feedback
    )
    track(EventTypes.feedbackSubmitted, feedback)
  }

  function generateFeedback({
    issueDescription,
    completed,
  }: {
    issueDescription?: string
    completed: boolean
  }) {
    return {
      call_id: convoID,
      user_iD: auth?.uid,
      platform: 'web',
      rating: 'negative',
      during_convo: inCall,
      anonymous_user: true,
      issues: compact([topicSelected?.id]),
      sub_issue: subtopicSelected?.id,
      issue_description: issueDescription,
      completed,
    }
  }

  const renderButton = (
    id: string,
    label: string,
    onClick: (selected: TopicState) => void
  ) => (
    <button
      className={styles.topic}
      key={id}
      onClick={() => onClick({ id, label })}
    >
      {label}
    </button>
  )

  const topicButtons = CallFeedbackModel.map((topic) =>
    renderButton(topic.id, topic.label, setTopicSelected)
  )

  const topicFound = CallFeedbackModel.find(
    (topic) => topic.id === topicSelected?.id
  )
  const subtopicButtons = topicFound?.subtopics.map((subtopic) =>
    renderButton(subtopic.id, subtopic.label, setSubtopicSelected)
  )

  if (subtopicSelected || topicSelected?.id === 'other') {
    const onCancel = subtopicSelected
      ? () => setSubtopicSelected(undefined)
      : () => setTopicSelected(undefined)
    return (
      <CallFeedbackDetails
        darkMode={darkMode}
        subtopic={subtopicSelected}
        onCancel={onCancel}
        onSubmit={(issueDescription) =>
          submitFeedback(
            generateFeedback({
              issueDescription,
              completed: true,
            })
          )
        }
      />
    )
  }

  return (
    <div
      className={classNames(
        styles.callFeedbackContent,
        darkMode && styles.darkMode
      )}
    >
      <h3>{topicFound ? topicFound.subtopicLabel : 'What can be improved?'}</h3>
      <div
        className={classNames(styles.buttonContainer, {
          [styles.topics]: !topicFound,
        })}
      >
        {topicFound ? subtopicButtons : topicButtons}
        {topicFound && (
          <Button
            className={styles.back}
            kind='secondary'
            borderless
            darkMode={darkMode}
            onClick={() => setTopicSelected(undefined)}
          >
            Back
          </Button>
        )}
      </div>
    </div>
  )
}

const CallFeedbackDetails: React.FC<CallFeedbackDetailsProps> = (props) => {
  const { subtopic, onSubmit, onCancel, darkMode } = props
  const [details, setDetails] = useState('')

  return (
    <div
      className={classNames(
        styles.callFeedbackDetails,
        darkMode && styles.darkMode
      )}
    >
      <h3>Details (Optional)</h3>
      <div>
        {subtopic && <div className={styles.subtopic}>{subtopic.label}</div>}
        <textarea
          className={styles.textarea}
          rows={1}
          value={details}
          onChange={(e) => setDetails(e.target.value)}
          placeholder='Describe what you experienced in more detail'
        />
        <div className={styles.buttons}>
          <Button
            onClick={onCancel}
            kind='secondary'
            borderless
            darkMode={darkMode}
          >
            Back
          </Button>
          <Button darkMode={darkMode} onClick={() => onSubmit(details)}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  )
}

type CallFeedbackContentProps = {
  darkMode?: boolean
  inCall?: boolean
  closeSelf: () => void
  onSubmit?: () => void
}

type CallFeedbackDetailsProps = {
  darkMode?: boolean
  subtopic?: TopicState
  onCancel: () => void
  onSubmit: (details: string) => void
}

type TopicState = {
  id: string
  label: string
}

const CallFeedbackModel = [
  {
    id: 'audio',
    label: '🔊 Audio',
    subtopicLabel: 'Audio issues',
    subtopics: [
      {
        id: 'cantHearTeam',
        label: 'I can’t hear my team',
      },
      {
        id: 'teamCantHear',
        label: 'My team can’t hear me',
      },
      {
        id: 'audioLowQuality',
        label: 'Audio is low quality',
      },
      {
        id: 'echoes',
        label: 'I hear echoes of myself',
      },
      {
        id: 'other',
        label: 'Other',
      },
    ],
  },
  {
    id: 'video',
    label: '🎥 Video',
    subtopicLabel: 'Video issues',
    subtopics: [
      {
        id: 'cantSeeTeam',
        label: 'I can’t see my team',
      },
      {
        id: 'teamCantSee',
        label: 'My team can’t see me',
      },
      {
        id: 'videoLowQuality',
        label: 'Video is low quality',
      },
      {
        id: 'other',
        label: 'Other',
      },
    ],
  },
  {
    id: 'connecting',
    label: '📶 Connection',
    subtopicLabel: 'Connection issues',
    subtopics: [
      {
        id: 'cantConnect',
        label: 'I had trouble connecting',
      },
      {
        id: 'teamCantConnect',
        label: 'My teammate(s) had trouble connecting',
      },
      {
        id: 'other',
        label: 'Other',
      },
    ],
  },
  {
    id: 'other',
    label: '❓Other feedback',
    subtopicLabel: 'Other issues',
    subtopics: [],
  },
]

export { InCallFeedback, PostCallFeedback }
