import * as Sentry from '@sentry/browser'
import config from 'configs/generalConfig.json'
import { stackWithCauses } from 'pony-cause'

// There will likely be more cases later. Just using this in the AV section for now, though
export enum SentryBreadcrumbCategory {
  AV = 'AV',
  GuestCallFeedback = 'GuestCallFeedback',
}

export const initialize = async (): Promise<void> => {
  console.log('Initializing Sentry', { release: process.env.SENTRY_VERSION })

  // According to Sentry, this is not an async call, but the network tab shows a
  // init request being sent to the Sentry API endpoint. We noticed that adding an
  // await here seems to fix an issue where the init request is rejected with a
  // http 502 bad gateway, so we're keeping it here for now.
  await Sentry.init({
    dsn: config.sentryDSN,
    release: process.env.SENTRY_VERSION,
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
    ],
    transportOptions: { fetchOptions: { mode: 'no-cors' } },
  })
}

export const setUser = (userID?: string): void => {
  Sentry.setUser(userID ? { id: userID } : null)
}

export const breadcrumb = (
  message: string,
  category: SentryBreadcrumbCategory,
  data: { [key: string]: unknown }
): void => {
  Sentry.addBreadcrumb({
    level: 'info',
    message,
    category,
    data,
  })
}

export const warning = (
  message: string,
  context?: Record<string, unknown>
): void => {
  Sentry.captureMessage(message, {
    level: 'warning',
    contexts: context ? { context } : undefined,
  })

  // We log it to console after sending it to Sentry so the stack trace doesn't show up in the breadcrumbs as well
  console.warn(message, context)
}

export const error = (
  err: unknown,
  context?: Record<string, unknown>
): void => {
  const contexts: Record<string, Record<string, unknown>> = {}

  if (context) {
    contexts.context = context
  }

  Sentry.captureException(err, { contexts })

  // We log it to console after sending it to Sentry so the stack trace doesn't show up in the breadcrumbs as well
  if (err instanceof Error) {
    console.error(stackWithCauses(err) || err, context)
  } else {
    console.error(err, context)
  }
}
