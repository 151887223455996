import { LocalStorageItem } from 'data/localStorage'

export type CallLinkData = {
  convoID: string
  convoToken: string
  roomName?: string
}
export const resetCallLinkData = (): void =>
  localStorage.removeItem(LocalStorageItem.callLinkData)

export const setCallLinkData = (callLinkData: CallLinkData): void =>
  localStorage.setItem(
    LocalStorageItem.callLinkData,
    JSON.stringify(callLinkData)
  )

export const getCallLinkData = (): CallLinkData | undefined => {
  const dataString = localStorage.getItem(LocalStorageItem.callLinkData)

  if (!dataString) {
    return
  }

  const callLinkData = JSON.parse(dataString)
  return callLinkData
}
