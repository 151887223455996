import React, { useEffect } from 'react'
import SpinnerOverlay from 'components/UI/Spinner'
import { useHistory } from 'react-router'
import { useQuery } from 'utilities/locationHooks'
import { useAuth } from 'components/AuthProvider'

const Logout: React.FC = () => {
  const { logOut } = useAuth()
  const query = useQuery()
  const history = useHistory()

  const redirect = query.get('redirect') || ''

  useEffect(() => {
    logOutAndRedirect()

    async function logOutAndRedirect() {
      await logOut()

      if (redirect) {
        window.location.replace(redirect)
      }

      history.push('/')
    }
  }, [history, logOut, redirect])

  return <SpinnerOverlay darkMode />
}

export default Logout
