import React, { useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useAsync } from 'react-async-hook'
import { functions } from 'utilities/firebase-utils'
import { Spinner } from 'components/UI/Spinner'
import LoggedOut from './LoggedOut'
import { useQuery } from 'utilities/locationHooks'
import LoggedIn from './LoggedIn'
import { useSession } from 'components/Session/SessionProvider'
import { launchRemotion as launchRemotionApp } from './launchRemotion'
import containerStyles from '../../scss/container.module.scss'
import styles from './JoinRoom.module.scss'
import classNames from 'classnames'
import { useAuth } from 'components/AuthProvider'

type JoinRoomParams = {
  // used by both /call-user and /join route
  teamInfo: string
  roomInfo?: string
  userInfo?: string
}

const JoinRoom: React.FC = () => {
  const { isLoggedInWithAccount, isAuthAndAccountInitialized, user } = useAuth()
  const { teamInfo, roomInfo, userInfo } = useParams<JoinRoomParams>()
  const query = useQuery()
  const history = useHistory()
  const { setConvoToJoin, setUserToCall } = useSession()

  const token = query.get('t') || ''
  const source = query.get('source') || ''

  const tokenAsync = useAsync(async () => {
    if (!token) throw 'The invite is invalid'
    const response = await functions.validateInviteLink({
      teamID,
      inviteID: token,
      convoID,
    })
    if (response.errorCode && response.error) {
      if (response.errorCode === 105) {
        return { roomIcon: response.roomIcon, teamName: undefined }
      }
      throw 'The invite is invalid'
    } else if (response.text === 'success') {
      return response
    }
    return undefined
  }, [token])

  const { teamID, teamName } = useMemo(() => {
    const [teamName, teamID] = teamInfo.split('--')
    return { teamID, teamName }
  }, [teamInfo])

  const { roomName, convoID } = useMemo(() => {
    if (!roomInfo) {
      return {}
    }
    const [roomName, convoID] = roomInfo.split('--')
    return { roomName, convoID }
  }, [roomInfo])

  const { userName, userID } = useMemo(() => {
    if (!userInfo) {
      return {}
    }
    const [userName, userID] = userInfo.split('--')
    return { userName, userID }
  }, [userInfo])

  const joinTeam = () => {
    // VerifyTeamLink component
    let url = `/join/${teamName}--${teamID}?t=${token}&source=${source}`
    if (convoID) {
      setConvoToJoin(convoID)
      url += `&convoID=${convoID}`
    }
    if (userID) {
      setUserToCall(userID)
      url += `&userID=${userID}`
    }
    history.push(url)
  }

  const launchRemotion = () =>
    launchRemotionApp(history, { convoID, userID, source })

  const loading = !isAuthAndAccountInitialized || tokenAsync.loading
  const tokenIsValid = !!tokenAsync.result?.teamName

  let color: any
  if (tokenAsync.result?.roomIcon) {
    color = {
      start: tokenAsync.result?.roomIcon.background?.value.startColor,
      end: tokenAsync.result?.roomIcon.background?.value.endColor,
    }
  }

  const getJoinRoomComponent = () => {
    if (loading) {
      return <Spinner darkMode active />
    }

    return (
      <>
        {isLoggedInWithAccount ? (
          <LoggedIn
            hasValidToken={tokenIsValid}
            user={user}
            teamID={teamID}
            onLaunch={launchRemotion}
            onJoinTeam={joinTeam}
            teamName={teamName}
            roomName={roomName}
            userName={userName}
            icon={tokenAsync.result?.roomIcon?.value}
            color={color}
          />
        ) : (
          <LoggedOut
            hasValidToken={tokenIsValid}
            onLaunch={launchRemotion}
            onJoinTeam={joinTeam}
            roomName={roomName}
            userName={userName}
            icon={tokenAsync.result?.roomIcon?.value}
            color={color}
            teamName={teamName}
          />
        )}
      </>
    )
  }

  return (
    <div className={classNames(containerStyles.container, styles.joinRoom)}>
      {getJoinRoomComponent()}
    </div>
  )
}

export default JoinRoom
