import React, { SVGProps } from 'react'

type Props = {
  props?: SVGProps<SVGSVGElement>
  dotFillColor: string
}
const IconDot: React.FC<Props> = (props: Props) => {
  return (
    <svg
      {...props.props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='12' cy='12' r='5' fill={props.dotFillColor} />
    </svg>
  )
}

export default IconDot
