import Button from 'brand/Button'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Popup } from 'semantic-ui-react'

import { getUserNames } from '../guest-call-utils'
import { useGuestCall } from '../GuestCallProvider'

import styles from './InCallUpsell.module.scss'
import { track, EventTypes } from 'utilities/analytics'
import { useAuth } from 'components/AuthProvider'

const InCallUpsell: React.FC = () => {
  const { userMap, teamID } = useGuestCall()
  const [open, setOpen] = useState(true)

  const openModal = setOpen.bind(null, true)
  const closeModal = setOpen.bind(null, false)
  const { isRegistered } = useAuth()

  const usersInCall = useMemo(() => {
    // trick to get users from macOS, since guest users won't have avatars
    return Object.values(userMap).filter((user) => user.avatar)
  }, [userMap])

  const userNames = getUserNames(usersInCall)
  // limit to showing 4 avatars, and have first avatar cover subsequent avatars
  const userAvatars = usersInCall
    .slice(0, 4)
    .map((user, i) => (
      <img
        key={user.id}
        src={user.avatarUrl}
        className={styles.userAvatar}
        alt='avatar'
        width='24'
        height='24'
        loading='lazy'
        style={{ zIndex: 4 - i }}
      />
    ))

  if (isRegistered) {
    return null // Don't display an up-sell to registered users.
  }

  return (
    <Popup
      basic
      onClose={closeModal}
      onOpen={openModal}
      open={open}
      inverted
      className={styles.signUpPopup}
      trigger={
        <Link
          to='/signup'
          className={styles.signUpLink}
          target='_blank'
          rel='noopener noreferrer'
        >
          <Button
            darkMode
            kind='primary'
            onClick={() =>
              track(EventTypes.incallSignupClicked, { team_id: teamID })
            }
          >
            Sign up
          </Button>
        </Link>
      }
    >
      <Popup.Content>
        <h3>Multi is better on macOS</h3>
        {userNames && (
          <div className={styles.usersInCall}>
            {userAvatars && (
              <div className={styles.userAvatarsContainer}>{userAvatars}</div>
            )}
            <span className={styles.joinUsersText}>Join {userNames}</span>
          </div>
        )}
        <p>
          Designed to recapture the energy of working side by side. Make room
          for the casual conversations and collaboration that build momentum.
        </p>
      </Popup.Content>
    </Popup>
  )
}

export default InCallUpsell
