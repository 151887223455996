import React from 'react'
import { useHistory } from 'react-router'
import { Modal } from 'semantic-ui-react'

import Button from 'brand/Button'

import styles from './ListenModal.module.scss'

const ListenModal: React.FC = () => {
  const [open, setOpen] = React.useState(false)
  const history = useHistory()

  const goToSignup = () => {
    history.push('/signup')
  }

  return (
    <Modal
      basic
      className={styles.listenModal}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Button
          onClick={() => setOpen(true)}
          darkMode
          kind='tertiary'
          className={styles.listenButton}
        >
          Listen
        </Button>
      }
    >
      <Modal.Content>
        <div className={styles.content}>
          <div>
            <h1>Download the app to listen to Spotify</h1>
            <p>
              You&apos;ll need the macOS app to share tracks and manage volume.
              Join your team and download for the best experience.
            </p>
          </div>
          <Button darkMode size='large' onClick={goToSignup}>
            Try Multi
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default ListenModal
