import React from 'react'
import Button from 'brand/Button'
import { PostCallFeedback } from '../components/CallFeedback'
import { useGuestCall } from '../GuestCallProvider'
import { track, EventSources, EventTypes } from 'utilities/analytics'
import { signOutIfGuest } from 'components/AuthProvider'
import styles from './GuestCallEnded.module.scss'

/** CAUTION: When a user visits a call link, they enter an isolated context re: CORS.
 *  To exit this context, we must fully navigate (by URL, not router) to pages
 *  that require authentication.
 */
const navigateTo = (relativePath: string) => {
  window.location.href = relativePath
  return
}

// Presented to users who are already registered.
export const GuestCallEndNoRedirect: React.FC = () => {
  return (
    <div className={styles.guestCallEndRedirect}>
      <PostCallFeedback />
      <h1>You left the call</h1>
      <p>Multi works best in our macOS app.</p>
      <Button onClick={() => navigateTo('/download')} size='large' fluid>
        Download Multi for macOS
      </Button>
    </div>
  )
}

const GuestCallEndRedirect: React.FC = () => {
  const { teamID, teamName } = useGuestCall()

  const createTeamLink = `/signup?source=${EventSources.guestCall}`

  const createTeam = async () => {
    track(EventTypes.callEndedCreateTeamClicked, {
      team_id: teamID,
      team_name: teamName,
    })
    await signOutIfGuest()
    navigateTo(createTeamLink)
  }

  return (
    <div className={styles.guestCallEndRedirect}>
      <PostCallFeedback />
      <h1>Sign up for Multi</h1>
      <p>Video calls designed for engineers.</p>
      <div className={styles.actions}>
        <Button fluid onClick={() => createTeam()} size='large'>
          Sign up
        </Button>
      </div>
    </div>
  )
}

export default GuestCallEndRedirect
