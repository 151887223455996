import React, { useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { Participant } from '@zoom/videosdk'

import { useGuestCall } from '../../GuestCallProvider'
import { useGuestCallZoom, userMapByZoomUID } from './GuestCallZoomProvider'
import { getInitials } from '../../guest-call-utils'
import {
  calcRenderVideoLocation,
  CalcRenderVideoLocationReturn,
  CalcVideoCanvasGridReturn,
  MIN_HEIGHT,
  MIN_WIDTH,
} from './video-size-calc-util'

import DefaultAvatar from '../../components/DefaultAvatar'
import micOffIcon from './assets/mic-off-orange.svg'
import videoOffIcon from './assets/camera-off-orange.svg'
import screenshareActiveIcon from './assets/screenshare-on-orange.svg'

import styles from './Zoom.module.scss'

/* The "swiss cheese slice" that provides holes for each participant */
const ZoomVideoCanvasOverlay: React.FC<{
  width: number
  height: number
  canvas: HTMLCanvasElement
}> = ({ canvas, width, height }) => {
  const {
    zoomRemoteUsers,
    zoomLocalUser,
    getVideoGridParams,
    isRemoteScreensharing,
  } = useGuestCallZoom()
  const [gridParams, setGridParams] =
    useState<ReturnType<typeof getVideoGridParams>>()

  const participantsToRender = useMemo(() => {
    return [zoomLocalUser, ...zoomRemoteUsers]
  }, [zoomLocalUser, zoomRemoteUsers])

  const cols = gridParams?.numCols || 1
  const rows = gridParams?.numRows || 1

  const cellWidth = useMemo(() => {
    if (isRemoteScreensharing) {
      return MIN_WIDTH
    }
    return gridParams?.videoWidth || MIN_WIDTH
  }, [gridParams?.videoWidth, isRemoteScreensharing])

  const cellHeight = useMemo(() => {
    if (isRemoteScreensharing) {
      return MIN_HEIGHT
    }
    return gridParams?.videoHeight || MIN_HEIGHT
  }, [gridParams?.videoHeight, isRemoteScreensharing])

  const cssGridStyle = useMemo(() => {
    return {
      gridTemplateColumns: `repeat(${cols}, ${cellWidth}px)`,
      gridTemplateRows: `repeat(${rows}, ${cellHeight}px)`,
    }
  }, [cellHeight, cellWidth, cols, rows])

  const calcLocation = useCallback(
    (gridParams: CalcVideoCanvasGridReturn, index: number) => {
      return calcRenderVideoLocation({
        ...gridParams,
        videoIndex: index,
        y: canvas.offsetHeight,
      })
    },
    [canvas.offsetHeight]
  )

  const participants = participantsToRender.map((person, idx) => {
    if (!gridParams) return <React.Fragment key={idx} />
    const location = calcLocation(gridParams, idx)
    return (
      <ParticipantOverlay
        key={idx}
        participant={person}
        cellHeight={cellHeight}
        cellWidth={cellWidth}
        location={location}
      />
    )
  })

  // update the grid params (number of rows/cols and width/height of each ParticipantOverlay element)
  useEffect(() => {
    const params = getVideoGridParams({ canvas, participantsToRender })
    setGridParams(params)
  }, [
    participantsToRender,
    canvas,
    getVideoGridParams,
    width,
    height,
    isRemoteScreensharing,
  ])

  return (
    <div className={styles.zoomVideoCanvasOverlay} style={cssGridStyle}>
      {participants}
    </div>
  )
}

/* ParticipantOverlay renders each user's av status icons, initials, and fullname */
export const ParticipantOverlay: React.FC<{
  participant?: Participant
  location?: CalcRenderVideoLocationReturn
  cellHeight: number
  cellWidth: number
}> = (props) => {
  const { participant, location, cellHeight, cellWidth } = props
  const { userMap } = useGuestCall()

  const { isUserScreensharing, speakingUsers, getZoomParticipantFullName } =
    useGuestCallZoom()

  const x = useMemo(() => {
    return location?.xOffset
  }, [location])
  const y = useMemo(() => {
    return location?.yOffset
  }, [location])

  const isAudioOff = useMemo(() => {
    if (!participant) {
      return true
    }
    return participant.muted
  }, [participant])

  const isVideoOff = useMemo(() => {
    if (!participant) {
      return true
    }
    return !participant.bVideoOn
  }, [participant])

  const isScreensharing = useMemo(() => {
    if (!participant) {
      return false
    }

    return isUserScreensharing(participant.displayName)
  }, [isUserScreensharing, participant])

  const isSpeaking = useMemo(() => {
    if (!participant) {
      return false
    }

    const speakingState = speakingUsers[`${participant.displayName}`]
    return speakingState?.isSpeaking
  }, [participant, speakingUsers])

  const zoomParticipantFullName = useMemo(() => {
    if (!participant) {
      return
    }
    return getZoomParticipantFullName(participant?.displayName)
  }, [getZoomParticipantFullName, participant])

  const zoomParticipantInitials = useMemo(() => {
    return getInitials(zoomParticipantFullName)
  }, [zoomParticipantFullName])

  const avatarPhotoUrl = useMemo(() => {
    if (!participant) {
      return
    }
    const zoomUserMap = userMapByZoomUID(userMap)
    const firebaseUser = zoomUserMap[participant.displayName]
    return firebaseUser?.avatarUrl
  }, [participant, userMap])

  if (!location || !participant) return <></>

  return (
    <div
      className={classNames(styles.participant, {
        [styles.speaking]: isSpeaking,
      })}
      style={{
        left: x,
        top: y,
        width: cellWidth,
        height: cellHeight,
      }}
    >
      {/* if video off and user has an avatar photo, show the photo */}
      {isVideoOff && avatarPhotoUrl && (
        <img src={avatarPhotoUrl} className={styles.showWhenVideoOff} />
      )}
      {/* if video off and user doesn't have an avatar photo, show user initials*/}
      {isVideoOff && !avatarPhotoUrl && (
        <div
          className={classNames(
            styles.showWhenVideoOff,
            styles.defaultAvatarWrapper
          )}
        >
          <DefaultAvatar
            userInitials={zoomParticipantInitials}
            removeZIndex={true}
            clipCorners={true}
          />
        </div>
      )}
      <div className={styles.avInfo}>
        {isAudioOff && (
          <div className={styles.avElement}>
            <img src={micOffIcon} width='24' height='24' />
          </div>
        )}
        {isVideoOff && (
          <div className={styles.avElement}>
            <img src={videoOffIcon} width='24' height='24' />
          </div>
        )}
        {isScreensharing && (
          <div className={styles.avElement}>
            <img src={screenshareActiveIcon} width='24' height='24' />
          </div>
        )}
      </div>
      {zoomParticipantFullName && (
        <span className={styles.fullNameContainer}>
          <span className={styles.fullName}>{zoomParticipantFullName}</span>
        </span>
      )}
    </div>
  )
}

export default ZoomVideoCanvasOverlay
