import { track, EventTypes, TeamWhence } from 'utilities/analytics'
import { functions } from 'utilities/firebase-utils'
import { JoinableTeamData } from '../Team/Team'
import {
  ProfileData,
  getUserProfileData,
  getUserTeamData,
  getInviteData,
  setSetupData,
} from './SetupDataHandler'
import { fetchAndStoreTeamInviteLink } from './accountCreationOperations'
import { pickDefaultAvatar } from '../AvatarPicker/AvatarList'

export const getReadyToCreateProfileData = (): ProfileData => {
  const profileData = getUserProfileData()
  if (!profileData) {
    throw Error('User profile not set up')
  }

  if (!profileData.fullname) {
    throw Error('User has no name')
  }
  return profileData
}

export const getReadyToCreateJoinableTeamData = (): JoinableTeamData => {
  const teamData = getUserTeamData()
  if (!teamData) {
    throw Error('User has no stored team')
  }

  if (!('id' in teamData)) {
    throw Error('Team has no id and thus is not joinable')
  }

  return teamData
}

export const createAccountAndJoinTeam = async (
  skippedTeamConfiguration = false
): Promise<void> => {
  try {
    const profileData = getReadyToCreateProfileData()
    const inviteData = getInviteData()
    let teamData: JoinableTeamData
    let teamToJoinID: string
    let fromWhence: TeamWhence

    // if not using an invite link, get joinable team data through domain capture
    if (inviteData && inviteData.inviteID) {
      // joining a team through team invite link
      teamToJoinID = inviteData.teamID
      fromWhence = TeamWhence.joinedViaInvite
    } else {
      // joining a team through domain capture
      teamData = getReadyToCreateJoinableTeamData()
      teamToJoinID = teamData.id
      fromWhence = TeamWhence.joinedViaCapture

      // if team is type JoinableTeamOnDomain, it will have property id
    }

    const { teamID, userID } = await functions.createAccountAndJoinTeam({
      teamID: teamToJoinID,
      inviteLinkID: inviteData?.inviteID, // string if users are signing up through team invite link, undefined if through guest call or domain capture
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      fullName: profileData.fullname!, // checked in getUserProfileAndTeam
      googleData: profileData.googleData,
      avatarFile: pickDefaultAvatar(), // start users with default avatar and update in postaccountcreation step
      skippedTeamConfiguration,
    })

    setSetupData({ teamJoinedData: { teamID, userID } })

    await fetchAndStoreTeamInviteLink(teamToJoinID)

    track(EventTypes.userAccountCreated, {
      whence: fromWhence,
      team_id: teamID,
    })
  } catch (err) {
    if (err instanceof Error && err.message === 'Not authorized') {
      // domain capture flow, team joiner not on the same domain
    } else if (err instanceof Error && err.message === 'Selfie Error') {
      console.error('Error saving selfie and avatar')
    } else {
      throw err
    }
  }
}
