import config from 'configs/generalConfig.json'
import React from 'react'
import { Redirect } from 'react-router'
import * as Sentry from 'sentry'
const POPUP_CLOSED = 'auth/popup-closed-by-user'
const POPUP_BLOCKED = 'auth/popup-blocked'
export const INVALID_ACTION_CODE = 'auth/invalid-action-code'
export const NO_ACCOUNT_ERROR = 'NO_ACCOUNT_ERROR'
export const NO_NAME_ERROR = 'NO_NAME_ERROR'
export const NO_EMAIL_ERROR = 'NO_EMAIL_ERROR'
export const NO_EMAIL_FROM_EMAIL_AUTH_LINK = 'NO_EMAIL_FROM_EMAIL_AUTH_LINK'
export const INVALID_EMAIL_ERROR = 'INVALID_EMAIL_ERROR'
export const WAITLIST_ERROR = 'WAITLIST_ERROR'

export const getErrorMessage = (
  error: string | undefined
): JSX.Element | string => {
  if (error === POPUP_CLOSED) {
    if (window.crossOriginIsolated) {
      Sentry.error(
        'Observed auth/popup-closed-by-user when cross origin isolation true.  COI should be false on authentication pages, likely leak from from /call'
      )
    }
    return 'It seems like you closed the Google authentication pop-up early. Please try again.'
  } else if (error === POPUP_BLOCKED) {
    return 'It looks like the Google authentication pop-up was blocked. Please check your browser settings and try again.'
  } else if (error === INVALID_ACTION_CODE) {
    return 'It looks like your authentication link has expired or is invalid. Please request a new link above'
  } else if (error === NO_ACCOUNT_ERROR) {
    return <Redirect to='/no-account' />
  } else if (error === NO_EMAIL_ERROR) {
    return 'Email is required to proceed.'
  } else if (error === INVALID_EMAIL_ERROR) {
    return 'Please enter a valid email address.'
  } else if (error === WAITLIST_ERROR) {
    return (
      <p>
        Please sign up with the same email address you used for the waitlist at{' '}
        <a href='https://multi.app' target='_blank' rel='noopener noreferrer'>
          {config.remotionSite}
        </a>
      </p>
    )
  } else if (error === NO_NAME_ERROR) {
    return 'Name is required to proceed.'
  } else if (error === NO_EMAIL_FROM_EMAIL_AUTH_LINK) {
    return 'Could not get email from email auth link. Try again, or contact support@multi.app'
  }

  return error
    ? 'Something went wrong. Try again, or contact support@multi.app.'
    : ''
}
