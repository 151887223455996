import React, { useEffect, useMemo, useState } from 'react'
import { ChatMessage } from '@zoom/videosdk'

import { useGuestCallZoom } from './GuestCallZoomProvider'

import { Button } from 'brand'
import IconPaperPlane from 'components/UI/Icons/PaperPlane'
import chat from './assets/chat.svg'
import chatAlert from './assets/chat-alert.svg'

import styles from './Zoom.module.scss'

const ChatPanel: React.FC<{
  isOpen: boolean
}> = ({ isOpen }) => {
  const { chatMessages, sendChat } = useGuestCallZoom()
  const [currentMessage, setCurrentMessage] = useState('')

  const messages = chatMessages.map((m: ChatMessage) => (
    <Message key={m.timestamp} message={m} />
  ))

  const sendCurrentChat = () => {
    sendChat(currentMessage)
    setCurrentMessage('')
  }

  const handleEnterKey = (event: React.KeyboardEvent) => {
    // ignore shift + enter
    if (event.key === 'Enter' && event.shiftKey) {
      return
    }

    if (event.key === 'Enter') {
      // send message on enter button
      sendCurrentChat()
    }
  }

  useEffect(() => {
    setCurrentMessage((state) => {
      if (state === '\n') {
        return ''
      }
      return state
    })
  }, [currentMessage])

  if (!isOpen) return <></>

  return (
    <aside className={styles.chatPanel}>
      <section className={styles.titlebar}>
        <h3 className={styles.chatTitle}>Chat</h3>
      </section>
      <section className='messages'>
        <div className={styles.messageWrapper}>{messages}</div>
      </section>

      <section className='input'>
        <textarea
          placeholder='Type your message here...'
          rows={4}
          onKeyDown={handleEnterKey}
          value={currentMessage}
          onChange={(event) => setCurrentMessage(event.target.value)}
        />
        <Button
          darkMode
          kind='tertiary'
          size='small'
          onClick={sendCurrentChat}
          disabled={!currentMessage}
        >
          <IconPaperPlane />
        </Button>
      </section>
    </aside>
  )
}

const Message: React.FC<{ message: ChatMessage }> = ({ message }) => {
  const { getZoomParticipantFullName } = useGuestCallZoom()

  const zoomParticipantFullName = useMemo(() => {
    return getZoomParticipantFullName(message.sender.name)
  }, [getZoomParticipantFullName, message.sender.name])

  const formattedTimestamp = useMemo(() => {
    const datetime = new Date(message.timestamp)
    return datetime.toLocaleTimeString('en-US')
  }, [message.timestamp])

  return (
    <div className={styles.message}>
      <span className={styles.fullname}>{zoomParticipantFullName}</span>
      <span className={styles.timestamp}>{formattedTimestamp}</span>
      <div className={styles.messageText}>{message.message}</div>
    </div>
  )
}

export const ToggleChatButton: React.FC<{
  isOpen: boolean
  setOpen: (_: boolean) => void
}> = ({ isOpen, setOpen }) => {
  const [messagesSinceLastOpen, setMessagesSincelastOpen] = useState(0)
  const { chatMessages, isCallJoined } = useGuestCallZoom()

  const toggleChat = () => {
    setMessagesSincelastOpen(chatMessages.length)
    setOpen(!isOpen)
  }

  const showAlertIcon = !isOpen && chatMessages.length !== messagesSinceLastOpen

  return (
    <Button
      darkMode
      size='small'
      kind='tertiary'
      onClick={toggleChat}
      disabled={!isCallJoined}
    >
      <img
        src={showAlertIcon ? chatAlert : chat}
        className={styles.chatIcon}
        alt='Toggle Chat'
      />
    </Button>
  )
}

export default ChatPanel
