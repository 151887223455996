import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { functions } from 'utilities/firebase-utils'
import { Link, Redirect } from 'react-router-dom'
import * as Sentry from '../../sentry'
import { sendGenericErrorNotification } from '../LocalNotification'
import Helmet from 'react-helmet'
import {
  defaultScheme,
  maybeTransformToScheme,
  Scheme,
  schemesList,
} from '../../utilities/MacOSAppScheme'
import config from 'configs/generalConfig.json'
import styles from '../scss/container.module.scss'
import SpinnerOverlay from 'components/UI/Spinner'
import { useQuery } from 'utilities/locationHooks'
import { useAuth } from 'components/AuthProvider'
import Button from 'brand/Button'
import { getCallLinkData, resetCallLinkData } from 'utilities/callLinkData'
import { isProbablyMobile } from 'utilities/browserUtil'
import {
  FeatureFlags,
  getFeatureFlag,
} from 'components/FeatureFlags/FeatureFlagsHelper'
import { LocationDescriptor } from 'history'

type TestAppProps = {
  openApp: (scheme: Scheme) => Promise<void>
}

const OpenTestApps: React.FC<TestAppProps> = (props: TestAppProps) => (
  <div style={{ paddingTop: '1em' }}>
    <strong>
      <h2 className={styles.blink}>Secret Dev Menu (plz ignore)</h2>
      <div>
        <Button
          darkMode
          borderless
          kind='secondary'
          onClick={() => props.openApp(defaultScheme)}
        >
          {defaultScheme} (default)
        </Button>
      </div>
    </strong>
    {schemesList
      .filter((currentScheme) => currentScheme !== defaultScheme)
      .map((currentScheme, i) => (
        <div key={i}>
          <Button
            darkMode
            borderless
            kind='secondary'
            onClick={() => props.openApp(currentScheme)}
          >
            {currentScheme}
          </Button>
        </div>
      ))}
    <h1>🤫</h1>
  </div>
)

function createAuthLink(scheme: string, tokenID?: string): string {
  return `${scheme}://auth?tokenID=${tokenID}`
}

const Launch: React.FC = () => {
  const showRemotionAlpha = config.isProd
  const [loading, setLoading] = useState(false)
  const query = useQuery()
  const [secretClicks, setSecretClicks] = useState(0)
  const secretClickThreshold = 10
  const { auth } = useAuth()
  const signupFromCallLinkEnabled = getFeatureFlag(
    FeatureFlags.signupFromCallLink
  )
  const [redirectPath, setRedirectPath] = useState<LocationDescriptor | undefined>()

  // Open Multi, or the scheme provided as a URL search param.
  useEffect(() => {
    if (isProbablyMobile()) {
      // do not try to open the app on mobile
      return
    }

    const callLinkData = getCallLinkData()
    if (callLinkData && signupFromCallLinkEnabled) {
      resetCallLinkData()

      let queryParameters: string[] = []

      if (callLinkData.roomName) {
        queryParameters.push(`room=${encodeURIComponent(callLinkData.roomName)}`)
      }

      if (callLinkData.convoToken) {
        queryParameters.push(`t=${encodeURIComponent(callLinkData.convoToken)}`)
      }

      const scheme = query.get('scheme')
      if (scheme) {
        queryParameters.push(`scheme=${encodeURIComponent(scheme)}`)
      }
      
      let search: string | undefined
      if (queryParameters.length) {
        search = `?${queryParameters[0]}`

        for(var i = 1; i < queryParameters.length; i++) {
          search = search + '&' + queryParameters[i]
        }
      } else {
        search = undefined
      }

      setRedirectPath({
        pathname: `/call/${callLinkData.convoID}`,
        search
      })
      return
    }
    const potentialScheme: string | null = query.get('scheme')

    if (!potentialScheme) {
      openApp() // Open default app
    } else {
      const scheme: Scheme | undefined = maybeTransformToScheme(potentialScheme)
      if (scheme !== undefined) {
        openApp(scheme)
      } else {
        alert(`${potentialScheme} is not a valid scheme.`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const openApp = useCallback(async (scheme: Scheme = defaultScheme) => {
    setLoading(true)
    functions
      .createToken()
      .then((response: functions.ResponseWithTokenID) => {
        const url = createAuthLink(scheme, response.tokenID)

        window.location.href = url
      })
      .catch((error) => {
        sendGenericErrorNotification()
        Sentry.error(error, {
          message: `Error while creating token to open app ${error.message}`,
        })
      })
      .finally(() => setLoading(false))
  }, [])

  const isTeam =
    auth?.email?.match(/@remotion\.com$/) ||
    auth?.email?.match(/@multi\.app$/) ||
    secretClicks >= secretClickThreshold

  const onHeaderClick = () => {
    setSecretClicks(secretClicks + 1)
  }

  const content = useMemo(() => {
    if (isProbablyMobile()) {
      return (
        <p style={{ margin: '10px 0px' }}>
          To launch Multi, navigate to{' '}
          <a href='https://app.multi.app/launch'>
            https://app.multi.app/launch
          </a>{' '}
          on your macOS computer.
        </p>
      )
    }

    // default desktop case
    return (
      <p style={{ margin: '10px 0px' }}>
        Multi App not opening?
        <button
          className='transparentButton darkMode'
          onClick={() => openApp()}
        >
          Try again
        </button>
        or
        <Link className='transparentButton darkMode' to='/download'>
          Download for macOS
        </Link>
      </p>
    )
  }, [openApp])

  return (
    <>
      <Helmet>
        <title>Launch Multi</title>
      </Helmet>
      {loading ? (
        <SpinnerOverlay darkMode active={loading}>
          Launching Multi...
        </SpinnerOverlay>
      ) : redirectPath ? (
        <Redirect to={redirectPath} />
      ) : (
        <div className={styles.container}>
          <h1 className={styles.header} onClick={onHeaderClick}>
            {isProbablyMobile() ? 'Multi is a macOS app' : 'Launching Multi...'}
          </h1>
          {content}
          {showRemotionAlpha && (
            <p style={{ margin: '20px 0px' }}>
              Using Multi Alpha?
              <button
                className='transparentButton darkMode'
                onClick={() => openApp('multiAlpha')}
              >
                Click here
              </button>
            </p>
          )}
          {isTeam && <OpenTestApps openApp={openApp} />}
        </div>
      )}
    </>
  )
}

export default Launch
