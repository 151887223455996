import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router'
import { default as firebaseConfig } from 'configs/firebaseConfig.json'
import { initFirebase } from 'utilities'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Helmet } from 'react-helmet'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import config from 'configs/generalConfig.json'

import { App } from 'components/App'
import * as Sentry from './sentry'
import SessionProvider from 'components/Session/SessionProvider'
import AuthProvider from 'components/AuthProvider'

const initializeApp = async () => {
  Sentry.initialize()
  initFirebase(firebaseConfig)

  const history = createBrowserHistory()
  ReactDOM.render(
    <AuthProvider>
      <Router history={history}>
        <Helmet defaultTitle='Multi' titleTemplate='%s | Multi' />
        <ToastContainer />
        <SessionProvider>
          <GoogleReCaptchaProvider
            reCaptchaKey={config.recaptchaSiteKey}
            useRecaptchaNet
          >
            <App />
          </GoogleReCaptchaProvider>
        </SessionProvider>
      </Router>
    </AuthProvider>,
    document.getElementById('root')
  )
}

initializeApp()
