import React from 'react'
import { Helmet } from 'react-helmet'
import GuestCallEndRedirect, {
  GuestCallEndNoRedirect,
} from './GuestCallEndRedirect'
import Header from 'components/UI/Header'

import styles from './GuestCallEnded.module.scss'
import { useAuth } from 'components/AuthProvider'

const GuestCallEnded: React.FC = () => {
  const { isRegistered } = useAuth()

  const body = isRegistered ? (
    <GuestCallEndNoRedirect />
  ) : (
    <GuestCallEndRedirect />
  )

  return (
    <>
      <Helmet>
        <title>Get Started</title>
      </Helmet>
      <Header transparent hideNav size='medium' hideLogout />
      <div className={styles.guestCallEnded}>{body}</div>
    </>
  )
}

export default GuestCallEnded
