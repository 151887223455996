import React, { SetStateAction, useState } from 'react'
import { Redirect } from 'react-router'
import GoogleButton from 'react-google-button'
import { Grid } from 'semantic-ui-react'

import {
  sendNotification,
  sendGenericErrorNotification,
} from 'components/LocalNotification'
import SpinnerOverlay from 'components/UI/Spinner'
import { functions } from 'utilities/firebase-utils'
import * as Sentry from '../../../sentry'
import Card from '../Card'
import gcal from '../Icons/Logos/gcal-logo.svg'
import ToggleSwitch from 'components/UI/ToggleSwitch/ToggleSwitch'
import styles from './GoogleCalendarConnectionStatus.module.css'
import config from 'configs/generalConfig.json'

interface Props {
  userID: string | undefined
  googleUserEmail: string | undefined
  googleCalendarEnabled: boolean | undefined
  hideEventTitles: boolean | undefined
}

type Loading = {
  active: boolean
  text: string
}

const GoogleCalendarConnectionStatus = (props: Props) => {
  const [loading, setLoading] = useState<Loading>({ active: false, text: '' })
  const { userID } = props
  const googleCalendarEnabled =
    props.googleCalendarEnabled === undefined
      ? false
      : props.googleCalendarEnabled
  const hideEventTitles = !!props.hideEventTitles

  if (!userID) {
    return <Redirect to='/teams' />
  }

  if (!googleCalendarEnabled) {
    return (
      <Card>
        <SpinnerOverlay active={loading.active} darkMode>
          {' '}
          {loading.text}
        </SpinnerOverlay>
        <h3>Calendar sharing</h3>
        <hr className='app-divider' />
        <div className={styles.calendarRow}>
          <div className={styles.calendarLabel}>
            <img src={gcal} alt='Google Calendar' style={{ height: '3em' }} />
            <span style={{ paddingLeft: '1em' }}>
              <b>
                {
                  "Share when you're in a meeting from your Google Calendar Account."
                }
              </b>
              <br />
              Your teammates will see public events on your calendar. If you
              would like finer-grained control, contact support@multi.app.
            </span>
          </div>
          <div className={styles.calendarSpacer}></div>
          <GoogleButton
            label='Sign in with Google'
            type='light'
            className={styles.signInButton}
            onClick={(e) => {
              e.stopPropagation()
              authGoogleAndLinkCalendar(userID, setLoading)
            }}
          />
        </div>
      </Card>
    )
  } else {
    return (
      <Card>
        <SpinnerOverlay active={loading.active} darkMode></SpinnerOverlay>
        <div className={styles.header}>
          <h3>Calendar sharing</h3>
        </div>
        <hr className='app-divider' />
        <div></div>
        <Grid columns={3} verticalAlign='middle'>
          <Grid.Row>
            <Grid.Column width='3'>
              <strong>Account</strong>
            </Grid.Column>
            <Grid.Column stretched width='10'>
              {props.googleUserEmail}
            </Grid.Column>
            <Grid.Column width='3' floating='right' textAlign='right'>
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  disconnectGoogleAccount(userID, setLoading)
                }}
                className='transparentButton mediumButton darkMode'
              >
                Disconnect
              </button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width='3'></Grid.Column>
            <Grid.Column stretched width='10'>
              <span>
                {
                  'Share public event titles. (When off, events show as "Busy.")'
                }
              </span>
            </Grid.Column>
            <Grid.Column width='3' floating='right' textAlign='right'>
              <ToggleSwitch
                id='toggle-id2'
                Small={true}
                currentValue={!hideEventTitles}
                onChange={() => {
                  toggleEventTitleSharing(userID, setLoading, !hideEventTitles)
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    )
  }
}

const authGoogleAndLinkCalendar = (
  userID: string | undefined,
  setLoading: React.Dispatch<SetStateAction<Loading>>
) => {
  userID
  setLoading({ active: true, text: 'Connecting to Google Calendar' })
  const clientID = config.webAppClientID
  const client = google.accounts.oauth2.initCodeClient({
    client_id: clientID,
    scope: 'https://www.googleapis.com/auth/calendar.readonly',
    ux_mode: 'popup',
    callback: (tokenResponse: any) => {
      connectGCalWithCode(tokenResponse.code, setLoading)
    },
  })
  client.requestCode()
}

function connectGCalWithCode(
  code: string,
  setLoading: React.Dispatch<SetStateAction<Loading>>
) {
  functions
    .connectGoogleCalendar({ code: code })
    .then((response: functions.ConnectGoogleCalendarResponse) => {
      setLoading({ active: false, text: '' })
      if (response.errorCode) {
        if (response.errorCode === 1001) {
          sendNotification(
            'Your Google account is already linked with a different Multi account.',
            'error'
          )
        } else if (response.errorCode === 1002) {
          sendNotification(
            'Your Multi account is already linked with a different Google account.',
            'error'
          )
        } else {
          sendGenericErrorNotification()
        }

        Sentry.warning(
          `Error linking Google Calendar with Multi account for user`,
          {
            error: response.error,
          }
        )
      }
    })
    .catch((error: any) => {
      sendGenericErrorNotification()
      Sentry.warning(
        `Error linking Google Calendar with Multi account for user `,
        error
      )
    })
}

const disconnectGoogleAccount = (
  userID: string | undefined,
  setLoading: (loading: Loading) => void
) => {
  setLoading({ active: true, text: 'Disconnecting Google Calendar' })
  functions
    .disconnectGoogleAccount()
    .then((response) => {
      if (response.error) {
        sendGenericErrorNotification()
        Sentry.warning(
          `Error disconnecting Google Calendar for user ${userID}`,
          { error: response.error }
        )
      }
    })
    .catch((error: any) => {
      sendGenericErrorNotification()
      Sentry.warning(
        `Error disconnecting Google Calendar for user ${userID}`,
        error
      )
    })
    .finally(() => setLoading({ active: false, text: '' }))
}

const toggleEventTitleSharing = (
  userID: string | undefined,
  setLoading: (loading: Loading) => void,
  hideTitles: boolean
) => {
  setLoading({
    active: true,
    text: `${hideTitles ? 'Hiding' : 'Unhiding'} Google Calendar event titles`,
  })
  functions
    .toggleGoogleCalendarEventTitleSharing({ hideTitles })
    .then((response: functions.Response) => {
      if (response.error) {
        sendGenericErrorNotification()
        Sentry.warning(
          `Error toggling Google Calendar event titles for user ${userID}`,
          { error: response.error }
        )
      }
    })
    .catch((error: any) => {
      sendGenericErrorNotification()
      Sentry.warning(
        `Error toggling Google Calendar event titles for user ${userID}`,
        error
      )
    })
    .finally(() => setLoading({ active: false, text: '' }))
}

export default GoogleCalendarConnectionStatus
