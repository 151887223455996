import React from 'react'
import * as Sentry from '../../../sentry'
import styles from './footer.module.css'
import { withRouter, RouteComponentProps } from 'react-router'
import { useAuth } from 'components/AuthProvider'

const Footer: React.FC<RouteComponentProps> = () => {
  const { auth, isAuthAndAccountInitialized } = useAuth()
  Sentry.setUser(auth?.uid)
  return (
    <>
      <nav className={styles.navbar}>
        {isAuthAndAccountInitialized && (
          <>
            <ul className={styles.mainNav}>
              <li>
                <a
                  href='https://multi.app/terms-of-service'
                  target='_blank'
                  className={styles.navLink}
                  rel='noreferrer'
                >
                  Terms of Service
                </a>
              </li>
              <li>
                <a
                  href='mailto:feedback@multi.app?subject=Hello'
                  target='_blank'
                  className={styles.navLink}
                  rel='noreferrer'
                >
                  Contact
                </a>
              </li>
            </ul>
          </>
        )}
      </nav>
    </>
  )
}
export default React.memo(withRouter(Footer))
