import React, { useMemo, useState, useCallback } from 'react'
import { useAsync } from 'react-async-hook'
import { Loader } from 'semantic-ui-react'
import classnames from 'classnames'

import { addUserToConvoField, ConvoField, useConvo } from 'models'

import { useGuestCall } from '../../GuestCallProvider'
import { useGuestCallZoom } from './GuestCallZoomProvider'
import { useAuth } from 'components/AuthProvider'

import { getImageUrl } from 'components/UI/AvatarImage/AvatarImage.helper'
import {
  generateModalContent,
  getScreenshareError,
  ScreenshareAttemptResult,
} from '../../guest-call-utils'

import ZoomRemoteScreenShare from 'components/Public/GuestCall/GuestCallOngoing/Zoom/ZoomRemoteScreenShare'
import CallControls from '../../components/CallControls'
import { InCallFeedback } from '../../components/CallFeedback'
import { GuestCallScreensharingPopup } from '../GuestCallScreensharingPopup'
import InCallUpsell from '../../components/InCallUpsell'
import ChatPanel, { ToggleChatButton } from './ChatPanel'
import { Modal } from 'brand'

import GuestCallSpotify from '../GuestCallSpotify'

import IconScreenshare from 'components/UI/Icons/Screenshare'
import phone from '../../assets/phone.svg'
import threePerson from '../../assets/three-person.svg'

import styles from '../GuestCallOngoing.module.scss'
import PaginationControls from './PaginationControls'
import SpinnerOverlay from 'components/UI/Spinner'
import ZoomVideoCanvas from './ZoomVideoCanvas'

const GuestCallZoom: React.FC = () => {
  const { roomName, userMap, convoID } = useGuestCall()

  const {
    leaveCall,
    toggleAudio,
    toggleVideo,
    changeAudioInputDevice,
    changeVideoInputDevice,
    startScreenshare,
    stopScreenshare,
    isLocalScreensharing,
    localScreenshareRef,
    zoomRemoteUsers,
    remoteScreenshareRef,
    screensharingUserID,
    screensharingUserName,
    isRemoteScreensharing,
    isVideoRenderedOnce,
    isCallJoined,
    isChatOpen,
    setChatOpen,
  } = useGuestCallZoom()

  const convo = useConvo(convoID)

  const { auth } = useAuth()
  const currentUserID = auth?.uid

  const [screenshareResult, setScreenshareResult] =
    useState<ScreenshareAttemptResult>()

  const remoteScreenshareElement = useMemo(() => {
    return (
      <ZoomRemoteScreenShare
        ref={remoteScreenshareRef}
        hidden={!screensharingUserID}
      />
    )
  }, [remoteScreenshareRef, screensharingUserID])

  const [leaveCallClicked, setLeaveCallClicked] = useState(false)

  const spotifyMedia = convo?.spotifyMedia

  const getUserSharingSpotify = useAsync(async () => {
    if (!spotifyMedia?.createdBy) return

    const userSharing = userMap[spotifyMedia.createdBy]

    if (!userSharing) return

    const avatar = await getImageUrl(userSharing.avatar)
    return { name: userSharing.firstName, avatar }
  }, [spotifyMedia, userMap])

  const modalContent = generateModalContent(screenshareResult)

  const stopLocalScreenshare = useCallback(async () => {
    try {
      await stopScreenshare()
    } catch (error) {
      console.error('Error stopping screenshare:', error)
    }
  }, [stopScreenshare])

  const startLocalScreenshare = useCallback(async () => {
    if (!currentUserID || !convoID || !localScreenshareRef.current) {
      return
    }

    await stopLocalScreenshare()

    try {
      await startScreenshare(localScreenshareRef.current)
    } catch (error: any) {
      const result = getScreenshareError(error.message)
      setScreenshareResult(result)
      return
    }

    try {
      await addUserToConvoField({
        field: ConvoField.ScreensharingUsers,
        userID: currentUserID,
        convoID,
      })
    } catch (error) {
      console.error('Error adding screensharing user to convo:', error)
    }
  }, [
    currentUserID,
    convoID,
    startScreenshare,
    stopLocalScreenshare,
    localScreenshareRef,
  ])

  return (
    <>
      {!(isVideoRenderedOnce && isCallJoined) && <SpinnerOverlay darkMode />}
      <div
        className={classnames(styles.inCall, { [styles.chatOpen]: isChatOpen })}
      >
        {modalContent && (
          <Modal
            open={true}
            onClose={() => setScreenshareResult(undefined)}
            dismissBtnText={modalContent.buttonTitle}
          >
            <>
              <h3>{modalContent.title}</h3>
              <p>{modalContent.message}</p>
            </>
          </Modal>
        )}
        <div className={styles.topBar}>
          <div className={styles.topBarSection}>
            <span className={styles.convoName}>{roomName}</span>
            <span className={styles.convoUsers}>
              <img src={threePerson} /> You
              {zoomRemoteUsers.length
                ? ` + ${zoomRemoteUsers.length} others`
                : ''}
            </span>
            {screensharingUserName && (
              <div className={styles.screensharingUser}>
                <IconScreenshare
                  className={styles.screensharingIcon}
                  color='white'
                />
                Viewing {screensharingUserName || 'someone'}&apos;s screen
              </div>
            )}
          </div>
          <div className={styles.topBarSection}>
            <InCallFeedback />
            <InCallUpsell />
          </div>
        </div>

        <div
          className={classnames(styles.videoElementsContainer, {
            [styles.screenshare]: isRemoteScreensharing,
          })}
        >
          {remoteScreenshareElement}
          <div className={styles.avatarsContainer}>
            <ZoomVideoCanvas />
          </div>
        </div>
        <PaginationControls />
        <ChatPanel isOpen={isChatOpen} />
        <div className={styles.bottomBar}>
          <div className={styles.callControlsContainer}>
            <CallControls
              toggleAudio={toggleAudio}
              toggleVideo={toggleVideo}
              changeAudioInputDevice={changeAudioInputDevice}
              changeVideoInputDevice={changeVideoInputDevice}
            />
            {!isRemoteScreensharing && (
              <GuestCallScreensharingPopup
                startShare={startLocalScreenshare}
                stopShare={stopLocalScreenshare}
                isLocalScreensharing={isLocalScreensharing}
              >
                <video
                  id='zoom-local-screenshare'
                  ref={localScreenshareRef}
                  className={styles.zoomLocalScreenshare}
                />
              </GuestCallScreensharingPopup>
            )}
            <button
              className={styles.hangUpButton}
              onClick={() => {
                leaveCall()
                setLeaveCallClicked(true)
              }}
            >
              {leaveCallClicked ? (
                <Loader size='small' inline='centered' active inverted />
              ) : (
                <img
                  src={phone}
                  className={styles.phoneIcon}
                  width='30'
                  alt='Leave conversation'
                />
              )}
            </button>
          </div>

          <div className={styles.callControlsContainer}>
            {getUserSharingSpotify.result && spotifyMedia?.title && (
              <>
                <GuestCallSpotify
                  spotifyMedia={spotifyMedia}
                  userSharing={getUserSharingSpotify.result}
                />
                <div className={styles.divider}></div>
              </>
            )}

            <ToggleChatButton isOpen={isChatOpen} setOpen={setChatOpen} />
          </div>
        </div>
      </div>
    </>
  )
}

export default GuestCallZoom
