import React, { useState } from 'react'
import { Modal } from 'semantic-ui-react'
import styles from './alert.module.scss'
import * as Sentry from '../../../sentry'
import sendNotification, {
  sendGenericErrorNotification,
} from 'components/LocalNotification'
import { functions } from 'utilities/firebase-utils'

import Popover from '../Popover'
import Button from 'brand/Button'

interface Props {
  children?: React.ReactNode
  slackWorkspaceName: string
  teamName: string
  teamID: string
}

const unlinkSlackWorkspace = async (
  teamID: string,
  setLoading: (loading: boolean) => void
): Promise<void> => {
  setLoading(true)
  functions
    .unlinkSlackWorkspace({ teamID })
    .then((response) => {
      if (response.error) {
        sendNotification(
          `Error disconnecting Slack workspace: ${response.error}`,
          'error'
        )
      }
      setLoading(false)
    })
    .catch((error) => {
      sendGenericErrorNotification()
      Sentry.error(error, {
        message: 'Error while creating token to auth with Slack',
      })
    })
    .finally(() => setLoading(false))
}

const DisconnectSlackAlert: React.FC<Props> = (props: Props) => {
  const [alertOpen, setAlertOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  return (
    <Modal
      trigger={
        <Popover
          content={`Disconnect ${
            props.slackWorkspaceName ?? ''
          } Slack workspace`}
          flowing={true}
        >
          <button
            onClick={() => setAlertOpen(true)}
            className='transparentButton mediumButton desktopOnly'
            style={{ marginRight: '20px' }}
          >
            Disconnect Slack
          </button>
        </Popover>
      }
      size='tiny'
      open={alertOpen}
      closeOnDimmerClick={!loading}
      onClose={() => setAlertOpen(false)}
    >
      <div className={styles.alert}>
        <h3 className={styles.header}>
          {`Disconnect Slack workspace ${props.slackWorkspaceName} from the team ${props.teamName}?`}
        </h3>
        <div className={styles.alertButtons}>
          <Button
            kind='secondary'
            disabled={loading}
            onClick={() => setAlertOpen(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={() => unlinkSlackWorkspace(props.teamID, setLoading)}
          >
            {loading ? 'Disconnecting...' : ' Disconnect'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default DisconnectSlackAlert