import React, { useState } from 'react'
import { functions } from 'utilities/firebase-utils'
import * as Sentry from '../../../sentry'
import { sendGenericErrorNotification } from 'components/LocalNotification'
import SpinnerOverlay from '../Spinner'
import { Icon } from 'semantic-ui-react'
import { track, EventTypes } from 'utilities/analytics'
import DisconnectSlackAlert from '../Alerts/DisconnectSlackAlert'
import { Button } from 'brand'

interface Props {
  connectedToSlack: boolean
  slackWorkspaceName?: string | undefined
  slackAuthTokenInvalid?: boolean | undefined
  teamID: string
  teamName: string
}

const SlackConnectionStatus = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const { connectedToSlack, teamID, slackAuthTokenInvalid } = props

  if (connectedToSlack) {
    if (slackAuthTokenInvalid) {
      return (
        <>
          <SpinnerOverlay active={loading} darkMode>
            Connecting to Slack...{' '}
          </SpinnerOverlay>
          <Button
            onClick={(e) => {
              e.stopPropagation()
              authSlackAndLinkTeam(teamID, setLoading)
            }}
            darkMode
            kind='tertiary'
          >
            Reconnect Slack{' '}
            <span style={{ border: 'none' }}>
              <Icon name='exclamation circle' color='red'></Icon>
            </span>
          </Button>
        </>
      )
    } else {
      return (
        <div className={`mediumButton desktopOnly`}>
          <DisconnectSlackAlert
            slackWorkspaceName={props.slackWorkspaceName ?? ''}
            teamName={props.teamName}
            teamID={props.teamID}
          />
        </div>
      )
    }
  } else {
    return (
      <>
        <SpinnerOverlay active={loading} darkMode>
          Connecting to Slack...{' '}
        </SpinnerOverlay>
        <Button
          onClick={(e) => {
            e.stopPropagation()
            track(EventTypes.connectSlackClicked, {
              prompt_location: 'web teams page',
            })
            authSlackAndLinkTeam(teamID, setLoading)
          }}
          darkMode
          size='small'
        >
          Connect Slack
        </Button>
      </>
    )
  }
  return null
}

async function authSlackAndLinkTeam(
  teamID: string,
  setLoading: (loading: boolean) => void
): Promise<void> {
  setLoading(true)
  functions
    .createConnectSlackLink({ teamID })
    .then((response: functions.CreateConnectSlackLinkResponse) => {
      window.location.href = response.url
    })
    .catch((error) => {
      sendGenericErrorNotification()
      Sentry.error(error, {
        message: `Error while creating token to auth with slack for team: ${teamID}`,
      })
    })
    .finally(() => setLoading(false))
}

export default SlackConnectionStatus
