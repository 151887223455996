import React, { useState } from 'react'
import { Modal, Icon } from 'semantic-ui-react'
import { useAsyncCallback } from 'react-async-hook'

import { useFormFields } from '../../../utilities/formHooks'
import * as Sentry from '../../../sentry'
import sendNotification, {
  sendGenericErrorNotification,
} from '../../LocalNotification'
import styles from './alert.module.scss'
import Button from 'brand/Button'

interface Props {
  fieldName: string
  label: string
  initialValue?: string
  placeholder?: string
  optional?: boolean
  onSave: (value: string) => void
}

const EditTextFieldAlert: React.FC<Props> = (props: Props) => {
  const { label, initialValue, placeholder, fieldName, optional, onSave } =
    props
  const [alertOpen, setAlertOpen] = useState(false)
  const [fields, handleFieldChange] = useFormFields<any>({
    [fieldName]: initialValue,
  })

  const fieldValue = fields[fieldName]
  const onConfirm = useAsyncCallback(async (value: string) => {
    try {
      await onSave(value)
      sendNotification(`Succesfully updated field`, 'success')
      setAlertOpen(false)
    } catch {
      Sentry.error(
        {},
        {
          error: `Failed to update field: ${label}`,
          requestData: { [fieldName]: value },
        }
      )
      sendGenericErrorNotification()
    }
  })

  return (
    <Modal
      trigger={
        <div>
          <button
            onClick={() => setAlertOpen(true)}
            className='transparentButton mediumButton desktopOnly darkMode'
          >
            Change
          </button>
          <button
            onClick={() => setAlertOpen(true)}
            className='mobileOnly iconButton'
          >
            <Icon name='edit' inverted />
          </button>
        </div>
      }
      size='tiny'
      open={alertOpen}
      onClose={() => setAlertOpen(false)}
    >
      <div className={styles.alert}>
        <h3 className={styles.header}>{label}</h3>
        <input
          className='inputSecondary'
          name={fieldName}
          type='string'
          placeholder={placeholder}
          value={fieldValue}
          onChange={handleFieldChange}
          disabled={onConfirm.loading}
        />
        <div className={styles.alertButtons}>
          <Button
            kind='secondary'
            onClick={() => setAlertOpen(false)}
            disabled={onConfirm.loading}
          >
            Cancel
          </Button>
          <Button
            disabled={
              onConfirm.loading ||
              initialValue === fieldValue ||
              (!optional && fieldValue.trim().length === 0)
            }
            onClick={() => onConfirm.execute(fieldValue)}
          >
            {onConfirm.loading ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default EditTextFieldAlert
