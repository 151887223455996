import { createHmac } from 'crypto'

const UID_SALT = '1e0b1d0eb66212a4f03c937bfc97122a'

/**
 * This function accepts a Multi userId and returns an 8 character hash for use as call provider userIds
 * It is used in both useAgora and useZoom to generate the zoomUserId (assigned under the zoom participant displayName property)
 */
export function hashUID(input: string): string {
  const hash = createHmac('sha256', UID_SALT)
  hash.update(input)
  const digest = hash.digest('hex')
  return digest.substring(0, 8)
}
