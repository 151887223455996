import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import { Icon } from 'semantic-ui-react'
import Logo from '../Logo'
import * as Sentry from '../../../sentry'
import { useAuth } from 'components/AuthProvider'

import styles from './header.module.scss'

type Props = {
  transparent?: boolean
  darkMode?: boolean
  hideNav?: boolean
  hideLogout?: boolean
  size?: 'small' | 'regular' | 'medium'
}
const Header: React.FC<Props> = (props: Props) => {
  const {
    auth,
    user,
    isAuthAndAccountInitialized,
    isLoggedIn,
    isLoggedInWithAccount,
  } = useAuth()
  const {
    transparent,
    darkMode = false,
    hideNav,
    size = 'regular',
    hideLogout,
  } = props
  const logoColor = darkMode ? 'white' : 'black'
  const [active, setActive] = useState(false)
  Sentry.setUser(auth?.uid)

  const hasAccount = isLoggedInWithAccount && user && !user?.isGuest

  return (
    <>
      <nav
        className={classNames(styles.navbar, {
          [styles.transparent]: transparent,
          [styles.darkMode]: darkMode,
        })}
      >
        {!hideNav && (
          <span
            className={styles.navbarToggle}
            onClick={() => setActive(!active)}
          >
            <Icon name={active ? 'close' : 'bars'} inverted />
          </span>
        )}
        <Logo color={logoColor} size={size} />
        {!isAuthAndAccountInitialized ? (
          <></>
        ) : hasAccount ? (
          <ul
            className={classNames(styles.mainNav, { [styles.active]: active })}
          >
            {!hideNav && (
              <>
                <li>
                  <NavLink
                    to='/teams'
                    className={styles.navLink}
                    activeClassName={styles.activeLink}
                    onClick={() => setActive(false)}
                  >
                    Team
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to='/account'
                    className={styles.navLink}
                    activeClassName={styles.activeLink}
                    onClick={() => setActive(false)}
                  >
                    Account
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to='/download'
                    className={styles.navLink}
                    activeClassName={styles.activeLink}
                    onClick={() => setActive(false)}
                  >
                    Download
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to='/launch'
                    className={styles.navLink}
                    activeClassName={styles.activeLink}
                    onClick={() => setActive(false)}
                  >
                    Open App
                  </NavLink>
                </li>
              </>
            )}
            {!hideLogout && (
              <li>
                <NavLink to='/logout' className={styles.navLink}>
                  Log out
                </NavLink>
              </li>
            )}
          </ul>
        ) : (
          isLoggedIn &&
          !hideLogout && (
            <>
              <ul
                className={classNames(styles.mainNav, {
                  [styles.active]: active,
                })}
              >
                <li>
                  <NavLink to='/logout' className={styles.navLink}>
                    Log out
                  </NavLink>
                </li>{' '}
              </ul>{' '}
            </>
          )
        )}
      </nav>
    </>
  )
}

export default React.memo(Header)
