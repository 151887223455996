import React, { useEffect, useState } from 'react'
import styles from '../scss/container.module.scss'
import queryString from 'query-string'
import Helmet from 'react-helmet'
import { functions } from 'utilities/firebase-utils'
import { sendGenericErrorNotification } from 'components/LocalNotification'
import * as Sentry from '../../sentry'
import SpinnerOverlay from 'components/UI/Spinner'
import { useAuth } from 'components/AuthProvider'
import { useUserTeams } from 'models'
import Button from 'brand/Button'
import { useHistory, useLocation } from 'react-router'

enum LinkState {
  None,
  SelectTeam,
  LinkFailed,
  NoTeamToLink,
  LinkSuccess,
}

const LinkSlackAccount: React.FC = () => {
  const location = useLocation()
  const history = useHistory()
  const [linkState, setLinkState] = useState<{
    state: LinkState
    error: undefined | string
  }>({
    state: LinkState.None,
    error: undefined,
  })
  const [loading, setLoading] = useState(false)
  const [linkableTeams, setLinkableTeams] = useState<string[]>([])
  const [selectedNonce, setSelectedNonce] = useState('')

  const [selectedTeamID, setSelectedTeamID] = useState('')
  const { user } = useAuth()
  const teams = useUserTeams(user)

  useEffect(() => {
    const query = queryString.parse(location.search)
    const queryNonce = query.slackNonce
    if (typeof queryNonce !== 'string') {
      return
    }
    setSelectedNonce(queryNonce)
    getTeamsToLinkSlackAccount(queryNonce)
  }, [])

  const linkButtonClicked = (nonce: string, teamID: string) => {
    setLoading(true)
    if (!teamID) {
      setLinkState({
        state: LinkState.LinkFailed,
        error: 'A team needs to be selected.',
      })
      return
    }

    functions
      .linkSlackAccount({ nonce, teamID })
      .then((linkSlackAccountResponse) => {
        if (linkSlackAccountResponse.errorCode) {
          history.replace(
            `/linkSlackAccountError?errorCode=${linkSlackAccountResponse.errorCode}`
          )
        } else {
          setLinkState({ state: LinkState.LinkSuccess, error: undefined })
        }
      })
      .catch((err) => {
        setLinkState({
          state: LinkState.LinkFailed,
          error:
            'Something went wrong while linking Multi team to Slack workspace.',
        })
        sendGenericErrorNotification()
        Sentry.error(err, {
          message: `Error linking Multi team ${selectedTeamID} to Slack workspace`,
        })
      })
      .finally(() => setLoading(false))
  }

  const getTeamsToLinkSlackAccount = (nonce: string) => {
    functions
      .getTeamsToLinkSlackAccount({ nonce })
      .then((response: functions.GetTeamsToLinkSlackAccountResponse) => {
        const {
          canLinkTeamIDs,
          workspaceAlreadyLinkedWithTeam,
          error: responseError,
          isUserOnLinkedTeam,
        } = response
        if (responseError && typeof responseError === 'string') {
          setLinkState({ state: LinkState.LinkFailed, error: responseError })
        } else {
          if (workspaceAlreadyLinkedWithTeam) {
            if (isUserOnLinkedTeam) {
              const teamID = canLinkTeamIDs[0]
              linkButtonClicked(nonce, teamID)
            } else {
              setLinkState({
                state: LinkState.LinkFailed,
                error:
                  'You’re not part of the team that this Slack workspace is linked to. Contact a team member to get invited.',
              })
              sendGenericErrorNotification()
            }
          } else {
            if (canLinkTeamIDs.length > 0) {
              setLinkableTeams(canLinkTeamIDs)
              if (!selectedTeamID) {
                setSelectedTeamID(canLinkTeamIDs[0])
              }
              setLinkState({ state: LinkState.SelectTeam, error: undefined })
            } else {
              setLinkState({
                state: LinkState.NoTeamToLink,
                error:
                  'All your teams are already linked. Create a new team in Multi to link this Slack workspace',
              })
            }
          }
        }
      })
      .catch((err) => {
        setLinkState({ state: LinkState.LinkFailed, error: err })
        sendGenericErrorNotification()
      })
  }
  const showLinkSuccess = (
    <div>
      <h1 className={styles.header}>Thanks for linking your account</h1>
      <p className={styles.subHeader}>
        Your Slack account is successfully linked with your Multi team. You can
        now start Multi calls from Slack.
      </p>
    </div>
  )
  const showSelectTeam = (
    <>
      <h1 className={styles.header}>One more step</h1>
      <p className={styles.subHeader}>
        Choose the team that you’d like to connect to Slack.
      </p>
      <form
        className={styles.form}
        onSubmit={(evt) => {
          evt.preventDefault()
          linkButtonClicked(selectedNonce, selectedTeamID)
        }}
      >
        <select
          onChange={(event) => {
            setSelectedTeamID(event.target.value)
          }}
          placeholder='Choose team...'
          className='selectPrimary'
          disabled={loading}
          defaultValue={selectedTeamID}
        >
          {teams
            .filter((team) => linkableTeams.includes(team.ID ?? ''))
            .map((team) => (
              <option key={team.ID} value={team.ID}>
                {team.name}
              </option>
            ))}
        </select>
        <input
          type='submit'
          disabled={loading}
          className='primaryButton'
          value={'Connect team to Slack'}
        />
      </form>
    </>
  )

  const showLinkFailed = (
    <div>
      <h1 className={styles.header}>We can’t link your account</h1>
      <p className={styles.subHeader}>{linkState.error}</p>
      {linkState.state === LinkState.NoTeamToLink && (
        <Button fluid size='large' onClick={() => history.replace('/teams')}>
          Go to team management page
        </Button>
      )}
    </div>
  )

  return (
    <>
      <Helmet>
        <title>Link slack account</title>
      </Helmet>
      <div className={styles.container}>
        {linkState.state === LinkState.None && <SpinnerOverlay darkMode />}
        {linkState.state === LinkState.SelectTeam && showSelectTeam}
        {linkState.state === LinkState.LinkFailed && showLinkFailed}
        {linkState.state === LinkState.NoTeamToLink && showLinkFailed}
        {linkState.state === LinkState.LinkSuccess && showLinkSuccess}
      </div>
    </>
  )
}

export default LinkSlackAccount
