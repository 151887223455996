export enum SessionStorageItem {
  avatarFileUrlMap = 'avatarMap',
}

export function setItem(key: SessionStorageItem, value?: string | null) {
  if (!value) {
    sessionStorage.removeItem(key)
  } else {
    sessionStorage.setItem(key, value)
  }
}

export function getItem(key: SessionStorageItem): string | null {
  return sessionStorage.getItem(key)
}

export function getAvatarURL(file: string): string | null {
  const dictString = getItem(SessionStorageItem.avatarFileUrlMap) ?? '{}'
  const dict = JSON.parse(dictString)
  return dict[file] as string
}

export function cacheAvatarURL(file: string, url: string): string | null {
  const dictString = getItem(SessionStorageItem.avatarFileUrlMap) ?? '{}'
  const dict = JSON.parse(dictString)
  dict[file] = url
  setItem(SessionStorageItem.avatarFileUrlMap, JSON.stringify(dict))
  return dict[file] as string
}
