import React, { useState } from 'react'
import { Modal, Icon } from 'semantic-ui-react'

import styles from './alert.module.scss'
import { InviteTeammate } from '../InviteTeammates'
import { sendGenericErrorNotification } from '../../LocalNotification'
import Button from 'brand/Button'

interface Props {
  teamID: string
  teamName: string
}

const InviteTeammatesAlert: React.FC<Props> = (props: Props) => {
  const [open, setAlertOpen] = useState(false)

  return (
    <div>
      <Modal
        trigger={
          <div>
            <Button
              darkMode
              onClick={(e) => {
                e.stopPropagation()
                setAlertOpen(true)
              }}
            >
              Invite teammates
            </Button>
          </div>
        }
        size='tiny'
        open={open}
        closeIcon={<Icon name='close' />}
        onClose={() => setAlertOpen(false)}
      >
        <div className={styles.alert} onClick={(e) => e.stopPropagation()}>
          <InviteTeammate
            teamID={props.teamID}
            teamName={props.teamName}
            onError={() => sendGenericErrorNotification}
          />
        </div>
      </Modal>
    </div>
  )
}

export default InviteTeammatesAlert
