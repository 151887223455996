import React from 'react'
import styles from '../SetupPage.module.scss'

const TermsOfService = () => (
  <div className={styles.terms_of_service}>
    <p>
      By continuing, you agree to Multi&apos;s{' '}
      <a href='https://multi.app/terms-of-service'>Terms of Service</a>
      <span> and </span>
      <a href='https:/multi.app/privacy-policy'>Privacy Policy</a>
      <span>.</span>
    </p>
  </div>
)

export default TermsOfService
