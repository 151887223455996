import { DocumentData, DocumentReference } from 'firebase/firestore'

/**
 *
 * @param source firebase data source to fetch from
 * @param fieldName the name of the field to fetch from the firebase data
 * @param typeName primitive type name of the field
 * @returns T|undefined only returns the value
 */
export const safePrimitiveGet = (
  source: DocumentData,
  fieldName: string,
  typeName: string
): any | undefined => {
  if (
    (source[fieldName] !== null || source[fieldName] !== undefined) &&
    typeof source[fieldName] === typeName
  ) {
    return source[fieldName]
  } else {
    return undefined
  }
}

// when we need to go 3 deep, use lodash get and check the type
export const safePrimitiveNestedGet = (
  source: DocumentData,
  fieldName1: string,
  fieldName2: string,
  typeName: string
): any | undefined => {
  if (source[fieldName1]) {
    return safePrimitiveGet(source[fieldName1], fieldName2, typeName)
  } else {
    return undefined
  }
}

export const safeDateGet = (
  source: DocumentData,
  fieldName: string
): Date | undefined => {
  const data = source[fieldName]
  if (!data) return undefined
  if (typeof data === 'object' && data.toDate) {
    return data.toDate()
  }

  const timestamp = safePrimitiveGet(source, fieldName, 'number')
  if (!timestamp) {
    return undefined
  } else {
    return timestamp.toDate()
  }
}

/**
 * @returns array of document references, or an empty array if any value is not a ref
 */
export const safeArrayRefGet = (
  source: DocumentData,
  fieldName: string
): DocumentReference[] => {
  const arrayObj = source[fieldName]
  if (!(arrayObj instanceof Array)) {
    return []
  }

  for (const item of arrayObj) {
    if (!item.id) {
      return []
    }
  }

  return arrayObj
}
