import React from 'react'
import Helmet from 'react-helmet'
import TeamUser from '../../UI/TeamUser/TeamUser'
import styles from './manageteams.module.scss'
import { Redirect, useHistory, useParams } from 'react-router'
import TeamSettingsFooter from './TeamSettingsFooter'
import InviteTeammatesAlert from 'components/UI/Alerts/InviteTeammatesAlert'
import DomainCapture from './DomainCapture'
import { useAuth } from 'components/AuthProvider'
import {
  AppUser,
  Team,
  useLegacyAllUniqueUsersInTeams,
  useUserTeams,
} from 'models'
import { Spinner } from 'components/UI/Spinner'
import { functions } from 'utilities/firebase-utils'
import { useAsync } from 'react-async-hook'
import Card from 'components/UI/Card'

export type FirestoreConnection = {
  collection: string
  doc: string
}

// When a user leaves their last team, we briefly park them on
// this Route to safely remove them from their last team
// and log them out.
// we do not use SemiPrivateRoute here as the user will have their app user
// removed, and we do not want the SemiPrivateRoute redirect to
// /signup to trigger until we log out their auth user as well
export const ManageTeamsLeaveLastTeam: React.FC = () => {
  const { teamID } = useParams<{ teamID: string }>()
  const { logOut } = useAuth()
  const history = useHistory()

  useAsync(async () => {
    if (teamID) {
      await functions.leaveTeam({ teamID })
      await logOut()
      history.push('/signup')
    }
  }, [])

  return <Spinner darkMode />
}

export const ManageTeams: React.FC = () => {
  const { auth, user } = useAuth()
  const userTeams = useUserTeams(user)
  const allTeamUsers = useLegacyAllUniqueUsersInTeams(userTeams)

  const shouldGoToOnboarding = localStorage.getItem('onboarding')
  if (shouldGoToOnboarding) {
    return <Redirect to='/onboarding' />
  }

  return (
    <div className={styles.manageTeams}>
      <Helmet>
        <title>Team</title>
      </Helmet>
      <div className={styles.header}>
        <h1>My team</h1>
      </div>
      {userTeams.map((team) => {
        return (
          <Card key={team.ID}>
            <h2>{team.name}</h2>
            <div className={styles.teamSettingsContainer}>
              <div className={styles.compoundTitle}>
                <h3>Team settings</h3>
              </div>
              {auth?.email && (
                <DomainCapture team={team} userEmail={auth.email} />
              )}
              <TeamSettingsFooter
                teamID={team.ID!}
                teamName={team.name}
                connectedToSlack={!!team.slackWorkspaceID}
                slackWorkspaceName={team?.slackWorkspaceName}
                slackAuthTokenInvalid={team?.slackAuthTokenInvalid}
              />
            </div>
            <div className={styles.teamSettingsContainer}>
              <div className={styles.compoundTitle}>
                <h3>Team members</h3>
                <div className={styles.inviteButton}>
                  <InviteTeammatesAlert
                    teamID={team.ID!}
                    teamName={team.name}
                  />
                </div>
              </div>
              {usersForTeam(team, allTeamUsers, auth?.uid)
                .filter((user) => user.type !== 'room')
                .map((user) => {
                  return (
                    <TeamUser
                      key={user.ID}
                      name={user.displayName ?? ''}
                      email={user.email}
                      teamID={team.ID!}
                      userID={user.ID}
                      teamName={team.name}
                      avatarFile={user.avatarFile}
                      isCurrentUser={user.ID === auth?.uid}
                    />
                  )
                })}
            </div>
          </Card>
        )
      })}
    </div>
  )
}

function usersForTeam(
  team: Team,
  users: AppUser[],
  currentUserID?: string
): AppUser[] {
  if (!currentUserID) {
    return []
  }
  const teamUsers = team.users
    .map((userRef) => users.find((user) => user.ID === userRef.id))
    .filter((val) => val !== undefined) as AppUser[]
  teamUsers.forEach((user, i) => {
    if (user.ID === currentUserID) {
      teamUsers.splice(i, 1)
      teamUsers.unshift(user)
    }
  })
  return teamUsers
}
