import React, { useContext, createContext, useState } from 'react'

const SessionContext = createContext({} as any)

export type SessionContextType = {
  convoToJoin: string
  setConvoToJoin: (convoID: string) => void
  userToCall: string
  setUserToCall: (convoID: string) => void
}

export const useSession = (): SessionContextType => useContext(SessionContext)

type Props = {
  children: React.ReactNode
}

const SessionProvider: React.FC<Props> = (props: Props) => {
  const [convoToJoin, setConvoToJoin] = useState('')
  const [userToCall, setUserToCall] = useState('')

  return (
    <SessionContext.Provider
      value={{ convoToJoin, setConvoToJoin, userToCall, setUserToCall }}
    >
      {props.children}
    </SessionContext.Provider>
  )
}

export default SessionProvider
