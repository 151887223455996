import { LocalStorageItem, getItem, setItem } from 'data/localStorage'

const guestCallKey = 'GuestCallData'

export type GuestCallData = {
  guestName?: string
  hasHadGuestCall: boolean
}
export const resetGuestCallData = (): void =>
  localStorage.removeItem(guestCallKey)

export const setGuestCallData = (guestCallData: GuestCallData): void =>
  localStorage.setItem(guestCallKey, JSON.stringify(guestCallData))

export const getGuestCallData = (): GuestCallData | undefined => {
  const dataString = localStorage.getItem(guestCallKey)

  if (!dataString) {
    return
  }

  const guestCallData = JSON.parse(dataString)
  return guestCallData
}

export const hasGuestCall = (): boolean => {
  const guestCallData = getGuestCallData()

  if (!guestCallData?.hasHadGuestCall) {
    return false
  }

  return guestCallData.hasHadGuestCall
}

export const getGuestName = (): string | undefined => {
  const guestCallData = getGuestCallData()
  if (!guestCallData) {
    return
  }

  return guestCallData.guestName
}

export const setFromGuestCall = (): void => {
  setItem(LocalStorageItem.fromGuestCall, 'true')
}

export const checkFromGuestCall = (): boolean => {
  const fromGuestCall = getItem(LocalStorageItem.fromGuestCall)
  return !!fromGuestCall
}
