import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Helmet from 'react-helmet'
import config from 'configs/generalConfig.json'
import styles from '../../scss/container.module.scss'
import SpinnerOverlay from 'components/UI/Spinner'
import { useSession } from 'components/Session/SessionProvider'
import { functions } from 'utilities/firebase-utils'
import { useAsync } from 'react-async-hook'
import { useAuth } from 'components/AuthProvider'
import OpenRemotionTestApps from '../Internal/OpenRemotionTestApps'

const JoinRoomInMacOS: React.FC = () => {
  const { isLoggedInWithAccount } = useAuth()
  const [loading, setLoading] = useState(false)
  const { setConvoToJoin = () => null } = useSession()
  const { convoID } = useParams<{ convoID: string }>()

  const defaultUrl = useAsync(async () => {
    return createLink(config.scheme, convoID)
  }, [])

  const createLink = async (
    scheme: string,
    convoID: string
  ): Promise<string> => {
    let url = `${scheme}://joinRoom?convo-id=${convoID}`
    if (isLoggedInWithAccount) {
      try {
        const response = await functions.createToken()
        if (response.tokenID) {
          url += `&token-id=${response.tokenID}`
        }
      } catch {
        console.error('Token creation failed')
      }
    }
    return url
  }

  const openApp = async (convoID: string, scheme?: string) => {
    setLoading(true)
    setConvoToJoin('')
    const url: string = await createLink(
      scheme ? scheme : config.scheme,
      convoID
    )
    window.location.href = url
    setLoading(false)
  }

  return (
    <>
      <iframe src={defaultUrl.result} className='hidden' />
      <Helmet>
        <title>Join room</title>
      </Helmet>
      <SpinnerOverlay darkMode active={loading}>
        All aboard the Multi Express!
      </SpinnerOverlay>
      <div className={styles.container}>
        <h1 className={styles.header}>All aboard the Multi Express!</h1>
        <p>
          Join your team for nonstop service to chill tunes and good co-working
          vibes
        </p>
        <p style={{ margin: '10px 0px' }}>
          Multi App not opening?
          <button
            className='transparentButton darkMode'
            onClick={() => openApp(convoID)}
          >
            Try again
          </button>
          or
          <Link className='transparentButton darkMode' to='/download'>
            Download for macOS
          </Link>
        </p>
        <OpenRemotionTestApps openApp={openApp} convoID={convoID} />
      </div>
    </>
  )
}

export default JoinRoomInMacOS
