import React, { useRef, useEffect } from 'react'
import {
  ILocalVideoTrack,
  IRemoteVideoTrack,
  ILocalAudioTrack,
  IRemoteAudioTrack,
} from 'agora-rtc-sdk-ng'

import styles from './Agora.module.scss'

type Props = {
  videoTrack: ILocalVideoTrack | IRemoteVideoTrack | undefined
  audioTrack?: ILocalAudioTrack | IRemoteAudioTrack | undefined
}

const AgoraVideo: React.FC<Props> = (props: Props) => {
  const videoRef = useRef<HTMLDivElement>(null)
  const { videoTrack, audioTrack } = props

  // Start the video track once the element is added to the DOM and has a ref
  // For remote video tracks, which are managed by Agora objects, we can
  // start and stop the tracks here.
  useEffect(() => {
    // Sometimes the video does not start playing, so adding an interval to check it and play it
    const intervalId = setInterval(() => {
      if (!videoRef.current || !videoTrack) return
      if (videoTrack.play && !videoTrack.isPlaying) {
        videoTrack.play(videoRef.current)
      }
    }, 100)
    return () => {
      clearInterval(intervalId)
      videoTrack?.stop()
    }
  }, [videoRef, videoTrack])

  useEffect(() => {
    audioTrack?.play()
    return () => {
      audioTrack?.stop()
    }
  }, [audioTrack])

  return <div className={styles.expandToFitParent} ref={videoRef} />
}

export default AgoraVideo
