import { h32 } from 'xxhashjs'

export enum AvatarColors {
  blue = '#577ef7',
  brown = '#946b53',
  fuchsia = '#c4fc2',
  green = '#55b568',
  indigo = '#7251b2',
  lime = '#ADD250',
  orange = '#ed9451',
  purple = '#ac48de',
  red = '#dc644e',
  turquoise = '#70cec6',
}

const black = '#000000'
const white = '#FFFFFF'

// matching the order given in RMUser+AccentColor.swift in the macOS client
const avatarColorArray = [
  AvatarColors.red,
  AvatarColors.fuchsia,
  AvatarColors.purple,
  AvatarColors.indigo,
  AvatarColors.blue,
  AvatarColors.turquoise,
  AvatarColors.green,
  AvatarColors.lime,
  AvatarColors.brown,
  AvatarColors.orange,
]

export function getAvatarColorFromAppUserID(userID: string): AvatarColors {
  const hash = h32(userID, 1)
  const index = Number(hash) % avatarColorArray.length
  return avatarColorArray[index]
}

export function getTextColorFromAvatarColor(
  avatarColor: AvatarColors
): typeof black | typeof white {
  const lightAvatarColors = [
    AvatarColors.lime,
    AvatarColors.turquoise,
    AvatarColors.fuchsia,
  ]

  if (lightAvatarColors.includes(avatarColor)) {
    return black
  }
  return white
}
