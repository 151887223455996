import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { hot } from 'react-hot-loader'

import Header from './UI/Header'
import Footer from './UI/Footer'

import './scss/app.scss'

import Download from './Public/Download'
import JoinRoom from './Public/JoinRoom/JoinRoom'
import JoinRoomInMacOS from './Public/JoinRoomInMacOS'
import SignInLink from './Public/SignInLink'
import TalkInRemotion from './Public/TalkInRemotion'
import TokenLink from './Public/TokenLink'
import GuestCall from './Public/GuestCall'

import Account from './Private/Account/Account'
import Logout from './Private/Logout'
import { ManageTeams, ManageTeamsLeaveLastTeam } from './Private/ManageTeams'
import Launch from './Private/Launch'
import {
  RequireAuthUser,
  RequireAuthUserAndAppUser,
} from './Private/RouteWrappers'
import VerifyTeamLink from './Private/JoinTeamLink/VerifyTeamLink'

import SetupAskForInvite from './Setup/AskForInvite'
import SetupDownload from './Setup/Download'
import SetupInvitesPage from './Setup/Invite'
import SetupJoinTeamRedirect from './Setup/JoinTeamRedirect'
import SetupLoginPage from './Setup/Login'
import SetupNoAccount from './Setup/NoAccount'
import SetupSignUpPage from './Setup/SignUp'
import SetupTeam from './Setup/Team/Team'
import SetupTeamCreate from './Setup/Team/TeamCreate'
import SetupTeamInvite from './Setup/Team/TeamInvite'
import SetupTeamJoin from './Setup/Team/TeamJoin'
import SetupTeamPostAccountCreation from './Setup/Team/TeamPostAccountCreation'
import SetupWindowsWaitlist from './Setup/WindowsWaitlist'
import { StyleGuide } from './StyleGuide'
import CloseAddonAuthPopup from './Setup/CloseAddonAuthPopup'
import LinkSlackAccountError from './Private/LinkSlackAccountError'
import LinkSlackAccount from './Private/LinkSlackAccount'

export const App = hot(module)(() => {
  return (
    <div className='AppMain'>
      <Switch>
        {/* Account Creation & Authentication */}
        <Route path='/tokenLink'>
          <TokenLink />
        </Route>

        <Route path='/login'>
          <SetupLoginPage />
        </Route>

        <Route path='/signup'>
          <SetupSignUpPage />
        </Route>
        <Route path='/onboarding'>
          <Redirect to='/signup' />
        </Route>
        <Route path='/join-team'>
          <SetupJoinTeamRedirect />
        </Route>
        <Route path='/no-account'>
          <SetupNoAccount />
        </Route>
        <Route path='/logout'>
          <Logout />
        </Route>

        <Route
          path='/setup/team/join'
          render={() => (
            <RequireAuthUser>
              <SetupTeamJoin />
            </RequireAuthUser>
          )}
        />
        <Route
          path='/setup/team/invite'
          render={() => (
            <RequireAuthUser>
              <SetupTeamInvite />
            </RequireAuthUser>
          )}
        />
        <Route
          path='/setup/team/create'
          render={() => (
            <RequireAuthUser>
              <SetupTeamCreate />
            </RequireAuthUser>
          )}
        />
        <Route
          path='/setup/team/post-create'
          render={() => (
            <RequireAuthUser>
              <SetupTeamPostAccountCreation />
            </RequireAuthUser>
          )}
        />
        <Route
          path='/setup/team'
          render={() => (
            <RequireAuthUser>
              <SetupTeam />
            </RequireAuthUser>
          )}
        />

        <Route
          path='/setup/waitlist'
          render={() => (
            <RequireAuthUser>
              <SetupWindowsWaitlist />
            </RequireAuthUser>
          )}
        />
        <Route
          path='/setup/download'
          render={() => (
            <RequireAuthUserAndAppUser>
              <SetupDownload />
            </RequireAuthUserAndAppUser>
          )}
        />
        <Route
          path='/setup/invite'
          render={() => (
            <RequireAuthUserAndAppUser>
              <SetupInvitesPage />
            </RequireAuthUserAndAppUser>
          )}
        />
        <Route
          path='/setup/close-auth-popup'
          render={() => (
            <RequireAuthUserAndAppUser>
              <CloseAddonAuthPopup />
            </RequireAuthUserAndAppUser>
          )}
        />
        <Route
          path='/setup/ask-for-invite'
          render={() => (
            <RequireAuthUser>
              <SetupAskForInvite />
            </RequireAuthUser>
          )}
        />

        {/* Guest call */}
        <Route path='/call/:convoID'>
          <GuestCall />
        </Route>

        {/* used by macOS and web app to invite teammate */}
        <Route path='/join/:teamInfo' exact>
          <VerifyTeamLink />
        </Route>

        <Route>
          <Header size='medium' darkMode />
          <div className='AppContainer'>
            <Switch>
              {process.env.NODE_ENV !== 'production' && (
                <Route path='/styleguide'>
                  <StyleGuide />
                </Route>
              )}
              <Route path='/auth'>
                <SignInLink />
              </Route>
              <Route path='/download'>
                <Download />
              </Route>
              <Route path='/join-room/:convoID'>
                <JoinRoomInMacOS />
              </Route>
              <Route path='/close-account/:teamID'>
                <ManageTeamsLeaveLastTeam />
              </Route>
              <Route
                path='/teams'
                render={() => (
                  <RequireAuthUserAndAppUser>
                    <ManageTeams />
                  </RequireAuthUserAndAppUser>
                )}
              />
              <Route
                path='/launch'
                render={() => (
                  <RequireAuthUserAndAppUser>
                    <Launch />
                  </RequireAuthUserAndAppUser>
                )}
              />
              <Route
                path='/openRemotionApp'
                render={() => (
                  <RequireAuthUserAndAppUser>
                    <Launch />
                  </RequireAuthUserAndAppUser>
                )}
              />
              <Route
                path='/account'
                render={() => (
                  <RequireAuthUserAndAppUser>
                    <Account />
                  </RequireAuthUserAndAppUser>
                )}
              />
              <Route
                path='/calendar'
                render={() => (
                  <RequireAuthUserAndAppUser>
                    <Account />
                  </RequireAuthUserAndAppUser>
                )}
              />
              <Route
                path='/linkSlackAccountError'
                render={() => (
                  <RequireAuthUserAndAppUser>
                    <LinkSlackAccountError />
                  </RequireAuthUserAndAppUser>
                )}
              />
              <Route
                path='/linkSlackAccount'
                render={() => (
                  <RequireAuthUserAndAppUser>
                    <LinkSlackAccount />
                  </RequireAuthUserAndAppUser>
                )}
              />

              {/* used by macOS app */}
              <Route path='/talk'>
                <TalkInRemotion />
              </Route>
              <Route path='/join/:teamInfo/:roomInfo'>
                <JoinRoom />
              </Route>
              {/* used by the Slack app */}
              <Route path='/call-user/:teamInfo/:userInfo'>
                <JoinRoom />
              </Route>
              {/* redirect */}
              <Route>
                <Redirect to='/launch' />
              </Route>
            </Switch>
          </div>
          <Footer />
        </Route>
      </Switch>
    </div>
  )
})
