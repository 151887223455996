/**
 * Resolves a promise that may be unreliable and may not resolve in a timely manner.
 * @param unreliablePromise The promise to resolve.
 * @param timeout The maximum time to wait for the promise to resolve, in milliseconds.
 * @returns A promise that resolves with the value of the unreliable promise, or undefined if the unreliable promise did not resolve in time or encountered an error.
 */
export async function guarenteedPromiseResolver<T>(
  unreliablePromise: Promise<T>,
  timeout: number
): Promise<T | undefined> {
  let timeoutHandle: NodeJS.Timeout

  // we define a setTimeout promise that rejects after the timeout duration like this
  // because with our current node version, we cannot await setTimeout directly
  const timeoutPromise = new Promise((_resolve, reject) => {
    timeoutHandle = setTimeout(
      () => reject(new Error('Async call timeout limit reached')),
      timeout // reject if not resolved in one second
    )
  })

  try {
    // whichever promise resolves first, we return that value
    // in the case that the timeout resolves first, we catch it as an error
    const value: T | undefined = await Promise.race([
      unreliablePromise,
      timeoutPromise,
    ]).then((result) => {
      clearTimeout(timeoutHandle)
      return (result as T) ?? undefined
    })

    return value
  } catch (error) {
    console.warn(
      `unreliablePromiseResolver encountered an error or timed out, resolving undefined`,
      error
    )
    return undefined
  }
}
