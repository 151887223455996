import Button from 'brand/Button'
import React, { useState } from 'react'
import { Icon, Input } from 'semantic-ui-react'

import styles from './CopyLink.module.scss'

type Props = {
  link: string
  onLinkCopied?: () => void
}

export const CopyLink: React.FC<Props> = (props: Props) => {
  const { link, onLinkCopied } = props
  const [linkCopied, setLinkCopied] = useState(false)

  const onLinkClick = (e: any) => {
    if (link) {
      e.target.select()
      copyLink()
    }
  }

  const copyLink = () => {
    navigator.clipboard.writeText(link)
    setLinkCopied(true)

    if (onLinkCopied) {
      onLinkCopied()
    }
  }

  return (
    <div className={styles.flexContainer}>
      <Input
        type='text'
        value={link}
        readOnly
        className={styles.linkInput}
        disabled={link === ''}
        onFocus={onLinkClick}
        onClick={onLinkClick}
      />
      <Button onClick={copyLink} className={styles.copy}>
        <Icon
          name={linkCopied ? 'clipboard check' : 'clipboard'}
          className={styles.copyIcon}
        />
        {linkCopied ? 'Copied!' : 'Copy invite'}
      </Button>
    </div>
  )
}
