import React, { FunctionComponent, useEffect, useState } from 'react'
import { functions } from 'utilities/firebase-utils'
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom'
import queryString from 'query-string'
import { sendGenericErrorNotification } from 'components/LocalNotification'
import SpinnerOverlay from 'components/UI/Spinner'
import InviteLinkError from './InviteLinkError'
import styles from '../../scss/container.module.scss'
import Helmet from 'react-helmet'
import Header from 'components/UI/Header'
import { useAuth } from 'components/AuthProvider'

const VerifyTeamLink: FunctionComponent = () => {
  const [errorCode, setErrorCode] = useState<
    functions.InviteLinkErrorCode | undefined
  >(undefined)
  const [loading, setLoading] = useState(false)
  const [teamName, setTeamName] = useState('')
  const [redirectPath, setRedirectPath] = useState('')
  const { isLoggedInWithAccount } = useAuth()
  const location = useLocation()
  const history = useHistory()

  // params here is typed incorrectly by react-router, it is actually { teamInfo: string }
  const { teamInfo } = useParams<{ teamInfo: string }>()

  useEffect(() => {
    // url is : /join/team-name--teamID?t=inviteID
    const teamInfoRegex = /([a-zA-Z0-9-]+)--([a-zA-Z0-9]+)$/

    const query = queryString.parse(location.search)
    const inviteID = query.t
    let source = query.source
    if (!source || typeof source !== 'string') {
      source = ''
    }

    const matches = teamInfo.match(teamInfoRegex)
    if (
      matches === null ||
      matches.length < 3 ||
      typeof inviteID !== 'string'
    ) {
      setErrorCode(102)
      return
    }
    const teamID = matches[2]

    validateInvite(teamID, inviteID, source)
  }, [location.search, teamInfo])

  const validateInvite = (teamID: string, inviteID: string, source: string) => {
    setLoading(true)
    functions
      .validateInviteLink({ teamID, inviteID })
      .then((response) => {
        setTeamName(response.teamName ?? '')
        if (response.errorCode && response.error) {
          setErrorCode(response.errorCode)
        } else if (response.text === 'success') {
          sessionStorage.setItem('teamInfo', JSON.stringify(response))
          setRedirectPath(
            `/join-team?teamID=${teamID}&inviteID=${inviteID}&source=${source}&teamName=${response.teamName}`
          )
        }
      })
      .catch(() => {
        setErrorCode(110)
        sendGenericErrorNotification()
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <Helmet>
        <title>{`Join ${teamName}`}</title>
        <meta property='og:title' content={`Join ${teamName} team on Multi`} />
        <meta name='twitter:title' content={`Join ${teamName} team on Multi`} />
      </Helmet>
      <Header darkMode hideNav size='medium' />
      {loading ? (
        <SpinnerOverlay active={loading} darkMode>
          {' '}
          Fetching invite...
        </SpinnerOverlay>
      ) : (
        <div className={styles.container}>
          {errorCode ? (
            <InviteLinkError
              teamName={teamName}
              errorCode={errorCode}
              redirectToLaunch={() => history.push('/launch')}
              isLoggednInWithAccount={isLoggedInWithAccount}
            />
          ) : redirectPath ? (
            <Redirect to={redirectPath} />
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  )
}

export default VerifyTeamLink
