import React, { FormEvent, useState, useEffect } from 'react'
import queryString from 'query-string'
import { Redirect, useHistory, useLocation } from 'react-router'
import SpinnerOverlay from 'components/UI/Spinner'
import {
  getItem,
  LocalStorageItem,
  getRedirectInfo,
  setRedirectInfo,
} from 'data/localStorage'
import { useFormFields } from 'utilities/formHooks'
import styles from '../scss/container.module.scss'
import { sendGenericErrorNotification } from 'components/LocalNotification'
import { useSession } from 'components/Session/SessionProvider'
import { useAuth } from 'components/AuthProvider'

interface Fields {
  email: string
}

function SignInLink() {
  const [fields, handleFieldChange] = useFormFields<Fields>({ email: '' })
  const [showForm, setShowForm] = useState(false)
  const { setConvoToJoin = () => null } = useSession()
  const { isLoading, isLoggedIn, errorMessage, logInWithEmailLink } = useAuth()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const query = queryString.parse(location.search)
    if (
      query.redirectPath &&
      typeof query.redirectPath === 'string' &&
      query.redirectPath === '/join-team'
    ) {
      if (query.convoID) {
        setConvoToJoin(query.convoID as string)
      }
      setRedirectInfo({
        pathname: '/join-team',
        search: `?teamID=${query.teamID}&inviteID=${query.inviteID}`,
      })
    }
  }, [])

  useEffect(() => {
    const savedEmail = getItem(LocalStorageItem.savedEmail)
    if (!savedEmail) {
      setShowForm(true)
    } else {
      const query = queryString.parse(location.search)
      if (query.apiKey && typeof query.apiKey === 'string') {
        logInWithEmailLink(savedEmail)
      }
    }
  }, [])

  useEffect(() => {
    if (errorMessage && showForm === false) {
      sendGenericErrorNotification()
      history.replace('/login')
    }
  }, [errorMessage, history, showForm])

  const handleSubmit = (event: FormEvent<HTMLElement>) => {
    event.preventDefault()
    logInWithEmailLink(fields.email)
  }

  return (
    <div>
      {isLoading ? (
        <SpinnerOverlay />
      ) : isLoggedIn ? (
        <div>
          <>
            <Redirect to={getRedirectInfo() ?? { pathname: '/launch' }} />
            {setRedirectInfo(null)}
          </>
        </div>
      ) : showForm ? (
        <div className={styles.container}>
          <h1 className={styles.header}>Confirm your email</h1>
          <form className={styles.form} onSubmit={handleSubmit}>
            <input
              className='inputPrimary'
              name='email'
              type='string'
              placeholder='example@email.com'
              value={fields.email}
              onChange={handleFieldChange}
              disabled={isLoading}
            />

            {errorMessage && (
              <p className='errorMessage'>
                <>{errorMessage}</>
              </p>
            )}
            <input
              type='submit'
              value='Continue'
              className='primaryButton'
              disabled={isLoading || fields.email.length === 0}
            />
          </form>
        </div>
      ) : (
        <SpinnerOverlay>Signing you in...</SpinnerOverlay>
      )}
    </div>
  )
}

export default SignInLink
