import React, { HTMLInputTypeAttribute } from 'react'

import styles from './Input.module.scss'
import classNames from 'classnames'

type Props = {
  value: string | number | undefined
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  name?: string
  type?: HTMLInputTypeAttribute
  placeholder?: string
  darkMode?: boolean // default true
  readOnly?: boolean
  fluid?: boolean
  ellipsisOverflow?: boolean
}

const Input: React.FC<Props> = (props) => {
  const {
    value = '',
    onChange,
    placeholder,
    darkMode = true,
    readOnly = false,
    fluid = true,
    name,
    type,
    ellipsisOverflow = false,
  } = props

  return (
    <input
      value={value}
      onChange={(event) => {
        if (onChange) {
          onChange(event)
        }
      }}
      name={name}
      type={type}
      placeholder={placeholder}
      readOnly={readOnly}
      className={classNames(styles.input, {
        [styles.darkMode]: darkMode,
        [styles.fluid]: fluid,
        [styles.ellipsisOverflow]: ellipsisOverflow,
      })}
    />
  )
}

export default Input
