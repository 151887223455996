import React, { SVGProps } from 'react'

const IconPaperPlane = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_di_1055_29683)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.5054 0.112253C14.8349 -0.00803373 15.1917 -0.0322573 15.5345 0.0425206C15.8807 0.118047 16.198 0.291393 16.4485 0.541951C16.699 0.792509 16.8724 1.10974 16.9479 1.45594C17.0227 1.79871 16.9984 2.1556 16.8782 2.485L12.7936 14.7272L12.7934 14.728C12.6939 15.0292 12.5187 15.2997 12.2845 15.5136C12.051 15.7269 11.7668 15.8768 11.4589 15.9489C11.151 16.024 10.8288 16.0177 10.5241 15.9307C10.2196 15.8437 9.94295 15.679 9.72127 15.4529L7.53002 13.2718L5.22824 14.462C5.04875 14.5549 4.83359 14.546 4.66235 14.4387C4.49113 14.3313 4.38929 14.1416 4.39454 13.9396L4.4889 10.3011L12.5403 4.4526C12.8595 4.22075 12.9303 3.77407 12.6984 3.4549C12.4665 3.13572 12.0199 3.06493 11.7007 3.29677L3.51274 9.24444L1.53594 7.26763L1.53587 7.26757L1.53573 7.26743C1.32164 7.05349 1.16404 6.78975 1.07707 6.49985C0.990723 6.21202 0.976694 5.90738 1.03618 5.61289C1.09574 5.29116 1.23956 4.99093 1.45302 4.74285C1.66795 4.49307 1.94568 4.30522 2.25751 4.19872L2.26136 4.1974V4.19741L14.5054 0.112253Z'
          fill='url(#paint0_linear_1055_29683)'
        />
      </g>
      <defs>
        <filter
          id='filter0_di_1055_29683'
          x='0'
          y='0'
          width='17.9905'
          height='18.001'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='0.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_1055_29683'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_1055_29683'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_1055_29683'
          />
        </filter>
        <linearGradient
          id='paint0_linear_1055_29683'
          x1='8.99179'
          y1='-2.81258e-07'
          x2='5.74803'
          y2='15.3067'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#DBDBDB' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default IconPaperPlane
