import Button from 'brand/Button'
import { useAuth } from 'components/AuthProvider'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { page, PageCategory } from 'utilities/analytics'
import { useQuery } from 'utilities/locationHooks'
import { SetupPage } from './SetupPage'
import { getInviteData, setSetupData } from './utils/SetupDataHandler'
import { functions } from 'utilities'

const AskForInvite: React.FC = () => {
  const history = useHistory()
  const { auth, user, isAuthAndAccountInitialized } = useAuth()
  const inviteData = getInviteData()
  const teamName = inviteData?.teamName || 'this team'
  const query = useQuery()
  const source = query.get('source') || ''

  useEffect(() => {
    if (isAuthAndAccountInitialized) {
      page(PageCategory.onboarding, 'Ask For Invite', {
        source,
        userHasAccount: !!user,
      })
    }
  }, [isAuthAndAccountInitialized, source, user])

  const handleRedirect = () => {
    // if user arrived at AskForInvite, then their invite is invalid
    // as a cleanup action, remove invite
    setSetupData({ invite: undefined })

    functions.onboardingEvent({
      step: `"Create new team" clicked on AskForInvite page`,
    })

    // if user has auth user and app user, take them to /teams
    if (user) {
      history.push('/teams')
    } else if (auth) {
      history.push('/setup/team')
    } else {
      history.push('/signup')
    }
  }

  return (
    <SetupPage title='Ask for an invite'>
      <h1>You need an invite to join {teamName}</h1>
      <p>
        Ask a member of {teamName} on Multi for an invite. Want to create a team
        instead?
      </p>
      <Button fluid onClick={handleRedirect}>
        Create new team
      </Button>
    </SetupPage>
  )
}

export default AskForInvite
