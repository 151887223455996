import React, { useState } from 'react'
import { Modal } from 'semantic-ui-react'
import Avatar, { AvatarType } from './Avatar'
import closeIcon from '../assets/close-icon.svg'

import styles from './AvatarPicker.module.scss'
import Button from 'brand/Button'

/** @deprecated Used in OnboardingProvider */
type Props = {
  avatars: AvatarType[]
  onAvatarSelected: (avatar: AvatarType) => void
  value?: AvatarType
}

/** @deprecated Used in OnboardingProvider */
const AvatarList: React.FC<Props> = (props: Props) => {
  const { avatars, value, onAvatarSelected } = props

  return (
    <div className={styles.avatarList}>
      {avatars.map((avatar, index) => (
        <>
          <Avatar
            key={avatar.id}
            avatar={avatar}
            active={value?.id === avatar.id}
            onSelect={() => onAvatarSelected(avatar)}
          />
          {(index + 1) % 3 === 0 && <div className={styles.break} />}
        </>
      ))}
    </div>
  )
}

/**
 * Hard coded for now for RM-5902. If the images are missing (or new accounts don't have images),
 * please run the initialization script in projects/server/scripts/defaultAvatars/seed.sh
 */
const defaultAvatars = [
  { id: '1', url: 'default-avatars/Avatar-1.png' },
  { id: '2', url: 'default-avatars/Avatar-2.png' },
  { id: '3', url: 'default-avatars/Avatar-3.png' },
  { id: '4', url: 'default-avatars/Avatar-4.png' },
  { id: '5', url: 'default-avatars/Avatar-5.png' },
  { id: '6', url: 'default-avatars/Avatar-6.png' },
  { id: '7', url: 'default-avatars/Avatar-7.png' },
  { id: '8', url: 'default-avatars/Avatar-8.png' },
  { id: '9', url: 'default-avatars/Avatar-9.png' },
]

export function pickDefaultAvatar(): string {
  const len = defaultAvatars.length
  const idx = Math.floor(Math.random() * len)
  return defaultAvatars[idx].url
}

type PropsV2 = {
  avatars?: AvatarType[]
  onSelect: (avatar: AvatarType) => void
  value?: AvatarType
}

/** *  A version of AvatarList that provides its own modal, default avatars and built-in "close" button.
 *
 *  The property {modalState} should be the return value of useState<boolean>
 */
export const AvatarPicker: React.FC<PropsV2> = ({
  value,
  onSelect,
  avatars = defaultAvatars,
}) => {
  const [isModalShown, setShowModal] = useState(false)
  const openModal = () => {
    setShowModal(true)
  }
  const closeModal = () => {
    setShowModal(false)
  }

  const showModalButton = (
    <div className={styles.chooseAvatarBtnWrapper}>
      <Button kind='secondary' onClick={openModal} darkMode>
        Choose an avatar
      </Button>
    </div>
  )

  const selectAvatar = (avatar: AvatarType) => {
    onSelect(avatar)
    closeModal()
  }

  return (
    <Modal trigger={showModalButton} open={isModalShown}>
      <div className={styles.avatarPicker}>
        <div className={styles.closeIcon} onClick={closeModal}>
          <img src={closeIcon} width={32} />
        </div>
        <div className={styles.avatarList}>
          {avatars.map((avatar, index) => (
            <React.Fragment key={avatar.id}>
              <Avatar
                avatar={avatar}
                active={value?.id === avatar.id}
                onSelect={() => selectAvatar(avatar)}
              />
              {/* TODO: This seems extraneous and could be accompilshed with CSS */}
              {(index + 1) % 3 === 0 && <div className={styles.break} />}
            </React.Fragment>
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default AvatarList
