declare const MediaRecorder: any

type RecordedVideo = {
  blobs: any
  format: string
  mimeType: string
}

export const isMp4Supported = (): boolean => {
  return (
    MediaRecorder.isTypeSupported && MediaRecorder.isTypeSupported('video/mp4')
  )
}

export default class VideoRecorder {
  recordVideo = (
    video: HTMLVideoElement,
    length: number,
    framesPerSecond = 30
  ): Promise<RecordedVideo> => {
    const canvas: any = document.createElement('canvas')
    canvas.width = video.width
    canvas.height = video.height
    canvas.getContext('2d')
    const canvasStream = canvas.captureStream(framesPerSecond)

    return new Promise<RecordedVideo>((resolve) => {
      const mp4Supported = isMp4Supported()
      const format = mp4Supported ? 'mp4' : 'webm'
      const mimeType = mp4Supported ? 'video/mp4' : 'video/webm;codecs=vp8'
      const mediaRecorder = new MediaRecorder(canvasStream, { mimeType })

      const videoInterval = setInterval(() => {
        const context = canvas.getContext('2d')
        context.save()
        context.translate(video.width, 0)
        context.scale(-1, 1)
        context.drawImage(video, 0, 0, video.width, video.height)
        context.restore()
      }, 1000 / framesPerSecond)

      const blobs: any = []
      mediaRecorder.addEventListener('dataavailable', (e: any) => {
        blobs.push(e.data)
      })
      mediaRecorder.addEventListener('stop', () => {
        resolve({ blobs, format, mimeType })
      })
      mediaRecorder.start(1000)
      setTimeout(() => {
        clearInterval(videoInterval)
        mediaRecorder.stop()
      }, length)
    })
  }

  getDevices = async (): Promise<MediaDeviceInfo[]> => {
    const devices = await navigator.mediaDevices.enumerateDevices()
    return devices
  }

  getVideoDevices = async (): Promise<MediaDeviceInfo[]> => {
    const devices = await navigator.mediaDevices.enumerateDevices()
    const currentVideoDevices = devices.filter(
      (device) => device.kind === 'videoinput'
    )
    return currentVideoDevices
  }
}
