import { useGuestCall } from 'components/Public/GuestCall/GuestCallProvider'
import { useEffect, useMemo, useState } from 'react'

type UseMicVolumeReturn = {
  volume: number | undefined
  isLocalUserSpeaking: boolean
}

/**
 * @returns user mic volume (volume) and whether the user is speaking (isLocalUserSpeaking)
 * * uses this stackoverflow answer to calculate volume: https://stackoverflow.com/a/64650826
 */
export default function useMicVolume(): UseMicVolumeReturn {
  const { avSetup } = useGuestCall()
  const [volume, setVolume] = useState<number>()

  /**
   * isLocalUserSpeaking will be true if: the volume exists, it is over 30, and audio is enabled
   */
  const isLocalUserSpeaking = useMemo(() => {
    if (!volume || !avSetup?.audioEnabled) {
      return false
    }
    return volume > 30
  }, [avSetup?.audioEnabled, volume])

  useEffect(() => {
    let volumeIntervalId: number

    async function getVolume() {
      try {
        const audioStream = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
          },
        })

        const audioContext = new AudioContext()
        const audioSource = audioContext.createMediaStreamSource(audioStream)
        const analyser = audioContext.createAnalyser()

        analyser.fftSize = 512
        analyser.smoothingTimeConstant = 0.4

        audioSource.connect(analyser)

        const volumes = new Uint8Array(analyser.frequencyBinCount)
        const volumeCallback = () => {
          analyser.getByteFrequencyData(volumes)
          let volumeSum = 0
          for (const volume of volumes) volumeSum += volume
          const volumePercent = volumeSum / volumes.length
          setVolume(Math.floor(volumePercent))
        }

        volumeIntervalId = window.setInterval(volumeCallback, 100)
      } catch (err) {
        console.warn('Failed to check user mic volume', err)
      }
    }

    getVolume()

    return () => {
      clearInterval(volumeIntervalId)
    }
  }, [])

  return {
    volume,
    isLocalUserSpeaking,
  }
}
