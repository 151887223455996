// This file stores additional operations that occur in tandem with account creation

import { LocalStorageItem, setItem } from 'data/localStorage'
import { functions } from 'utilities/firebase-utils'

export async function fetchAndStoreTeamInviteLink(
  teamID: string
): Promise<string | undefined> {
  try {
    const data = await functions.generateTeamInviteLink({ teamID })
    const link = data.inviteLink

    setItem(LocalStorageItem.teamInviteLink, link)
  } catch (error) {
    console.error('failed to get invite link in setup flow', {
      error,
      teamID,
    })
  }

  return undefined
}
