import React, { useMemo } from 'react'
import { IAgoraRTCRemoteUser, UID } from 'agora-rtc-sdk-ng'
import mapKeys from 'lodash/mapKeys'

import AgoraVideo from 'components/Public/GuestCall/GuestCallOngoing/Agora/AgoraVideo'
import { getRemotionToAgoraUID } from 'components/Public/GuestCall/GuestCallOngoing/Agora/useAgora'
import { getFullName } from '../guest-call-utils'
import { useGuestCallAgora } from '../GuestCallOngoing/Agora/GuestCallAgoraProvider'
import { useGuestCall, UserWithAvatar } from '../GuestCallProvider'
import AvatarContainer from './AvatarContainer'
import AvatarGrid, { GridItem } from './AvatarGrid'

type UserMapByAgoraUID = {
  [agoraUID: string]: UserWithAvatar
}

type Props = {
  isRemoteScreensharing: boolean
  isLocalScreensharing: boolean
  isUserScreensharing: (agoraUID: UID) => boolean
}

const AvatarGridWrapper: React.FC<Props> = (props) => {
  const { isRemoteScreensharing, isLocalScreensharing, isUserScreensharing } =
    props
  const { avSetup, userMap, name } = useGuestCall()
  const { localUID, cameraTrack, agoraRemoteUsers, speakingUsers } =
    useGuestCallAgora()

  const userMapByAgoraUID = useMemo((): UserMapByAgoraUID => {
    return mapKeys(userMap, (_, userID) => getRemotionToAgoraUID(userID))
  }, [userMap])

  const agoraLocalElement = useMemo(() => {
    return (
      <AvatarContainer
        key='localvideo'
        fullName={name}
        videoOff={!avSetup.videoEnabled || !cameraTrack}
        audioOff={!avSetup.audioEnabled}
        screensharing={isLocalScreensharing}
        speakingState={localUID ? speakingUsers[`${localUID}`] : undefined}
      >
        {!isLocalScreensharing && avSetup.videoEnabled && (
          <AgoraVideo videoTrack={cameraTrack} />
        )}
      </AvatarContainer>
    )
  }, [
    isLocalScreensharing,
    localUID,
    avSetup.audioEnabled,
    avSetup.videoEnabled,
    cameraTrack,
    name,
    speakingUsers,
  ])

  const unsortedGridElements = useMemo((): GridItemForSort[] => {
    const localUserElement: GridItemForSort = {
      element: agoraLocalElement,
      key: 'local_track',
      isScreensharing: isLocalScreensharing,
      isLocalUser: true,
    }

    let remoteUserElements: GridItemForSort[] = []

    if (agoraRemoteUsers?.length) {
      remoteUserElements = agoraRemoteUsers.map(
        (remoteUser: IAgoraRTCRemoteUser): GridItemForSort => {
          const user = userMapByAgoraUID[remoteUser.uid]
          const fullName = getFullName(user?.firstName, user?.lastName)
          const isScreensharing = isUserScreensharing(remoteUser.uid)
          const speakingState = speakingUsers[`${remoteUser?.uid}`]

          const remoteElement = (
            <AvatarContainer
              key={remoteUser.uid}
              avatarUrl={user?.avatarUrl}
              fullName={fullName}
              videoOff={!remoteUser.hasVideo}
              audioOff={!remoteUser.hasAudio}
              screensharing={isScreensharing}
              speakingState={speakingState}
            >
              {((remoteUser.hasVideo && remoteUser.videoTrack) ||
                remoteUser.hasAudio) && (
                <AgoraVideo
                  videoTrack={
                    isScreensharing ? undefined : remoteUser.videoTrack
                  }
                  audioTrack={remoteUser.audioTrack}
                />
              )}
            </AvatarContainer>
          )
          return {
            key: remoteUser.uid.toString(),
            element: remoteElement,
            isScreensharing,
            isLocalUser: false,
          }
        }
      )
    }

    return [localUserElement, ...remoteUserElements]
  }, [
    agoraLocalElement,
    agoraRemoteUsers,
    isLocalScreensharing,
    speakingUsers,
    isUserScreensharing,
    userMapByAgoraUID,
  ])

  const elements = useMemo(() => {
    return unsortedGridElements
      .sort(sortByScreensharingUser)
      .map(({ key, element }) => ({ key, element }))
  }, [unsortedGridElements])

  return elements ? (
    <AvatarGrid verticalNav={isRemoteScreensharing} elements={elements} />
  ) : null
}

type GridItemForSort = GridItem & {
  isScreensharing: boolean
  isLocalUser: boolean
}

function sortByScreensharingUser(a: GridItemForSort, b: GridItemForSort) {
  if (a.isScreensharing && b.isScreensharing) {
    return a.isLocalUser ? -1 : 0
  }
  if (a.isScreensharing) return -1
  if (b.isScreensharing) return 1
  return 0
}

export default AvatarGridWrapper
