import React, { useState } from 'react'
import { Redirect, useHistory } from 'react-router'

import { getJoinableTeams, setSetupData } from '../utils/SetupDataHandler'
import { createAccountAndJoinTeam } from '../utils/createAccountAndJoinTeam'
import { JoinableTeamData } from './Team'

import { Icon } from 'semantic-ui-react'
import { Spinner } from 'components/UI/Spinner'
import Button from 'brand/Button'
import Error from '../Error'
import { SetupPage } from '../SetupPage'

import styles from './Team.module.scss'
import { PageCategory, usePageTracking } from 'utilities/analytics'
import { functions } from 'utilities'

/**
 * The TeamJoin component (corresponding to /setup/team/join) is used in the domain capture flow
 * @note If you are looking for joining a team through team invite links
 * look in the Team component (corresponding to /setup/team)
 */
const TeamJoin: React.FC = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [errJoiningTeam, setErrJoiningTeam] = useState<string>()

  usePageTracking(PageCategory.onboarding, 'Team Join', {
    source: 'Domain Capture',
  })

  const asyncCreateAccountJoinTeam = async () => {
    try {
      setLoading(true)
      setErrJoiningTeam(undefined)

      functions.onboardingEvent({
        step: `"Join" clicked on domain team on TeamJoin page`,
      })
      await createAccountAndJoinTeam()
      setLoading(false)
      history.push('/setup/team/post-create')
    } catch (err) {
      if (err !== null && 'message' in (err as ErrorEvent)) {
        setErrJoiningTeam((err as ErrorEvent).message)
      } else {
        setErrJoiningTeam("Couldn't join team")
      }
      setLoading(false)
    }
  }

  return (
    <SetupPage title='Join Your Team'>
      {loading && <Spinner darkMode />}
      {errJoiningTeam && (
        <Error
          headline='Something went wrong, please try again.'
          errorMsg={errJoiningTeam}
          retryAction={asyncCreateAccountJoinTeam}
        />
      )}
      {!errJoiningTeam && !loading && (
        <section>
          <h1>Join or create a team</h1>
          <JoinTeamCard
            asyncCreateAccountJoinTeam={asyncCreateAccountJoinTeam}
          />
          <CreateTeamCard />
        </section>
      )}
    </SetupPage>
  )
}

const JoinTeamCard: React.FC<{
  asyncCreateAccountJoinTeam: () => Promise<void>
}> = ({ asyncCreateAccountJoinTeam }) => {
  const [isScrolledToBottomJoinableTeams, setScrolledToBottomJoinableTeams] =
    useState(false)
  const numTeamsToShow = 3
  const joinableTeams = getJoinableTeams()

  if (!joinableTeams || joinableTeams.length === 0) {
    return <Redirect to='/setup/team/create' />
  }

  const setIsScrolledToBottom = (event: React.UIEvent<HTMLDivElement>) => {
    const containerHeight = event.currentTarget.clientHeight
    const scrollHeight = event.currentTarget.scrollHeight
    const scrollTop = event.currentTarget.scrollTop
    const isAtBottom = scrollHeight - scrollTop === containerHeight
    setScrolledToBottomJoinableTeams(isAtBottom)
  }

  return (
    <div className={styles.joinTeamCard}>
      <h2>Join an existing team at {joinableTeams[0].sharedDomain}</h2>
      <div
        className={styles.joinableTeamsList}
        onScroll={setIsScrolledToBottom}
      >
        {joinableTeams.map((team: JoinableTeamData) => (
          <JoinableTeamRow
            team={team}
            key={team.id}
            asyncCreateAccountJoinTeam={asyncCreateAccountJoinTeam}
          />
        ))}
      </div>
      {joinableTeams.length < numTeamsToShow ||
      isScrolledToBottomJoinableTeams ? (
        <p className={styles.bottomText}>
          Can&apos;t find your team? Ask a teammate for an invite.
        </p>
      ) : (
        <p className={styles.bottomText}>
          <Icon name='angle down' />
          scroll down for {joinableTeams.length - numTeamsToShow} more
        </p>
      )}
    </div>
  )
}

// Persist a change of selected teams to the `SetupData` local cache.
const setUserTeamData = (team: JoinableTeamData) => {
  setSetupData({ team })
}

/** Present a single, joinable team as a row. When the "Join" button is clicked, the selection is persisted
 * locally (sync), and then against the server (async) */
const JoinableTeamRow: React.FC<{
  team: JoinableTeamData
  asyncCreateAccountJoinTeam: () => Promise<void>
}> = ({ team, asyncCreateAccountJoinTeam }) => {
  return (
    <div className={styles.teamRow}>
      <div>
        <p className={styles.teamName}>{team.name}</p>
        <p className={styles.teamDescription}>{team.description}</p>
      </div>
      <Button
        kind='primary'
        darkMode
        onClick={(_e) => {
          setUserTeamData(team)
          asyncCreateAccountJoinTeam()
        }}
      >
        Join
      </Button>
    </div>
  )
}

const CreateTeamCard: React.FC = () => {
  const history = useHistory()

  const redirectToTeamCreate = () => {
    history.push('/setup/team/create')
  }
  return (
    <div className={styles.createTeamCard} onClick={redirectToTeamCreate}>
      <h2>Create a new team</h2>
      <h2>{'>'}</h2>
    </div>
  )
}

export default TeamJoin
