import React, { useCallback, useState } from 'react'
import { Modal } from 'brand'
import CallControl, { DeviceTypes } from './CallControl'
import styles from './CallControl.module.scss'
import { useGuestCall } from '../GuestCallProvider'

type Props = {
  toggleAudio?: () => void
  toggleVideo?: () => void
  changeAudioInputDevice?: (deviceID: string) => void
  changeVideoInputDevice?: (deviceID: string) => void
}

enum DeviceModalMsg {
  noAudio = 'There are no audio input devices available. In order for others to see and hear you, Multi needs access to your microphone and camera.',
  noVideo = 'There are no video input devices available. In order for others to see and hear you, Multi needs access to your microphone and camera.',
}

const CallControls: React.FC<Props> = (props: Props) => {
  const {
    toggleAudio,
    toggleVideo,
    changeAudioInputDevice,
    changeVideoInputDevice,
  } = props

  const { audioDevices, videoDevices, loadDevices, avSetup, setAVSetup } =
    useGuestCall()
  const { audioDeviceId, videoDeviceId, audioEnabled, videoEnabled } = avSetup
  const [deviceModal, setDeviceModal] = useState<{
    open: boolean
    message: string
  }>({
    open: false,
    message: '',
  })

  const onAudioDeviceSelected = useCallback(
    (deviceID: string) => {
      setAVSetup((state) => ({ ...state, audioDeviceId: deviceID }))
      if (changeAudioInputDevice) {
        changeAudioInputDevice(deviceID)
      }
    },
    [changeAudioInputDevice]
  )

  const onVideoDeviceSelected = useCallback(
    (deviceID: string) => {
      setAVSetup((state) => ({ ...state, videoDeviceId: deviceID }))
      if (changeVideoInputDevice) {
        changeVideoInputDevice(deviceID)
      }
    },
    [changeVideoInputDevice]
  )

  const toggleAudioControlPressed = useCallback(() => {
    if (audioDevices.length) {
      setAVSetup((state) => ({ ...state, audioEnabled: !state.audioEnabled }))
      if (toggleAudio) {
        toggleAudio()
      }
    } else {
      setDeviceModal({
        open: true,
        message: DeviceModalMsg.noAudio,
      })
    }
  }, [audioDevices, toggleAudio])

  const toggleVideoControlPressed = useCallback(() => {
    if (videoDevices.length) {
      setAVSetup((state) => ({ ...state, videoEnabled: !state.videoEnabled }))
      if (toggleVideo) {
        toggleVideo()
      }
    } else {
      setDeviceModal({
        open: true,
        message: DeviceModalMsg.noVideo,
      })
    }
  }, [toggleVideo, videoDevices])

  return (
    <div className={styles.callControls}>
      <CallControl
        type={DeviceTypes.AudioInput}
        devices={audioDevices}
        enabled={audioEnabled && audioDevices.length !== 0}
        onToggle={toggleAudioControlPressed}
        selectedDeviceId={audioDeviceId}
        onDeviceSelected={onAudioDeviceSelected}
        onDeviceSelectionOpen={loadDevices}
      />
      <CallControl
        type={DeviceTypes.VideoInput}
        devices={videoDevices}
        enabled={videoEnabled && videoDevices.length !== 0}
        onToggle={toggleVideoControlPressed}
        selectedDeviceId={videoDeviceId}
        onDeviceSelected={onVideoDeviceSelected}
        onDeviceSelectionOpen={loadDevices}
      />
      <ControlModal
        open={deviceModal.open}
        message={deviceModal.message}
        close={() => setDeviceModal((modal) => ({ ...modal, open: false }))}
      />
    </div>
  )
}

type ControlModalProps = {
  open: boolean
  close: () => void
  message: string
}

const ControlModal: React.FC<ControlModalProps> = (props) => {
  const { open, close, message } = props

  return (
    <Modal open={open} onClose={close}>
      <h3>Enable microphone and camera</h3>
      <p>{message}</p>
    </Modal>
  )
}

export default CallControls
