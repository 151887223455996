import { AnalyticsBrowser } from '@segment/analytics-next'
import config from 'configs/generalConfig.json'
import { AppUser } from 'models/AppUser'
import { useEffect } from 'react'

// Type interface
export {
  EventTypes,
  TeamWhence,
  EventSources,
  PageCategory,
} from './analytics.types'
import {
  EventTypes,
  SnakeCasedRecord,
  PageCategory,
  PageTitle,
} from './analytics.types'
import { guarenteedPromiseResolver } from './guarenteedPromiseResolver'

/** Stub of AnalyticsBrowser for environments that don't emit to Product Analytics */
class EmptyAnalyticsBrowser {
  forEnv: string

  constructor(envName: string) {
    this.forEnv = envName.toUpperCase()
    console.info(`No write key for ${envName} analytics; emitting logs only`)
  }

  async identify(...args: any) {
    console.log(`[AX][${this.forEnv}] Not emitting identify: `, args)
    return
  }
  async track(...args: any) {
    console.log(`[AX][${this.forEnv}] Not emitting track: `, args)
    return
  }
  async page(...args: any) {
    console.log(`[AX][${this.forEnv}] Not emitting page: `, args)
    return
  }
  async group(...args: any) {
    console.log(`[AX][${this.forEnv}] Not emitting group: `, args)
    return
  }
  async then(...args: any) {
    console.log(`[AX][${this.forEnv}] Not emitting then: `, args)
    return
  }
}

export const analytics = {
  debug: AnalyticsBrowser.load({
    writeKey: config.segmentDebugWriteKey,
  }),
  product: config.segmentProductWriteKey
    ? AnalyticsBrowser.load({ writeKey: config.segmentProductWriteKey })
    : new EmptyAnalyticsBrowser('product'),
}

analytics.debug.then(() => {
  console.debug('[AX] Segment Initialized (Debug Metrics)')
})
analytics.product.then(() => {
  console.debug('[AX] Segment Initialized (Product Analytics)')
})

/** Asynchronously emit 'identify' and 'group' events to Segment analytics. */
export async function identify(user: AppUser): Promise<void> {
  const traits = {
    email: user.email,
    first_name: user.firstName,
    last_name: user.lastName,
  }

  // User must be identified for automatic identification during `group`
  await analytics.product.identify(user.ID, traits)
  await analytics.debug.identify(user.ID, traits)
  console.debug(`[AX] identify(${user.ID})`)

  user.teams.forEach(async (team) => {
    analytics.debug.group(team.id)
    analytics.product.group(team.id)
    console.debug(`[AX] group(${team.id})`)
  })
}

function isProductAnalyticPage(category: PageCategory): boolean {
  switch (category) {
    case PageCategory.onboarding:
    case PageCategory.call:
      return true
      break
    default:
      return false
  }
}

/** Custom hook that emits 'page' tracking events */
export function usePageTracking(
  category: PageCategory,
  title: PageTitle,
  properties?: SnakeCasedRecord
): void {
  useEffect(() => {
    page(category, title, properties)
    return
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only call on initial page load
  }, [])
}

/** Emit a page event. */
export async function page(
  category: PageCategory,
  title: Capitalize<string>,
  properties?: SnakeCasedRecord
): Promise<void> {
  if (isProductAnalyticPage(category)) {
    await analytics.product.page(category, title, properties)
    await analytics.debug.page(category, title, properties)
  } else {
    await analytics.debug.page(category, title, properties)
  }
  console.debug(`[AX] page(${category}, ${title})`)
}

function isProductAnalyticEvent(event: EventTypes): boolean {
  switch (event) {
    case EventTypes.teamInviteLinkCopied:
    case EventTypes.userLoggedIn:
      return true
      break
    default:
      return false
  }
}

/** Emit a tracking event. */
export async function track(
  event: EventTypes,
  properties?: SnakeCasedRecord
): Promise<void> {
  const eventName = event.valueOf()
  if (isProductAnalyticEvent(event)) {
    await analytics.product.track(eventName, properties)
    await analytics.debug.track(eventName, properties)
  } else {
    await analytics.debug.track(eventName, properties)
  }
}

/**
 * Get Segment's Anonymous ID for consistent tracking on server.
 * It was found during a bug bash that 1Blocker on Safari causes this function to never resolve.
 * Thus, we use the guarenteed promise resolver utility to resolve this promise after a timeout
 */
export async function getAnonID(): Promise<string | undefined> {
  const anonUser = await guarenteedPromiseResolver(analytics.debug.user(), 1000)

  // anonymousId may be of type string | null | undefined, coerce to string | undefined
  return anonUser?.anonymousId() ?? undefined
}
