import classNames from 'classnames'
import React, { forwardRef } from 'react'

import styles from './Zoom.module.scss'

type Props = {
  hidden: boolean
}
const ZoomRemoteScreenShare: React.ForwardRefRenderFunction<
  HTMLCanvasElement,
  Props
> = (props, canvasRef) => {
  return (
    <div
      className={classNames(styles.screenshare, {
        [styles.hidden]: props.hidden,
      })}
    >
      <canvas
        id='zoom-remote-screenshare'
        ref={canvasRef}
        className={styles.remoteScreenshareCanvas}
      />
    </div>
  )
}

export default forwardRef(ZoomRemoteScreenShare)
