import React, {
  useState,
  useEffect,
  useCallback,
  useReducer,
  SetStateAction,
} from 'react'
import ZoomVideo, {
  VideoClient,
  Participant,
  VideoQuality,
  MediaSDKEncDecPayload,
  VideoCapturingState,
  AudioChangeAction,
  MutedSource,
  ParticipantPropertiesPayload,
  ChatMessage,
} from '@zoom/videosdk'
import { hashUID } from '../uid-hasher'
import { MIN_WIDTH } from 'components/Public/GuestCall/components/AvatarGrid'
import {
  ZOOM_ASPECT_RATIO,
  calcRenderVideoLocation,
  calcVideoCanvasGrid,
  doVideosFitOnOnePageIfAtLeastMinVideoSize,
  calcVideoCanvasGridMinHeightAndWidth,
  CalcVideoCanvasGridReturn,
} from './video-size-calc-util'
import { AVSetup } from '../../useCallControls'
import { isSupportedBrowser } from 'utilities/browserUtil'
import { ConvoField } from 'models'

const REMO_PLUGIN_COMMAND_PREFIX = '/remoPlugin'

export type SpeakingUserState = {
  isSpeaking: boolean
  timestamp: number
}

export function getRemotionToZoomUID(userID: string): string {
  return hashUID(userID)
}

export function createZoomClient(): typeof VideoClient {
  return ZoomVideo.createClient()
}

export type RenderVideoProps = {
  userID: number // the Zoom userId of the remote participant, the userID prop of the Participant
  canvas: HTMLCanvasElement // HTML canvas element where video should be rendered
  canvasHeight: number // used as the height of the canvas element (matching avatar size, the diameter of the avatar circle)
  videoQuality?: VideoQuality
}

export type StopRenderVideoProps = Omit<
  RenderVideoProps,
  'canvasHeight' | 'videoQuality'
>

export type UpdateVideoCanvasProps = {
  canvas: HTMLCanvasElement
  canvasHeight: number
  canvasWidth?: number
}

export type GetVideoGridProps = {
  canvas: HTMLCanvasElement
  participantsToRender: Participant[]
}

type AVReadyState = {
  video: {
    encode: boolean
    decode: boolean
  }
  audio: {
    encode: boolean
    decode: boolean
  }
}

export type UseZoomReturn = {
  join: (
    channel: string,
    token: string,
    remotionToZoomUID: string
  ) => Promise<void>
  leave: () => Promise<void>
  isCallJoined: boolean

  zoomLocalUser: Participant
  zoomRemoteUsers: Participant[]
  avatarSize: number
  setAvatarSize: React.Dispatch<React.SetStateAction<number>>

  toggleVideo: () => void
  toggleAudio: () => void // mute and unmute local user

  changeAudioInputDevice: (deviceID: string) => Promise<void>
  changeVideoInputDevice: (deviceID: string) => Promise<void>

  renderRemoteVideo: (props: RenderVideoProps) => Promise<void>
  updateVideoCanvas: (props: UpdateVideoCanvasProps) => void
  renderAllUsersOnCanvas: () => Promise<void>
  getVideoGridParams: (
    props: GetVideoGridProps
  ) => CalcVideoCanvasGridReturn | undefined
  stopRenderRemoteVideo: (props: StopRenderVideoProps) => void
  isVideoRenderedOnce: boolean // has video been rendered at least once?

  startScreenshare: (element: HTMLVideoElement) => Promise<void>
  stopScreenshare: () => Promise<void>
  isLocalScreensharing: boolean

  numPages: number // the total number of pages of user videos
  currentPage: number // the current page that the user is on
  setCurrentPage: React.Dispatch<SetStateAction<number>>

  chatMessages: ChatMessage[]
  sendChat: (text: string) => void
}

export default function useZoom(
  client: typeof VideoClient,
  localVideoRef: React.RefObject<HTMLVideoElement>,
  videoCanvasRef: React.RefObject<HTMLCanvasElement>,
  remoteScreenshareRef: React.RefObject<HTMLCanvasElement>,
  setAVSetup: React.Dispatch<React.SetStateAction<AVSetup>>,
  setIsRemoteScreensharing: React.Dispatch<React.SetStateAction<boolean>>,
  removeLocalUserFirebaseConvoField: (field: ConvoField) => Promise<void>
): UseZoomReturn {
  const [localUser, setLocalUser] = useState<Participant>(
    client.getCurrentUserInfo()
  )
  const [remoteUsers, setRemoteUsers] = useState<Participant[]>([])
  const [avatarSize, setAvatarSize] = useState(MIN_WIDTH)

  const [isCallJoined, setIsCallJoined] = useState(false)
  const [isCallAudioJoined, setIsCallAudioJoined] = useState(false)

  const [isAVReady, setIsAVReady] = useState<AVReadyState>({
    video: {
      encode: false,
      decode: false,
    },
    audio: {
      encode: false,
      decode: false,
    },
  })
  const [isLocalScreensharing, setIsLocalScreensharing] = useState(false)
  const [isVideoRenderedOnce, setIsVideoRenderedOnce] = useState(false)

  const [numPages, setNumPages] = useState(1) // for call pagination, default to 1 page
  const [currentPage, setCurrentPage] = useState(0) // the current call page, defaults to 0th page

  const getRemoteParticipants = useCallback(() => {
    const participants = client.getAllUser()
    // filter out local user
    const localUser = client.getCurrentUserInfo()
    const localUserIndex = participants.findIndex(
      (participant: Participant) => participant.userId === localUser.userId
    )

    if (localUserIndex > -1) {
      participants.splice(localUserIndex, 1)
    }
    return participants
  }, [client])

  const sendChat = useCallback(
    async (message: string) => {
      if (!message) {
        return
      }
      const chat = client.getChatClient()
      return await chat.sendToAll(message)
    },
    [client]
  )

  const [chatMessages, receiveChatMessages] = useReducer(
    (
      msgs: ChatMessage[],
      newMessage: ChatMessage | ChatMessage[]
    ): ChatMessage[] => {
      let newMessages = []
      if (newMessage instanceof Array) {
        newMessages = [...newMessage]
      } else {
        newMessages = [newMessage]
      }

      // remove messages that are Multi plugin commands
      const filteredMessages = newMessages.filter(
        (msg) =>
          msg.message.substring(0, REMO_PLUGIN_COMMAND_PREFIX.length) !==
          REMO_PLUGIN_COMMAND_PREFIX
      )
      return [...msgs, ...filteredMessages]
    },
    []
  )

  const getChatHistory = useCallback(() => {
    const chat = client.getChatClient()
    const history = chat.getHistory()
    return history
  }, [client])

  const joinAudio = useCallback(async () => {
    try {
      let localAVSetup: AVSetup | undefined
      // use set function to access avSetup without mutating its state
      setAVSetup((state) => {
        localAVSetup = state
        return state
      })

      if (!localAVSetup) {
        return
      }

      const noAudioPermission = !localAVSetup.audioDeviceId

      const stream = client.getMediaStream()
      await stream.startAudio({ speakerOnly: noAudioPermission })
    } catch (err) {
      console.error('Error starting audio:', err)
    }
  }, [client])

  const join = useCallback(
    async (channel: string, token: string, remotionToZoomUID: string) => {
      try {
        await client.init('en-US', 'Global', {
          enforceMultipleVideos: true,
        })
        // setting remotionToZoomUID as the Zoom Participant displayName prop
        await client.join(channel, token, remotionToZoomUID)

        setRemoteUsers(getRemoteParticipants())
        receiveChatMessages(getChatHistory())
        setIsCallJoined(true)

        if (isSupportedBrowser) {
          await joinAudio()
        }
      } catch (err) {
        console.error('Error joining Zoom: ', err)
      }
    },
    [
      client,
      getRemoteParticipants,
      joinAudio,
      receiveChatMessages,
      getChatHistory,
    ]
  )

  const leave = useCallback(async () => {
    try {
      setIsCallJoined(false)
      const stream = client.getMediaStream()
      await stream.stopAudio()
      if (stream.isCapturingVideo()) {
        await stream.stopVideo()
      }
    } catch (err) {
      console.warn('Error stopping audio/video on leaving Zoom', err)
    } finally {
      await client.leave()
    }
  }, [client])

  /**
   * render remote user's video
   */
  const renderRemoteVideo = useCallback(
    async ({
      userID,
      canvas,
      canvasHeight,
      videoQuality = VideoQuality.Video_360P,
    }: RenderVideoProps) => {
      try {
        const stream = client.getMediaStream()
        await stream.renderVideo(
          canvas,
          userID,
          canvasHeight * ZOOM_ASPECT_RATIO, // the width of the canvas element (matching avatar size)
          canvasHeight, // the height of the canvas element (matching avatar size)
          0, // these two zeros refer to x and y coordinates
          0, // so that the video renders starting from the bottom left corner
          videoQuality
        )
      } catch (err) {
        console.error('Error rendering video: ', err)
      }
    },
    [client]
  )

  /**
   * stop rendering a remote user's video
   */
  const stopRenderRemoteVideo = useCallback(
    ({ userID, canvas }: StopRenderVideoProps) => {
      try {
        const stream = client.getMediaStream()
        stream.stopRenderVideo(canvas, userID)
      } catch (err) {
        console.error('Error stopping rendering video: ', err)
      }
    },
    [client]
  )

  const stopRenderOneUserOnCanvas = useCallback(
    async (zoomUserId: number) => {
      try {
        if (!videoCanvasRef.current) {
          return
        }
        const stream = client.getMediaStream()
        await stream.stopRenderVideo(videoCanvasRef.current, zoomUserId)
      } catch (err) {
        console.warn(
          `error stop render video of user with id ${zoomUserId}`,
          err
        )
      }
    },
    [client, videoCanvasRef]
  )

  const renderVideoWrapper = useCallback(
    async (
      user: Participant,
      canvas: HTMLCanvasElement,
      videoHeight: number,
      videoWidth: number,
      xOffset: number,
      yOffset: number
    ) => {
      const stream = client.getMediaStream()
      await stream.renderVideo(
        canvas,
        user.userId,
        videoWidth,
        videoHeight,
        xOffset,
        yOffset,
        VideoQuality.Video_360P
      )
    },
    [client]
  )

  const getVideoGridParams = useCallback(
    ({
      canvas,
      participantsToRender,
    }: GetVideoGridProps): CalcVideoCanvasGridReturn | undefined => {
      // access isRemoteScreensharing from firebase convo without mutating state
      let isScreenshare = false
      setIsRemoteScreensharing((state) => {
        isScreenshare = state
        return state
      })

      // get canvas grid params
      const calcVideoGridParams = {
        x: canvas.offsetWidth,
        y: canvas.offsetHeight,
        n: participantsToRender.length,
      }

      const willAllParticipantsFitOnOnePage =
        doVideosFitOnOnePageIfAtLeastMinVideoSize({
          ...calcVideoGridParams,
          isScreenshare,
        })

      const videoGridParams = willAllParticipantsFitOnOnePage
        ? calcVideoCanvasGrid(calcVideoGridParams)
        : calcVideoCanvasGridMinHeightAndWidth({
            ...calcVideoGridParams,
            isScreenshare,
          })

      return videoGridParams
    },
    [setIsRemoteScreensharing]
  )

  const renderAllUsersOnCanvas = useCallback(async () => {
    // TODO-CC: RM-7569 - reduce unecesary re-renders
    if (!isCallJoined || !isAVReady.video.decode || !videoCanvasRef.current) {
      return
    }

    const canvas = videoCanvasRef.current

    // construct array of users to render, ensuring local user is the first element
    const participantsToRender = [
      client.getCurrentUserInfo(),
      ...getRemoteParticipants(),
    ]

    // attempt stop video for each of these users, in case any of them are currently rendered
    const stopRenderPromises = participantsToRender.map((user) =>
      stopRenderOneUserOnCanvas(user.userId)
    )
    await Promise.all(stopRenderPromises)

    const videoGridParams = getVideoGridParams({
      canvas,
      participantsToRender,
    })

    if (!videoGridParams) {
      return
    }

    const { videoWidth, videoHeight, numCols, numRows, numPages } =
      videoGridParams
    setNumPages(numPages)

    participantsToRender.forEach((user, i) => {
      setIsVideoRenderedOnce(true)

      if (!user.bVideoOn) {
        return
      }

      const { xOffset, yOffset, page } = calcRenderVideoLocation({
        numCols,
        numRows,
        videoHeight,
        videoWidth,
        videoIndex: i,
        y: canvas.offsetHeight,
      })

      if (page === currentPage) {
        renderVideoWrapper(
          user,
          canvas,
          videoHeight,
          videoWidth,
          xOffset,
          yOffset
        )
      }
    })
  }, [
    client,
    currentPage,
    getRemoteParticipants,
    getVideoGridParams,
    isAVReady.video.decode,
    isCallJoined,
    renderVideoWrapper,
    stopRenderOneUserOnCanvas,
    videoCanvasRef,
  ])

  const updateVideoCanvas = useCallback(
    ({
      canvas,
      canvasHeight,
      canvasWidth = canvasHeight * ZOOM_ASPECT_RATIO,
    }: UpdateVideoCanvasProps) => {
      try {
        if (!isCallJoined) {
          return
        }
        const stream = client.getMediaStream()
        stream.updateVideoCanvasDimension(canvas, canvasWidth, canvasHeight)
      } catch (err) {
        console.error('Error updating video canvas dimension: ', err)
      }
    },
    [client, isCallJoined]
  )

  const startLocalVideo = useCallback(() => {
    if (!isAVReady.video.encode) return

    const stream = client.getMediaStream()
    try {
      stream.startVideo({
        mirrored: true,
      })
    } catch (err) {
      console.error('Error starting local video:', err)
    }
  }, [client, isAVReady.video.encode])

  const stopLocalVideo = useCallback(() => {
    try {
      const stream = client.getMediaStream()
      stream.stopVideo()
    } catch (err) {
      console.error('Error stoping local video:', err)
    }
  }, [client])

  const toggleVideo = useCallback(() => {
    const localUser = client.getCurrentUserInfo()
    if (!localUser) {
      return
    }
    const isVideoOn = localUser.bVideoOn
    if (isVideoOn) {
      stopLocalVideo()
    } else {
      startLocalVideo()
    }
  }, [client, startLocalVideo, stopLocalVideo])

  // Toggle speaker output of audio received without affecting the stream's connection.
  const toggleAudio = useCallback(() => {
    try {
      const localUser = client.getCurrentUserInfo()
      if (!localUser) {
        return
      }
      const isAudioMuted = localUser.muted
      const stream = client.getMediaStream()

      if (isAudioMuted) {
        stream.unmuteAudio()
      } else {
        stream.muteAudio()
      }
    } catch (err) {
      console.error('Error toggling audio', err)
    }
  }, [client])

  const changeVideoInputDevice = useCallback(async (deviceID: string) => {
    try {
      const stream = client.getMediaStream()
      await stream.switchCamera(deviceID)
    } catch (err) {
      console.error('Error switching video device:', err)
    }
  }, [])

  const changeAudioInputDevice = useCallback(async (deviceID: string) => {
    try {
      const stream = client.getMediaStream()
      await stream.switchMicrophone(deviceID)
    } catch (err) {
      console.error('Error switching audio device:', err)
    }
  }, [])

  const stopScreenshare = useCallback(async () => {
    const stream = client.getMediaStream()
    const zoomUser = client.getCurrentUserInfo()
    // if user is screensharing in zoom, stop the screenshare (could already be stopped for passively-stop-share)
    if (zoomUser?.sharerOn) {
      stream.stopShareScreen()
    }
    await removeLocalUserFirebaseConvoField(ConvoField.ScreensharingUsers)
  }, [client])

  const startScreenshare = useCallback(
    async (element: HTMLVideoElement): Promise<void> => {
      const stream = client.getMediaStream()
      //startShareScreen is typed to accept HTMLCanvasElement, but docs say can be either HTMLVideoElement or HTMLCanvasElement
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await stream.startShareScreen(element as any)
    },
    [client]
  )

  // start local user's video if video encode is ready and user is on the page (for pagination)
  useEffect(() => {
    startVideoWhenReady()

    async function startVideoWhenReady() {
      let videoEnabled = false

      // use set function to access avSetup without mutating its state
      setAVSetup((state) => {
        videoEnabled = state.videoEnabled
        return state
      })

      if (
        !isCallJoined ||
        !isAVReady.video.encode ||
        !videoCanvasRef.current ||
        !videoEnabled
      ) {
        return
      }

      try {
        const stream = client.getMediaStream()
        const videoDevices = stream.getCameraList()
        if (videoDevices.length > 0) {
          const videoAlreadyOn = client.getCurrentUserInfo().bVideoOn
          // videoAlreadyOn can happen if in pagination, users move off the local user's page and back on
          if (videoAlreadyOn) {
            await stream.stopVideo()
          }

          await stream.startVideo({ mirrored: true })
        }
      } catch (err) {
        console.warn('problem starting video:', err)
      }
    }
  }, [isAVReady.video, isCallJoined, localVideoRef, client])

  // listen to audio/video encode/decode ready state
  useEffect(() => {
    function onMediaSDKChange(payload: MediaSDKEncDecPayload) {
      const { type, action, result } = payload
      if (result === 'fail') {
        return
      }

      // ignore screenshare
      if (type === 'share') {
        return
      }

      setIsAVReady((state) => ({
        ...state,
        [type]: {
          ...state[type],
          [action]: true,
        },
      }))
    }

    client.on('media-sdk-change', onMediaSDKChange)

    return () => {
      client.off('media-sdk-change', onMediaSDKChange)
    }
  }, [client])

  // update avSetup when local video changes
  useEffect(() => {
    function onVideoCapturingChange(payload: { state: VideoCapturingState }) {
      setAVSetup((state) => ({
        ...state,
        videoEnabled: payload.state === VideoCapturingState.Started,
      }))
    }

    client.on('video-capturing-change', onVideoCapturingChange)

    return () => {
      client.off('video-capturing-change', onVideoCapturingChange)
    }
  }, [client])

  // update when local audio changes
  useEffect(() => {
    function onCurrentAudioChange(payload: {
      action: AudioChangeAction
      source?: MutedSource
      type?: 'phone' | 'computer'
    }) {
      // on join, leave, or mute, set audioEnabled to false. only set to true on unmute
      switch (payload.action) {
        case AudioChangeAction.Unmuted: {
          setAVSetup((state) => ({
            ...state,
            audioEnabled: true,
          }))
          break
        }
        default: {
          setAVSetup((state) => ({
            ...state,
            audioEnabled: false,
          }))
          break
        }
      }

      if (payload.action === AudioChangeAction.Join) {
        setIsCallAudioJoined(true)
      }
    }

    client.on('current-audio-change', onCurrentAudioChange)

    return () => {
      client.off('current-audio-change', onCurrentAudioChange)
    }
  }, [client])

  // after both call and call audio joined, mute user
  useEffect(() => {
    // CC: this is a temporary fix to address inconsistent localAVSetup.audioEnabled state when quickly joining after toggling mic on/off
    //     from pre-call AV setup as found by Reed. All users will now join muted by default, as the alternative is much more dangerous.
    //     This bug to be investigated further and fixed properly in RM-7346.
    if (isCallJoined && isCallAudioJoined) {
      const stream = client.getMediaStream()
      setTimeout(() => {
        stream.muteAudio()
      }, 200)
    }
  }, [client, isCallAudioJoined, isCallJoined])

  // update remote users
  useEffect(() => {
    client.on('user-added', refreshParticipants)
    client.on('user-removed', refreshParticipants)
    client.on('user-updated', refreshParticipants)

    function refreshParticipants() {
      setRemoteUsers(getRemoteParticipants())
      setLocalUser(client.getCurrentUserInfo())
    }

    return () => {
      client.off('user-added', refreshParticipants)
      client.off('user-removed', refreshParticipants)
      client.off('user-updated', refreshParticipants)
    }
  }, [client, getRemoteParticipants])

  // render new users as they join
  useEffect(() => {
    client.on('user-added', renderAllUsersOnCanvas)

    return () => {
      client.off('user-added', renderAllUsersOnCanvas)
    }
  }, [client, renderAllUsersOnCanvas])

  // stop rendering user on leave
  useEffect(() => {
    client.on('user-removed', stopRenderingUsersOnLeave)

    function stopRenderingUsersOnLeave(
      payload: ParticipantPropertiesPayload[]
    ) {
      payload.forEach((user) => stopRenderOneUserOnCanvas(user.userId))
      renderAllUsersOnCanvas()
    }

    return () => {
      client.off('user-removed', stopRenderingUsersOnLeave)
    }
  }, [client, renderAllUsersOnCanvas, stopRenderOneUserOnCanvas])

  // handle user toggle camera on/off
  useEffect(() => {
    client.on('user-updated', updateVideoOnUserUpdate)

    function updateVideoOnUserUpdate(payload: ParticipantPropertiesPayload[]) {
      payload.forEach((user) => {
        if (user.bVideoOn) {
          renderAllUsersOnCanvas()
        } else if (user.bVideoOn === false) {
          // bVideoOn can be undefined or false, we only want to stop rendering video when it is false
          stopRenderOneUserOnCanvas(user.userId)
        }
      })
    }
    return () => {
      client.off('user-updated', updateVideoOnUserUpdate)
    }
  }, [client, renderAllUsersOnCanvas, stopRenderOneUserOnCanvas])

  // render all users on intial join
  useEffect(() => {
    renderAllUsersOnCanvas()
  }, [renderAllUsersOnCanvas])

  // update isLocalScreensharing based on user-updated event
  useEffect(() => {
    client.on('user-updated', updateLocalUserScreenshare)

    function updateLocalUserScreenshare() {
      const zoomUser = client.getCurrentUserInfo()
      if (zoomUser?.sharerOn === undefined) {
        return
      }
      setIsLocalScreensharing(zoomUser.sharerOn)
    }

    return () => {
      client.off('user-updated', updateLocalUserScreenshare)
    }
  }, [client])

  // call stopScreenshare if user passively stops share (using browser provided stop screenshare buttons)
  useEffect(() => {
    client.on('passively-stop-share', stopScreenshare)

    return () => {
      client.off('passively-stop-share', stopScreenshare)
    }
  }, [client, stopScreenshare])

  // start/stop rendering remote users' screenshare
  useEffect(() => {
    client.on('active-share-change', onActiveShareChange)

    function onActiveShareChange(payload: {
      state: 'Active' | 'Inactive'
      userId: number
    }) {
      if (!remoteScreenshareRef.current) {
        return
      }

      const stream = client.getMediaStream()

      if (payload.state === 'Active') {
        stream.startShareView(remoteScreenshareRef.current, payload.userId)
      } else if (payload.state === 'Inactive') {
        stream.stopShareView()
      }
    }

    return () => {
      client.off('active-share-change', onActiveShareChange)
    }
  }, [client, remoteScreenshareRef])

  useEffect(() => {
    if (!isCallJoined) return

    client.on('chat-on-message', receiveChatMessages)
    return () => {
      client.off('chat-on-message', receiveChatMessages)
    }
  }, [client, receiveChatMessages, isCallJoined])

  return {
    join,
    leave,
    isCallJoined,

    zoomLocalUser: localUser,
    zoomRemoteUsers: remoteUsers,
    chatMessages: chatMessages,
    sendChat,

    avatarSize,
    setAvatarSize,
    updateVideoCanvas,
    getVideoGridParams,

    toggleVideo,
    toggleAudio,
    renderRemoteVideo,
    stopRenderRemoteVideo,
    renderAllUsersOnCanvas,
    isVideoRenderedOnce,

    changeAudioInputDevice,
    changeVideoInputDevice,

    startScreenshare,
    stopScreenshare,
    isLocalScreensharing,

    numPages,
    currentPage,
    setCurrentPage,
  }
}
