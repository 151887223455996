import React, { useState } from 'react'

import styles from './alert.module.scss'
import { Modal } from 'semantic-ui-react'
import { useFormFields } from '../../../utilities/formHooks'
import { functions } from 'utilities/firebase-utils'
import sendNotification, {
  sendGenericErrorNotification,
} from '../../LocalNotification'
import * as Sentry from '../../../sentry'
import Button from 'brand/Button'
import Input from 'brand/Input/Input'

interface Props {
  children?: React.ReactNode
  teamID: string
  teamName: string
  open: boolean
  setAlertOpen: (open: boolean) => void
}

const EditTeamNameAlert: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [fields, handleFieldChange] = useFormFields<{ teamName: string }>({
    teamName: props.teamName,
  })

  const save = () => {
    setLoading(true)
    functions
      .updateTeamName({
        teamID: props.teamID,
        teamName: fields.teamName,
      })
      .then(() => {
        sendNotification(
          `Renamed ${props.teamName} to ${fields.teamName}.`,
          'success'
        )
        setLoading(false)
        props.setAlertOpen(false)
      })
      .catch((err) => {
        Sentry.error(err)
        sendGenericErrorNotification()
        setLoading(false)
      })
  }

  return (
    <div>
      <Modal
        closeOnDimmerClick={!loading}
        trigger={
          <div>
            <Button
              onClick={(e) => {
                e.stopPropagation()
                props.setAlertOpen(true)
              }}
              darkMode
              size='small'
            >
              Edit name
            </Button>
          </div>
        }
        size='tiny'
        open={props.open}
        onClose={() => props.setAlertOpen(false)}
      >
        <div className={styles.alert} onClick={(e) => e.stopPropagation()}>
          <h3 className={styles.header}>Edit team name</h3>
          <div>
            <Input
              name='teamName'
              type='string'
              placeholder='Team name'
              value={fields.teamName}
              onChange={handleFieldChange}
              readOnly={loading}
            />
            <div className={styles.alertButtons}>
              <Button
                kind='secondary'
                darkMode
                disabled={loading}
                onClick={() => props.setAlertOpen(false)}
              >
                Cancel
              </Button>
              <Button
                darkMode
                disabled={
                  loading ||
                  fields.teamName.trim() === '' ||
                  props.teamName === fields.teamName
                }
                onClick={save}
              >
                {loading ? 'Saving...' : 'Save'}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default EditTeamNameAlert
