import { functions } from 'utilities/firebase-utils'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { setItem, LocalStorageItem } from 'data/localStorage'
import config from 'configs/generalConfig.json'
import { sendNotification } from 'components/LocalNotification'
import * as Sentry from '../sentry'
import {
  getAuth,
  signInWithCustomToken,
  signInWithEmailLink,
  User,
  UserCredential,
  onAuthStateChanged,
  inMemoryPersistence,
  signInAnonymously,
  signOut,
  browserLocalPersistence,
  setPersistence,
} from 'firebase/auth'
import { doc, getDoc, getFirestore, onSnapshot } from 'firebase/firestore'
import { AppUser, userConverter, USERS_COLLECTION } from 'models/AppUser'
import { EventTypes, identify, track, getAnonID } from 'utilities/analytics'
import { setUnconfirmedEmail } from './Setup/utils/UnconfirmedEmailHandler'

const AuthContext = createContext({} as any)

/** The AuthProvider context includes authentication, authorization statuses, as well as
 *  functions to log in or out.
 */
export type AuthContextType = {
  isLoggedIn: boolean // True when the user is authenticated
  isLoading: boolean // True while a login action is executing that should display a Loading spinner (e.g. google popup)
  isAuthInitialized: boolean // False until authentication has loaded once (i.e. during onMount), then always true
  isAuthAndAccountInitialized: boolean //  False until both auth and user data is loaded once., then always true
  isLoggedInWithAccount: boolean // True when the user is both authenticated and authorized (has an AppUser)
  isRegistered: boolean // True if the authenticated user is not anonymous.
  errorMessage?: Error
  auth?: User
  user?: AppUser
  logOut: () => Promise<void>
  logInWithEmail: () => void
  logInWithTokenLink: (userID: string, tokenID: string) => void
  logInWithEmailLink: (email: string) => void
}

export const useAuth = (): AuthContextType => useContext(AuthContext)

type Props = {
  children: React.ReactNode
}

/* A Promise-chain Fn that performs a yes/no test of whether a user account exists.
   This allows API consumers to more directly detect if an authentication & authorization will succeed. */
export const verifyHasAppUser = async (
  cred?: UserCredential
): Promise<boolean> => {
  if (cred) {
    console.debug('User authenticated, seeing if AppUser exists...')
    const ref = doc(getFirestore(), USERS_COLLECTION, cred.user.uid)
    const hasAppUser = (await getDoc(ref)).exists()
    console.debug('Has AppUser?', hasAppUser)
    return hasAppUser
  } else {
    console.debug('User is not authenticated')
    throw new Error('User is not authenticated')
  }
}

// Authenticate anonymously for a short time during a guest call.
export async function signInAsGuest(): Promise<UserCredential> {
  const firebaseAuth = getAuth()
  await setPersistence(firebaseAuth, inMemoryPersistence)
  return signInAnonymously(firebaseAuth)
}

export async function signOutIfGuest(): Promise<void> {
  const firebaseAuth = getAuth()

  if (firebaseAuth.currentUser?.isAnonymous) {
    await signOut(firebaseAuth)
  }
}

/**
 * AuthProvider is a context-based component for
 *
 * - Retrieving authentication status (`auth`)
 * - Managing authentication (e.g. `logOut`, `logInWith...`)
 * - Retrieving authorization (`user`)
 *
 * Access is enabled by default in all of web. See @useAuth() or example below:
 *
 * @example
 *  // ...  In a Component that requires `user` and `teams`
 *  { auth, user, logOut } = useAuth()
 *  teams = useUserTeams(user)
 *  // ...
 *
 */
const AuthProvider: React.FC<Props> = (props: Props) => {
  const [errorMessage, setError] = useState<Error>()
  const [isLoading, setIsLoading] = useState(false)
  const [isAuthInitialized, setAuthInitialized] = useState(false)
  const [isAuthAndAccountInitialized, setAuthAndAccountInitialized] =
    useState(false)
  const [auth, setAuth] = useState<User>()
  const [user, setAppUser] = useState<AppUser>()

  const firebaseAuth = getAuth()

  // When the component loads, immediately subscribe to initial and subsequent 'auth' Users
  useEffect(() => {
    console.debug('AUTH: Subscribed to onAuthStateChanged')
    const unsub = onAuthStateChanged(firebaseAuth, (newUser) => {
      if (newUser) {
        console.debug(`Received Auth user snapshot for user ${newUser.uid}`)
        setAuthAndAccountInitialized(false)
        setAuth(newUser)
        setAuthInitialized(true)
      } else {
        console.debug(
          'Received Auth status; No User / User is not logged in. Auth init complete'
        )
        setAuth(undefined)
        setAuthInitialized(true)
        setAuthAndAccountInitialized(true)
      }
    })
    return unsub
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // Run only on mount/unmount

  // Maintain a subscription to authorized AppUser, if authenticated
  useEffect(() => {
    if (!auth?.uid) return // We must be authenticated to fetch a user

    // Create a reference to an AppUser document (with automatic conversion)
    const ref = doc(getFirestore(), USERS_COLLECTION, auth.uid).withConverter(
      userConverter
    )
    const options = { includeMetadataChanges: true }
    /** Subscribe to document changes, updating the Context each time we see
     *  a new value
     *
     * nb. React doesn't allow setting state after components are unmounted.
     *     Since onSnapshot returns an 'unsubscribe' function, we capture its
     *     value and return it so that React will automatically invoke it on unmount,
     *     preventing unsafe updates.
     */
    const unsub = onSnapshot(ref, options, (snap) => {
      const appUser = snap.data()
      if (appUser) {
        identify(appUser)
      }

      setAppUser(appUser)
      setAuthAndAccountInitialized(true)
      console.debug('App User was updated:', { data: snap.data(), appUser })
    })
    return unsub
  }, [auth?.uid])

  /* Provides a shim for legacy `isLoggedIn` */
  const isLoggedIn = !!auth
  /* Provides a shim for legacy `isLoggedInWithAccount`. */
  const isLoggedInWithAccount = !!auth && !!user
  const isRegistered = isLoggedInWithAccount && !auth.isAnonymous

  // Internal function to mark the page as loading, you *must* always call setIsLoading(false) at the completion
  // of an action, regardless of if you encounter an error.
  const setIsLoadingAndClearErrorMessage = () => {
    setIsLoading(true)
    setAuthAndAccountInitialized(false)
    setError(undefined)
  }

  // When a request fails, mark the page as finished loading and supply the error
  const setErrorMessage = (err: Error) => {
    setIsLoading(false)
    setError(err)
  }

  /* Log out the currently authenticated user */
  const logOut = useCallback(async () => {
    setItem(LocalStorageItem.savedEmail, null)
    await firebaseAuth.signOut()
    // Clean up any dangling references to the Auth user's AppUser account
    setAppUser(undefined)
  }, [firebaseAuth])

  /* When a user attempts to log in via email, send them an email invitation link */
  const logInWithEmail = async (
    payload: functions.EmailLinkRequest,
    isResend = false
  ) => {
    const { email, redirectPath, recaptchaToken, teamId, inviteId } = payload
    setItem(LocalStorageItem.savedEmail, email)
    setIsLoadingAndClearErrorMessage()
    const anonID = await getAnonID()

    try {
      const response = await functions.sendEmailLink({
        email,
        domain: config.hostedDomain,
        scheme: config.scheme,
        redirectPath,
        recaptchaToken,
        teamId,
        inviteId,
        anonymousID: anonID,
      })

      if (response.error) {
        if (typeof response.error === 'string') {
          throw new Error(response.error)
        } else {
          throw new Error(response.error.message)
        }
      }

      if (isResend) {
        sendNotification(
          `We sent an email to ${email} with a link to log you in.`,
          'success'
        )
      }
    } catch (err) {
      setErrorMessage(err as Error)
      Sentry.error(err, { message: 'Error while sending log in email' })
    }
    setIsLoading(false)
  }

  /** When a user clicks a valid email verification link, they will be logged in */
  const logInWithEmailLink = async (email: string) => {
    setIsLoadingAndClearErrorMessage()

    if (!email) {
      setErrorMessage(Error('No email for login link.'))
      return
    }

    if (isLoggedIn) {
      await logOut()
    }

    try {
      await setPersistence(firebaseAuth, browserLocalPersistence)
      await signInWithEmailLink(firebaseAuth, email, window.location.href)
    } catch (err) {
      setErrorMessage(err as Error)
    }

    track(EventTypes.userLoggedIn, { method: 'email' })
    setIsLoading(false)
  }

  /** When a user visits a valid token link, they will be signed in */
  const logInWithTokenLink = async (userID: string, tokenID: string) => {
    setIsLoadingAndClearErrorMessage()
    if (isLoggedIn) {
      if (userID === auth?.uid) {
        setErrorMessage(
          Error('Already logged in with current user when token received')
        )
        return
      }
      await logOut()
    }

    try {
      await functions.validateToken({ tokenID }).then(async (resp) => {
        if (resp.authToken) {
          await setPersistence(firebaseAuth, browserLocalPersistence)
          signInWithCustomToken(firebaseAuth, resp.authToken)
          track(EventTypes.userLoggedIn, { method: 'token' })
        }
      })
    } catch (err) {
      setErrorMessage(err as Error)
    }
    setIsLoading(false)
  }

  // when user is authed, clear unconfirmed email stored in session storage
  useEffect(() => {
    if (auth?.email) {
      setUnconfirmedEmail(undefined)
    }
  }, [auth?.email])

  return (
    <AuthContext.Provider
      value={{
        auth,
        user,
        isLoggedIn,
        isLoggedInWithAccount,
        isAuthInitialized,
        isAuthAndAccountInitialized,
        isRegistered,
        isLoading,
        errorMessage,
        logInWithEmail,
        logOut,
        logInWithEmailLink,
        logInWithTokenLink,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
