import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useGuestCallZoom } from './GuestCallZoomProvider'
import { MIN_HEIGHT, MIN_WIDTH } from './video-size-calc-util'

import styles from './Zoom.module.scss'
import ZoomVideoCanvasOverlay from './ZoomVideoCanvasOverlay'

/* for rendering all users' videos on one canvas  */

const ZoomVideoCanvas: React.FC = () => {
  const {
    videoCanvasRef,
    updateVideoCanvas,
    renderAllUsersOnCanvas,
    isRemoteScreensharing,
    isChatOpen,
  } = useGuestCallZoom()
  const videoWrapperRef = useRef<HTMLDivElement>(null)

  const [canvasWidth, setCanvasWidth] = useState(MIN_WIDTH)
  const [canvasHeight, setCanvasHeight] = useState(MIN_HEIGHT)

  const updateCanvasSize = useCallback(() => {
    if (
      !videoWrapperRef.current ||
      !videoCanvasRef.current ||
      isRemoteScreensharing
    ) {
      return
    }
    const newWidth = videoWrapperRef.current.offsetWidth
    const newHeight = videoWrapperRef.current.offsetHeight
    setCanvasWidth(newWidth)
    setCanvasHeight(newHeight)
    updateVideoCanvas({
      canvas: videoCanvasRef.current,
      canvasHeight: newHeight,
      canvasWidth: newWidth,
    })
    renderAllUsersOnCanvas()
  }, [
    isRemoteScreensharing,
    renderAllUsersOnCanvas,
    updateVideoCanvas,
    videoCanvasRef,
  ])

  // handle browser window resizing, + react to chat open/close
  useEffect(() => {
    updateCanvasSize()

    window.addEventListener('resize', updateCanvasSize)
    return () => window.removeEventListener('resize', updateCanvasSize)
  }, [updateCanvasSize, isChatOpen])

  // handle remote user screensharing
  useEffect(() => {
    if (isRemoteScreensharing) {
      setCanvasWidth(MIN_WIDTH)
      renderAllUsersOnCanvas()
    } else {
      updateCanvasSize()
    }
  }, [
    canvasWidth,
    isRemoteScreensharing,
    renderAllUsersOnCanvas,
    updateCanvasSize,
  ])

  return (
    <div className={styles.zoomVideoCanvasWrapper} ref={videoWrapperRef}>
      {videoCanvasRef.current && (
        <ZoomVideoCanvasOverlay
          width={canvasWidth}
          height={canvasHeight}
          canvas={videoCanvasRef.current}
        />
      )}

      <canvas
        id='zoom-remote-video-canvas'
        ref={videoCanvasRef}
        width={canvasWidth}
        height={canvasHeight}
      />
    </div>
  )
}

export default ZoomVideoCanvas
