import React, { useEffect, useState } from 'react'
import {
  getAuth,
  GoogleAuthProvider,
  signInWithCredential,
  signInWithEmailLink,
} from 'firebase/auth'
import { Redirect } from 'react-router-dom'

import { useAuth } from 'components/AuthProvider'
import { useQuery } from 'utilities/locationHooks'

import { SetupPage } from './SetupPage'
import SpinnerOverlay from 'components/UI/Spinner'
import GetStarted from './GetStarted/GetStarted'
import config from 'configs/generalConfig.json'

import { getFirebaseWebAPIBaseUrl } from 'utilities'
import { HeaderWithAppIcon } from 'components/UI/HeaderWithAppIcon/HeaderWithAppIcon'

const LoginPage: React.FC = () => {
  const { auth, user } = useAuth()
  const query = useQuery()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const [redirectUrl, setRedirectUrl] = useState<string>()

  useEffect(() => {
    if (user && !user.isGuest) {
      setRedirectUrl('/launch')
    }
  }, [user])

  useEffect(() => {
    maybeAuthFromGoogleToken()
    maybeAuthFromURL()

    async function maybeAuthFromGoogleToken() {
      if (!query) {
        return
      }

      const authToken = query.get('token')

      if (!authToken) {
        return
      }
      setLoading(true)

      try {
        const auth = getAuth()
        auth.useDeviceLanguage()
        // use the auth token to get a credential to sign into Firebase
        const credential = GoogleAuthProvider.credential(null, authToken)
        await signInWithCredential(auth, credential)
      } catch (error) {
        setError('Something went wrong while logging in with Google')
      } finally {
        setLoading(false)
      }
    }

    async function maybeAuthFromURL() {
      const apiKey = query.get('apiKey')
      const email = query.get('email')
      const uid = auth?.uid
      if (!apiKey || uid || !email) {
        return
      }

      setLoading(true)
      try {
        // apiKey must be part of window.location.href for this function to work
        await signInWithEmailLink(getAuth(), email, window.location.href)
      } catch (error) {
        setError('Something went wrong while logging in with email')
      } finally {
        setLoading(false)
      }
    }
  }, [auth?.uid, query])

  const loginWithGoogle = async () => {
    const clientID = config.webAppClientID

    const client = google.accounts.oauth2.initCodeClient({
      client_id: clientID,
      scope:
        'openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
      ux_mode: 'redirect',
      redirect_uri: `${getFirebaseWebAPIBaseUrl()}/api/v1/google/auth`,
    })
    client.requestCode()
  }

  return (
    <SetupPage title='Log in'>
      {redirectUrl ? (
        <Redirect to={redirectUrl} />
      ) : loading ? (
        <SpinnerOverlay darkMode />
      ) : (
        <GetStarted
          isLogin
          continueWithGoogle={loginWithGoogle}
          error={error}
          header={<HeaderWithAppIcon title='Log in to Multi' />}
          redirect={<p></p>} // redirect to sign up link removed for wind down
        />
      )}
    </SetupPage>
  )
}

export default LoginPage
