export enum EventTypes {
  // Product Analytic
  teamInviteLinkCopied = 'Team Invite Link Copied',
  userLoggedIn = 'User Logged In',

  // Debugging Metrics
  selfieUploaded = 'Selfie Uploaded',
  selfieTaken = 'Selfie Taken',

  functionExecuted = 'Function Executed',

  // during guest call
  callJoinClicked = 'Call Join Clicked',
  incallSignupClicked = 'In-call Sign-up Clicked',
  callJoined = 'Call Joined',
  callCompleted = 'Call Completed',
  feedbackSubmitted = 'Feedback Submitted',

  // after guest call
  callEndedCreateTeamClicked = 'Call Ended Create Team Clicked',

  // tracked in /signup
  callEndedSignupClicked = 'Call Ended Signup Clicked',
  signupGoogleAuthClicked = 'Signup Google Auth Button Clicked',
  signupEmailAuthClicked = 'Signup Email Auth Button Clicked',

  defaultAvatarUsed = 'Default Avatar Used',

  redownloadLinkClicked = 'Redownload Link Clicked',
  userAccountCreated = 'User Account Created',

  connectSlackClicked = 'Connect Slack Clicked',

  userBlockedByWaitlist = 'User Blocked By Waitlist',

  exportStarted = 'Export Started',
  exportFailed = 'Export Failed',
  exportSuccessful = 'Export Successful',
  accountDeleted = 'Account Deleted',
}

/* "Whence" • adverb • meaning "from what place or source".
 Indicates the team-source for account creation in `surfAccountCreated` events. */
export enum TeamWhence {
  createdTeam = 'created-team',
  joinedViaCapture = 'joined-team-via-domain-capture',
  joinedViaInvite = 'joined-team-via-direct-invite',
}

export enum EventSources {
  guestCall = 'guest-call',
  guestCallRedirect = 'guest-call-redirect',
}

export enum PageCategory {
  home = 'Home',
  onboarding = 'Onboarding',
  call = 'Call',
}
/** Page titles are limitied to a few words in capitalized form */
export type PageTitle =
  | Capitalize<string>
  | `${Capitalize<string>} ${Capitalize<string>}`
  | `${Capitalize<string>} ${Capitalize<string>} ${Capitalize<string>}`

/** Event properties should be snake case, at most six parts */
export type SnakeCaseString =
  | Lowercase<string>
  | `${Lowercase<string>}_${Lowercase<string>}`
  | `${Lowercase<string>}_${Lowercase<string>}_${Lowercase<string>}`
  | `${Lowercase<string>}_${Lowercase<string>}_${Lowercase<string>}_${Lowercase<string>}`
  | `${Lowercase<string>}_${Lowercase<string>}_${Lowercase<string>}_${Lowercase<string>}_${Lowercase<string>}`
  | `${Lowercase<string>}_${Lowercase<string>}_${Lowercase<string>}_${Lowercase<string>}_${Lowercase<string>}_${Lowercase<string>}`

/** Event properties should be keyed by PropertyKeys (snake_case) */
export type SnakeCasedRecord = Record<SnakeCaseString, unknown>
