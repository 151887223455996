import React from 'react'
import config from 'configs/generalConfig.json'
import styles from 'components/scss/container.module.scss'
import { useAuth } from 'components/AuthProvider'
import Button from 'brand/Button'

type Props = {
  openApp: (convoID: string, scheme?: string) => Promise<void>
  convoID: string
}

const devSchemes = [
  'multiDev',
  'multiDev2',
  'multiDev3',
  'multiDev4',
  'multiDevPersonal',
  'multiDevPersonal2',
]
const prodSchemes = ['multi', 'multiAlpha']
const defaultScheme = config.scheme

const OpenRemotionTestApps: React.FC<Props> = (props: Props) => {
  const { auth, isLoggedInWithAccount } = useAuth()
  const isRemotionTeamMember =
    auth &&
    isLoggedInWithAccount &&
    auth.email &&
    (auth.email.match(/@remotion\.com$/) || auth.email.match(/@multi\.app$/))
  const schemesList: string[] = config.isProd ? prodSchemes : devSchemes

  if (!isRemotionTeamMember) {
    return null
  }

  return (
    <div style={{ paddingTop: '1em' }}>
      <strong>
        <h2 className={styles.blink}>Secret dev menu</h2>
        <div>
          <Button
            darkMode
            borderless
            kind='secondary'
            onClick={() => props.openApp(props.convoID, defaultScheme)}
          >
            {defaultScheme} (default)
          </Button>
        </div>
      </strong>
      {schemesList
        .filter((currentScheme) => currentScheme !== defaultScheme)
        .map((currentScheme, i) => (
          <div key={i}>
            <Button
              darkMode
              borderless
              kind='secondary'
              onClick={() => props.openApp(props.convoID, currentScheme)}
            >
              {currentScheme}
            </Button>
          </div>
        ))}
      <h1>🤫</h1>
    </div>
  )
}

export default OpenRemotionTestApps
