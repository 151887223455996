import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import styles from './download.module.scss'
import { PageCategory, usePageTracking } from 'utilities/analytics'

const DOWNLOAD_URL = 'https://updates.multi.app/latest'

export const downloadApp = () => {
  window.open(DOWNLOAD_URL, '_self')
}

const Download: React.FC = () => {
  usePageTracking(PageCategory.home, 'Download')
  useEffect(() => {
    setTimeout(downloadApp, 1000)
  }, [])

  return (
    <div>
      <Helmet>
        <title>Download</title>
      </Helmet>
      <div className='AppFull'>
        <div className={styles.titles}>
          <h1 className={styles.header}>
            {"You're on the way to using Multi"}
          </h1>
          <p className={styles.subHeader}>
            {"If your download didn't start automatically,"}
            <a href={DOWNLOAD_URL} download>
              try again.
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Download
