import React from 'react'
import classNames from 'classnames'
import styles from './toast.module.scss'
import successIcon from './images/greentick.svg'
import errorIcon from './images/redalert.svg'
import closeIcon from './images/closebutton.svg'
export declare type ToastType = 'info' | 'success' | 'error'

interface Props {
  message: string
  type: ToastType
}

const Toast: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <div
      className={classNames(
        styles.container,
        props.type === 'success'
          ? styles.containerSuccess
          : styles.containerFailure
      )}
    >
      <div className={styles.flex}>
        <div>
          <img src={props.type === 'success' ? successIcon : errorIcon} />
        </div>
        <p className={styles.message}>{props.message}</p>
        <div className={styles.closeButton}>
          <img src={closeIcon} />{' '}
        </div>
      </div>
    </div>
  )
}

export default Toast
