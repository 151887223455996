import { getAvatarURL, cacheAvatarURL } from '../../../data/sessionStorage'
import { getDownloadURL, getStorage, ref } from 'firebase/storage'

export const getImageUrl = async (url: string): Promise<string> => {
  const storage = getStorage()

  const cacheURL = getAvatarURL(url ?? '')
  if (cacheURL) {
    return cacheURL
  }
  const fileRef = ref(storage, url)
  const fileUrl = await getDownloadURL(fileRef)
  cacheAvatarURL(url, fileUrl)
  return fileUrl
}
