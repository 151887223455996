import { FormEvent, useState } from 'react'

export const useFormFields = <T>(
  initialState: T
): [
  T,
  (cb: FormEvent<HTMLInputElement>) => void,
  (key: string, value: any) => void
] => {
  const [fields, setValues] = useState(initialState)

  return [
    fields,
    (event: FormEvent<HTMLInputElement>) => {
      if (event.currentTarget.type === 'checkbox') {
        setValues({
          ...fields,
          [event.currentTarget.name]: event.currentTarget.checked,
        })
      } else {
        setValues({
          ...fields,
          [event.currentTarget.name]: event.currentTarget.value,
        })
      }
    },
    (key: string, value: any) => {
      setValues({
        ...fields,
        [key]: value,
      })
    },
  ]
}
