import React, { useState } from 'react'

import AvatarImage from '../AvatarImage/AvatarImage'

type Props = {
  avatarFile: string
  liveSelfieFile?: string
  url?: string
  size?: 'big' | 'regular' | 'fluid'
  hasVideo?: boolean
}

const UserSelfie: React.FC<Props> = (props: Props) => {
  const { avatarFile, liveSelfieFile, url, size, hasVideo } = props
  const [isMouseHover, setIsMouseHover] = useState(false)

  const isVideo = (isMouseHover && hasVideo) || (hasVideo && url)

  return (
    <div
      onMouseEnter={() => setIsMouseHover(true)}
      onMouseLeave={() => setIsMouseHover(false)}
    >
      <AvatarImage
        type={isVideo ? 'video' : 'image'}
        avatarFile={isVideo ? liveSelfieFile : avatarFile}
        url={url}
        size={size}
      />
    </div>
  )
}

export default UserSelfie
