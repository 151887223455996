import React, { useState } from 'react'
import { functions } from 'utilities/firebase-utils'
import { Grid } from 'semantic-ui-react'
import Helmet from 'react-helmet'
import { saveAs } from 'file-saver'

import Card from '../../UI/Card'
import GoogleCalendarConnectionStatus from '../../UI/GoogleCalendarConnectionStatus'
import SelfieUpdater from './SelfieUpdater'
import styles from './Account.module.scss'
import EditTextFieldAlert from 'components/UI/Alerts/EditTextFieldAlert'
import { useAuth } from 'components/AuthProvider'
import Button from 'brand/Button'
import { ExportSessionsResponse } from 'utilities/firebase-utils/functions'
import { EventTypes, track } from 'utilities/analytics'

const Account: React.FC = () => {
  const { auth, user } = useAuth()

  const [exportClicked, isExportClicked] = useState(false)
  const [deleteClicked, isDeleteClicked] = useState(false)

  const fullName = (user?.firstName ?? '') + ' ' + (user?.lastName ?? '')
  const pronouns = user?.pronouns ?? undefined
  const displayName = user?.displayName ?? undefined

  const changeUserName = (userFullName: string) => {
    functions.updateUserFullName({ userFullName })
  }

  const changeUserDisplayName = (displayName: string) => {
    functions.updateUserDisplayName({ displayName })
  }

  const changeUserPronouns = (pronouns: string) => {
    functions.updateUserPronouns({ pronouns })
  }

  const exportSessions = async () => {
    track(EventTypes.exportStarted, { userID: user?.ID })
    isExportClicked(true)
    let res: ExportSessionsResponse | undefined
    try {
      res = await functions.exportSessions()
    } catch (error) {
      track(EventTypes.exportFailed, { userID: user?.ID })
      isExportClicked(false)
      alert('Your export failed. Please email support@multi.app')
      return
    }

    if (!res || !res.csvResult) {
      track(EventTypes.exportFailed, { userID: user?.ID })
      isExportClicked(false)
      alert('Your export failed. Please email support@multi.app')
      return
    }

    const csvResult = res.csvResult
    if (csvResult) {
      const blob = new Blob([csvResult], { type: 'text/csv;charset=utf-8;' })
      saveAs(blob, 'export.csv')
    }

    track(EventTypes.exportSuccessful, { userID: user?.ID })
    isExportClicked(false)
  }

  const deleteAccount = async () => {
    isDeleteClicked(true)
    track(EventTypes.accountDeleted, { userID: user?.ID })
    await functions.leaveTeam({ teamID: undefined })
  }

  return (
    <div>
      <Helmet>
        <title>Account</title>
      </Helmet>
      <h1 style={{ margin: '0px 0px 32px' }}>Account</h1>
      <div>
        <Card>
          <h3>Profile </h3>
          <hr className='app-divider' />
          <div className={styles.selfieUpdaterContainer}>
            <SelfieUpdater
              buttonProps={{ borderless: false, kind: 'primary' }}
              userID={user?.ID || ''}
              teamID={user?.teams[0].id || ''}
              avatarFile={user?.avatarFile || ''}
              liveSelfieFile={user?.liveSelfieFile || ''}
              confirmationTxt='Confirm'
            />
          </div>
          <Grid columns={3} verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column width='3'>
                <strong>Full name</strong>
              </Grid.Column>
              <Grid.Column stretched width='10'>
                {fullName}
              </Grid.Column>
              <Grid.Column
                width='3'
                textAlign={'right'}
                floated='right'
                stretched={true}
              >
                <EditTextFieldAlert
                  fieldName='fullName'
                  label='Change your name'
                  initialValue={fullName}
                  placeholder='Full Name'
                  onSave={changeUserName}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width='3'>
                <strong>Display name</strong>
              </Grid.Column>
              <Grid.Column stretched width='10'>
                {displayName ?? 'Not set (optional)'}
              </Grid.Column>
              <Grid.Column
                width='3'
                textAlign={'right'}
                floated='right'
                stretched={true}
              >
                <EditTextFieldAlert
                  optional
                  fieldName='displayName'
                  label='Change your display name'
                  initialValue={displayName}
                  onSave={changeUserDisplayName}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width='3'>
                <strong>Pronouns</strong>
              </Grid.Column>
              <Grid.Column stretched width='10'>
                {pronouns ?? 'Not set (optional)'}
              </Grid.Column>
              <Grid.Column
                width='3'
                textAlign={'right'}
                floated='right'
                stretched={true}
              >
                <EditTextFieldAlert
                  optional
                  fieldName='pronouns'
                  label='Change your pronouns'
                  initialValue={pronouns}
                  placeholder='She/he/they/xe..'
                  onSave={changeUserPronouns}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width='3'>
                <strong>Email</strong>
              </Grid.Column>
              <Grid.Column>{user?.email}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width='4' floated='left' stretched>
                <Button
                  darkMode
                  onClick={exportSessions}
                  disabled={exportClicked}
                >
                  {exportClicked ? 'Generating Export' : 'Export Session Notes'}
                </Button>
              </Grid.Column>
              <Grid.Column width='3' floated='right' stretched>
                <Button
                  darkMode
                  onClick={deleteAccount}
                  disabled={deleteClicked}
                >
                  Delete Account
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card>
        <div style={{ marginTop: '32px' }}>
          <GoogleCalendarConnectionStatus
            userID={auth?.uid}
            googleUserEmail={user?.googleUserEmail}
            googleCalendarEnabled={user?.googleCalendarEnabled}
            hideEventTitles={user?.hideGoogleCalendarEventTitles}
          />
        </div>
      </div>
    </div>
  )
}

export default Account
