import { getFunctions, httpsCallable } from 'firebase/functions'
import now from 'performance-now'
import { EventTypes, track } from 'utilities/analytics'

const MASTER_CALLABLE_NAME = 'masterCallable'

enum CallableEndpoint {
  cancelInvite = 'cancelInvite',
  connectGoogleCalendar = 'connectGoogleCalendar',
  createConnectSlackLink = 'createConnectSlackLink',
  createTeam = 'createTeam',
  createToken = 'createToken',
  disconnectGoogleAccount = 'disconnectGoogleAccount',
  generateTeamInviteLink = 'generateTeamInviteLink',
  getInvite = 'getInvite',
  getTeamsToLinkSlackAccount = 'getTeamsToLinkSlackAccount',
  joinTeamFromInviteLink = 'joinTeamFromInviteLink',
  leaveTeam = 'leaveTeam',
  linkSlackAccount = 'linkSlackAccount',
  removeTeammate = 'removeTeammate',
  resendInvite = 'resendInvite',
  sendEmailLink = 'sendEmailLink',
  toggleGoogleCalendarEventTitleSharing = 'toggleGoogleCalendarEventTitleSharing',
  unlinkSlackWorkspace = 'unlinkSlackWorkspace',
  acceptInviteUsingToken = 'acceptInviteUsingToken',
  updateTeamName = 'updateTeamName',
  updateUserFullName = 'updateUserFullName',
  validateInviteLink = 'validateInviteLink',
  validateToken = 'validateToken',
  startScreenshare = 'startScreenshare',
  stopScreenshare = 'stopScreenshare',
  joinRoom = 'joinRoom',
  conversationInvite = 'conversationInvite',
  cancelConversationInvite = 'cancelConversationInvite',
  acceptConversationInvite = 'acceptConversationInvite',
  delayConversationInvite = 'delayConversationInvite',
  declineConversationInvite = 'declineConversationInvite',
  inviteConversationExpired = 'inviteConversationExpired',
  joinConversation = 'joinConversation',
  leaveConversation = 'leaveConversation',
  createRoom = 'createRoom',
  getRoom = 'getRoom',
  updateRoom = 'updateRoom',
  deleteRoom = 'deleteRoom',
  updateAvatar = 'updateAvatar',
  updateUserPronouns = 'updateUserPronouns',
  updateUserDisplayName = 'updateUserDisplayName',
  getSlackChannels = 'getSlackChannels',
  getAvatars = 'getAvatars',
  selfOnboard = 'selfOnboard',
  createAccountAndJoinTeam = 'createAccountAndJoinTeam',
  getSelf = 'getSelf',
  getReferralLink = 'getReferralLink',
  checkReferralLink = 'checkReferralLink',
  validateConversationToken = 'validateConversationToken',
  getUsersInConversation = 'getUsersInConversation',
  createGuestUser = 'createGuestUser',
  toggleDomainCapture = 'toggleDomainCapture',
  checkUserMatchTeamDomain = 'checkUserMatchTeamDomain',
  listJoinableTeamsOnDomain = 'listJoinableTeamsOnDomain',
  joinWindowsWaitlist = 'joinWindowsWaitlist',
  createGoogleRecord = 'createGoogleRecord',
  createSession = 'createSession',
  getGuestCallAllowed = 'getGuestCallAllowed',
  sendEmailDownloadLink = 'sendEmailDownloadLink',
  onboardingEvent = 'onboardingEvent',
  exportSessions = 'exportSessions',
}

type Request = Record<string, unknown>

type EmailLoginError = { message: string; status: string }

export type Response = {
  text: string
  error?: string | EmailLoginError
}

type ResponseWithAuthToken = Response & { authToken: string }

type WithTokenID = { tokenID: string }
type RequestWithTokenID = Request & WithTokenID
export type ResponseWithTokenID = Response & WithTokenID

async function callableRequest<ResponseType = Response>(
  endpoint: CallableEndpoint,
  data: Request
): Promise<ResponseType> {
  console.debug(
    `Emitting a callable request for ${endpoint} with payload: `,
    data
  )

  const startMs = now()
  const fns = getFunctions()
  const callable = httpsCallable<Request, ResponseType>(
    fns,
    MASTER_CALLABLE_NAME,
    { timeout: 210000 }
  )
  const result = await callable({ callableName: endpoint, ...data })
  const finishMs = now()
  const executionTime = finishMs - startMs

  track(EventTypes.functionExecuted, {
    function_name: MASTER_CALLABLE_NAME,
    callable_name: endpoint,
    elapsed_time: executionTime,
  })

  return await result.data
}

export type RedirectPath = { path: string; search: { [key: string]: string } }

export type EmailLinkRequest = {
  email: string
  domain: string
  scheme: string
  redirectPath?: RedirectPath
  recaptchaToken?: string
  teamId?: string
  inviteId?: string
  isLogin?: boolean
  unverifiedUserFullname?: string
  anonymousID?: string
}

export const sendEmailLink = async (
  request: EmailLinkRequest
): Promise<Response> => {
  return callableRequest(CallableEndpoint.sendEmailLink, request)
}

export const createToken = async (): Promise<ResponseWithTokenID> => {
  return callableRequest<ResponseWithTokenID>(CallableEndpoint.createToken, {})
}

export const validateToken = async (
  request: RequestWithTokenID
): Promise<ResponseWithAuthToken> => {
  return callableRequest<ResponseWithAuthToken>(
    CallableEndpoint.validateToken,
    request
  )
}

export type CancelInviteRequest = {
  email: string
  teamID: string
}

export const cancelInvite = async (
  request: CancelInviteRequest
): Promise<Response> => {
  return callableRequest(CallableEndpoint.cancelInvite, request)
}

export type ResendInviteRequest = {
  teamID: string
  inviteeEmail: string
}

export const resendInvite = async (
  request: ResendInviteRequest
): Promise<Response> => {
  return callableRequest(CallableEndpoint.resendInvite, request)
}

export type RemoveTeammateRequest = {
  userIDToRemove: string
  teamID: string
}

export const removeTeammate = async (
  request: RemoveTeammateRequest
): Promise<Response> => {
  return callableRequest(CallableEndpoint.removeTeammate, request)
}

export type LeaveTeamRequest = {
  teamID: string | undefined
}

export const leaveTeam = async (
  request: LeaveTeamRequest
): Promise<Response> => {
  return callableRequest(CallableEndpoint.leaveTeam, request)
}

export type ExportSessionsResponse = Response & {
  csvResult?: string
}
export const exportSessions = async (): Promise<ExportSessionsResponse> => {
  return callableRequest(CallableEndpoint.exportSessions, {})
}

export type UpdateTeamNameRequest = {
  teamName: string
  teamID: string
}

export const updateTeamName = async (
  request: UpdateTeamNameRequest
): Promise<Response> => {
  return callableRequest(CallableEndpoint.updateTeamName, request)
}

export type UpdateUserFullNameRequest = {
  userFullName: string
  omitLastName?: boolean
}

export const updateUserFullName = async (
  request: UpdateUserFullNameRequest
): Promise<Response> => {
  return callableRequest(CallableEndpoint.updateUserFullName, request)
}

export type CreateTeamRequest = {
  teamName: string
  email: string
  isSharedDomain: boolean
}

export const createTeam = async (
  request: CreateTeamRequest
): Promise<Response> => {
  return callableRequest(CallableEndpoint.createTeam, request)
}

export type LinkSlackAccountRequest = {
  nonce: string
  teamID: string
}

export type LinkSlackAccountResponse = Response & {
  errorCode?: string
}

export const linkSlackAccount = async (
  request: LinkSlackAccountRequest
): Promise<LinkSlackAccountResponse> => {
  return callableRequest<LinkSlackAccountResponse>(
    CallableEndpoint.linkSlackAccount,
    request
  )
}

export type UnlinkSlackWorkspaceRequest = {
  teamID: string
}

export const unlinkSlackWorkspace = async (
  request: UnlinkSlackWorkspaceRequest
): Promise<Response> => {
  return callableRequest(CallableEndpoint.unlinkSlackWorkspace, request)
}

export type GetTeamsToLinkSlackAccountRequest = {
  nonce: string
}

export type GetTeamsToLinkSlackAccountResponse = Response & {
  canLinkTeamIDs: string[]
  workspaceAlreadyLinkedWithTeam: string
  isUserOnLinkedTeam: boolean
}

export const getTeamsToLinkSlackAccount = async (
  request: GetTeamsToLinkSlackAccountRequest
): Promise<GetTeamsToLinkSlackAccountResponse> => {
  return callableRequest<GetTeamsToLinkSlackAccountResponse>(
    CallableEndpoint.getTeamsToLinkSlackAccount,
    request
  )
}

export type CreateConnectSlackLinkRequest = {
  teamID: string
}

export type CreateConnectSlackLinkResponse = Response & {
  url: string
}

export const createConnectSlackLink = async (
  request: CreateConnectSlackLinkRequest
): Promise<CreateConnectSlackLinkResponse> => {
  return callableRequest<CreateConnectSlackLinkResponse>(
    CallableEndpoint.createConnectSlackLink,
    request
  )
}

export type ConnectGoogleCalendarRequest = {
  code: string
}

export type ConnectGoogleCalendarErrorCode = 1001 | 1002
export type ConnectGoogleCalendarResponse = Response & {
  errorCode?: ConnectGoogleCalendarErrorCode
}

export const connectGoogleCalendar = async (
  request: ConnectGoogleCalendarRequest
): Promise<ConnectGoogleCalendarResponse> => {
  return callableRequest<ConnectGoogleCalendarResponse>(
    CallableEndpoint.connectGoogleCalendar,
    request
  )
}

export const disconnectGoogleAccount = async (): Promise<Response> => {
  return callableRequest(CallableEndpoint.disconnectGoogleAccount, {})
}

export type ToggleGoogleCalendarEventTitleSharingRequest = {
  hideTitles: boolean
}

export const toggleGoogleCalendarEventTitleSharing = async (
  request: ToggleGoogleCalendarEventTitleSharingRequest
): Promise<Response> => {
  return callableRequest(
    CallableEndpoint.toggleGoogleCalendarEventTitleSharing,
    request
  )
}

export type GenerateTeamInviteLinkRequest = {
  teamID: string
}

export type GenerateTeamInviteLinkResponse = Response & { inviteLink?: string }

export const generateTeamInviteLink = async (
  request: GenerateTeamInviteLinkRequest
): Promise<GenerateTeamInviteLinkResponse> => {
  return callableRequest<GenerateTeamInviteLinkResponse>(
    CallableEndpoint.generateTeamInviteLink,
    request
  )
}

export type ValidateInviteLinkRequest = {
  teamID: string
  inviteID: string
  convoID?: string
}
export type InviteLinkErrorCode = 101 | 102 | 103 | 104 | 105 | 110
export type ValidateInviteLinkResponse = Response & {
  errorCode?: InviteLinkErrorCode
  teamName?: string
  invitedByName?: string
  roomName?: string
  roomIcon?: {
    background?: {
      type: string
      value: {
        endColor: string
        startColor: string
      }
    }
    type: string
    value: string
  }
  users?: {
    id: string
    avatarFile: string
  }[]
}

export const validateInviteLink = async (
  request: ValidateInviteLinkRequest
): Promise<ValidateInviteLinkResponse> => {
  return callableRequest<ValidateInviteLinkResponse>(
    CallableEndpoint.validateInviteLink,
    request
  )
}

export type JoinTeamFromInviteLinkRequest = {
  teamID: string
  inviteID: string
}

export const joinTeamFromInviteLink = async (
  request: JoinTeamFromInviteLinkRequest
): Promise<Response> => {
  return callableRequest(CallableEndpoint.joinTeamFromInviteLink, request)
}

export const startScreenshare = async (): Promise<Response> => {
  return callableRequest(CallableEndpoint.startScreenshare, {})
}

export const stopScreenshare = async (): Promise<Response> => {
  return callableRequest(CallableEndpoint.stopScreenshare, {})
}

export type JoinRoomRequest = {
  roomID: string
}

export const joinRoom = async (request: JoinRoomRequest): Promise<Response> => {
  return callableRequest(CallableEndpoint.joinRoom, request)
}

export type ConversationInviteRequest = {
  userID: string
  inviteSpecification: InviteSpecification
}

export type InviteSpecification = {
  inviteID: string
  topic: string
  mode: string
}

export const conversationInvite = async (
  request: ConversationInviteRequest
): Promise<Response> => {
  return callableRequest(CallableEndpoint.conversationInvite, request)
}

export type CancelConversationInviteRequest = {
  inviteeID: string
  reason: string
}

export const cancelConversationInvite = async (
  request: CancelConversationInviteRequest
): Promise<Response> => {
  return callableRequest(CallableEndpoint.cancelConversationInvite, request)
}

export type AcceptConversationInviteRequest = {
  inviteID: string
}

export const acceptConversationInvite = async (
  request: CancelConversationInviteRequest
): Promise<Response> => {
  return callableRequest(CallableEndpoint.acceptConversationInvite, request)
}

export type DelayConversationInviteRequest = {
  inviteID: string
}

export const delayConversationInvite = async (
  request: DelayConversationInviteRequest
): Promise<Response> => {
  return callableRequest(CallableEndpoint.delayConversationInvite, request)
}

export type DeclineConversationInviteRequest = {
  inviteID: string
  reason: string // CodableEmojiDescription?
}

export const declineConversationInvite = async (
  request: DeclineConversationInviteRequest
): Promise<Response> => {
  return callableRequest(CallableEndpoint.declineConversationInvite, request)
}

export const inviteConversationExpired = async (
  request: Request
): Promise<Response> => {
  return callableRequest(CallableEndpoint.inviteConversationExpired, request)
}

export type JoinConversationRequest = {
  conversationID: string
  token: string
  sessionID?: string
  preferredAVProvider?: AVProvider
}

export enum AVProvider {
  AGORA = 'agora',
  ZOOM = 'zoom',
}

export type ChannelDetails = {
  provider: AVProvider
  channelID: string
  userID: string
  signedJWT?: string // zoom
  token?: string // agora
  secret?: string // agora
}

export type ChannelAccessDescription = {
  conversationID: string
  channelDetails: ChannelDetails
}

let prevRequestID = 0
/** Provides a monotonic id for conversation requests */
const requestID = () => {
  return prevRequestID++
}

export type JoinConversationResponse = Response & {
  channelAccessDescription: ChannelAccessDescription
}

export const joinConversation = async (
  request: JoinConversationRequest
): Promise<JoinConversationResponse> => {
  return callableRequest<JoinConversationResponse>(
    CallableEndpoint.joinConversation,
    { requestID: requestID(), ...request }
  )
}

export type LeaveConversationRequest = {
  reason: string
  sessionID?: string
}

export const leaveConversation = async (
  request: LeaveConversationRequest
): Promise<Response> => {
  return callableRequest(CallableEndpoint.leaveConversation, {
    requestID: requestID(),
    ...request,
  })
}

export type GetRoomRequest = {
  teamID: string
  roomID: string
}

export enum AVPreference {
  Allowed = 'allowed',
  NotAllowed = 'not-allowed',
  DefaultOff = 'default-off',
  audioPreference = 'audioPreference',
}

export type AVSettings = {
  audioPreference: AVPreference
  videoPreference: AVPreference
}

export type RoomIconLinearGradient = {
  type: string // "linear-gradient"
  value: {
    startColor: string
    endColor: string
  }
}

export type RoomIcon = {
  type: string
  value: string
  background?: RoomIconLinearGradient
}

export enum RoomType {
  coworking = 'coworking',
  meeting = 'meeting',
}

export type RoomResponse = {
  room: {
    id: string
    name: string
    convoID: string
    description?: string
    icon: RoomIcon
    slack?: {
      channelID?: string | null
      channelName?: string | null
      autoBroadcastTurnedOn?: boolean
      mostRecentThreadID?: string
      lastAutoBroadcastDateNumber?: number
    }
    type: RoomType
  }
}

export const getRoom = async (
  request: GetRoomRequest
): Promise<RoomResponse> => {
  return callableRequest<RoomResponse>(CallableEndpoint.getRoom, request)
}

export type CreateRoomRequest = {
  teamID: string
  name: string
  description: string
  icon: RoomIcon
  avSettings: AVSettings
  shouldAutoplayMedia: boolean
  slack: {
    channelID: string | null
    channelName: string | null
    autoBroadcastTurnedOn: boolean
  }
}

export const createRoom = async (
  request: CreateRoomRequest
): Promise<Response> => {
  return callableRequest(CallableEndpoint.createRoom, request)
}

export type UpdateRoomRequest = CreateRoomRequest & { roomID: string }

export const updateRoom = async (
  request: UpdateRoomRequest
): Promise<Response> => {
  return callableRequest<Response>(CallableEndpoint.updateRoom, request)
}

export type DeleteRoomRequest = {
  teamID: string
  roomID: string
}

export const deleteRoom = async (
  request: DeleteRoomRequest
): Promise<Response> => {
  return callableRequest<Response>(CallableEndpoint.deleteRoom, request)
}

export type UpdateAvatarRequest = {
  avatarFile: string
  liveSelfieFile?: string
  liveSelfieFileToProcess?: string
}

export const updateAvatar = async (
  request: UpdateAvatarRequest
): Promise<Response> => {
  return callableRequest<Response>(CallableEndpoint.updateAvatar, request)
}

export type UpdateUserPronounsRequest = {
  pronouns?: string
}

export const updateUserPronouns = async (
  request: UpdateUserPronounsRequest
): Promise<Response> => {
  return callableRequest<Response>(CallableEndpoint.updateUserPronouns, request)
}

export type UpdateUserDisplayNameRequest = {
  displayName?: string
}

export const updateUserDisplayName = async (
  request: UpdateUserDisplayNameRequest
): Promise<Response> => {
  return callableRequest<Response>(
    CallableEndpoint.updateUserDisplayName,
    request
  )
}

export type GetSlackChannelsRequest = {
  teamID: string
}

export type SlackChannel = {
  id: string
  name: string
}

export type GetSlackChannelsResponse = Response & {
  channels: SlackChannel[]
}

export const getSlackChannels = async (
  request: GetSlackChannelsRequest
): Promise<GetSlackChannelsResponse> => {
  return callableRequest<GetSlackChannelsResponse>(
    CallableEndpoint.getSlackChannels,
    request
  )
}

export type Avatar = {
  id: string
  url: string
}

export type GetAvatarsResponse = Response & {
  avatars: Avatar[]
}

export const getAvatars = async (
  request: Request
): Promise<GetAvatarsResponse> => {
  return callableRequest<GetAvatarsResponse>(
    CallableEndpoint.getAvatars,
    request
  )
}

export type SelfOnboardRoom = {
  name: string
  description: string
  isCoworking: boolean
  emoji: {
    value: string
    startColor: string
    endColor: string
  }
}

export type HubspotContactProps = {
  source?: string // How did you hear about Multi?
  teamOS?: string // What OS does your core team use?
  windowsWaitlist?: boolean // is this a Windows users wanting to join the waitlist?
  referrer?: string // the referrer's name if the source is "A friend or colleague"
}

export type OnboardingRoom = {
  name: string
  description: string
  avSettings: AVSettings
  shouldAutoplayMedia: boolean
  emoji: {
    value: string
    startColor: string
    endColor: string
  }
}

export type SelfOnboardRequest = {
  teamName: string
  userFullName: string
  isSharedDomain?: boolean
  avatarFile?: string
  selfie?: string
  liveSelfieFile?: string
  googleData?: {
    googleUserID: string
  }
  teamGoal?: string
  otherGoal?: string
  rooms?: SelfOnboardRoom[] | OnboardingRoom[]
  password?: string
  hubspotProps?: HubspotContactProps
  skippedTeamConfiguration?: boolean
}

export type ResponseWithTeamAndUserIDs = Response & {
  teamID: string
  userID: string
}

export const selfOnboard = async (
  request: SelfOnboardRequest
): Promise<ResponseWithTeamAndUserIDs> => {
  return callableRequest<ResponseWithTeamAndUserIDs>(
    CallableEndpoint.selfOnboard,
    request
  )
}

export type CreateAccountAndJoinTeamRequest = {
  fullName: string
  avatarFile?: string
  selfie?: string
  teamID: string
  inviteLinkID?: string
  googleData?: {
    googleUserID: string
  }
  skippedTeamConfiguration?: boolean
}

export type ResponseWithTeamIDAndUserIDAndInfo = ResponseWithTeamAndUserIDs & {
  info: string
}

export const createAccountAndJoinTeam = async (
  request: CreateAccountAndJoinTeamRequest
): Promise<ResponseWithTeamIDAndUserIDAndInfo> => {
  return callableRequest<ResponseWithTeamIDAndUserIDAndInfo>(
    CallableEndpoint.createAccountAndJoinTeam,
    request
  )
}

export type GetSelfResponse = Response & {
  user?: any
}

export const getSelf = async (request: Request): Promise<GetSelfResponse> => {
  return callableRequest<GetSelfResponse>(CallableEndpoint.getSelf, request)
}

export type GetReferralLinkResponse = Response & {
  link?: string
  error?: string
}

export const getReferralLink = async (
  request: Request
): Promise<GetReferralLinkResponse> => {
  return callableRequest<GetReferralLinkResponse>(
    CallableEndpoint.getReferralLink,
    request
  )
}

export type CheckReferralLinkRequest = {
  encryptedUserID: string
}

export type CheckReferralLinkResponse = Response & {
  text?: string
  referrerFirstName?: string
  referrerUserID?: string
  referrerEmail?: string
  error?: string
}

export const checkReferralLink = async (
  request: CheckReferralLinkRequest
): Promise<CheckReferralLinkResponse> => {
  return callableRequest<CheckReferralLinkResponse>(
    CallableEndpoint.checkReferralLink,
    request
  )
}

export type ValidateConversationTokenRequest = {
  convoID: string
  token: string
  previousUserID?: string
  sender?: string
  name: string
}

export type ResponseWithTeamIDAndName = Response & {
  teamID: string
  teamName: string
}

export const validateConversationToken = async (
  request: ValidateConversationTokenRequest
): Promise<ResponseWithTeamIDAndName> => {
  return callableRequest<ResponseWithTeamIDAndName>(
    CallableEndpoint.validateConversationToken,
    request
  )
}

export type GetUsersInConversationRequest = {
  convoID: string
  userIDs: string[]
  token: string
}

export type PublicUser = {
  id: string
  firstName: string
  lastName: string
  displayName: string
  avatar: string
}

export type GetUsersInConversationResponse = Response & {
  users: PublicUser[]
}

export const getUsersInConversation = async (
  request: GetUsersInConversationRequest
): Promise<GetUsersInConversationResponse> => {
  return callableRequest<GetUsersInConversationResponse>(
    CallableEndpoint.getUsersInConversation,
    request
  )
}

export type CreateGuestUserRequest = {
  name: string
}

export type CreateGuestUserResponse = Response & {
  text?: string
}

export const createGuestUser = async (
  request: CreateGuestUserRequest
): Promise<CreateGuestUserResponse> => {
  return callableRequest<CreateGuestUserResponse>(
    CallableEndpoint.createGuestUser,
    request
  )
}

export type ToggleDomainCaptureRequest = {
  teamID: string
}

export type ToggleDomainCaptureResponse = Response & {
  text: string
}

export const toggleDomainCapture = async (
  request: ToggleDomainCaptureRequest
): Promise<ToggleDomainCaptureResponse> => {
  return callableRequest<ToggleDomainCaptureResponse>(
    CallableEndpoint.toggleDomainCapture,
    request
  )
}

/** See docstring on type with same name in /functions for details. */
type JoinableTeamInfo = {
  readonly id: string
  readonly sharedDomain: string
  readonly name: string
  readonly description: string
}

export type ListJoinableTeamsOnDomainRequest = {
  convoID?: string
}

export type ListJoinableTeamsOnDomainResponse = {
  readonly joinableTeamInfos: ReadonlyArray<JoinableTeamInfo>
}

export const listJoinableTeamsOnDomain = async (
  request: ListJoinableTeamsOnDomainRequest
): Promise<ListJoinableTeamsOnDomainResponse> => {
  return callableRequest<ListJoinableTeamsOnDomainResponse>(
    CallableEndpoint.listJoinableTeamsOnDomain,
    request
  )
}

export const joinWindowsWaitlist = async (): Promise<Response> => {
  return callableRequest(CallableEndpoint.joinWindowsWaitlist, {})
}

type SessionResponse = {
  sessionID: string
}

// Initialize a client session, returning the generated session ID.
export const createSession = async (request: Request): Promise<string> => {
  return callableRequest<SessionResponse>(
    CallableEndpoint.createSession,
    request
  ).then((resp) => resp.sessionID)
}

export type GetGuestCallAllowedRequest = {
  conversationID: string
}

type GetGuestCallAllowedResponse = {
  isGuestCallAllowed: boolean
}

export const getGuestCallAllowed = async (
  request: GetGuestCallAllowedRequest
): Promise<GetGuestCallAllowedResponse> => {
  return callableRequest<GetGuestCallAllowedResponse>(
    CallableEndpoint.getGuestCallAllowed,
    request
  )
}

export const sendEmailDownloadLink = async (): Promise<Response> => {
  return callableRequest<Response>(CallableEndpoint.sendEmailDownloadLink, {})
}

export type OnboardingEventRequest = {
  step: string
}

export const onboardingEvent = async (
  request: OnboardingEventRequest
): Promise<Response> => {
  return callableRequest<Response>(CallableEndpoint.onboardingEvent, request)
}
