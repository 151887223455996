import React, { useState, useMemo } from 'react'
import { Loader } from 'semantic-ui-react'
import { functions } from 'utilities/firebase-utils'

import styles from './Account.module.scss'
import UserSelfie from 'components/UI/UserSelfie/UserSelfie'
import { getStorage, ref, uploadBytes } from 'firebase/storage'
import Button from 'brand/Button'
import { track, EventTypes } from 'utilities/analytics'

enum steps {
  initial = 'initial',
  imageSelected = 'imageSelected',
}

export type SelfieTakenHandler = (data: {
  capturedSelfie?: string
  liveSelfieUrl?: string
}) => void

type Props = {
  avatarFile: string
  liveSelfieFile?: string
  userID?: string
  teamID?: string
  onFinished?: () => void
  confirmationTxt?: string
  uploadDisabled?: boolean
  hideNext?: boolean
  liveSelfieEnabled?: boolean
  selfieUrl?: string
  onSelfieTaken?: SelfieTakenHandler
  buttonProps?: Record<string, unknown>
}

export const uploadFile = async (
  file: ArrayBuffer | Blob,
  name: string,
  teamID: string,
  userID: string
): Promise<string | undefined> => {
  const storage = getStorage()
  const filename = `avatars/${teamID}/${userID}/${name}`
  const fileRef = ref(storage, `/${filename}`)
  let bytes: Uint8Array | undefined
  if (file instanceof ArrayBuffer) {
    bytes = new Uint8Array(file)
  }
  try {
    await uploadBytes(fileRef, bytes || file)
    return filename
  } catch {
    return undefined
  }
}

export const uploadSelfie = async (
  urlData: string,
  teamID: string,
  userID: string,
  filename: string
): Promise<string | undefined> => {
  const imageBuffer = await (await fetch(urlData)).arrayBuffer()
  return uploadFile(imageBuffer, filename, teamID, userID)
}

export const updateSelfie = async (avatarFile: string): Promise<void> => {
  const payload: functions.UpdateAvatarRequest = { avatarFile }

  await functions.updateAvatar(payload)
}

export const uploadAndAssociateImageFileWithUser = async (
  profileImage: ArrayBuffer,
  extension: string,
  teamID: string,
  userID: string // app user id
): Promise<void> => {
  const filename = await uploadFile(
    profileImage,
    `${generateFilename()}.${extension}`,
    teamID,
    userID
  )
  if (!filename) {
    throw new Error('failed to upload image file')
  }

  await functions.updateAvatar({ avatarFile: filename })
  track(EventTypes.selfieUploaded)
}

const generateFilename = () => Date.now().toString()

const SelfieUpdater: React.FC<Props> = (props: Props) => {
  const {
    avatarFile,
    liveSelfieFile,
    teamID = 'temp',
    userID = '',
    onFinished,
    confirmationTxt = 'Next',
    uploadDisabled,
    hideNext,
  } = props

  const [uploading, setUploading] = useState(false)
  const [imageFile, setImageFile] = useState<File | undefined>(undefined)
  const [imageObjectUrl, setImageObjectUrl] = useState<string | undefined>(
    undefined
  )
  const [uploadError, setUploadError] = useState<string | boolean>(false)

  const cleanupUploadedImage = () => {
    setImageFile(undefined)
    setImageObjectUrl(undefined)
  }

  const onImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files
    if (!files || files.length === 0) return
    const image = files[0]
    setImageFile(image)
    setImageObjectUrl(URL.createObjectURL(image))
  }

  const uploadPhoto = async () => {
    if (imageFile) {
      setUploadError(false)
      setUploading(true)
      const extension = imageFile.name.substring(
        imageFile.name.lastIndexOf('.') + 1
      )

      // check if user uploaded jpg or png, otherwise don't allow
      if (
        !(extension === 'jpg' || extension === 'jpeg' || extension === 'png')
      ) {
        setUploadError('We only allow jpg or png')
        setUploading(false)
        return
      }

      const reader = new FileReader()
      reader.onloadend = async () => {
        if (reader.result) {
          try {
            // upload photo file
            await uploadAndAssociateImageFileWithUser(
              reader.result as ArrayBuffer,
              extension,
              teamID,
              userID
            )
            finish()
          } catch {
            setUploadError(true)
          }
          setUploading(false)
        }
      }
      reader.readAsArrayBuffer(imageFile)
    }
  }

  const finish = () => {
    cleanupUploadedImage()
    if (onFinished) onFinished()
  }

  const uploadImageComponent = (
    <a href='#' className={styles.uploadImage}>
      <label htmlFor='avatar'>
        upload image
        <input
          type='file'
          id='avatar'
          name='avatar'
          accept='image/png, image/jpeg'
          onChange={onImageSelect}
        />
      </label>
    </a>
  )

  const step = useMemo(() => {
    setUploadError(false)
    if (imageFile) return steps.imageSelected
    return steps.initial
  }, [imageFile])

  const hasVideo = !!liveSelfieFile && !imageObjectUrl

  return (
    <div className={styles.selfieUpdater}>
      <div className={styles.photoContainer}>
        {uploading && (
          <div className={styles.photoLoader}>
            {uploading && <Loader inline active size='medium' />}
          </div>
        )}
        <UserSelfie
          avatarFile={avatarFile}
          liveSelfieFile={liveSelfieFile}
          hasVideo={hasVideo}
          url={imageObjectUrl}
          size='big'
        />
      </div>
      {uploadError && (
        <div className={styles.error}>
          {typeof uploadError === 'string' && uploadError !== ''
            ? uploadError
            : 'There was an error while uploading your photo'}
        </div>
      )}
      {step === steps.initial && !uploadDisabled && uploadImageComponent}
      {step === steps.imageSelected && (
        <div>
          <div className={styles.buttonsContainer}>
            {!hideNext && (
              <Button onClick={() => uploadPhoto()} disabled={uploading}>
                {confirmationTxt}
              </Button>
            )}
            <Button
              kind='secondary'
              onClick={() => {
                setImageFile(undefined)
                setImageObjectUrl(undefined)
              }}
            >
              Cancel
            </Button>
          </div>
          {!uploadDisabled && uploadImageComponent}
        </div>
      )}
    </div>
  )
}

export default SelfieUpdater
