import { Button } from 'brand'
import React from 'react'
import { Link } from 'react-router-dom'

import styles from './ErrorPage.module.scss'

type Props = {
  headline?: string
  body?: string
  btnLink?: {
    copy: string // text for button to display
    linkTo: string // path that button should navigate to
  }
  btnElement?: React.ReactNode
}

const ErrorPage: React.FC<Props> = (props) => {
  const {
    headline = 'Unable to join this meeting',
    body = 'Try refreshing the page or asking the host of this meeting for a new link.',
    btnLink,
    btnElement,
  } = props
  return (
    <div className={styles.errorPage}>
      <h1>{headline}</h1>
      <p>{body}</p>
      {btnLink && (
        <Link to={btnLink.linkTo}>
          <Button darkMode>{btnLink.copy}</Button>
        </Link>
      )}
      {btnElement}
    </div>
  )
}

export default ErrorPage
