import { DeviceTypes } from 'components/Public/GuestCall/components/CallControl'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useAsyncAbortable } from 'react-async-hook'
import VideoRecorder from 'utilities/VideoRecorder'

export type AVSetup = {
  audioDeviceId: string | undefined
  videoDeviceId: string | undefined

  audioEnabled: boolean
  videoEnabled: boolean
}

export type UseCallControlsReturn = {
  audioDevices: MediaDeviceInfo[]
  videoDevices: MediaDeviceInfo[]
  loadDevices: () => Promise<void>
  avSetup: AVSetup
  setAVSetup: React.Dispatch<React.SetStateAction<AVSetup>>
}

export default function useCallControls(): UseCallControlsReturn {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([])
  const [avSetup, setAVSetup] = useState<AVSetup>({
    audioDeviceId: undefined,
    videoDeviceId: undefined,
    audioEnabled: false,
    videoEnabled: false,
  })

  const loadDevicesAsync = useCallback(async (abortSignal: AbortSignal) => {
    const videoRecorder = new VideoRecorder()
    // When no permissions are given, a single element with an empty ID is returned. Filter this out
    const devices = (await videoRecorder.getDevices()).filter((i) => i.deviceId)

    if (!abortSignal.aborted) {
      setDevices(devices)
    }
  }, [])

  const loadDevicesAsyncAbortable = useAsyncAbortable(
    async (abortSignal: AbortSignal) => {
      return loadDevicesAsync(abortSignal)
    },
    []
  )
  const loadDevices = loadDevicesAsyncAbortable.execute

  useEffect(() => {
    loadDevices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const audioDevices = useMemo(() => {
    return devices.filter((device) => device.kind === DeviceTypes.AudioInput)
  }, [devices])

  const videoDevices = useMemo(() => {
    return devices.filter((device) => device.kind === DeviceTypes.VideoInput)
  }, [devices])

  useEffect(() => {
    if (audioDevices.length) {
      setAVSetup((state) => ({ ...state, audioEnabled: true }))
    }
  }, [audioDevices.length])

  useEffect(() => {
    if (videoDevices.length) {
      setAVSetup((state) => ({ ...state, videoEnabled: true }))
    }
  }, [videoDevices.length])

  return {
    avSetup,
    setAVSetup,
    audioDevices,
    videoDevices,
    loadDevices,
  }
}
