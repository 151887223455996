import React, { useState } from 'react'
import EditTeamNameAlert from 'components/UI/Alerts/EditTeamNameAlert'
import styles from './TeamSettingsFooter.module.scss'
import SlackConnectionStatus from 'components/UI/SlackConnectionStatus/SlackConnectionStatus'
import {
  FeatureFlags,
  getFeatureFlag,
} from 'components/FeatureFlags/FeatureFlagsHelper'

interface Props {
  teamID: string
  teamName: string
  connectedToSlack: boolean
  slackWorkspaceName?: string
  slackAuthTokenInvalid?: boolean
}

const TeamSettingsFooter = (props: Props) => {
  const [editNameAlertOpen, setEditNameAlertOpen] = useState(false)
  const slackFlagOn = getFeatureFlag(FeatureFlags.slackIntegration2)

  return (
    <div className={styles.teamSettingsFooter}>
      {slackFlagOn && (
        <SlackConnectionStatus
          connectedToSlack={props.connectedToSlack}
          slackWorkspaceName={props.slackWorkspaceName}
          slackAuthTokenInvalid={props.slackAuthTokenInvalid}
          teamID={props.teamID}
          teamName={props.teamName}
        />
      )}
      <EditTeamNameAlert
        teamID={props.teamID}
        teamName={props.teamName}
        open={editNameAlertOpen}
        setAlertOpen={setEditNameAlertOpen}
      />
    </div>
  )
}

export default TeamSettingsFooter
