import React from 'react'
import GuestCall from './GuestCall'
import GuestCallProvider from './GuestCallProvider'

const GuestCallContainer: React.FC = () => (
  <GuestCallProvider>
    <GuestCall />
  </GuestCallProvider>
)

export default GuestCallContainer
