import { track, EventTypes, TeamWhence } from 'utilities/analytics'
import { functions } from 'utilities/firebase-utils'
import { hasGuestCall, resetGuestCallData } from 'utilities/guestCallData'
import {
  ProfileData,
  getUserProfileData,
  getUserTeamData,
  CreateTeamData,
  setSetupData,
  getGoogleAdData,
} from './SetupDataHandler'
import { fetchAndStoreTeamInviteLink } from './accountCreationOperations'
import * as Sentry from 'sentry'
import { pickDefaultAvatar } from '../AvatarPicker/AvatarList'

const getUserProfileAndTeam = (): {
  profileData: ProfileData
  teamData: CreateTeamData
} => {
  const profileData = getUserProfileData()
  const teamData = getUserTeamData()

  if (!profileData) {
    throw Error('User profile not set up')
  }

  if (!profileData.fullname) {
    throw Error('Profile Data does not contain fullname')
  }
  if (!teamData) {
    throw Error('User has no stored team')
  }

  if (!('teamName' in teamData)) {
    throw Error('Team has no teamName and thus is not joinable')
  }

  return { profileData, teamData }
}

export const createAccountCreateTeam = async (
  skippedTeamConfiguration = false
): Promise<void> => {
  const { profileData, teamData } = getUserProfileAndTeam()

  const request: functions.SelfOnboardRequest = {
    teamName: teamData.teamName,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    userFullName: profileData.fullname!, // checked in getUserProfileAndTeam
    googleData: profileData.googleData,
    isSharedDomain: teamData.allowDomainCapture,
    hubspotProps: {
      teamOS: teamData.teamOS,
    },
    avatarFile: pickDefaultAvatar(), // start users with default avatar and update in postaccountcreation step
    skippedTeamConfiguration,
  }

  const { teamID, userID } = await functions.selfOnboard(request)
  try {
    setSetupData({ teamJoinedData: { teamID, userID } })

    await fetchAndStoreTeamInviteLink(teamID)

    // Make a record of account creation in our analytics suite.
    const signupAfterGuestCall = hasGuestCall()
    const googleAdData = getGoogleAdData()

    track(EventTypes.userAccountCreated, {
      whence: TeamWhence.createdTeam,
      signup_after_guest_call: signupAfterGuestCall,
      google_ad_data: googleAdData,
    })

    if (hasGuestCall()) {
      track(EventTypes.callEndedSignupClicked, {
        team_id: teamID,
        joiner: false,
      })
      resetGuestCallData()
    }
  } catch (err) {
    Sentry.warning(
      'Error saving browser state or tracking analytics post team creation',
      { userID, err }
    )
    console.error(
      'Error saving browser state or tracking analytics post team creation',
      err
    )
  }
}
