import React, { SVGProps } from 'react'

const IconCaretDown = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.75 10.5L12.5 15.75L7.25 10.5'
        stroke='white'
        strokeWidth='2.25'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default IconCaretDown
