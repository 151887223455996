import { initializeApp, FirebaseApp, getApp } from 'firebase/app'
import { getAnalytics, logEvent as firebaseLogEvent } from 'firebase/analytics'
import { getAuth, UserCredential } from 'firebase/auth'
import { getDatabase } from 'firebase/database'
import { getFirestore } from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { getStorage } from 'firebase/storage'

/**
 * This module provides utilities for initializing
 * the application's connection-to & interacting-with
 * Firebase.
 */

export * as database from './database'
export * as functions from './functions'

export type GoogleLoginResult = {
  userCredential: UserCredential
  googleUserID?: string
}

/**
 * Analytics Helpers
 */

export enum AnalyticsEvent {
  INVITE_LINK_COPIED,
  JOIN_THE_WAITLIST_CLICKED,
  SIGN_UP_ANYWAYS_CLICKED,
  SIGN_UP_MACOS_ONLY_CLICKED,
  SIGN_UP_WITH_GOOGLE_CLICKED,
  LOG_IN_WITH_GOOGLE_CLICKED,
}

/**
 * * Asynchronously log an event via Firebase Analytics.
 *
 *  Returns an empty promise.
 *
 * @export
 * @param {AnalyticsEvent} eventName
 * @param {*} params
 * @return {*}  {Promise<void>}
 */
export async function logEvent(
  eventName: AnalyticsEvent,
  params: any
): Promise<void> {
  const analytics = getAnalytics()
  firebaseLogEvent(analytics, AnalyticsEvent[eventName], params)
}

/**
 * Initialize the application's connections to Firebase
 * using the values supplied in `config`
 *
 * Components using the Firebase SDK *do not* need to fetch
 * the firebase app in v9+
 * @param {object} config
 * @return {*}  {FirebaseApp}
 */
export const initFirebase = (config: object): FirebaseApp => {
  const app = initializeApp(config)
  getFirestore(app)
  getFunctions(app)
  if (process.env.USE_EMULATOR) {
    console.debug('Connecting to Functions Emulator on port 5001')
    connectFunctionsEmulator(getFunctions(), 'localhost', 5001)
  }
  getDatabase(app)
  getAuth(app)
  getStorage(app)
  getAnalytics(app)
  return app
}

/** Default project ID */
const REMOTION_DEV = 'dally-da01b'

/** Return the Firebase Web API's Base URI.
 * @throws  When Firebase app is not yet initialized
 */
export const getFirebaseWebAPIBaseUrl = (): string => {
  const app = getApp()
  const projectID = app.options?.projectId || REMOTION_DEV
  return `https://us-central1-${projectID}.cloudfunctions.net/webAPI`
}
