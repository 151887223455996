import Button from 'brand/Button'
import React, { useState, useEffect } from 'react'
import { Popup } from 'semantic-ui-react'
import classNames from 'classnames'

import { isSupportedBrowser } from 'utilities/browserUtil'
import IconCaretDown from 'components/UI/Icons/CaretDown'
import screenshareOn from '../assets/screenshare-on.svg'
import screenshareOff from '../assets/screenshare-off.svg'

import styles from './GuestCallScreensharingPopup.module.scss'

type GuestCallScreensharingPopupProps = {
  isLocalScreensharing: boolean
  startShare: () => void
  stopShare: () => void
  children: React.ReactNode
}

export const GuestCallScreensharingPopup: React.FC<
  GuestCallScreensharingPopupProps
> = (props) => {
  const { children, isLocalScreensharing, startShare, stopShare } = props
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  useEffect(() => {
    setIsPopupOpen(isLocalScreensharing)
  }, [isLocalScreensharing])

  const handleScreensharingButtonPress = () => {
    if (isLocalScreensharing) {
      setIsPopupOpen((state) => !state)
    } else {
      startShare()
    }
  }

  // Screensharing on the web can be hidden/disabled by returning null here
  // while leaving the rest of the code intact and not having to worry about
  // unused import errors, etc
  // return null
  return (
    <Popup
      open={true}
      basic
      inverted
      position='bottom center'
      className={classNames(styles.screensharingPopup, {
        [styles.hidden]: !isPopupOpen,
      })}
      trigger={
        <Button
          className={styles.screenshareButton}
          darkMode
          kind='tertiary'
          onClick={handleScreensharingButtonPress}
          size='small'
        >
          <img
            src={isLocalScreensharing ? screenshareOn : screenshareOff}
            alt='Screenshare'
            className={styles.screenshareButtonIcon}
          />
        </Button>
      }
    >
      <Popup.Content>
        <div>
          <div className={styles.header}>
            <h3>Sharing your screen</h3>
            <Button
              onClick={() => setIsPopupOpen(false)}
              darkMode
              kind='tertiary'
              size='small'
            >
              <IconCaretDown className={styles.hidePopupButton} />
            </Button>
          </div>
          {children}
          <div className={styles.buttonRow}>
            {isSupportedBrowser && (
              <Button onClick={startShare} darkMode kind='tertiary'>
                New share
              </Button>
            )}
            <Button onClick={stopShare} darkMode>
              Stop sharing
            </Button>
          </div>
        </div>
      </Popup.Content>
    </Popup>
  )
}
