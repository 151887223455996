import React, { useState } from 'react'
import { Icon } from 'semantic-ui-react'

import styles from './TeamUser.module.scss'
import AvatarImage from '../AvatarImage/AvatarImage'
import RemoveUserAlert from '../Alerts/RemoveUserAlert'
import { functions } from 'utilities/firebase-utils'
import sendNotification, {
  sendGenericErrorNotification,
} from '../../LocalNotification'
import * as Sentry from '../../../sentry'

interface TeamUsersProps {
  children?: React.ReactElement[]
  name: string
  email: string
  teamID: string
  userID?: string
  teamName: string
  avatarFile?: string
  isCurrentUser: boolean
  isInvited?: boolean
  inviteID?: string
  onRemoveClick?: () => void
}

const TeamUser = (props: TeamUsersProps) => {
  const [loading, setLoading] = useState(false)

  return (
    <div className={styles.teamUser}>
      <AvatarImage avatarFile={props.avatarFile} />
      <div className={styles.info}>
        {!props.isInvited ? (
          <p className={styles.name}>
            <strong>{props.name}</strong>
            {props.isCurrentUser && ' (you)'}
          </p>
        ) : (
          <p className={styles.pending}> Pending invite</p>
        )}
        <p
          className={
            props.isInvited ? `${styles.pending} ${styles.email}` : styles.email
          }
        >
          {props.email}
        </p>
      </div>
      <div className={styles.actionButtons}>
        {props.isInvited === true && props.inviteID && (
          <>
            <button
              disabled={loading}
              className='transparentButton desktopOnly'
              onClick={() =>
                resendInvite(props.teamID, props.email, setLoading)
              }
            >
              {loading ? 'Inviting...' : 'Resend invite'}
            </button>
            <button
              disabled={loading}
              className='mobileOnly iconButton'
              onClick={() =>
                resendInvite(props.teamID, props.email, setLoading)
              }
            >
              <Icon name={loading ? 'mail' : 'send'} />
            </button>
          </>
        )}
        <RemoveUserAlert
          isCurrentUser={props.isCurrentUser}
          teamID={props.teamID}
          name={props.name}
          isInvited={props.isInvited}
          email={props.email}
          teamName={props.teamName}
          userIDToRemove={props.userID}
        />
      </div>
    </div>
  )
}

export default React.memo(TeamUser)

const resendInvite = (
  teamID: string,
  inviteeEmail: string,
  setLoading: (loading: boolean) => void
) => {
  const requestData = {
    teamID,
    inviteeEmail,
  }
  setLoading(true)
  functions
    .resendInvite(requestData)
    .then((data) => {
      if (data.error) {
        Sentry.warning('Resend invite failed', requestData)
        sendGenericErrorNotification()
      } else {
        sendNotification(`Sent invite to ${inviteeEmail}`, 'success')
      }
    })
    .catch((err) => {
      sendGenericErrorNotification()
      Sentry.error(err, requestData)
    })
    .finally(() => setLoading(false))
}
