import React, { useRef, useEffect } from 'react'
import { ILocalVideoTrack, IRemoteVideoTrack } from 'agora-rtc-sdk-ng'
import classNames from 'classnames'

import styles from './Agora.module.scss'

type Props = {
  videoTrack: ILocalVideoTrack | IRemoteVideoTrack | undefined
  isLocal?: boolean
}

const AgoraScreenShare = (props: Props) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const { videoTrack, isLocal } = props

  // Start the video track once the element is added to the DOM and has a ref
  // For remote video tracks, which are managed by Agora objects, we can
  // start and stop the tracks here.
  useEffect(() => {
    // Sometimes the video does not start playing, so adding an interval to check it and play it
    const intervalId = setInterval(() => {
      if (!containerRef.current || !videoTrack) return
      if (videoTrack.play && !videoTrack.isPlaying) {
        videoTrack.play(containerRef.current)
      }
    }, 100)
    return () => {
      clearInterval(intervalId)
      videoTrack?.stop()
    }
  }, [containerRef, videoTrack])

  return (
    <div
      className={classNames(
        'agoraMediaPlayer',
        styles.videoPlayer,
        styles.screenshare,
        {
          [styles.remoteScreenshare]: !isLocal,
        }
      )}
    >
      <div ref={containerRef} />
    </div>
  )
}

export default AgoraScreenShare
