import React from 'react'
import { HeaderWithAppIcon } from 'components/UI/HeaderWithAppIcon/HeaderWithAppIcon'

import styles from 'components/Setup/SetupPage.module.scss'

type Props = {
  email: string
  showEmailForm: () => void
}

const EmailSent: React.FC<Props> = (props) => {
  const { showEmailForm, email } = props

  return (
    <div className={styles.emailSentSection}>
      <div>
        <HeaderWithAppIcon title='Check your email' />
        <p>
          We sent an email to <strong>{email}</strong> with a magic link for you
          to continue.
        </p>
      </div>
      <div>
        <p>
          Wrong email? <a onClick={showEmailForm}>Re-enter.</a>
        </p>
        <p>
          Not getting it?{' '}
          <a href='mailto:support@multi.app' target='_blank' rel='noreferrer'>
            Email support@multi.app
          </a>
        </p>
      </div>
    </div>
  )
}

export default EmailSent
