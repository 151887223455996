import React from 'react'

import styles from './JoinRoom.module.scss'

type Props = {
  icon: string
  color: {
    start: string
    end: string
  }
}

const RoomEmoji: React.FC<Props> = ({ icon, color }: Props) => {
  const roomIconStyle = {
    background: `linear-gradient(180deg, ${color.start} 0%, ${color.end} 100%)`,
  }

  return (
    <div className={styles.roomEmoji} style={roomIconStyle}>
      {icon}
    </div>
  )
}

export default RoomEmoji
