import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import keyBy from 'lodash/keyBy'
import mapKeys from 'lodash/mapKeys'

import { functions } from 'utilities/firebase-utils'
import { getFullName } from '../../guest-call-utils'

import useZoom, {
  createZoomClient,
  getRemotionToZoomUID,
  SpeakingUserState,
  UseZoomReturn,
} from 'components/Public/GuestCall/GuestCallOngoing/Zoom/useZoom'
import { useGuestCall, UserWithAvatar, UserMap } from '../../GuestCallProvider'
import {
  useConvo,
  ConvoField,
  addUserToConvoField,
  removeUserFromConvoField,
} from 'models'
import { useAuth } from 'components/AuthProvider'
import { getImageUrl } from 'components/UI/AvatarImage/AvatarImage.helper'
import useMicVolume from 'components/Public/GuestCall/GuestCallOngoing/Zoom/useMicVolume'

const GuestCallZoomContext = createContext({} as GuestCallZoomContextType)

type GuestCallZoomContextType = Omit<UseZoomReturn, 'join' | 'leave'> & {
  localUID: string | undefined

  leaveCall: () => Promise<void>
  speakingUsers: Record<string, SpeakingUserState>

  localVideoRef: React.RefObject<HTMLVideoElement>
  localScreenshareRef: React.RefObject<HTMLVideoElement>
  remoteScreenshareRef: React.RefObject<HTMLCanvasElement>
  videoCanvasRef: React.RefObject<HTMLCanvasElement>

  screensharingUserID?: string // the Multi userId of a remote user who is screensharing
  screensharingUserName: string | undefined // the Multi user name of a remote user who is screensharing
  isRemoteScreensharing: boolean
  isUserScreensharing: (zoomUID: string) => boolean // given a zoomUID (participant.displayName), find whether the user is screensharing

  isChatOpen: boolean
  setChatOpen: React.Dispatch<React.SetStateAction<boolean>>

  getZoomParticipantFullName: (zoomDisplayName: string) => string
}

export const useGuestCallZoom = (): GuestCallZoomContextType =>
  useContext(GuestCallZoomContext)

type Props = {
  children: React.ReactNode
}

const GuestCallZoomProvider: React.FC<Props> = (props) => {
  const { children } = props
  const {
    convoID,
    convoToken,
    channelDetails,
    avSetup,
    userMap,
    setUserMap,
    setAVSetup,
    shouldLeaveCall,
    leaveCallFirebase,
  } = useGuestCall()

  const convo = useConvo(convoID)
  const { auth } = useAuth()
  const currentUserID = auth?.uid
  const { isLocalUserSpeaking } = useMicVolume()

  const localVideoRef = useRef<HTMLVideoElement>(null)
  const videoCanvasRef = useRef<HTMLCanvasElement>(null)
  const localScreenshareRef = useRef<HTMLVideoElement>(null)
  const remoteScreenshareRef = useRef<HTMLCanvasElement>(null)

  const screensharingUserID = useMemo((): string | undefined => {
    const remoteScreensharingUsers =
      convo?.screensharingUsers.filter(({ id }) => id !== currentUserID) || []

    if (!remoteScreensharingUsers.length) return

    const lastScreensharingUserID =
      remoteScreensharingUsers[remoteScreensharingUsers.length - 1].id

    return lastScreensharingUserID
  }, [convo?.screensharingUsers, currentUserID])

  const screensharingUserName = screensharingUserID
    ? userMap[screensharingUserID]?.firstName
    : undefined

  const [isRemoteScreensharing, setIsRemoteScreensharing] = useState(false)
  // tracking open/closed state of chat panel
  const [isChatOpen, setChatOpen] = useState(false)

  const isUserScreensharing = useCallback(
    (zoomUID: string): boolean => {
      return screensharingUserID
        ? zoomUID === getRemotionToZoomUID(screensharingUserID)
        : false
    },
    [screensharingUserID]
  )

  const removeLocalUserFirebaseConvoField = useCallback(
    async (field: ConvoField) => {
      if (!currentUserID) {
        return
      }
      try {
        await removeUserFromConvoField({
          field,
          userID: currentUserID,
          convoID,
        })
      } catch (error) {
        console.error(
          `Error removing local user from firebase convo field ${field}:`,
          error
        )
      }
    },
    [convoID, currentUserID]
  )

  const addLocalUserFirebaseConvoField = useCallback(
    async (field: ConvoField) => {
      if (!currentUserID || !convoID) {
        return
      }

      try {
        await addUserToConvoField({
          field,
          userID: currentUserID,
          convoID,
        })
      } catch (error) {
        console.error(
          `Error adding local user from firebase convo field ${field}:`,
          error
        )
      }
    },
    [convoID, currentUserID]
  )

  const zoomClient = useMemo(createZoomClient, [])
  const zoom = useZoom(
    zoomClient,
    localVideoRef,
    videoCanvasRef,
    remoteScreenshareRef,
    setAVSetup,
    setIsRemoteScreensharing,
    removeLocalUserFirebaseConvoField
  )

  const localUID = useMemo(() => {
    if (!channelDetails?.userID) return
    return getRemotionToZoomUID(channelDetails.userID)
  }, [channelDetails?.userID])

  const speakingUsers: Record<string, SpeakingUserState> = useMemo(() => {
    if (!convo) {
      return {}
    }

    const talkingUsers: Record<string, SpeakingUserState> = {}

    convo.talkingUsers.forEach((talkingUser) => {
      const remoteUserRemotionToZoomUID = getRemotionToZoomUID(talkingUser.id)
      talkingUsers[remoteUserRemotionToZoomUID] = {
        isSpeaking: true,
        timestamp: Date.now(),
      }
    })
    // update local user based on local value rather than remote to elimate lag
    if (localUID) {
      talkingUsers[localUID] = {
        isSpeaking: isLocalUserSpeaking,
        timestamp: Date.now(),
      }
    }
    return talkingUsers
  }, [convo?.talkingUsers, isLocalUserSpeaking, localUID])

  const leaveCall = useCallback(async () => {
    // As a call is active, capture the providers' leave function to be called first

    // sets local state, calls leaveConversation server function and clear firebase presence
    await leaveCallFirebase(zoom.leave)
  }, [leaveCallFirebase, zoom.leave])

  // Follow changes to Convo.
  useEffect(() => {
    if (shouldLeaveCall(convo)) {
      leaveCall()
    }
  }, [convo, shouldLeaveCall, leaveCall])

  // leave call when in call experience is unmounting
  useEffect(() => {
    return () => {
      leaveCall()
    }
    // intentionally disabling since we don't want leaveCall() run on each re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // also leave call when user closes the tab
  useEffect(() => {
    function handleTabClose(event: BeforeUnloadEvent) {
      event.preventDefault()
      leaveCall()
      event.returnValue = '' // as of Chrome 51, the message displayed in the alert window on close tab is not customizable
    }

    window.addEventListener('beforeunload', handleTabClose)

    return () => {
      window.removeEventListener('beforeunload', handleTabClose)
    }
  }, [leaveCall])

  // join a call as soon as channelDetails are available
  useEffect(() => {
    joinCall()

    async function joinCall() {
      if (!channelDetails || !localUID) {
        return
      }

      const { channelID, signedJWT: zoomToken = '' } = channelDetails

      await zoom.join(channelID, zoomToken, localUID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelDetails, localUID])

  // get remote users' avatars and names from server
  useEffect(() => {
    if (!channelDetails || !convo?.users.length) return

    // fetch for users not in userMap
    const usersToQuery = convo.users
      .filter((user) => !userMap[user.id])
      .map((user) => user.id)

    if (usersToQuery.length > 0) {
      fetchUsersAndAvatars(usersToQuery)
    }

    async function fetchUsersAndAvatars(userIDs: string[]) {
      const { users } = await functions.getUsersInConversation({
        convoID,
        userIDs,
        token: convoToken,
      })
      const fetchAvatars = users.map((user) =>
        user.avatar ? getImageUrl(user.avatar) : Promise.resolve('')
      )
      const avatars = (await Promise.allSettled(fetchAvatars)).map((result) =>
        result.status === 'fulfilled' ? result.value : ''
      )
      const usersWithAvatars = users.map((user, i) => ({
        ...user,
        avatarUrl: avatars[i],
      }))

      const newUserData = keyBy(usersWithAvatars, 'id')
      setUserMap((currentValue) => ({
        ...currentValue,
        ...newUserData,
      }))
    }

    // omit userMap and setUserMap
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelDetails, convo?.users, convoID, convoToken])

  // update local user AV mute/unmute states to firebase
  const updateUserAVToFirebase = useCallback(
    (isAVEnabled: boolean, field: ConvoField) => {
      if (!currentUserID || !convoID) return

      if (isAVEnabled) {
        addUserToConvoField({
          field,
          userID: currentUserID,
          convoID,
        })
      } else {
        removeUserFromConvoField({
          field,
          userID: currentUserID,
          convoID,
        })
      }
    },
    [currentUserID, convoID]
  )

  const getZoomParticipantFullName = useCallback(
    (zoomDisplayName: string) => {
      const zoomUserMap = userMapByZoomUID(userMap)
      const firebaseUser = zoomUserMap[zoomDisplayName]
      let fullName = getFullName(
        firebaseUser?.firstName,
        firebaseUser?.lastName
      )

      // if a user quickly leaves and rejoins a conversation, it is possible that the user's previous entry will not have
      // cleared from firebase, making the userMapByZoomUID incorrect for that user. in this case, show a default name
      if (!fullName && zoomDisplayName === localUID) {
        fullName = 'You'
      } else if (!fullName) {
        fullName = 'Participant'
      }

      return fullName
    },
    [localUID, userMap]
  )

  useEffect(() => {
    updateUserAVToFirebase(avSetup.videoEnabled, ConvoField.CameraSharingUsers)
  }, [avSetup.videoEnabled, updateUserAVToFirebase])

  useEffect(() => {
    updateUserAVToFirebase(avSetup.audioEnabled, ConvoField.AudioSharingUsers)
  }, [avSetup.audioEnabled, updateUserAVToFirebase])

  // update local user speaking state to firebase talkingUsers
  useEffect(() => {
    if (isLocalUserSpeaking) {
      addLocalUserFirebaseConvoField(ConvoField.TalkingUsers)
    } else {
      removeLocalUserFirebaseConvoField(ConvoField.TalkingUsers)
    }
  }, [
    addLocalUserFirebaseConvoField,
    isLocalUserSpeaking,
    removeLocalUserFirebaseConvoField,
  ])

  // update isRemoteScreensharing
  useEffect(() => {
    setIsRemoteScreensharing(!!screensharingUserID)
  }, [screensharingUserID])

  return (
    <GuestCallZoomContext.Provider
      value={{
        ...zoom,
        localUID,
        leaveCall,
        localVideoRef,
        localScreenshareRef,
        remoteScreenshareRef,
        videoCanvasRef,
        speakingUsers,
        screensharingUserID,
        screensharingUserName,
        isRemoteScreensharing,
        isUserScreensharing,
        isChatOpen,
        setChatOpen,
        getZoomParticipantFullName,
      }}
    >
      {children}
    </GuestCallZoomContext.Provider>
  )
}

type UserMapByZoomUID = {
  [zoomUID: string]: UserWithAvatar | undefined
}

export const userMapByZoomUID = (userMap: UserMap): UserMapByZoomUID => {
  return mapKeys(userMap, (_, userID) => getRemotionToZoomUID(userID))
}

export default GuestCallZoomProvider
