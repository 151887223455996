import { useAsync, UseAsyncReturn } from 'react-async-hook'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Loader } from 'semantic-ui-react'
import { isIOS, isMacOs, isSafari } from 'react-device-detect'
import { isSupportedBrowser } from 'utilities/browserUtil'

import * as Sentry from '../../../../sentry'
import ErrorPage from '../components/ErrorPage'
import {
  defaultScheme,
  maybeTransformToScheme,
  Scheme,
  schemesList,
} from 'utilities/MacOSAppScheme'
import { useAuth } from 'components/AuthProvider'
import AvatarContainer from '../components/AvatarContainer'
import { Button, Modal } from 'brand'
import CallControls from '../components/CallControls'
import { GuestModeState, useGuestCall } from '../GuestCallProvider'
import styles from './GuestCallSetup.module.scss'
import { Link } from 'react-router-dom'
import { functions } from 'utilities/firebase-utils'
import config from 'configs/generalConfig.json'
import { AppUser } from 'models'
import useMicVolume from '../GuestCallOngoing/Zoom/useMicVolume'
import classNames from 'classnames'
import { v4 as uuidv4 } from 'uuid'
import SpinnerOverlay from 'components/UI/Spinner'
import { useQuery } from 'utilities/locationHooks'
import { setFromGuestCall } from 'utilities/guestCallData'
import { HeaderWithAppIcon } from 'components/UI/HeaderWithAppIcon/HeaderWithAppIcon'
import Card from 'components/UI/Card'

type PreventableEvent = { preventDefault: () => void }

export async function createLink(
  scheme: string,
  convoID: string,
  convoToken: string,
  isRegisteredUser: boolean
): Promise<string> {
  let url = `${scheme}://joinRoom?convo-id=${convoID}&t=${convoToken}`

  if (isRegisteredUser) {
    try {
      const response = await functions.createToken()
      if (response.tokenID) {
        url += `&token-id=${response.tokenID}`
      }
    } catch {
      console.error('Token creation failed')
    }
  }

  return url
}

/** Return a type-safe handler Fn that can be passed to onClick or onSubmit.
 *
 *    nb. Several sub-components receive an async handle used
 *        to join a call on web. As it may be a form component or anchor
 *        tag, this small helper function returns a handler function that
 *        will mediate access to the handle while preventing default event-bubbling
 */
function joinCallHandlerFn(joinCallHandle: UseAsyncReturn<void, []>) {
  return (e: PreventableEvent) => {
    if (joinCallHandle.loading) return
    e.preventDefault()
    joinCallHandle.execute()
  }
}

// *Curry* an openApp invocation by wrapping its convo ID & token.
function openAppHandler(
  convoID: string,
  convoToken: string,
  scheme: Scheme = defaultScheme,
  isRegisteredUser: boolean
) {
  return async (e: PreventableEvent) => {
    e.preventDefault()
    const url = await createLink(scheme, convoID, convoToken, isRegisteredUser)
    console.log('opening url: ', url) // logging to help with debugging if needed.
    window.location.href = url
  }
}

type TestAppProps = {
  convoID: string
  convoToken: string
}

const OpenRemotionTestApps: React.FC<TestAppProps> = (props: TestAppProps) => (
  <div>
    <strong>
      <h2>Secret Dev Menu (plz ignore)</h2>
      <div>
        <Button
          borderless
          onClick={openAppHandler(
            props.convoID,
            props.convoToken,
            defaultScheme,
            true
          )}
        >
          {defaultScheme} (default)
        </Button>
      </div>
    </strong>
    {schemesList
      .filter((currentScheme) => currentScheme !== defaultScheme)
      .map((currentScheme) => (
        <div key={uuidv4()}>
          <Button
            borderless
            onClick={openAppHandler(
              props.convoID,
              props.convoToken,
              currentScheme,
              true
            )}
          >
            {currentScheme}
          </Button>
        </div>
      ))}
    <h1>🤫</h1>
  </div>
)

const RemotionCaption = () => {
  return (
    <div className={styles.caption}>
      <p>
        Multi is the video app designed for remote collaboration. Move faster
        when you spark more conversations where work gets done.{' '}
        <a href={config.remotionSite}>Learn More</a>
      </p>
    </div>
  )
}

const PrivacyPolicy = () => {
  return (
    <p className={styles.termsOfService}>
      By continuing, you agree to Multi&apos;s
      <a
        href='https://multi.app/terms-of-service'
        target='_blank'
        rel='noreferrer'
      >
        Terms of Service
      </a>
      and
      <a
        href='https://multi.app/privacy-policy'
        target='_blank'
        rel='noreferrer'
      >
        Privacy Policy
      </a>
      .
    </p>
  )
}
const SignedInAs: React.FC<{ user: AppUser }> = ({ user }) => {
  return <div className={styles.caption}>Signed in as {user?.email}</div>
}

export const GuestCallPreCall: React.FC = () => {
  const { convoID, convoToken, roomName, advanceToSetup } = useGuestCall()
  const { isRegistered, user } = useAuth()
  const query = useQuery()
  const isRemotionEmployee =
    isRegistered &&
    (user?.email.endsWith('@remotion.com') ||
      user?.email.endsWith('@multi.app'))

  const [loading, setLoading] = useState<boolean>(false)
  const [appLink, setAppLink] = useState<string>()
  const [guestCallAllowed, setGuestCallAllowed] = useState<boolean>()

  const [warningModalData, setWarningModalData] =
    useState<{
      open: boolean
      header: string
      body: string
      dismissBtnText: string
      secondaryButtonLink?: {
        path: string
        displayText: string
      }
    }>()

  useEffect(() => {
    // if iOS, skip warning modal
    if (isIOS) {
      return
    }

    // if not macOS
    if (!isMacOs) {
      setWarningModalData({
        open: true,
        header: "You're on an unsupported browser",
        body: 'You may encounter unexpected errors. Multi works best in Chrome on macOS.',
        dismissBtnText: 'Continue to call',
      })
      return
    }

    // if macOS, but not chrome or safari
    if (!isSupportedBrowser) {
      setWarningModalData({
        open: true,
        header: "You're on an unsupported browser",
        body: 'You may encounter unexpected errors. Multi is supported in Chrome on macOS. For the best experience, download our macOS desktop app.',
        dismissBtnText: 'Continue to call',
        secondaryButtonLink: {
          path: '/signup',
          displayText: 'Download app',
        },
      })
      return
    }
  }, [])

  useEffect(() => {
    // Only check GuestCallAllowed once
    if (guestCallAllowed === undefined) {
      checkGuestCallAllowed()
    }

    async function checkGuestCallAllowed() {
      try {
        const response = await functions.getGuestCallAllowed({
          conversationID: convoID,
        })
        setGuestCallAllowed(response.isGuestCallAllowed)

        // If web calls are disabled for this team try opening Multi automatically
        if (!response.isGuestCallAllowed) {
          await generateAppLink(convoID, convoToken)
        }
      } catch (err) {
        Sentry.error(err, {
          message: `Error in getGuestCallAllowed function from pre call ${err}`,
        })
      } finally {
        setLoading(false)
      }
    }

    async function generateAppLink(convoID: string, convoToken: string) {
      // Open Multi, or the scheme provided as a URL search param.
      const potentialScheme: String | null = query.get('scheme')
      let newAppLink: string | undefined

      if (!potentialScheme) {
        newAppLink = await createLink(
          defaultScheme,
          convoID,
          convoToken,
          isRegistered
        ) // Open default app
      } else {
        const scheme: Scheme | undefined =
          maybeTransformToScheme(potentialScheme)
        if (scheme !== undefined) {
          newAppLink = await createLink(
            scheme,
            convoID,
            convoToken,
            isRegistered
          )
        } else {
          alert(`${potentialScheme} is not a valid scheme.`)
        }
      }

      if (newAppLink) {
        setAppLink(newAppLink)
      }
    }
  }, [convoID, convoToken, guestCallAllowed, query, roomName])

  // record in local storage that user has visited guest call pre call page
  useEffect(() => {
    setFromGuestCall()
  }, [])

  const openAppFn = openAppHandler(
    convoID,
    convoToken,
    defaultScheme,
    isRegistered
  )

  const PrimaryCTAButton = useMemo(() => {
    return (
      <Button darkMode fluid size='small' onClick={openAppFn}>
        Join call in app
      </Button>
    )
  }, [openAppFn])

  const SecondarySetupCallButton = useMemo(() => {
    return (
      <Button
        darkMode
        fluid
        size='small'
        kind='secondary'
        borderless
        onClick={advanceToSetup}
      >
        Join call {isRegistered ? 'on web' : 'as guest'}
      </Button>
    )
  }, [advanceToSetup, isRegistered])

  return (
    <div className={styles.guestCallSetup}>
      <SpinnerOverlay darkMode active={loading} />
      <div className={styles.oneColumn}>
        <div>
          <HeaderWithAppIcon
            title={
              isIOS ? 'Get the iOS app' : 'Joining call in your Multi app...'
            }
            message={
              <>
                {isIOS ? (
                  <>
                    Email{' '}
                    <a href='mailto:feedback@multi.app'>feedback@multi.app</a>{' '}
                    for a link to the iOS TestFlight. <br />
                    Already have the iOS TestFlight?{' '}
                    <a href='#' onClick={openAppFn}>
                      Join the session
                    </a>
                  </>
                ) : (
                  <>
                    App not opening?
                    <a href='#' onClick={openAppFn}>
                      Try again
                    </a>
                  </>
                )}
              </>
            }
          />
          {guestCallAllowed ? (
            <>
              <div className={styles.mainContentCard}>
                <p>
                  Multi web calls are in beta. Collaborative features only
                  available in the macOS app.
                </p>
                {PrimaryCTAButton}
              </div>
              {SecondarySetupCallButton}
            </>
          ) : (
            // only unregistered users should see the signup CTA
            // hide on iOS
            !user?.email &&
            !isIOS && (
              <Card darkMode>
                <div className={styles.signupCard}>
                  <div>
                    <p className={styles.signupCardTitle}>New to Multi?</p>
                    <p className={styles.signupCardSubtitle}>
                      Multiplayer collaboration for macOS
                    </p>
                  </div>
                  <Link to='/signup' className={styles.signupCardLink}>
                    <Button lightOnDark className={styles.signupCardButton}>
                      Sign up for free
                    </Button>
                  </Link>
                </div>
              </Card>
            )
          )}
          {user && isRegistered && <SignedInAs user={user} />}
          {isRemotionEmployee && (
            <OpenRemotionTestApps convoID={convoID} convoToken={convoToken} />
          )}
        </div>
      </div>

      <RemotionCaption />

      {/* Modal warning for users not on macOS and (Chrome or Safari) */}
      {warningModalData && (
        <Modal
          open={warningModalData.open}
          onClose={() => {
            if (isIOS && appLink) {
              window.location.assign(appLink)
            }
            setWarningModalData({
              ...warningModalData,
              open: false,
            })
          }}
          buttons={
            warningModalData.secondaryButtonLink && (
              <Link to={warningModalData.secondaryButtonLink.path}>
                <Button darkMode kind='secondary'>
                  {warningModalData.secondaryButtonLink.displayText}
                </Button>
              </Link>
            )
          }
          dismissBtnText={warningModalData.dismissBtnText}
        >
          {
            <>
              <h3>{warningModalData.header}</h3>
              <p>{warningModalData.body}</p>
            </>
          }
        </Modal>
      )}
      {appLink && !guestCallAllowed && (
        // Try to open the app link in an iframe to hide the error alert in Safari
        // If it works the good alert still shows.
        // This is based on this answer from SO: https://stackoverflow.com/a/27766603/4013587
        <iframe src={appLink} width={0} height={0}></iframe>
      )}
    </div>
  )
}

export const GuestCallAVSetup: React.FC = () => {
  const { isRegistered, user } = useAuth()
  const {
    loadDevices,
    avSetup,
    joinCallAsync,
    name,
    setName,
    setGuestModeState,
  } = useGuestCall()
  const { audioEnabled, videoEnabled, videoDeviceId } = avSetup
  const [cameraStream, setCameraStream] =
    useState<MediaStream | undefined>(undefined)
  const { isLocalUserSpeaking } = useMicVolume()
  const videoRef = useRef(null)

  const stopTracks = (stream: MediaStream) => {
    const tracks = stream.getTracks()
    tracks.forEach((track) => track.stop())
  }

  const previewAV = useAsync(async () => {
    const videoConstraints = {
      audio: true,
      video: { width: 960, height: 540 },
      ...(videoDeviceId ? { deviceId: videoDeviceId } : {}),
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia(videoConstraints)
      loadDevices()
      setCameraStream(stream)
    } catch (error) {
      console.warn('Could not get video and audio devices', error)
    }
  }, [videoDeviceId, audioEnabled, videoEnabled])

  useEffect(() => {
    if (videoRef.current && cameraStream) {
      const video: HTMLVideoElement = videoRef.current as any
      video.srcObject = cameraStream
      video.onloadedmetadata = () => video.play()
    }

    return () => {
      if (cameraStream) {
        stopTracks(cameraStream)
      }
    }
  }, [cameraStream])

  useEffect(() => {
    if (!videoEnabled && cameraStream) {
      stopTracks(cameraStream)
      setCameraStream(undefined)
    }
  }, [videoEnabled, cameraStream])

  // If there is an error joining the call, we want to stop the tracks
  useEffect(() => {
    if (joinCallAsync.error && cameraStream) {
      stopTracks(cameraStream)
    }
  }, [joinCallAsync.error, cameraStream])

  // if users are on safari, direct them to download the macOS app
  useEffect(() => {
    if (isSafari) {
      setGuestModeState(GuestModeState.SafariUser)
    }
  })

  const GuestNameInput = useMemo(() => {
    return (
      <div className={styles.nameContainer}>
        <input
          value={name}
          onChange={(e) => setName(e.target.value)}
          className={styles.nameInput}
          placeholder='Your name'
          required
          autoFocus
        />
      </div>
    )
  }, [name, setName])

  const JoinCallText =
    isRegistered && user
      ? `Join call as ${user.displayName}`
      : 'Join call as guest'

  if (joinCallAsync.loading) {
    return <Loader active size='large' />
  }

  if (joinCallAsync.error) {
    Sentry.error(joinCallAsync.error, {
      message: 'Error joining guest web call',
    })

    return <ErrorPage />
  }

  const joinCall = joinCallHandlerFn(joinCallAsync)
  const canJoin =
    !previewAV.loading && !joinCallAsync.loading && (isRegistered || name)

  // check that shared array buffer is available (necessary for Zoom calls) by creating a small SharedArrayBuffer
  // return an error on failure
  try {
    new SharedArrayBuffer(8)
  } catch (_err) {
    return (
      <ErrorPage
        headline="This browser can't securely access your call"
        body='Try refreshing or email support@multi.app'
      />
    )
  }

  return (
    <div className={styles.guestCallSetup}>
      <div className={styles.splitColumns}>
        <div className={styles.leftContainer}>
          <div
            className={classNames(styles.cameraContainerRectangular, {
              [styles.speakingBorder]: isLocalUserSpeaking,
            })}
          >
            <AvatarContainer cover='rectangle' fullName={name}>
              {videoEnabled && (
                <video
                  ref={videoRef}
                  muted
                  playsInline
                  style={{
                    position:
                      !!cameraStream && videoEnabled ? 'relative' : 'absolute',
                  }}
                />
              )}
            </AvatarContainer>
          </div>
          <CallControls />
        </div>
        {/* End className=left */}

        <form className={styles.rightContainer} onSubmit={joinCall}>
          {isRegistered ? <SignedInAs user={user!} /> : GuestNameInput}
          <Button
            darkMode
            fluid
            size='small'
            onClick={joinCall}
            disabled={!canJoin}
          >
            {JoinCallText}
          </Button>
          {!isRegistered && <PrivacyPolicy />}
        </form>
      </div>

      <RemotionCaption />
    </div>
  )
}
