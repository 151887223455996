import React from 'react'
import classnames from 'classnames'

import styles from './avatarImage.module.scss'

type Props = {
  children: React.ReactNode
  size?: 'regular' | 'big' | 'fluid'
}

const AvatarMask: React.FC<Props> = (props: Props) => {
  const { size = 'regular' } = props

  return (
    <div className={classnames(styles.avatar, styles[size])}>
      {props.children}
    </div>
  )
}

export default AvatarMask
