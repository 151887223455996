import React, { SVGProps } from 'react'

const IconCaretLeft = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.5 6.75L8.25 12L13.5 17.25'
        stroke='white'
        strokeWidth='2.25'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default IconCaretLeft
