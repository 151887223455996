import classNames from 'classnames'
import React from 'react'

import styles from './Button.module.scss'

// https://www.figma.com/file/OffR06tG5WtRa0UjmImO2Q/Remotion-Web-%26-Brand-Library?node-id=4%3A6678

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  size?: 'small' | 'medium' | 'large' | 'x-large'
  kind?: 'primary' | 'secondary' | 'tertiary'
  darkMode?: boolean
  darkOnDark?: boolean
  lightOnDark?: boolean
  borderless?: boolean
  disabled?: boolean
  fluid?: boolean
  googleBrand?: boolean
  children: React.ReactNode
  className?: string
}

export const Button: React.FC<Props> = (props: Props) => {
  const {
    onClick,
    size = 'medium',
    kind = 'primary',
    darkMode = false,
    darkOnDark = false,
    lightOnDark = false,
    borderless = false,
    disabled = false,
    fluid = false,
    googleBrand = false,
    children,
    className,
    ...buttonProps
  } = props

  return (
    <button
      {...buttonProps}
      className={classNames(
        styles.button,
        styles[size],
        styles[kind],
        {
          [styles.darkMode]: darkMode,
          [styles.darkOnDark]: darkOnDark,
          [styles.lightOnDark]: lightOnDark,
          [styles.borderless]: borderless,
          [styles.disabled]: disabled,
          [styles.fluid]: fluid,
          [styles.googleBrand]: googleBrand,
        },
        className
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
