import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'

type SearchParamObject = { [key: string]: string }

export function useQuery(): URLSearchParams {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

// helper function to convert search param to object
export function paramsToObject(
  entries: IterableIterator<[string, string]>
): SearchParamObject {
  const result: SearchParamObject = {}
  for (const [key, value] of entries) {
    result[key] = value
  }
  return result
}
