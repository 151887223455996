import React, { SVGProps } from 'react'

const IconCaretRight = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.5 6.75L15.75 12L10.5 17.25'
        stroke='white'
        strokeWidth='2.25'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default IconCaretRight
