import classNames from 'classnames'
import IconCaretLeft from 'components/UI/Icons/CaretLeft'
import IconCaretRight from 'components/UI/Icons/CaretRight'
import IconDot from 'components/UI/Icons/Dot'
import React, { useEffect } from 'react'
import { useGuestCallZoom } from './GuestCallZoomProvider'

import styles from './Zoom.module.scss'

const PaginationControls: React.FC = () => {
  const { numPages, currentPage, setCurrentPage, isRemoteScreensharing } =
    useGuestCallZoom()

  useEffect(() => {
    if (currentPage < 0 || currentPage >= numPages) {
      setCurrentPage(0)
    }
  }, [currentPage, numPages, setCurrentPage])

  return (
    <div
      className={classNames(styles.paginationElements, {
        [styles.alignRight]: isRemoteScreensharing,
        [styles.hide]: numPages <= 1,
      })}
    >
      <div className={styles.elementsWrapper}>
        <div
          className={styles.paginationElement}
          onClick={() => {
            setCurrentPage((state) => state - 1)
          }}
        >
          <IconCaretLeft />
        </div>
        {Array.from(Array(numPages).keys()).map((numPage) => (
          <div
            key={numPage}
            className={styles.paginationElement}
            onClick={() => {
              setCurrentPage(numPage)
            }}
          >
            <IconDot
              dotFillColor={numPage === currentPage ? 'white' : '#52525B'}
            />
          </div>
        ))}
        <div
          className={styles.paginationElement}
          onClick={() => {
            setCurrentPage((state) => state + 1)
          }}
        >
          <IconCaretRight />
        </div>
      </div>
    </div>
  )
}

export default PaginationControls
