import merge from 'lodash/merge'
import {
  updateSelfie,
  uploadSelfie,
} from 'components/Private/Account/SelfieUpdater'
import { JoinableTeamData } from '../Team/Team'
import { UserCredential } from 'firebase/auth'
import { track, EventTypes } from 'utilities/analytics'
import { GoogleLoginResult } from 'utilities'
import * as Sentry from 'sentry'

export type InviteData = {
  teamID: string
  teamName?: string
  inviteID?: string
  source?: string
}

export type GoogleData = {
  googleUserID: string
}

// set on the signup page if a user has utm query params
export type GoogleAdData = {
  utm_source: string
  utm_medium: string
  utm_campaign: string
}

export type ProfileData = {
  authType?: 'google' | 'email'
  authSource?: string
  fullname?: string
  avatarImg?: string
  googleData?: GoogleData
}

export type CreateTeamData = {
  allowDomainCapture: boolean
  teamName: string
  teamOS: string
}

export type TeamData = CreateTeamData | JoinableTeamData

export type TeamJoinedData = {
  teamID: string
  userID: string
}

export type SetupData = {
  invite?: InviteData
  freshness?: number // UNIX timestamp
  profile?: ProfileData
  team?: TeamData
  joinableTeams?: Array<JoinableTeamData>
  teamJoinedData?: TeamJoinedData
  googleAdData?: GoogleAdData
  // these initials are used to generate the avatar image for email auth users
  unverifiedUserFullname?: string
}

const ONE_DAY_IN_MS = 86400000
function isDataFresh(data: SetupData): boolean {
  if (!data.freshness) {
    return false
  }

  const now = Date.now()
  const timeSince = now - data.freshness
  if (timeSince >= 0 && timeSince <= ONE_DAY_IN_MS) {
    return true
  } else {
    // The data is older than one day
    return false
  }
}

/** Retrieve setup data from local storage. If data is not fresh,
 * clears the local storage and returns empty SetupData.
 */
export const getSetupData = (): SetupData | undefined => {
  const dataString = localStorage.getItem('SetupData')
  if (!dataString) {
    return
  }

  const newSetupData = JSON.parse(dataString) as SetupData
  if (isDataFresh(newSetupData)) {
    return newSetupData
  } else {
    console.warn('SetupData is stale, clearing local storage values.')
    localStorage.removeItem('SetupData')
    return
  }
}

export const getUserProfileData = (): ProfileData | undefined => {
  const setupData = getSetupData()
  return setupData?.profile
}

export const getUserTeamData = (): TeamData | undefined => {
  const setupData = getSetupData()
  return setupData?.team
}

export const getInviteData = (): InviteData | undefined => {
  const setupData = getSetupData()
  return setupData?.invite
}

export const getJoinableTeams = (): JoinableTeamData[] | undefined => {
  const setupData = getSetupData()
  return setupData?.joinableTeams
}

export const getTeamJoinedData = (): TeamJoinedData | undefined => {
  const setupData = getSetupData()
  return setupData?.teamJoinedData
}

export const getGoogleAdData = (): GoogleAdData | undefined => {
  const setupData = getSetupData()
  return setupData?.googleAdData
}

export const getUnverifiedUserFullname = (): string | undefined => {
  const setupData = getSetupData()
  return setupData?.unverifiedUserFullname
}

/** Update the setup data store in local storage.
 *  Affixes a 'freshness' timestamp on each write. */
export const setSetupData = (setupData: SetupData): void => {
  let currentSetupData = getSetupData()
  if (!currentSetupData) {
    currentSetupData = {}
  }

  if (setupData.profile) {
    // for only profile data, we want to merge with existing values
    // for all other properties of SetupData, we want to overwrite sub props with new data
    setupData.profile = merge(currentSetupData.profile, setupData.profile)
  }

  const newSetupData = {
    ...currentSetupData,
    ...setupData,
  }

  newSetupData.freshness = Date.now()

  const newSetupString = JSON.stringify(newSetupData)
  localStorage.setItem('SetupData', newSetupString)
}

/**
 * cleanup function - remove SetupData after successful signup
 */
export const removeAllSetupData = (): void => {
  localStorage.removeItem('SetupData')
}

export const uploadAndSetAvatar = async (
  teamID: string,
  userID: string,
  // When avatars are remote URLs (e.g. Google Auth avatar)
  // recorded Gif/Still pair, or generated initial avatars
  // we need to upload them to firebase storage.
  uploadableImageData: string,
  uploadableVideoData?: string
): Promise<void> => {
  // upload selfie to storage
  const filename = Date.now().toString()
  let avatarImgURL: string | undefined

  if (uploadableImageData) {
    avatarImgURL = await uploadSelfie(
      uploadableImageData,
      teamID,
      userID,
      `${filename}.jpeg`
    )
  }

  if (uploadableVideoData) {
    Sentry.warning(
      'Attemped to upload video selfie data for user avatar. This is no longer supported'
    )
  }

  // If at least an image was uploaded, we can safely invoke updateSelfie
  // Note: avatarVideoURL may be undefined, but the fn checks before uploading.
  if (avatarImgURL) {
    // point avatar to file in storage
    await updateSelfie(avatarImgURL)
    track(EventTypes.selfieTaken)
  }
}

export const getGoogleDataAndUpdateSetupData = async (
  googleAuthRes?: GoogleLoginResult
): Promise<UserCredential> => {
  if (googleAuthRes && googleAuthRes.googleUserID) {
    const googleData: GoogleData = {
      googleUserID: googleAuthRes.googleUserID,
    }
    setSetupData({ profile: { googleData, authType: 'google' } })
    return googleAuthRes.userCredential
  } else {
    throw new Error('NO_ACCOUNT_ERROR')
  }
}

export const setUnverifiedUserFullname = (
  unverifiedUserFullname: string
): void => {
  setSetupData({
    unverifiedUserFullname,
  })
}
