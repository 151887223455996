import React, { useState, useEffect } from 'react'
import styles from './avatarImage.module.scss'
import defaultAvatar from './defaultAvatar.png'
import AvatarMask from './AvatarMask'
import { getImageUrl } from './AvatarImage.helper'

interface AvatarImageProps {
  avatarFile?: string
  url?: string
  size?: 'regular' | 'big' | 'fluid'
  type?: 'video' | 'image'
}

const AvatarImage: React.FC<AvatarImageProps> = (props: AvatarImageProps) => {
  const { avatarFile, size, url, type = 'image' } = props
  const [avatarUrl, setAvatarUrl] = useState(defaultAvatar)

  useEffect(() => {
    if (avatarFile && !url) {
      getImageUrl(avatarFile).then((url) => {
        setAvatarUrl(url)
      })
    }
  }, [avatarFile, url])

  return (
    <AvatarMask size={size}>
      {type === 'image' && (
        <img
          src={url || avatarUrl}
          alt='avatar image'
          className={styles.avatarImage}
          loading='lazy'
          referrerPolicy='no-referrer'
        ></img>
      )}
      {type === 'video' && (
        <video
          autoPlay
          loop
          playsInline
          src={url || avatarUrl}
          className={styles.avatarImage}
        />
      )}
    </AvatarMask>
  )
}

export default AvatarImage
