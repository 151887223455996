import React from 'react'
import styles from './HeaderWithAppIcon.module.scss'
import multiIcon from './multi-icon.png'
import blobs from './blurryblobs.png'

interface Props {
  title: string
  message?: string | JSX.Element
}

export const HeaderWithAppIcon = ({ title, message }: Props): JSX.Element => {
  return (
    <div className={styles.header}>
      <div className={styles.icon}>
        <img className={styles.multiIcon} src={multiIcon} />
        <img className={styles.blobs} src={blobs} />
      </div>
      <h1>{title}</h1>
      {message && <p>{message}</p>}
    </div>
  )
}
