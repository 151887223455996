export enum LocalStorageItem {
  savedEmail = 'savedEmail',
  redirectInfo = 'redirectInfo',
  teamInviteLink = 'teamInviteLink',
  waitlistUsers = 'waitlistUsers',
  fromGuestCall = 'fromGuestCall', // set on the guest call pre call page to indicate that the user has visited a call link
  referralID = 'referralID',
  multiWaitlistData = 'multiWaitlistData',
  callLinkData = 'CallLinkData',
}

export function setItem(key: LocalStorageItem, value?: string | null): void {
  if (!value) {
    localStorage.removeItem(key)
  } else {
    localStorage.setItem(key, value)
  }
}

export function getItem(key: LocalStorageItem): string | null {
  return localStorage.getItem(key)
}

// Cross tab redirection

/**
 * Contains list of all the paths that needs to be redirected from sign in link if user open the email in same browser but different tab
 */
const persistRedirectionPathList = ['/linkSlackAccount', '/join-team']

type RedirectionInfo = {
  hash?: string
  key?: string
  pathname: string
  search?: string
}
export function getRedirectInfo(): RedirectionInfo | null {
  const dictString = getItem(LocalStorageItem.redirectInfo)
  if (dictString) {
    return JSON.parse(dictString)
  } else {
    return null
  }
}

export function setRedirectInfo(object: any | null): void {
  if (object) {
    if (persistRedirectionPathList.includes(object.pathname ?? '')) {
      setItem(LocalStorageItem.redirectInfo, JSON.stringify(object))
    } else {
      setItem(LocalStorageItem.redirectInfo, null)
    }
  } else {
    setItem(LocalStorageItem.redirectInfo, null)
  }
}
