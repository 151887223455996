// Anonymous User Segments

/**
 * feature flags used in web and their default values
 */
export enum FeatureFlags {
  signupFromCallLink = 'signup-from-call-link',
  slackIntegration2 = 'slack-2',
  multiWaitlist = 'multi-waitlist',
}

export const FeatureFlagDefaults: Record<FeatureFlags, boolean> = {
  [FeatureFlags.signupFromCallLink]: true,
  [FeatureFlags.slackIntegration2]: false,
  [FeatureFlags.multiWaitlist]: true,
}

/**
 * Provide the default value for the feature flag
 * @param flagName - the name of the feature flag, use an enum value from FeatureFlags
 * @returns
 */
export function getFeatureFlag(flagName: FeatureFlags): boolean {
  return FeatureFlagDefaults[flagName]
}
