import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
  SetStateAction,
} from 'react'
import { useParams } from 'react-router'
import { useAsyncCallback, UseAsyncReturn } from 'react-async-hook'
import { ErrorWithCause } from 'pony-cause'
import { User } from 'firebase/auth'
import { isSafari, isIOS } from 'react-device-detect'

import { functions, database } from 'utilities/firebase-utils'
import { getGuestName, setGuestCallData } from 'utilities/guestCallData'
import { signInAsGuest, useAuth } from 'components/AuthProvider'
import { useQuery } from 'utilities/locationHooks'
import { track, EventTypes, page, PageCategory } from 'utilities/analytics'
import { getFullName } from './guest-call-utils'
import { AVProvider } from 'utilities/firebase-utils/functions'
import useCallControls, {
  UseCallControlsReturn,
} from 'components/Public/GuestCall/useCallControls'
import { SessionStartedResult } from 'utilities/firebase-utils/database'
import { Convo } from 'models'
import { setCallLinkData } from 'utilities/callLinkData'

export enum GuestModeState {
  PreCall = 'PreCall',
  SetupAV = 'SetupCall',
  InCall = 'InCall',
  CallEnded = 'CallEnded',
  SafariUser = 'SafariUser',
  ZoomUnsupported = 'ZoomUnsupported',
  IOSUser = 'IOSUser',
}

export type UserWithAvatar = functions.PublicUser & {
  avatarUrl: string | undefined
}

export type UserMap = {
  [userID: string]: UserWithAvatar
}

const GuestCallContext = createContext({} as GuestCallContextType)

type GuestCallContextType = UseCallControlsReturn & {
  convoID: string
  convoToken: string
  teamID: string
  teamName: string
  roomName: string
  channelDetails: functions.ChannelDetails | undefined

  guestModeState: GuestModeState
  setGuestModeState: React.Dispatch<SetStateAction<GuestModeState>>

  joinCallAsync: UseAsyncReturn<void, []>
  leaveCallFirebase: (providerLeaveFn?: () => Promise<void>) => void

  name: string
  setName: React.Dispatch<React.SetStateAction<string>>

  userMap: UserMap
  setUserMap: React.Dispatch<React.SetStateAction<UserMap>>

  shouldLeaveCall: (convo?: Convo) => boolean
  advanceToSetup: () => void
}

export const useGuestCall = (): GuestCallContextType =>
  useContext(GuestCallContext)

type Props = {
  children: React.ReactNode
}

const DEFAULT_GUEST_NAME = 'Guest'

const GuestCallProvider: React.FC<Props> = (props: Props) => {
  const query = useQuery()
  const convoToken = query.get('t') || ''
  const roomName = query.get('room') || ''
  const sender = query.get('sender') || ''

  const { auth, isAuthAndAccountInitialized, user, isRegistered } = useAuth()
  const { convoID } = useParams<{ convoID: string }>()

  const [name, setName] = useState(getGuestName() || '')
  const [teamID, setTeamID] = useState<string>('')
  const [teamName, setTeamName] = useState<string>('')

  const [userMap, setUserMap] = useState<UserMap>({})
  const [guestModeState, setGuestModeState] = useState(GuestModeState.PreCall)

  const [channelDetails, setChannelDetails] =
    useState<functions.ChannelDetails>()

  const { audioDevices, videoDevices, avSetup, setAVSetup, loadDevices } =
    useCallControls()

  // Reified Sessions
  const activeSessionID = useRef<string>()
  const isLeaving = useRef(false)

  const previousUserID = auth?.uid

  useEffect(() => {
    page(PageCategory.call, 'Call Setup', { callId: convoID })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only run on initial load
  }, [])

  /** Inspect a conversation, and return true when observers should leave the call */
  const shouldLeaveCall = useCallback(
    (convo?: Convo) => {
      const sessionID = activeSessionID.current
      const alreadyLeaving = isLeaving.current

      if (alreadyLeaving || !sessionID || !convo) {
        return false // No action to take
      }

      if (convo.participants && !convo.participants[sessionID]) {
        console.debug('Session removed from conversation, leaving..', sessionID)
        return true
      }

      return false
    },
    [activeSessionID, isLeaving]
  )

  const leaveCallFirebase = async (leaveProviderFn?: () => Promise<void>) => {
    if (isLeaving.current === true) {
      return // Only leave once
    }

    isLeaving.current = true // mutex over leaving conversation

    const sessionID = activeSessionID.current

    // When a call is active, leave it first
    if (leaveProviderFn) {
      // leaving call from InCall page, no need to wait for
      // presence before showing CallEnded page
      await leaveProviderFn()
      setGuestModeState(GuestModeState.CallEnded)
    }

    await functions.leaveConversation({ reason: 'USER', sessionID })
    await database.stopCallPresence()
  }

  /** Before joining a call, inspect the users' authentication state and selectively
      log anonymous users in depending on whether "Reified Sessions" are enabled. */
  const settleAuthState = useAsyncCallback(async (auth?: User) => {
    let settledUserID: string
    // RM-5821: We want to isolate the persistence of anon authentication to be sticky
    // to our active call window. Per Docs,
    //
    // browserSessionPersistence indicates that the state will only persist in the current
    // session or tab, and will be cleared when the tab or window in which the user
    // authenticated is closed. Applies only to web apps.
    if (!auth) {
      settledUserID = await signInAsGuest().then((cred) => cred.user.uid)
    } else {
      settledUserID = auth.uid
    }
    return settledUserID
  })

  // CH Note: this is a long async operation that needs to be done in order.
  // Adjust the async ops here at your own risk!
  const joinCallAsync = useAsyncCallback(async () => {
    const userID = await settleAuthState.execute(auth)

    // order important
    const signedInUserName = getFullName(user?.firstName, user?.lastName)
    const guestName = name || signedInUserName || DEFAULT_GUEST_NAME
    let validateConversationTokenResult: functions.ResponseWithTeamIDAndName
    try {
      validateConversationTokenResult =
        await functions.validateConversationToken({
          convoID,
          token: convoToken,
          previousUserID: userID,
          sender,
          name: guestName,
        })
    } catch (error) {
      throw new ErrorWithCause('Error validating conversation token', {
        cause: error,
      })
    }

    setTeamID(validateConversationTokenResult.teamID)
    setTeamName(validateConversationTokenResult.teamName)
    setName(guestName)

    track(EventTypes.callJoinClicked, {
      call_id: convoID,
      user_id: previousUserID,
      team_id: validateConversationTokenResult.teamID,
    })

    // Starting presence is an asynchronous operation
    // We await for some parts of that process to finish but not all of them. Specifically, we don't wait for presence state to be in Firestore.
    // It could happen that we join a conversation while offline as per Firestore.
    // However, we have strong guarantees that when this finishes, isOnline will be eventually set, so we won't leak guest users in conversations.
    let sessionIdOrPresenceToken: SessionStartedResult
    try {
      sessionIdOrPresenceToken = await database.startCallPresence(true)
    } catch (error) {
      throw new ErrorWithCause('Error setting up presence', { cause: error })
    }

    const { sessionID: newSessionID } = sessionIdOrPresenceToken
    if (newSessionID) {
      activeSessionID.current = newSessionID
    }

    let joinConversationResponse: functions.JoinConversationResponse
    try {
      joinConversationResponse = await functions.joinConversation({
        conversationID: convoID,
        token: convoToken,
        sessionID: newSessionID,
        preferredAVProvider: AVProvider.ZOOM,
      })
    } catch (error) {
      throw new ErrorWithCause('Error joining conversation', { cause: error })
    }

    if (
      joinConversationResponse.channelAccessDescription.channelDetails
        .provider === AVProvider.ZOOM
    ) {
      // if it is a zoom call and users are on safari, or on any iOS device
      // route to upsell page, do not proceed to call
      // leave the call and set the state to safari user to show error page
      if (isSafari) {
        setGuestModeState(GuestModeState.SafariUser)
        leaveCallFirebase()
        return
      }

      if (isIOS) {
        setGuestModeState(GuestModeState.IOSUser)
        leaveCallFirebase()
        return
      }
    }

    setChannelDetails(
      joinConversationResponse.channelAccessDescription.channelDetails
    )
    setGuestCallData({
      guestName: guestName === DEFAULT_GUEST_NAME ? undefined : guestName, // only store guestName if not default
      hasHadGuestCall: true,
    })
  })

  // When we observe the user has a display name, propogate this change to guest call provider
  useEffect(() => {
    if (user?.displayName) {
      setName(user.displayName)
    }
  }, [user?.displayName])

  // move to InCall page based on channelDetails
  useEffect(() => {
    if (channelDetails) {
      setGuestModeState(GuestModeState.InCall)
    }
  }, [channelDetails])

  // set callLinkData for the "join team via call link" flow
  useEffect(() => {
    if (convoID && convoToken && !isRegistered) {
      const callLink = {
        convoID,
        convoToken,
        roomName,
      }
      setCallLinkData(callLink)
    }
  }, [convoID, convoToken, roomName, isRegistered])

  // REDIRECT CODE
  // Update guestModeState based on auth/firebase data loads
  useEffect(() => {
    // We wait to transition out of Loading state until accounts are loaded
    if (isAuthAndAccountInitialized) {
      /** We set the teamID if it exists. Caution though!
       *
       * Caveat 1: If the user doesn't have an account or is anonymous, it won't have one. We shouldn't override this field
       * Caveat 2: Multi supports multiple teams, so the user could be in many - but we arbitrarily choose the first one for
       *           simplicity. This isn't perfect but makes things way easier.
       * Caveat 3: Every convo is guaranteed to belong to a team. We aren't checking if the user is in the right team - if not, we should verify it
       *           but we aren't right now.
       */
      const potentiallyTeamID = user?.teams[0]?.id
      if (potentiallyTeamID) {
        setTeamID(potentiallyTeamID)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- Should only trigger once, when load is complete
  }, [isAuthAndAccountInitialized])

  const advanceToSetup = useCallback(() => {
    setGuestModeState(GuestModeState.SetupAV)
  }, [])

  return (
    <GuestCallContext.Provider
      value={{
        convoID,
        convoToken,
        teamID,
        teamName,
        roomName,
        userMap,
        channelDetails,
        audioDevices,
        videoDevices,
        loadDevices,
        avSetup,
        setAVSetup,
        guestModeState,
        setGuestModeState,
        joinCallAsync,
        leaveCallFirebase,
        name,
        setName,
        setUserMap,
        shouldLeaveCall,
        advanceToSetup,
      }}
    >
      {props.children}
    </GuestCallContext.Provider>
  )
}

export default GuestCallProvider
