import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useLocation } from 'react-router'
import { useAsyncCallback } from 'react-async-hook'

import { functions } from 'utilities'
import { useAuth } from 'components/AuthProvider'

import Header from 'components/UI/Header'
import SpinnerOverlay from 'components/UI/Spinner'

import styles from './WindowsWaitlist.module.scss'
import { PageCategory, usePageTracking } from 'utilities/analytics'
import Button from 'brand/Button'

const copyOptions = {
  join: {
    headline: 'Are you on Windows? Multi is only available on macOS right now.',
    body: 'Join our waitlist for Windows and Linux versions today, so we can let you know about upcoming releases.',
  },
  success: {
    headline: "You're on the list!",
    body: "We'll let you know when we're available on Windows and Linux",
  },
}

type LocationState = {
  from?: string
}
const WindowsWaitlist: React.FC = () => {
  const history = useHistory()
  const location = useLocation<LocationState>()
  const { auth } = useAuth()
  const [copy, setCopy] = useState(copyOptions.join)
  const [showSignupButton, setShowSignupButton] = useState<boolean>(false)

  const onJoinWaitlist = useAsyncCallback(() => {
    return functions.joinWindowsWaitlist()
  })

  const onForceSignup = () => {
    history.push('/setup/team')
  }

  usePageTracking(PageCategory.onboarding, 'Windows Waitlist')

  useEffect(() => {
    if (!auth) {
      history.push('/signup')
    }

    const teamCreationAttempted = location.state?.from === '/setup/team/create'
    if (teamCreationAttempted) {
      setShowSignupButton(false)
    } else {
      setShowSignupButton(true)
    }
  }, [auth, history, location.state])

  useEffect(() => {
    if (!onJoinWaitlist.result) {
      return
    }
    setCopy(copyOptions.success)
  }, [onJoinWaitlist.result])

  return (
    <div>
      <Helmet>
        <title>Windows Waitlist</title>
      </Helmet>
      <Header hideLogout hideNav darkMode />
      {onJoinWaitlist.loading && <SpinnerOverlay darkMode />}
      <div className={styles.flexContainer}>
        <div>
          <h1>{copy.headline}</h1>
          <p>{copy.body}</p>
          {!onJoinWaitlist.result ? (
            <>
              <Button darkMode onClick={onJoinWaitlist.execute}>
                Join waitlist
              </Button>
              {showSignupButton && (
                <Button darkMode kind='secondary' onClick={onForceSignup}>
                  Continue signup anyway
                </Button>
              )}
            </>
          ) : (
            <a href='https://multi.app'>
              <Button darkMode>Back to home</Button>
            </a>
          )}
          {onJoinWaitlist.error && (
            <p className={styles.error}>
              Something went wrong, try again or contact support@multi.app.
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default WindowsWaitlist
