import { useState, useEffect, useCallback } from 'react'
import AgoraRTC, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  MicrophoneAudioTrackInitConfig,
  CameraVideoTrackInitConfig,
  IMicrophoneAudioTrack,
  ICameraVideoTrack,
  ILocalVideoTrack,
  ILocalAudioTrack,
  ConnectionState,
  IRemoteAudioTrack,
  UID,
} from 'agora-rtc-sdk-ng'
import { breadcrumb, SentryBreadcrumbCategory } from 'sentry'
import * as Sentry from '../../../../../sentry'
import { track, EventTypes } from 'utilities/analytics'
import { hashUID } from '../uid-hasher'
import { AVSetup } from '../../useCallControls'
import { ConvoField } from 'models'
import { SnakeCasedRecord } from 'utilities/analytics.types'

/**
 * The size (in samples) of the audio buffers that we request from Agora's
 * audio frame callback functions.
 */
const AUDIO_BUFFER_SIZE = 4096

/**
 * 480p_2 uses 640 × 480 at 30 fps and 1000 Kbps
 */
const VIDEO_ENCODER_CONFIG = '480p_2'

export type SpeakingUserState = {
  isSpeaking: boolean
  timestamp: number
}

export function getRemotionToAgoraUID(userID: string): number {
  return parseInt(hashUID(userID), 16)
}

export function createAgoraClient(): IAgoraRTCClient {
  return AgoraRTC.createClient({ codec: 'h264', mode: 'rtc' })
}

export type UseAgoraReturn = {
  localUID: UID | undefined
  // the user's camera
  cameraTrack: ILocalVideoTrack | undefined
  // the user's screenshare
  screenTrack: ILocalVideoTrack | undefined

  agoraRemoteUsers: IAgoraRTCRemoteUser[]
  speakingUsers: Record<string, SpeakingUserState>

  startScreenshare: () => Promise<void>
  stopScreenshare: () => Promise<void>

  toggleAudio: () => void
  toggleVideo: () => void

  changeVideoInputDevice: (deviceId: string, videoEnabled: boolean) => void
  changeAudioInputDevice: (deviceId: string, audioEnabled: boolean) => void

  leave: () => Promise<void>
  join: (
    appid: string,
    channel: string,
    token: string,
    secret: string,
    userID: string,
    avSetup: AVSetup
  ) => void
}

export default function useAgora(
  client: IAgoraRTCClient,
  removeLocalUserFirebaseConvoField: (field: ConvoField) => Promise<void>
): UseAgoraReturn {
  const [localAudioTrack, setLocalAudioTrack] = useState<
    ILocalAudioTrack | undefined
  >(undefined)
  const [cameraTrack, setCameraTrack] = useState<ILocalVideoTrack | undefined>(
    undefined
  )
  const [screenTrack, setScreenTrack] = useState<ILocalVideoTrack | undefined>(
    undefined
  )

  const [joinState, setJoinState] = useState(false)

  const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([])

  // Storage for the audio tracks (both local and remote) so that the frame listeners can be cancelled later
  type UserAudioTrackMap = Map<UID, IRemoteAudioTrack | ILocalAudioTrack>
  const [tracks, setTracks] = useState<UserAudioTrackMap>(new Map())

  // A dictionary containing real-time (not debounced) data about which users are speaking (based on
  // analysis of their incoming audio frame buffers)
  const [speakingUsers, setSpeakingUsers] = useState<
    Record<string, SpeakingUserState>
  >({})

  async function createLocalTracks(
    audioConfig?: MicrophoneAudioTrackInitConfig,
    videoConfig?: CameraVideoTrackInitConfig
  ): Promise<[IMicrophoneAudioTrack?, ICameraVideoTrack?]> {
    try {
      const [microphoneTrack, cameraTrack] =
        await AgoraRTC.createMicrophoneAndCameraTracks(audioConfig, videoConfig)
      setLocalAudioTrack(microphoneTrack)
      setCameraTrack(cameraTrack)
      breadcrumb(
        'createMicrophoneAndCameraTracks successful',
        SentryBreadcrumbCategory.AV,
        {
          microphoneTrack,
          cameraTrack,
        }
      )
      return [microphoneTrack, cameraTrack]
    } catch (error) {
      breadcrumb(
        'createMicrophoneAndCameraTracks failed',
        SentryBreadcrumbCategory.AV,
        { error }
      )
      // fall back to trying to create the tracks individually
      return createLocalTracksIndividually(audioConfig, videoConfig)
    }
  }

  async function createLocalTracksIndividually(
    audioConfig?: MicrophoneAudioTrackInitConfig,
    videoConfig?: CameraVideoTrackInitConfig
  ): Promise<[IMicrophoneAudioTrack?, ICameraVideoTrack?]> {
    const microphoneTrackPromise: () => Promise<
      IMicrophoneAudioTrack | undefined
    > = async () => {
      try {
        const microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack(
          audioConfig
        )
        breadcrumb(
          'createMicrophoneAudioTrack successful',
          SentryBreadcrumbCategory.AV,
          {
            microphoneTrack,
          }
        )
        setLocalAudioTrack(microphoneTrack)
        return microphoneTrack
      } catch (error) {
        breadcrumb(
          'createMicrophoneAudioTrack failed',
          SentryBreadcrumbCategory.AV,
          { error }
        )
        return undefined
      }
    }
    const cameraTrackPromise: () => Promise<
      ICameraVideoTrack | undefined
    > = async () => {
      try {
        const cameraTrack = await AgoraRTC.createCameraVideoTrack(videoConfig)
        breadcrumb(
          'createCameraVideoTrack successful',
          SentryBreadcrumbCategory.AV,
          {
            cameraTrack,
          }
        )
        setCameraTrack(cameraTrack)
        return cameraTrack
      } catch (error) {
        breadcrumb(
          'createMicrophoneAudioTrack failed',
          SentryBreadcrumbCategory.AV,
          { error }
        )
        return undefined
      }
    }

    const [microphoneTrack, cameraTrack] = await Promise.all([
      microphoneTrackPromise(),
      cameraTrackPromise(),
    ])

    return [microphoneTrack ?? undefined, cameraTrack ?? undefined]
  }

  async function join(
    appid: string,
    channel: string,
    token: string,
    secret: string,
    userID: string,
    avSetup: AVSetup
  ) {
    client.setEncryptionConfig('aes-128-xts', secret)
    await client.join(appid, channel, token, getRemotionToAgoraUID(userID))

    const { audioEnabled, videoEnabled, audioDeviceId, videoDeviceId } = avSetup
    const [microphoneTrack, cameraTrack] = await createLocalTracks(
      { microphoneId: audioDeviceId, AEC: true, ANS: true },
      { cameraId: videoDeviceId, encoderConfig: VIDEO_ENCODER_CONFIG }
    )
    if (microphoneTrack) {
      await client.publish(microphoneTrack)
      microphoneTrack.setEnabled(audioEnabled)
    }
    if (cameraTrack) {
      await client.publish(cameraTrack)
      cameraTrack.setEnabled(videoEnabled)
    }
    setJoinState(true)
    // Send the initial microphone and camera devices at the beginning of the call
    sendStatsToSegment(EventTypes.callJoined, {
      microphone: microphoneTrack?.getTrackLabel() || '(None)',
      camera: cameraTrack?.getTrackLabel() || '(None)',
    })
  }

  async function changeVideoInputDevice(
    deviceId: string,
    videoEnabled: boolean
  ) {
    if (client.connectionState !== 'CONNECTED') return
    const videoTrack = await AgoraRTC.createCameraVideoTrack({
      cameraId: deviceId,
      encoderConfig: VIDEO_ENCODER_CONFIG,
    })
    if (cameraTrack) {
      await Promise.all([
        client.unpublish([cameraTrack]),
        cameraTrack.setEnabled(false),
      ])
    }
    await client.publish([videoTrack])
    setCameraTrack(videoTrack)
    videoTrack.setEnabled(videoEnabled)
  }

  async function changeAudioInputDevice(
    deviceId: string,
    audioEnabled: boolean
  ) {
    if (client.connectionState !== 'CONNECTED') return
    const audioTrack = await AgoraRTC.createMicrophoneAudioTrack({
      microphoneId: deviceId,
      AEC: true,
      ANS: true,
    })
    if (localAudioTrack) {
      await client.unpublish([localAudioTrack])
    }
    await client.publish([audioTrack])
    setLocalAudioTrack(audioTrack)
    audioTrack.setEnabled(audioEnabled)
  }

  const stopScreenshare = useCallback(async (): Promise<void> => {
    if (!screenTrack) {
      return
    }

    try {
      await client.unpublish(screenTrack)
      screenTrack.stop()
      screenTrack.close()

      if (cameraTrack && cameraTrack.enabled) {
        await client.publish([cameraTrack])
      }

      setScreenTrack(undefined)
      await removeLocalUserFirebaseConvoField(ConvoField.ScreensharingUsers)
    } catch (err) {
      console.error('error in stopScreenshare:', err)
    }
  }, [screenTrack, client, cameraTrack, removeLocalUserFirebaseConvoField])

  const startScreenshare = useCallback(async (): Promise<void> => {
    const localScreenTrack = await AgoraRTC.createScreenVideoTrack(
      {
        encoderConfig: '1080p_1',
      },
      'disable'
    )

    if (!localScreenTrack) {
      Sentry.error('Unknown Agora screenshare error')
      throw new Error('Unknown Agora screenshare error')
    }

    if (cameraTrack) {
      await client.unpublish([cameraTrack])
    }

    await client.publish([localScreenTrack])
    setScreenTrack(localScreenTrack)
  }, [client, cameraTrack])

  function sendStatsToSegment(
    event: EventTypes.callCompleted | EventTypes.callJoined,
    stats: SnakeCasedRecord
  ) {
    track(event, stats)
  }

  async function leave() {
    if (!joinState) {
      return
    }

    // At the end of the call, we send the devices used (`localAudioTrack` and `cameraTrack`)
    // as well as any stats from Agora (`getRTCStats`)
    // Over time, we should add any metrics that we store ourselves as well about the A/V quality/experience
    sendStatsToSegment(EventTypes.callCompleted, {
      microphone: localAudioTrack?.getTrackLabel() ?? '',
      camera: cameraTrack?.getTrackLabel() ?? '',
      audio_stats: client.getLocalAudioStats(),
      video_stats: client.getLocalVideoStats(),
      rtc_stats: client.getRTCStats(),
    })

    if (localAudioTrack) {
      localAudioTrack.stop()
      localAudioTrack.close()
    }
    if (cameraTrack) {
      cameraTrack.stop()
      cameraTrack.close()
    }
    if (screenTrack) {
      screenTrack.stop()
      screenTrack.close()
    }
    try {
      tracks.forEach((track) => {
        track?.setAudioFrameCallback(null)
      })
    } catch (_) {
      // These tracks are likely disconnected already and we'll see "Failed to execute 'disconnect' on 'AudioNode'"
      // errors in the console unless we specifically try/catch them
    }

    setTracks(new Map())
    setRemoteUsers([])
    setJoinState(false)
    await client.leave()
  }

  const toggleAudio = useCallback(async () => {
    if (localAudioTrack) {
      await localAudioTrack.setEnabled(!localAudioTrack.enabled)
    }
  }, [localAudioTrack])

  const toggleVideo = useCallback(async () => {
    if (cameraTrack) {
      await cameraTrack.setEnabled(!cameraTrack.enabled)
    }
  }, [cameraTrack])

  // `addSpeakingUser` and `removeSpeakingUser` manage the contents of `speakingUsers`
  // and should be the only places where `speakingUsers` is mutated.
  // They are used by both the local and remote frame listeners
  const addSpeakingUser = (userID: UID) => {
    setSpeakingUsers((speakingUsers) => {
      const key = `${userID}`
      if (speakingUsers[key]?.isSpeaking !== true) {
        return {
          ...speakingUsers,
          [key]: { isSpeaking: true, timestamp: Date.now() },
        }
      } else {
        return speakingUsers
      }
    })
  }
  const removeSpeakingUser = (userID: UID) => {
    setSpeakingUsers((speakingUsers) => {
      const key = `${userID}`
      if (speakingUsers[key]?.isSpeaking !== false) {
        return {
          ...speakingUsers,
          [key]: { isSpeaking: false, timestamp: Date.now() },
        }
      } else {
        return speakingUsers
      }
    })
  }

  useEffect(() => {
    const localUserID = client.uid
    if (!localUserID) {
      console.warn('No local user')
      return
    }
    if (!localAudioTrack) {
      return
    }

    // Set a listener for the local user's audio track.
    // Because we're on the web and performance is likely a concern compared to native,
    // we're going to get relatively large buffers of `4096` samples at a time.
    localAudioTrack.setAudioFrameCallback((buffer) => {
      const data = buffer.getChannelData(0)
      if (isFrameBufferAboveEnergyThreshold(data)) {
        addSpeakingUser(localUserID)
      } else {
        removeSpeakingUser(localUserID)
      }
    }, AUDIO_BUFFER_SIZE)
    setTracks((tracks) => {
      tracks.set(localUserID, localAudioTrack)
      return tracks
    })
  }, [localAudioTrack, client.uid])

  useEffect(() => {
    setRemoteUsers(client.remoteUsers)

    const handleUserPublished = async (
      user: IAgoraRTCRemoteUser,
      mediaType: 'audio' | 'video'
    ) => {
      await client.subscribe(user, mediaType)

      if (mediaType == 'audio') {
        const audioTrack = user.audioTrack
        if (audioTrack) {
          // Set a listener for the remote user's audio track.
          // Because we're on the web and performance is likely a concern compared to native,
          // we're going to get relatively large buffers of `4096` samples at a time.
          audioTrack.setAudioFrameCallback((buffer) => {
            const data = buffer.getChannelData(0)
            if (isFrameBufferAboveEnergyThreshold(data)) {
              addSpeakingUser(user.uid)
            } else {
              removeSpeakingUser(user.uid)
            }
          }, AUDIO_BUFFER_SIZE)
          tracks.set(user.uid, audioTrack)
          setTracks(tracks)
        }
      }

      setRemoteUsers(Array.from(client.remoteUsers))
    }

    const handleUserUnpublished = (
      user: IAgoraRTCRemoteUser,
      mediaType: 'audio' | 'video'
    ) => {
      if (mediaType == 'audio') {
        // This (in the current version of the SDK) is ineffective, as the audio track has already been removed
        user.audioTrack?.setAudioFrameCallback(null)
        removeSpeakingUser(user.uid)
        // We store a reference to the track so that we can remove the callback
        tracks.get(user.uid)?.setAudioFrameCallback(null)
      }
      setRemoteUsers(Array.from(client.remoteUsers))
    }
    const handleUserJoined = () => {
      setRemoteUsers(Array.from(client.remoteUsers))
    }
    const handleUserLeft = (userLeft: IAgoraRTCRemoteUser) => {
      userLeft.audioTrack?.setAudioFrameCallback(null)
      setRemoteUsers(Array.from(client.remoteUsers))
    }
    // TODO: This is probably where we should actually send Segment events,
    // as it's guaranteed that it'll be called once per event. However,
    // this is dependent on fixing the GuestCallEnded redirect first (RM 5773)
    const handleConnectionStateChangePublished = (
      connectionState: ConnectionState
    ) => {
      switch (connectionState) {
        case 'CONNECTED':
          //joined a call
          break
        case 'DISCONNECTED':
          // ended a call
          // this isn't getting called, because Agora is cleaned up before
          // this event gets called/sent
          break
      }
    }
    client.on('connection-state-change', handleConnectionStateChangePublished)
    client.on('user-published', handleUserPublished)
    client.on('user-unpublished', handleUserUnpublished)
    client.on('user-joined', handleUserJoined)
    client.on('user-left', handleUserLeft)

    return () => {
      client.off(
        'connection-state-change',
        handleConnectionStateChangePublished
      )
      client.off('user-published', handleUserPublished)
      client.off('user-unpublished', handleUserUnpublished)
      client.off('user-joined', handleUserJoined)
      client.off('user-left', handleUserLeft)
    }
  }, [client, tracks])

  // handle users stopping screenshare from browser provided buttons
  useEffect(() => {
    if (!screenTrack) {
      return
    }

    screenTrack.on('track-ended', stopScreenshare)

    return () => {
      screenTrack.off('track-ended', stopScreenshare)
    }
  }, [screenTrack, stopScreenshare])

  return {
    leave,
    join,
    localUID: client.uid,
    screenTrack,
    cameraTrack,
    agoraRemoteUsers: remoteUsers,
    speakingUsers,
    toggleAudio,
    toggleVideo,
    changeAudioInputDevice,
    changeVideoInputDevice,
    startScreenshare,
    stopScreenshare,
  }
}

/**
 * The number at which point we consider the sound to be "loud" enough
 * to trigger the speaking indicator ring. This isn't `0`, because in most scenarios,
 * the microphone isn't listening to true complete silence when we aren't speaking, but rather
 * some low-level white noise. We only want to trigger the ring when it gets above a certain level.
 */
const ENERGY_THRESHOLD = 0.005 * AUDIO_BUFFER_SIZE

/**
 * Determine whether the contents of `data` is high enough energy ("loud" enough)
 * to trigger the speaking indicator ring.
 *
 * @param data A buffer containing audio data to analyze
 * @returns `true` if the buffer's energy is above `ENERGY_THRESHOLD`
 */
function isFrameBufferAboveEnergyThreshold(data: Float32Array): boolean {
  let energy = 0
  for (let i = 0; i < data.length; i++) {
    energy += Math.abs(data[i])
    if (energy >= ENERGY_THRESHOLD) {
      return true
    }
  }
  return false
}
