import React from 'react'
import { Popup } from 'semantic-ui-react'

interface Props {
  children: React.ReactNode
  content: string
  position?:
    | 'top left'
    | 'top right'
    | 'bottom right'
    | 'bottom left'
    | 'right center'
    | 'left center'
    | 'top center'
    | 'bottom center'
    | undefined
  flowing?: boolean | undefined
}

const Popover: React.FC<Props> = (props: Props) => {
  return (
    <Popup
      trigger={props.children}
      content={props.content}
      position={props.position ? props.position : 'bottom center'}
      flowing={props.flowing ?? false}
    />
  )
}

export default Popover
