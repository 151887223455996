import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Header from 'components/UI/Header'
import styles from './SetupPage.module.scss'
import Dock from './assets/dock-showcase.png'
import Dock2x from './assets/dock-showcase@2x.png'
import { functions } from 'utilities'
import { useHistory } from 'react-router'

type Showcase = {
  src: string
  alt?: string
  srcSet: string
}

/** Present the dock showcase images that feature on the
 *  left-hand side of /setup/-based pages.
 */
export const ShowcaseImage: React.FC<Showcase> = (props: Showcase) => {
  const { src, srcSet, alt = "A showcase of Multi's Dock" } = props

  return <img alt={alt} src={src} srcSet={srcSet} />
}

export const DockShowcase = <ShowcaseImage src={Dock} srcSet={`${Dock2x} 2x`} />

type SetupPageProps = React.PropsWithChildren & {
  title: string
  showcase?: React.ReactNode
}
/** Present a "Setup" page, consisting of a standard title,
 *  showcase image and main contents.
 *
 * The aim of this component is to provide a semantic wrapper for
 * standard elements on /setup/* pages (i.e. header, "left-hand" showcase, etc.)
 * without effects or asynchronous behavior.
 *
 * @example
 * <SetupContainer title="Invite your Team" showcase={<ShowcaseImage src="...png"}>
 *   <h1> Hey, you should invite your teammates!<h1>
 *   <p> Here is a link... </p
 *   ...
 * </SetupContainer>
 *
 * @param {string} title
 * @param {typeof ShowcaseImage} showcase
 * @param {React.ReactNode} children - The main "contents" of the page
 */
export const SetupPage: React.FC<SetupPageProps> = (props) => {
  // RM-7613: should Sam decide to remove product screenshots, delete the showcase aside and all corresponding styles
  // const { title, showcase = DockShowcase, children } = props

  const { title, children } = props
  const history = useHistory()

  useEffect(() => {
    const location = history.location.pathname

    if (
      location.includes('signup') ||
      location.includes('login') ||
      location.includes('join-team')
    )
      return

    functions.onboardingEvent({
      step: `Loaded ${location}`,
    })
  }, [history.location.pathname])

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header hideNav hideLogout transparent size='small' darkMode />
      <main className={styles.setupPageContainer}>
        {/* RM-7613: should Sam decide to remove product screenshots, delete the showcase aside and all corresponding styles */}
        {/* <aside className={styles.showcaseImage}>{showcase}</aside> */}
        <section className={styles.mainContents}>{children}</section>
      </main>
    </>
  )
}
