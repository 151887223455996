import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import {
  getErrorMessage,
  INVALID_EMAIL_ERROR,
  NO_ACCOUNT_ERROR,
  NO_EMAIL_ERROR,
  NO_NAME_ERROR,
} from 'components/Setup/GetStarted/getErrorMessage'
import { functions, validateEmail } from 'utilities'
import config from 'configs/generalConfig.json'

import TermsOfService from './TermsOfService'
import EmailForm from './EmailForm'
import EmailSent from './EmailSent'
import Button from 'brand/Button'
import googleLogo from 'components/UI/Icons/Logos/google-logo.svg'
import styles from '../SetupPage.module.scss'
import {
  getUnconfirmedEmail,
  setUnconfirmedEmail,
} from '../utils/UnconfirmedEmailHandler'
import {
  getInviteData,
  getUnverifiedUserFullname,
  setUnverifiedUserFullname,
} from '../utils/SetupDataHandler'
import { track, getAnonID } from 'utilities/analytics'
import { EventTypes } from 'utilities/analytics.types'
import { FirebaseError } from 'firebase/app'

enum Page {
  Default,
  EnterEmail,
  EmailSent,
}

type Props = {
  isLogin?: boolean
  header: React.ReactNode
  redirect: React.ReactNode
  continueWithGoogle: () => Promise<void>
  emailSent?: () => Promise<void>
  queryReferralID?: string
  queryAutoRedirected?: string
  error?: string
}

const GetStarted: React.FC<Props> = (props) => {
  const {
    header,
    redirect,
    continueWithGoogle,
    emailSent,
    isLogin = false,
    queryReferralID,
    queryAutoRedirected,
    error: propsError,
  } = props
  const { executeRecaptcha } = useGoogleReCaptcha()

  const unconfirmedEmail = getUnconfirmedEmail()
  const [email, setEmail] = useState(unconfirmedEmail ?? '')
  const unconfirmedName = getUnverifiedUserFullname()
  const [name, setName] = useState<string>(unconfirmedName ?? '')
  const [page, setPage] = useState<Page>(Page.Default)
  const [error, setError] = useState(propsError)

  const sendEmailLinkAttempted: React.MutableRefObject<boolean> = useRef(false)

  const sendEmailLink = useCallback(
    async (email: string) => {
      if (sendEmailLinkAttempted.current) return

      if (!isLogin && !name) {
        // on signup page, require name to proceed
        setError(NO_NAME_ERROR)
        return
      }

      if (!email) {
        setError(NO_EMAIL_ERROR)
        return
      }

      if (!validateEmail(email)) {
        setError(INVALID_EMAIL_ERROR)
        return
      }

      sendEmailLinkAttempted.current = true

      setError('')

      let recaptchaToken: string | undefined

      if (typeof executeRecaptcha !== 'undefined') {
        recaptchaToken = await executeRecaptcha('emailAuth')
      }

      const inviteData = getInviteData()

      const teamId = inviteData?.teamID
      const inviteId = inviteData?.inviteID
      const anonID = await getAnonID()

      try {
        await functions.sendEmailLink({
          email,
          domain: config.hostedDomain,
          scheme: config.scheme,
          recaptchaToken,
          isLogin,
          teamId,
          inviteId,
          unverifiedUserFullname: name ? name : undefined,
          anonymousID: anonID,
        })
        setPage(Page.EmailSent)

        if (emailSent){
          emailSent()
        }
      } catch (error) {
        console.error(`Unable to send email to ${email}`, { error })

        if ((error as FirebaseError).message === NO_ACCOUNT_ERROR) {
          setError(NO_ACCOUNT_ERROR)
        } else {
          setError('Unable to send email')
        }
      }
    },
    [executeRecaptcha, isLogin, name]
  )

  const errorMessage = getErrorMessage(error)

  const presentEmailForm = () => {
    // reset the sendEmail state because the user pressed "wrong email"
    sendEmailLinkAttempted.current = false

    setPage(Page.EnterEmail)
    try {
      if (isLogin) {
        // only track clicks from signup page
        return
      }
      track(EventTypes.signupEmailAuthClicked, {
        referral_id: queryReferralID,
        redirected: queryAutoRedirected,
      })
    } catch (error) {
      console.warn('Unable to track email auth button click')
    }
  }

  const continueWithGoogleSection = (
    <section>
      <Button fluid kind='secondary' googleBrand onClick={continueWithGoogle}>
        <img src={googleLogo} /> Continue with Google
      </Button>
    </section>
  )

  const continueWithEmailSection = (
    <Button fluid kind='secondary' onClick={presentEmailForm} darkMode>
      Continue with email
    </Button>
  )

  const emailInputSection = useMemo(() => {
    return (
      <EmailForm
        name={name}
        setName={setName}
        email={email}
        setEmail={setEmail}
        continueWithGoogle={continueWithGoogle}
        sendEmailLink={sendEmailLink}
        isLogin={isLogin}
      />
    )
  }, [continueWithGoogle, email, isLogin, name, sendEmailLink])

  const emailSentSection = (
    <EmailSent email={email} showEmailForm={presentEmailForm} />
  )

  // update unconfirmed email
  useEffect(() => {
    setUnconfirmedEmail(email)
  }, [email])

  // update unconfirmed name
  useEffect(() => {
    if (!name) {
      return
    }
    setUnverifiedUserFullname(name)
  }, [name])

  return (
    <>
      {page === Page.EnterEmail ? (
        emailInputSection
      ) : page === Page.EmailSent ? (
        emailSentSection
      ) : (
        // render default page
        <>
          {header}
          {continueWithGoogleSection}
          {continueWithEmailSection}
          <div className={styles.redirect}>
            <div>{redirect}</div>
          </div>
          <TermsOfService />
        </>
      )}

      {
        // show error message along the bottom, continue to render the email form
        errorMessage && (
          <div className={styles.errorMessage}>{errorMessage}</div>
        )
      }
    </>
  )
}

export default GetStarted
