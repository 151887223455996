import React from 'react'
import { Loader, LoaderProps, Dimmer, SemanticSIZES } from 'semantic-ui-react'

type Props = {
  children?: React.ReactNode
  active?: boolean
  inline?: boolean | 'centered'
  darkMode?: boolean // true for white background, false for black background. Defaults to true
  size?: SemanticSIZES
} & LoaderProps

export const Spinner = (props: Props) => {
  return (
    <Loader
      size={props.size ?? 'large'}
      active={props.active ?? true}
      inline={props.inline ?? false}
    >
      {props.children}
    </Loader>
  )
}

const SpinnerOverlay = (props: Props) => {
  const { active = true, size = 'large' } = props

  // confusing, but only invert when _not_ in dark mode
  const isInverted = props.darkMode ? false : true
  return (
    <Dimmer active={active} inverted={isInverted} page={true}>
      <Loader size={size} active={active}>
        {props.children}
      </Loader>
    </Dimmer>
  )
}

export default SpinnerOverlay
