import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Redirect, useHistory } from 'react-router'
import { functions } from 'utilities/firebase-utils'
import * as Sentry from 'sentry'

import { Spinner } from 'components/UI/Spinner'
import Error from '../Error'
import {
  CreateTeamData,
  ProfileData,
  getInviteData,
  getSetupData,
  getUserProfileData,
  setSetupData,
} from '../utils/SetupDataHandler'
import { SetupPage } from '../SetupPage'
import { useAuth } from 'components/AuthProvider'
import { createAccountAndJoinTeam } from '../utils/createAccountAndJoinTeam'
import { TEAM_OS_VALUES } from './TeamCreate'
import { createAccountCreateTeam } from '../utils/createAccountCreateTeam'
import { getCallLinkData } from 'utilities/callLinkData'
import {
  FeatureFlags,
  getFeatureFlag,
} from 'components/FeatureFlags/FeatureFlagsHelper'

export interface JoinableTeamData {
  id: string
  sharedDomain: string
  name: string
  description: string
}

// Note: we should track source (guest call, macOS link, etc) as is currently done
// in Onboarding/JoinTeam/JoinTeam.tsx
// we perform profile operations on the /setup/team page because it is the first page after auth
const Team: React.FC = () => {
  const history = useHistory()
  const { auth, user, logOut } = useAuth()

  const getJoinableTeamsAttempted = useRef<boolean>(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [redirectTarget, setRedirectTarget] = useState<string>()

  const signupFromCallLinkEnabled = getFeatureFlag(
    FeatureFlags.signupFromCallLink
  )
  const callLinkData = getCallLinkData()
  const skipTeamConfiguration = !!callLinkData && signupFromCallLinkEnabled

  const setProfileFullname = useCallback(() => {
    const setupData = getSetupData()
    // if name from google (displayName) is available, use that preferentially
    const fullname = auth?.displayName ?? setupData?.unverifiedUserFullname

    if (!fullname) {
      return
    }
    const newProfile: ProfileData = {
      ...setupData?.profile,
      fullname,
    }

    setSetupData({ ...setupData, profile: newProfile })
  }, [auth?.displayName])

  // set initial values and start async operations
  useEffect(() => {
    if (getJoinableTeamsAttempted.current) {
      return
    }

    setLoading(true)

    if (auth && user) {
      if (!skipTeamConfiguration) {
        setRedirectTarget('/launch')
        return
      }
    } else if (!getUserProfileData()?.authType) {
      // sign up always sets either 'email' or 'google' as authType
      // if this is not set, then either their signup is "not fresh" or they have not authed
      logOut().then(() => {
        history.push('/signup')
      })
      return
    }

    setProfileFullname()

    if (getInviteData()) {
      setLoading(false)
      setRedirectTarget('/setup/team/invite') // user has team invite link
      return
    }

    fetchJoinableTeamsOnDomain(
      skipTeamConfiguration,
      getJoinableTeamsAttempted,
      setLoading,
      setError,
      setRedirectTarget,
      skipTeamConfiguration ? callLinkData.convoID : undefined
    )
  }, [
    auth,
    callLinkData?.convoID,
    history,
    logOut,
    redirectTarget,
    setProfileFullname,
    skipTeamConfiguration,
    user,
  ])

  const retryGetJoinableTeams = () => {
    fetchJoinableTeamsOnDomain(
      skipTeamConfiguration,
      getJoinableTeamsAttempted,
      setLoading,
      setError,
      setRedirectTarget,
      skipTeamConfiguration ? callLinkData.convoID : undefined
    )
  }

  return (
    <SetupPage title='Setup a team'>
      {loading ? (
        <Spinner darkMode />
      ) : error ? (
        <Error
          headline='Something went wrong, please try again.'
          errorMsg={error}
          retryAction={retryGetJoinableTeams}
        />
      ) : redirectTarget ? (
        <Redirect to={redirectTarget} />
      ) : (
        <Spinner darkMode />
      )}
    </SetupPage>
  )
}

export default Team

async function fetchJoinableTeamsOnDomain(
  skipTeamConfiguration: boolean,
  getJoinableTeamsAttempted: React.MutableRefObject<boolean>,
  setLoading: React.Dispatch<SetStateAction<boolean>>,
  setError: React.Dispatch<SetStateAction<string | undefined>>,
  setRedirectTarget: React.Dispatch<SetStateAction<string | undefined>>,
  convoID: string | undefined
): Promise<void> {
  try {
    getJoinableTeamsAttempted.current = true
    const joinableTeamsOnDomainResponse =
      await functions.listJoinableTeamsOnDomain({ convoID })

    const joinableTeamsOnDomain: JoinableTeamData[] =
      (joinableTeamsOnDomainResponse?.joinableTeamInfos as Array<JoinableTeamData>) ??
      []

    storeJoinableTeamsAndRedirect(
      joinableTeamsOnDomain,
      skipTeamConfiguration,
      setRedirectTarget,
      setError
    )
  } catch (err) {
    setError('Unable to get joinable teams')
    Sentry.error('Unable to get joinable teams', { err })
  } finally {
    setLoading(false)
  }
}

function storeJoinableTeamsAndRedirect(
  joinableTeamsOnDomain: JoinableTeamData[],
  skipTeamConfiguration: boolean,
  setRedirectTarget: React.Dispatch<SetStateAction<string | undefined>>,
  setError: React.Dispatch<SetStateAction<string | undefined>>
) {
  if (joinableTeamsOnDomain.length <= 0) {
    // no joinable teams for user
    if (skipTeamConfiguration) {
      automaticallyJoinDomainTeamOrCreateDefault(
        [],
        setRedirectTarget,
        setError
      )
    } else {
      setRedirectTarget('/setup/team/create') // route user to team creation
    }
    return
  }

  // there are joinable teams for this user
  // order joinable teams alphabetically
  const joinableTeams = [...joinableTeamsOnDomain].sort((a, b) => {
    const aName = a.name.toLowerCase()
    const bName = b.name.toLowerCase()
    if (aName > bName) {
      return 1
    } else if (aName < bName) {
      return -1
    }
    return 0
  })

  setSetupData({ joinableTeams })

  if (skipTeamConfiguration) {
    automaticallyJoinDomainTeamOrCreateDefault(
      joinableTeams,
      setRedirectTarget,
      setError
    )
  } else {
    setRedirectTarget('/setup/team/join') // show user joinable teams through domain capture
  }
}

async function automaticallyJoinDomainTeamOrCreateDefault(
  joinableTeams: JoinableTeamData[],
  setRedirectTarget: React.Dispatch<SetStateAction<string | undefined>>,
  setError: React.Dispatch<SetStateAction<string | undefined>>
) {
  if (!joinableTeams || joinableTeams.length === 0) {
    // create default team
    const userName = getUserProfileData()?.fullname
    const teamName = userName ? `${userName}'s Team` : 'My Team'
    const team: CreateTeamData = {
      teamName: teamName,
      teamOS: TEAM_OS_VALUES[0],
      allowDomainCapture: false,
    }
    setSetupData({ team })

    try {
      await createAccountCreateTeam(true)
    } catch (error) {
      if (error !== null && 'message' in (error as ErrorEvent)) {
        setError((error as ErrorEvent).message)
      } else {
        setError("Couldn't create default team")
      }
    } finally {
      setRedirectTarget('/setup/team/post-create')
    }
  } else {
    setSetupData({ team: joinableTeams[0] })

    try {
      await createAccountAndJoinTeam(true)
    } catch (error) {
      if (error !== null && 'message' in (error as ErrorEvent)) {
        setError((error as ErrorEvent).message)
      } else {
        setError("Couldn't create join team by default")
      }
    } finally {
      setRedirectTarget('/setup/team/post-create')
    }
  }
}
