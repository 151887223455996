import React, { FunctionComponent, useEffect, useState } from 'react'
import queryString from 'query-string'
import { Redirect, useHistory, useLocation } from 'react-router'
import SpinnerOverlay from 'components/UI/Spinner'
import { sendNotification } from 'components/LocalNotification'
import { useAuth } from 'components/AuthProvider'

const TokenLink: FunctionComponent = () => {
  const history = useHistory()
  const location = useLocation()
  const [redirectInfo, setRedirectInfo] = useState({
    path: '/',
    canRedirect: false,
  })
  const { auth, isAuthInitialized, logOut, errorMessage, logInWithTokenLink } =
    useAuth()

  useEffect(() => {
    if (isAuthInitialized && !auth?.uid) {
      const query = queryString.parse(location.search)

      const tokenID = query.tokenID
      const userID = query.userID

      if (typeof tokenID === 'string' && typeof userID === 'string') {
        logInWithTokenLink(userID, tokenID)
      } else {
        history.replace('/')
      }
    } else if (auth?.uid) {
      const query = queryString.parse(location.search)
      if (
        query.userID &&
        typeof query.userID === 'string' &&
        query.userID !== auth?.uid
      ) {
        logOut()
      } else {
        if (typeof query.redirect === 'string') {
          setRedirectInfo({ path: query.redirect, canRedirect: true })
        } else {
          setRedirectInfo({ path: '/', canRedirect: true })
        }
      }
    }
  }, [
    isAuthInitialized,
    auth?.uid,
    location.search,
    logInWithTokenLink,
    history,
    logOut,
  ])

  useEffect(() => {
    if (errorMessage) {
      history.replace('/')
      sendNotification(
        'Something went wrong, please login to continue.',
        'error'
      )
    }
  }, [errorMessage, history])

  return (
    <div>
      {redirectInfo.canRedirect ? (
        <div>
          <Redirect to={{ pathname: redirectInfo.path }} />
        </div>
      ) : !isAuthInitialized ? (
        <SpinnerOverlay darkMode />
      ) : (
        <SpinnerOverlay darkMode>Signing you in...</SpinnerOverlay>
      )}
      {errorMessage && (
        <span>
          <>{errorMessage.message}</>
        </span>
      )}
    </div>
  )
}

export default TokenLink
