import React, {
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import * as Sentry from 'sentry'
import { functions } from 'utilities/firebase-utils'
import {
  getAvatarColorFromAppUserID,
  getTextColorFromAvatarColor,
} from '../utils/avatarGeneratorUtils'
import {
  getTeamJoinedData,
  getUserProfileData,
  removeAllSetupData,
  uploadAndSetAvatar,
} from '../utils/SetupDataHandler'

import styles from '../SetupPage.module.scss'
import { getInitials } from 'components/Public/GuestCall/guest-call-utils'
import { useAuth } from 'components/AuthProvider'
import { isProbablyMobile } from 'utilities/browserUtil'
import { SetupPage } from '../SetupPage'
import SpinnerOverlay from 'components/UI/Spinner'
import { Redirect, useLocation } from 'react-router'

type LocationState = {
  from?: string
}

const isLocationState = (expectedLocationState: unknown): expectedLocationState is LocationState => {
  const locationState = expectedLocationState as LocationState
  return typeof locationState === 'object'
  && (typeof locationState.from === 'undefined'
  || typeof locationState.from === 'string')
}

export const TeamPostAccountCreation: React.FC = () => {
  const { auth, user } = useAuth()

  const [generatedAvatar, setGeneratedAvatar] = useState<string>()
  const [generateAvatarError, setGenerateAvatarError] = useState<string>()
  const [redirectUrl, setRedirectUrl] = useState<string>()

  const avatarUploadAttempted = useRef<boolean>(false)
  const downloadEmailAttempted = useRef<boolean>(false)

  const [asyncAvatarOpsResolved, setAsyncAvatarOpsResolved] = useState(false)
  const [asyncDownloadOpsResolved, setAsyncDownloadOpsResolved] =
    useState(false)

  const fullName = useMemo(() => {
    return (user?.firstName ?? '') + ' ' + (user?.lastName ?? '')
  }, [user?.firstName, user?.lastName])

  // init conditional avatar upload
  useEffect(() => {
    if (!auth || !user) {
      return
    }

    if (avatarUploadAttempted.current) {
      return
    }

    try {
      const teamJoinedData = getTeamJoinedData()
      if (!teamJoinedData) {
        throw new Error('No team joined data, unable to upload avatar')
      }

      if (generateAvatarError) {
        throw new Error(generateAvatarError)
      }

      // default to google photo (auth.photoURL) where available
      const uploadableImageData = auth?.photoURL ?? generatedAvatar

      if (uploadableImageData) {
        avatarUploadAttempted.current = true
        const { teamID, userID } = teamJoinedData
        uploadAvatar(
          teamID,
          userID,
          uploadableImageData,
          setAsyncAvatarOpsResolved
        )
      }
    } catch (error) {
      Sentry.warning('Failed to upload avatar', { error })

      // resolve async task on failure (allow redirect to proceed)
      setAsyncAvatarOpsResolved(true)
    }
  }, [auth, generateAvatarError, generatedAvatar, user])

  // init conditional email download link (for mobile users only)
  useEffect(() => {
    if (!auth || !user) {
      return
    }

    if (downloadEmailAttempted.current) {
      return
    }

    try {
      if (isProbablyMobile()) {
        downloadEmailAttempted.current = true
        sendDownloadEmail(setAsyncDownloadOpsResolved)
      } else {
        // desktop users who don't need to be emailed a link
        downloadEmailAttempted.current = true
        setAsyncDownloadOpsResolved(true)
      }
    } catch (error) {
      Sentry.warning('Failed to send download link email', { error })

      // resolve async task on failure (allow redirect to proceed)
      setAsyncDownloadOpsResolved(true)
    }
  }, [auth, user])

  const location = useLocation()

  const from = useMemo(() => {
    return isLocationState(location.state) && location.state.from
  }, [location.state])

  // on resolved async operations, decide redirect path
  useEffect(() => {
    if (!auth || !user) {
      return
    }

    if (!asyncAvatarOpsResolved || !asyncDownloadOpsResolved) {
      return
    }

    const profileData = getUserProfileData()
    if (profileData?.authSource === 'conferenceaddon') {
      // from google calendar add on
      cleanupAndRedirect('/setup/close-auth-popup')
    } else if (isProbablyMobile() || from === '/setup/team/create') {
      // mobile users skip download step because they have a download link emailed to them
      // team creators skip download step because they have auto-download from the create button
      cleanupAndRedirect(`/setup/invite`)
    } else {
      // default case
      cleanupAndRedirect('/setup/download')
    }

    function cleanupAndRedirect(url: string) {
      removeAllSetupData()
      setRedirectUrl(url)
    }
  }, [
    asyncAvatarOpsResolved,
    asyncDownloadOpsResolved,
    auth,
    user,
    from
  ])

  return (
    <SetupPage title='A few finishing touches...'>
      <SpinnerOverlay
        darkMode
        active={!asyncAvatarOpsResolved || !asyncDownloadOpsResolved}
      >
        {!asyncAvatarOpsResolved
          ? 'Uploading avatar...'
          : !asyncDownloadOpsResolved
          ? 'Sending macOS download link to your email...'
          : 'A few finishing touches...'}
      </SpinnerOverlay>
      {fullName && user?.ID && (
        <AvatarCanvas
          name={fullName}
          userID={user.ID}
          setAvatar={setGeneratedAvatar}
          setError={setGenerateAvatarError}
        />
      )}
      {redirectUrl && <Redirect to={redirectUrl} />}
    </SetupPage>
  )
}

type AvatarCanvasProps = {
  name: string // user fullname
  userID: string // app user ID
  setAvatar: React.Dispatch<React.SetStateAction<string | undefined>>
  setError: React.Dispatch<React.SetStateAction<string | undefined>>
}

const AvatarCanvas: React.FC<AvatarCanvasProps> = (props) => {
  const { name, userID, setAvatar, setError } = props
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const imageSize = 360 // image height and width in px

  const initials = useMemo(() => {
    return getInitials(name).toUpperCase()
  }, [name])

  const avatarColor = useMemo(() => {
    return getAvatarColorFromAppUserID(userID)
  }, [userID])

  useEffect(() => {
    // attempt to draw avatar image on canvas
    try {
      const context = canvasRef.current?.getContext('2d')

      if (!context) {
        return
      }

      // image background
      context.fillStyle = avatarColor
      context.fillRect(0, 0, imageSize, imageSize)

      // initals on top of image
      // light colors get black as the text color
      context.fillStyle = getTextColorFromAvatarColor(avatarColor)
      context.font = '500 150px Inter'
      context.textAlign = 'center'
      context.textBaseline = 'middle'

      // offset from top in px, since it was experimentally determined with this specific font it tends to go higher than the real centerline
      const offset = 10

      context.fillText(initials, imageSize / 2, imageSize / 2 + offset)
    } catch (error) {
      Sentry.warning('unable to generate avatar image', { userID, name, error })
      setError('unable to generate avatar image')
      return
    }

    // check if the generated avatar image is all black and set error in this case
    try {
      const context = canvasRef.current?.getContext('2d')

      if (!context) {
        return
      }

      if (isCanvasProbablyBlackImage(context, imageSize)) {
        Sentry.warning('generated avatar image is all black', { userID, name })
        setError('generated avatar image is all black')
        return
      }
    } catch (error) {
      Sentry.warning('error checking generated avatar image for black canvas', {
        userID,
        name,
        error,
      })
      setError('error checking generated avatar image for black canvas')
      return
    }

    // store generated avatar as avatar image
    try {
      const dataUrl = canvasRef.current?.toDataURL()

      if (dataUrl) {
        setAvatar(dataUrl)
      } else {
        Sentry.warning('unable to get data url for generated avatar', {
          userID,
          name,
        })
        setError('unable to get dataUrl for generated avatar')
      }
    } catch (error) {
      Sentry.warning('unable to convert generated image to data: url', {
        userID,
        name,
        error,
      })
      setError('unable to convert generated image to data: url')
    }
  }, [avatarColor, initials, name, setAvatar, setError, userID])

  return (
    <div className={styles.avatarWrapper}>
      <canvas ref={canvasRef} height={imageSize} width={imageSize} />
    </div>
  )
}

export default TeamPostAccountCreation

/**
 * It was seem in testing that occasionally, the generated avatar would be an all black image
 * This function checks for whether the image is probably all black so that an error can be thrown
 * and avatar upload can be stopped.
 * @param context canvas 2D rendering context
 * @param imageSize size of the canvas (assuming square canvas)
 * @returns boolean representing whether the canvas is likely an all black image
 */
function isCanvasProbablyBlackImage(
  context: CanvasRenderingContext2D,
  imageSize: number
): boolean {
  // array in [r,b,g,a...] value order for each pixel of the canvas
  const imageData = context.getImageData(0, 0, imageSize, imageSize).data

  // get max rgb value appearing in the canvas
  const maxColorValue = imageData.reduce((accumulator, current, index) => {
    if ((index + 1) % 4 === 0) {
      // don't count every 4th array element as it is an opacity value (a in rbga)
      return accumulator
    }

    return Math.max(accumulator, current)
  })

  // hex #222222 is equivalent to rbg(34, 34, 34), a dark grey
  // if the maxColorValue is less than this, it's very likely we have an all black canvas
  const darkGreyValue = 34
  const canvasProbablyBlack = maxColorValue < darkGreyValue

  return canvasProbablyBlack
}

/**
 *
 * @param teamID team ID
 * @param userID app user ID
 * @param uploadableImageData data: string of a png image generated from AvatarCanvas component or google avatar https: string
 * @param setResolved optional parameter to mark async operation as resolved in React component with state variable
 */
async function uploadAvatar(
  teamID: string,
  userID: string,
  uploadableImageData: string,
  setResolved?: React.Dispatch<SetStateAction<boolean>>
): Promise<void> {
  try {
    await uploadAndSetAvatar(teamID, userID, uploadableImageData)
  } catch (error) {
    Sentry.warning('TeamPostAccountCreation: uploadAvatar failed', { error })
  } finally {
    if (setResolved) {
      setResolved(true)
    }
  }
}

/**
 *
 * @param setResolved optional parameter to mark async operation as resolved in React component with state variable
 */
async function sendDownloadEmail(
  setResolved?: React.Dispatch<SetStateAction<boolean>>
): Promise<void> {
  try {
    await functions.sendEmailDownloadLink()
  } catch (error) {
    Sentry.warning('TeamPostAccountCreation: sendEmailDownloadLink failed', {
      error,
    })
  } finally {
    if (setResolved) {
      setResolved(true)
    }
  }
}
